// https://ui.docs.amplify.aws/react/theming/default-theme/colors
import { Theme } from '@aws-amplify/ui-react';
import { I18n } from "aws-amplify";
import { Translations } from "@aws-amplify/ui-components";

export const Color = {
  blue: "#002846",
  transparentblue: '#33526c',
  lightblue: '#007cba',
  orange: "#ff7800",
  lightorange: '#ff9930',
  green: "#49b700",
  darkgreen: "#30900c",
  transparentgreen: '#abb7a3',
  red: "#b20000",
  lightred: '#f5bcbc',
  darkred: '#660000',
  lightgrey: '#777',
  darkgrey: '#474747',
  transparentgrey: '#e2d9d9'
};

export const zptheme: Theme = {
  name: 'zptheme',
  tokens: {
    colors: {
      font: {
        primary: { value: Color.blue },
        secondary: { value: Color.transparentblue },
        tertiary: { value: Color.transparentblue },
        disabled: { value: Color.lightgrey },
        inverse: { value: 'white' },
        interactive: { value: Color.transparentblue },
        hover: { value: Color.orange },
        focus: { value: Color.blue },
        active: { value: Color.blue },
        info: { value: Color.blue },
        warning: { value: Color.orange },
        error: { value: Color.red },
        success: { value: Color.green }
      },
      background: {
        info: { value: Color.transparentblue },
        warning: { value: Color.orange },
        error: { value: Color.red },
        success: { value: Color.green },
        disabled: { value: Color.transparentgrey }
      },
      border: {
        primary: { value: Color.blue },
        secondary: { value: Color.transparentblue },
        tertiary: { value: Color.darkgrey },
        disabled: { value: Color.transparentgrey },
        pressed: { value: Color.blue },
        focus: { value: Color.blue },
        error: { value: Color.red }
      },
      brand: {
        primary: {
          10: { value: Color.transparentgrey },
          20: { value: Color.transparentgrey },
          40: { value: Color.lightgrey },
          60: { value: Color.darkgrey },
          80: { value: Color.darkgrey },
          90: { value: Color.darkgrey },
          100: { value: Color.darkgrey },
        },
      },
    },
    components: {
      searchfield: {
        button: {
          color: { value: `${ Color.blue }` },
          _focus: {
            backgroundColor: {
              value: 'white',
            },
          },
          _hover: {
            backgroundColor: {
              value: 'white',
            },
          },
        },
      },
      checkbox: {
        button: {
          color: { value: Color.green },
          _focus: {
            outlineColor: { value: Color.lightblue },
            borderColor: { value: Color.red },
          },
          _disabled: {
            borderColor: { value: Color.transparentgrey }
          },
          before: {
            borderColor: { value: Color.red },
          },
        },
        icon: {
          backgroundColor: { value: Color.lightgrey },
        },
        _disabled: {
          cursor: {
            value: 'not-allowed',
          },
        },
      },
      button: {
        fontWeight: { value: '{fontWeights.thin}' },
        primary: {
          backgroundColor: { value: Color.green },
          _hover: {
            backgroundColor: { value: Color.darkgreen },
          },
          _focus: {
            backgroundColor: { value: Color.darkgreen },
          },
          _active: {
            backgroundColor: { value: Color.darkgreen },
          },
          _disabled: {
            color: { value: 'white' },
            backgroundColor: {value: Color.transparentgrey}
          },
        },
      },
      alert: {
        backgroundColor: { value: '{colors.teal.20}' },
        icon: {
          size: { value: '{fontSizes.xxxl}' },
        },
        heading: {
          fontSize: { value: '{fontSizes.large}' },
          fontWeight: { value: '{fontWeights.normal}' },
        },
        info: {
          color: { value: 'white' },
          backgroundColor: { value: Color.blue },
        },
        success: {
          color: { value: Color.blue },
          backgroundColor: { value: Color.transparentgreen },
        },
        error: {
          color: { value: Color.darkred },
          backgroundColor: { value: Color.lightred },
        },
      },
    },
  },
};

export const loginFormFields = {
  signIn: {
    username: {
      placeholder: 'E-Mail Adresse',
    },
    password: {
      placeholder: 'Passwort'
    }
  },
  forceNewPassword: {
    password: {
      labelHidden: true,
      placeholder: 'neues Passwort',
    },
  },
  resetPassword: {
    username: {
      placeholder: 'Deine E-Mail Adresse',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      labelHidden: true,
      placeholder: 'Gib deinen Bestätigungscode ein:',
      isRequired: false,
    },
    confirm_password: {
      labelHidden: true,
      placeholder: 'Passwort',
    },
  },
};


I18n.putVocabulariesForLanguage("de-DE", {
  [Translations.FORGOT_PASSWORD_TEXT]: 'Passwort vergessen?',
  [Translations.BACK_TO_SIGN_IN]: 'Zurück zum Login',
  [Translations.CHANGE_PASSWORD_ACTION]: 'Ändern',
  [Translations.CHANGE_PASSWORD]: 'Passwort ändern',
  [Translations.CODE_LABEL]: 'Bestätigungscode',
  [Translations.CODE_PLACEHOLDER]: 'code eingeben',
  [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: 'Bestätigungscode',
  [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: 'code eingeben',
  [Translations.CONFIRM_SMS_CODE]: 'SMS Code bestätigen',
  [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: 'Code erneut senden',
  [Translations.USERNAME_PLACEHOLDER]: 'Gib deine E-Mail Adresse ein',
  [Translations.CONFIRM]: 'Bestätigen',
  [Translations.EMAIL_LABEL]: 'Email Addresse *',
  [Translations.EMAIL_PLACEHOLDER]: 'Gib deine E-Mail Adresse ein',
  [Translations.NEW_PASSWORD_LABEL]: 'Neues Passwort',
  [Translations.NEW_PASSWORD_PLACEHOLDER]: 'Gib dein neues Passwort ein',
  [Translations.NO_ACCOUNT_TEXT]: 'Noch kein Benutzer?',
  [Translations.PASSWORD_REMOVE_WHITESPACE]: 'Das Passwort darf nicht mit einem Leerzeichen beginnen oder enden',
  [Translations.PASSWORD_LABEL]: 'Passwort *',
  [Translations.PASSWORD_PLACEHOLDER]: 'Gib dein Passwort ein',
  [Translations.PHONE_LABEL]: 'Telefonnummer *',
  [Translations.RESET_PASSWORD_TEXT]: 'Passwort zurück setzen',
  [Translations.RESET_YOUR_PASSWORD]: 'Passwort zurück setzen',
  [Translations.SEND_CODE]: 'Code senden',
  [Translations.SUBMIT]: 'senden',
  [Translations.SIGN_IN_ACTION]: 'Log In',
  [Translations.SIGN_IN_HEADER_TEXT]: 'Anmelden',
  [Translations.SIGN_IN_TEXT]: 'Log In',
  [Translations.SIGN_OUT]: 'Log Out',
  [Translations.SIGN_UP_EMAIL_PLACEHOLDER]: 'E-Mail',
  [Translations.SIGN_UP_PASSWORD_PLACEHOLDER]: 'Passwort',
  [Translations.USERNAME_LABEL]: 'Benutzername *',
  [Translations.EMPTY_USERNAME]: 'E-Mail Adresse darf nicht leer sein',
  [Translations.EMPTY_PASSWORD]: 'Passwort darf nicht leer sein',
  [Translations.DEFAULT_MSG]: 'Es ist ein Fehler aufgetreten',
  [Translations.EMPTY_CODE]: 'Code darf nicht leer sein',
});

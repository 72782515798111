import { SelectField } from '@aws-amplify/ui-react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { useAppDispatch } from '../../store/hooks'
import { filterByProductName } from '../../store/itemsSlice'

function FilterByProductNameSelectField() {
    const { items, filters } = useSelector((state: RootState) => state.itemsState)
    const dispatch = useAppDispatch()

    const uniqueProductNames = items.map(item => item.name).filter((value, index, self) => self.indexOf(value) === index)
    uniqueProductNames.sort()

    return <SelectField
        label={'product-name'}
        labelHidden={true}
        width={'20rem'}
        value={filters.productName}
        onChange={(e) => dispatch(filterByProductName((e.target.value)))}
        id='product-name-select-field' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}    >
        <option value=''>Alle Produkte</option>
        {uniqueProductNames.map((name, index) => {
            return <option key={index} value={name}>{name}</option>
        })}
        {/* if page has changed and the product doesn't exist on this page we keep the previously choosen filter*/}
        {!uniqueProductNames.includes(filters.productName) && <option value={filters.productName}>{filters.productName}</option>}
    </SelectField>
}

export default FilterByProductNameSelectField
import { Button, ScrollView, ThemeProvider, withAuthenticator } from '@aws-amplify/ui-react';
import { Color, zptheme } from '../theme';
import { API, graphqlOperation } from 'aws-amplify';
import { Contractor, ModelSortDirection, OrderState, OrderStatus, Product, ProductStatus, UpdateOrderMutationVariables } from '../API';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../store/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { fetchOngoingItemsByContractorByStatusAsync, fetchOngoingItemsByStatusAsync, resetItemsState, toggleMinimize, updateItemAsync } from '../store/itemsSlice';
import StickyHeader from '../components/header/StickyHeader';
import { FilterByContractorSelecField } from '../components/header/FilterByContractorSelectField';
import { HiCloudDownload, HiPrinter } from 'react-icons/hi';
import ToolTip from '../components/helper-components/ToolTip';
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi';
import OrderItemsCard from '../components/order/OrderItemsCard';
import Modal from '../components/helper-components/Modal';
import { LabelMessage, createDocument, resetSelectedOrderIds, setDocumentError, setLoading } from '../store/printDocumentsSlice';
import { createLabelMessages } from '../utils/create-labeldata';
import { updateOrderAsync } from '../store/itemsSlice';
import { getOrder } from '../graphql/queries';
import FilterByProductNameSelectField from '../components/header/FilterByProductNameSelectField';
import SearchOrderField from '../components/header/SearchOrderField';
import ShipmentsRow from '../components/shipment/ShipmentsRow';
import { exportOrdersToCSV, getNextTask, statusName } from '../utils';
import { GrDocumentCsv } from 'react-icons/gr';
import { fetchFutureToursAsync, resetTourState } from '../store/tourSlice';

// page for items in status new
function NewItemsPage({ user }: any) {
    const dispatch = useAppDispatch()
    const { items, filters, nextToken, minimize, isLoading } = useSelector((state: RootState) => state.itemsState)
    const documentsLoading = useSelector((state: RootState) => state.documentState.isLoading)
    const { permittedContractors } = useSelector((state: RootState) => state.userState)
    const { selectedOrderIds } = useSelector((state: RootState) => state.documentState)
    const [smallWindow, setSmallWindow] = useState(window.innerWidth <= 1000)
    const pageStatus = ProductStatus.new

    function filterItems(item: Product) {
        return ((item.name === filters.productName || !filters.productName) &&
            (item.orderNumber?.startsWith(filters.orderNumber) || !filters.orderNumber))
    }
    const filteredItems = items.filter(filterItems)
    const uniqueOrderId = [...new Set(filteredItems.map(item => item?.productOrderId))];

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1000) {
                setSmallWindow(true)
            } else {
                setSmallWindow(false)
            }
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        fetchItems(null)
        return () => {
            dispatch(resetItemsState())
        }
    }, [filters.contractor])

    function fetchItems(nextToken: string | null) {
        // filter should always be set on mounting app but if it is not yet we don't want to fetch any items
        if (filters.contractor) {
            if (filters.contractor === 'Alle') {
                // user filter for permitted contractors
                const filter = {
                    or: permittedContractors.map((c: Contractor) => { return { productContractorId: { eq: c.id } } })
                }
                dispatch(fetchOngoingItemsByStatusAsync({ status: pageStatus, nextToken, sortDirection: ModelSortDirection.ASC, filter }))
            } else {
                dispatch(fetchOngoingItemsByContractorByStatusAsync({ contractor_status: `${ filters.contractor }-${ pageStatus }`, nextToken, sortDirection: ModelSortDirection.ASC }))
            }
        }
    }

    // fetch tours in case the shipping method is changed to 'Ausfahrt durch Zuschnittprofi' and the itemsCard is maximized here
    // cannot fetch it in the tourSelectField because the tours will be loaded multiple times if there is more than 1 item
    useEffect(() => {
        dispatch(fetchFutureToursAsync({ nextToken: null }))
        return () => {
            dispatch(resetTourState())
        }
    }, [])

    async function onClickPrintAllLabels() {
        if (filters.contractor === 'Alle') {
            return dispatch(setDocumentError('Wähle erst aus für welchen Standort die Labels gedruckt werden sollen'))
        }
        dispatch(setLoading(true))
        let messages: LabelMessage[] = []
        let newOrders: string[] = []
        // orderIds for which the items should be printed
        const orderIds = selectedOrderIds && selectedOrderIds.length > 0 ? selectedOrderIds : uniqueOrderId
        // items in state for selected or new orders, additionally filtered by selected filters on page
        const itemsToPrint = items.filter(i => i.order && i.order.state !== OrderState.importing && orderIds.includes(i.order?.id)).filter(filterItems)
        if (itemsToPrint.length < 1) {
            return dispatch(setDocumentError('Es gibt keinen neuen Aufträge.'))
        }
        for (const item of itemsToPrint) {
            const response = await API.graphql(graphqlOperation(getOrder, { id: item.order?.id }))
            if (item.order?.status === OrderStatus.new) {
                newOrders.push(item.order?.id)
            }
            //@ts-ignore
            const allOrderItems = response.data.getOrder.products.items.filter((i: Product) => i.productContractorId === filters.contractor)
            // create Label Message for each item allone to update the status directly after creating the labelmessage
            const data = await createLabelMessages([item], allOrderItems)
            messages = messages.concat(data.messages)
            // updata product status to in_progress
            if (item.status === ProductStatus.new) {
                const nextTask = getNextTask(item)
                const variables = {
                    input: {
                        id: item.id,
                        status: ProductStatus.in_progress,
                        contractor_status: `${ item.productContractorId }-${ ProductStatus.in_progress }`,
                        in_progressAt: new Date().toISOString(),
                        currentTask: nextTask || null,
                        contractor_currentTask: nextTask ? `${ item.productContractorId }-${ nextTask }` : null
                    }
                }
                await dispatch(updateItemAsync(variables))
            }
        }
        if (messages.length < 1) {
            return dispatch(setDocumentError('Es konnten keine Labels erstellt werden.'))
        }
        const docResponse = await dispatch(createDocument({ data: { messages }, outputName: 'labels.pdf', templateName: 'samples/Produktion_Allgemein.docx' }))
        // @ts-ignore
        if (docResponse.payload.statusCode === 200) {
            // update OrderStatus to in_progress if no item is in status new
            for (const orderId of newOrders) {
                const orderRespoonse = await API.graphql(graphqlOperation(getOrder, { id: orderId }))
                // @ts-ignore
                const order = orderRespoonse.data.getOrder
                // also update orderstatus if the status is new and all items are in status in_progress now
                if (order.status === OrderStatus.new && order.products.items.every((i: Product) => i.status !== ProductStatus.new)) {
                    const variables = {
                        input: {
                            id: orderId,
                            status: OrderStatus.in_progress,
                            in_progressAt: new Date().toISOString()
                        }
                    } as unknown as UpdateOrderMutationVariables
                    await dispatch(updateOrderAsync(variables))
                }
            }
            dispatch(resetSelectedOrderIds())
        } else {
            dispatch(setDocumentError('Beim Erstellen der Labels ist etwas schief gelaufen.'))
        }
        setLoading(false)
    }

    return (
        <ThemeProvider theme={zptheme}>
            <StickyHeader>
                <div style={{ display: 'flex', gap: '1em', flexWrap: 'wrap' }}>
                    <div style={{ display: 'flex', gap: '1em', flex: 1, flexWrap: 'wrap' }}>
                        <SearchOrderField pageStatus={pageStatus} onClear={() => fetchItems(nextToken)} />
                        <FilterByProductNameSelectField />
                        <FilterByContractorSelecField />
                        <div style={{ display: 'flex', gap: '1em' }}>
                            {filters.contractor !== 'Alle' &&
                                <Modal
                                    triggerButton={
                                        <div>
                                            <ToolTip tipText={'Produktionslabel drucken'}>
                                                <Button
                                                    minHeight={'2.5rem'}
                                                    maxWidth={'20rem'}
                                                    isLoading={documentsLoading} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                                    {selectedOrderIds.length > 0 ? <div style={{ marginRight: '0.2em', color: Color.lightblue, fontWeight: 'bold' }}>{selectedOrderIds.length}</div> : <HiPrinter />}
                                                    {!smallWindow && 'Produktionslabel drucken'}
                                                </Button>
                                            </ToolTip>
                                        </div>
                                    }
                                    action={onClickPrintAllLabels}
                                >
                                    {selectedOrderIds && selectedOrderIds.length > 0 ?
                                        <div>Möchtest du die Labels für {selectedOrderIds.length} Aufträge drucken?</div> :
                                        <div>
                                            <div>Möchtest du wirklich die Produktionslabels für alle neuen Aufträge für den ausgewählten Fertiger drucken? </div>
                                            <div>Die Aktion lässt sich nicht rückgängig machen</div>
                                        </div>}
                                </Modal>
                            }
                            <ToolTip tipText={'Aufträge als csv Datei speichern'}>
                                <Button
                                    style={{ fontSize: 'large' }}
                                    onClick={() => exportOrdersToCSV(filteredItems, statusName(pageStatus))} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                    <GrDocumentCsv />
                                </Button>
                            </ToolTip>
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        {nextToken &&
                            <ToolTip tipText={'weitere Aufträge laden'}>
                                <div>
                                    <Button
                                        minHeight={'2.5rem'}
                                        variation='primary'
                                        isDisabled={isLoading}
                                        onClick={() => fetchItems(nextToken)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                        <HiCloudDownload />
                                    </Button>
                                </div>
                            </ToolTip>}
                        <div>
                            <Button
                                minHeight={'2.5rem'}
                                style={{ marginRight: '1rem', marginLeft: '1em' }}
                                onClick={() => dispatch(toggleMinimize())} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                            >
                                {minimize ?
                                    <FiMaximize2 /> :
                                    <FiMinimize2 />
                                }
                            </Button>
                        </div>
                    </div>
                </div>
            </StickyHeader>
            <ShipmentsRow />
            <ScrollView style={{ margin: '1rem', display: 'flex', flexDirection: 'column', gap: '1em' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                {uniqueOrderId.map(orderId => <OrderItemsCard key={orderId} orderId={orderId}></OrderItemsCard>)}
            </ScrollView>
        </ThemeProvider>
    )
}

export default withAuthenticator(NewItemsPage);


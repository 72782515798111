import styled from "styled-components";
import Favicon from "../assets/favicon_transparent.png";

const OverlayBox = styled.div`
position: fixed; /* Sit on top of the page content */
width: 100%; /* Full width (cover the whole page) */
height: 100%; /* Full height (cover the whole page) */
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
`;

const FlipBox = styled.div`
background-color: transparent;
perspective: 1000px;
`;

const FlipBoxInner = styled.div`
text-align: center;
transition: transform 0.8s;
transform-style: preserve-3d;
animation: spin 3s infinite linear;
@keyframes spin {
  from {
    transform: rotateY(360deg) ;
  }
}
`;

function Spinner() {

  return (
    <OverlayBox
      id='spinner'>
      <FlipBox>
        <FlipBoxInner>
          <img src={Favicon} style={{ width: "100px", margin: '30%' }} />
        </FlipBoxInner>
      </FlipBox>
    </OverlayBox>
  );
}
export default Spinner;

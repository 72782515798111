import { Alert, Button, Heading, ScrollView, Table, TableBody, TableCell, TableHead, TableRow, TextField, ThemeProvider } from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';
import { HiPencil } from 'react-icons/hi';
import { IoAddCircleOutline, IoClose } from 'react-icons/io5';
import { MdDone } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { CreateContractorInput, CreateContractorMutationVariables, Order, OrderState, OrderStatus, ProductStatus, UpdateContractorInput } from '../API';
import { StickyDiv } from "../components/helper-components";
import HeaderMenu from "../components/header/HeaderMenu";
import { RootState } from '../store';
import { createContractorAsync, fetchContractorsAsync, updateContractorAsync } from '../store/configSlice';
import { useAppDispatch } from '../store/hooks';
import { Color, zptheme } from '../theme';
import { createOrder } from '../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { createItemAsync } from '../store/itemsSlice';
import { FileInputLine, convertCsvToJson, convertGermanDate, getdeliveryDate, mandatoryFields, optionalFields } from '../utils/order-import';
import { ShippingMethods, numbersChar, specialChar } from '../utils/Constants';
import ToolTipPopup from '../components/helper-components/ToolTipPopup';

function ConfigPage() {
  const dispatch = useAppDispatch()

  const { contractors, error } = useSelector((state: RootState) => state.configState)
  const sortedContractor = [...contractors].sort((a, b) => (a.number > b.number) ? 1 : ((b.number > a.number) ? -1 : 0))

  const [editable, setEditable] = useState(false)
  const [ctrErrorMessage, setCtrErrorMessage] = useState('')
  const [fileErrorMessage, setFileErrorMessage] = useState<string | undefined>(undefined)
  const [newContractor, setNewContractor] = useState<CreateContractorInput | undefined>(undefined)
  const [contractorFormData, setContractorFormData] = useState<UpdateContractorInput[]>([])
  const [inputData, setInputData] = useState<FileInputLine[] | undefined>(undefined);

  useEffect(() => {
    dispatch(fetchContractorsAsync())
  }, [])

  useEffect(() => {
    if (newContractor && specialChar.test(newContractor.name)) {
      setCtrErrorMessage('nur Buchstaben und - erlaubt')
    }
    if (contractors.find(el => el.name === newContractor?.name?.toUpperCase())) {
      setCtrErrorMessage('existiert bereits')
    }
  }, [newContractor])

  async function createContractor() {
    if (ctrErrorMessage) {
      return
    }
    const variables = {
      input: { ...newContractor }
    } as CreateContractorMutationVariables
    dispatch(createContractorAsync(variables))
    setCtrErrorMessage('')
    setNewContractor(undefined)
  }

  async function updateContractor(contractorVariables: UpdateContractorInput) {
    if (ctrErrorMessage) {
      return
    }
    const variables = {
      input: { ...contractorVariables }
    }
    dispatch(updateContractorAsync(variables))
  }

  function checkName(contractorName: string) {
    if (specialChar.test(contractorName)) {
      return 'nur Buchstaben und - erlaubt'
    }
    if (contractors.find(el => el.name === contractorName.toUpperCase())) {
      return 'existiert bereits'
    }
    return ''
  }

  function checkNumber(contractorNumber: string) {
    if (contractors.find(el => el.number === contractorNumber)) {
      return 'existiert bereits'
    }
    if (!contractorNumber.match(/^[F]{1}/)) {
      return 'Fertigernummer muss mit F beginnen'
    }
    if (!contractorNumber.slice(1).match(numbersChar)) {
      return 'Fertigernummer muss mit F beginnen und 3 Ziffern enthalten'
    }
    if (contractorNumber.length > 4) {
      return 'Fertigernummer muss 4 Zeichen lang sein'
    }
    return ''
  }

  async function createOrders() {
    const createdOrders: Order[] = []
    let errorOnCreation = ''
    if (!inputData) {
      return
    }
    for (const line of inputData) {
      if (line.Pos === '1') {
        let order_delivery_date = new Date(getdeliveryDate(line))
        let weight = 0
        const itemsOfOrder = inputData.filter(el => el.Auftragsnummer === line.Auftragsnummer)
        for (const item of itemsOfOrder) {
          const itemDeliveryDate = new Date(getdeliveryDate(item))
          if (itemDeliveryDate > order_delivery_date) {
            order_delivery_date = itemDeliveryDate
          }
          weight += parseFloat(item.Gewicht)
        }
        const variables = {
          input: {
            orderNumber: line.Auftragsnummer,
            date_created: new Date(convertGermanDate(line.Auftragseingang)),
            date_paid: line.Bezahlt_am ? new Date(convertGermanDate(line.Bezahlt_am)).toISOString() : new Date(convertGermanDate(line.Auftragseingang)).toISOString(),
            total: parseFloat(line.Gesamtsumme),
            total_tax: parseFloat(line.MwSt_Gesamt),
            cart_tax: line.MwSt_Gesamtwarenwert ? parseFloat(line.MwSt_Gesamtwarenwert) : undefined,
            discount_total: line.Rabatt ? parseFloat(line.Rabatt) : undefined,
            discount_tax: line.MwSt_Rabatt ? parseFloat(line.MwSt_Rabatt) : undefined,
            shipping_total: parseFloat(line.Nettoversandkosten),
            shipping_tax: parseFloat(line.MwSt_Versandkosten),
            customer: {
              first_name: line.Vorname,
              last_name: line.Nachname,
              company: line.Firma || undefined,
              street: line.Straße,
              house_number: line.Hausnummer,
              add: line.Adresszusatz || undefined,
              city: line.Ort,
              zip: line.PLZ,
              country: line.Land,
              phone: line.Telefonnummer || undefined,
              email: line.E_Mail || undefined,
            },
            shipping_method: line.Versandmethode || ShippingMethods.VERSAND,
            delivery_date: order_delivery_date.toISOString(),
            weight,
            status: OrderStatus.new,
            state: OrderState.unfinished,
            source: line.Quelle,
          }
        }
        // create order and save id in an array with the ordernumbers for additional positions of this order
        const newOrderRes = await API.graphql(graphqlOperation(createOrder, variables))
        // @ts-ignore
        const newOrder = newOrderRes.data.createOrder
        createdOrders.push(newOrder)
      }
      const orderId = createdOrders.find(el => el.orderNumber === line.Auftragsnummer)?.id
      if (!orderId) {
        errorOnCreation += `Auftrag ${ line.Auftragsnummer } konnte nicht erstellt werden.`
        continue
      }

      const productContractorId = contractors.find(el => el.number === line.Fertiger)?.id || contractors.find(el => el.name === 'NIPSILD')?.id
      const tasks = line.Aufgaben ? line.Aufgaben.split(',') : line.Fertiger === 'F008' || line.Fertiger === 'F009' ? [] : ['zuschneiden']
      const status = tasks.length === 0 ? ProductStatus.ready_to_pack : ProductStatus.new
      const productVariables = {
        input: {
          name: line.Produktname,
          sku: line.Artikelnummer,
          product_id: line.Produkt_ID || undefined,
          status,
          // contractor-status is nessecary to find the orders by ordernumber
          contractor_status: `${ productContractorId }-${ status }`,
          orderNumber: line.Auftragsnummer,
          orderProductsId: orderId,
          productOrderId: orderId,
          quantity: parseFloat(line.Menge),
          length: line.Länge ? parseFloat(line.Länge) : undefined,
          width: line.Breite ? parseFloat(line.Breite) : undefined,
          height: line.Höhe ? parseFloat(line.Höhe) : undefined,
          area: line.Länge && line.Breite ? (parseFloat(line.Länge) * parseFloat(line.Breite)) : undefined,
          weight: line.Gewicht,
          categories: line.Kategorien ? line.Kategorien.split(',') : undefined,
          variations: line.Variationen ? line.Variationen.split(',') : undefined,
          tasks,
          productContractorId,
          total_before_discount: line.Gesamtsumme_vor_Rabatt ? parseFloat(line.Gesamtsumme_vor_Rabatt) : parseFloat(line.Nettopreis),
          total_net_price: parseFloat(line.Nettopreis),
          total_tax: parseFloat(line.MwSt),
          delivery_time: line.Lieferzeit || undefined,
          delivery_date: getdeliveryDate(line),
          backoffice_note: line.Hinweis || undefined,
        }
      }
      const productRes = await dispatch(createItemAsync(productVariables))
    }
    if (errorOnCreation) {
      setFileErrorMessage(errorOnCreation)
    }
    setInputData(undefined)
    const inputField = document.getElementById('order-file-import')
    if (inputField) {
      // @ts-ignore
      inputField.value = ''
    }
    if (!errorOnCreation) {
      // navigate to orders page
      window.location.href = '/orders'
    }
  }

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        //@ts-ignore
        const content = e.target.result;
        const jsonData = convertCsvToJson(content);
        setInputData(jsonData.data);
        if (jsonData.error) {
          setFileErrorMessage(jsonData.error)
        }
      };
      reader.readAsText(file);
    }
  };

  function downloadTemplate() {
    let csvContent = 'data:text/csv;charset=utf-8,';
    // mandatory fields and optional fields as headers
    let headers = mandatoryFields.join('*;') + ';';
    headers += optionalFields.join(';');
    csvContent += headers + '\r\n';
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'Importvorlage.csv');
    document.body.appendChild(link); // Required for FF
    link.click();
  }

  const renderOrderTable = () => {
    if (!inputData || inputData.length === 0) {
      return <></>;
    }

    const headers = Object.keys(inputData[0]);

    return (
      <Table
        caption=""
        highlightOnHover={true}
        style={{ alignSelf: 'center', marginTop: '1em', marginBottom: '1em' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <TableHead placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <TableRow placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            {headers.map((header, index) => (
              <TableCell key={index} as="th" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {inputData.map((dataRow, rowIndex) => (
            <TableRow key={rowIndex} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              {headers.map((header, colIndex) => (
                <TableCell key={colIndex} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                  {/* @ts-ignore */}
                  {dataRow[header]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <ThemeProvider theme={zptheme}>
      <StickyDiv>
        <HeaderMenu />
        {error && <Alert
          variation='error'
          isDismissible={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {error}
        </Alert>}
      </StickyDiv>
      <ScrollView placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
        <div style={{ padding: '1em' }}>
          <Heading
            level={4} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}          >
            Fertiger
          </Heading>
          <Table caption=""
          highlightOnHover={true}
          size="small"
          style={{ width: '50%' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <TableHead placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <TableRow placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <TableCell as="th" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Nummer</TableCell>
                <TableCell as="th" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Name</TableCell>
                <TableCell as="th" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Abrechnungssatz</TableCell>
                <TableCell as="th" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Maximalbetrag</TableCell>
                <TableCell placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              </TableRow>
            </TableHead>
            <TableBody placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              {contractors && sortedContractor.map(contractor =>
                <TableRow key={contractor.id} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <TableCell placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                  <ToolTipPopup tipText={contractor.id} trigger={
                    <div>{contractor.number}</div>} />
                  </TableCell>
                  {contractorFormData.find(el => el.id === contractor.id) ?
                    <TableCell placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                      <TextField
                        style={{ height: '1.5em', maxWidth: '9em' }}
                        size='small'
                        variation='quiet'
                        label={undefined}
                        defaultValue={contractorFormData.find(el => el.id === contractor.id)?.name || contractor.name}
                        onChange={(e: any) => {
                          setCtrErrorMessage(checkName(e.target.value))
                          const currentFormData = contractorFormData.find(el => el.id === contractor.id)
                          if (currentFormData) {
                            currentFormData.name = e.currentTarget.value
                          }
                          if (!currentFormData) {
                            setContractorFormData([...contractorFormData, { id: contractor.id, name: e.currentTarget.value }])
                          }
                        }}
                      ></TextField>
                    </TableCell> :
                    <TableCell placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                      {contractor.name}
                    </TableCell>}
                  {contractorFormData.find(el => el.id === contractor.id) ?
                    <TableCell placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                      <TextField
                        style={{ height: '1.5em', maxWidth: '6em' }}
                        size='small'
                        variation='quiet'
                        type='number'
                        label={undefined}
                        defaultValue={contractorFormData.find(el => el.id === contractor.id)?.billing_rate || contractor.billing_rate || ''}
                        onChange={(e: any) => {
                          const currentFormData = contractorFormData.find(el => el.id === contractor.id)
                          if (currentFormData) {
                            currentFormData.billing_rate = e.currentTarget.value
                          }
                          if (!currentFormData) {
                            setContractorFormData([...contractorFormData, { id: contractor.id, billing_rate: e.currentTarget.value }])
                          }
                        }}
                      />
                    </TableCell> :
                    <TableCell placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                      {contractor.billing_rate} %
                    </TableCell>}
                  {contractorFormData.find(el => el.id === contractor.id) ?
                    <TableCell placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                      <TextField
                        style={{ height: '1.5em', maxWidth: '6em' }}
                        size='small'
                        type='number'
                        variation='quiet'
                        label={'billing_max'}
                        labelHidden={true}
                        defaultValue={contractorFormData.find(el => el.id === contractor.id)?.billing_max || contractor.billing_max || ''}
                        onChange={(e: any) => {
                          const currentFormData = contractorFormData.find(el => el.id === contractor.id)
                          if (currentFormData) {
                            currentFormData.billing_max = parseFloat(e.currentTarget.value)
                          }
                          if (!currentFormData) {
                            setContractorFormData([...contractorFormData, { id: contractor.id, billing_max: parseFloat(e.currentTarget.value) }])
                          }
                        }}
                      />
                    </TableCell> :
                    <TableCell placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                      {contractor.billing_max ? `${ contractor.billing_max } €` : 'unbegrenzt'}
                    </TableCell>}
                  <TableCell placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    {contractorFormData.find(el => el.id === contractor.id) ?
                      <div style={{ display: 'inline-block', width: '5em', maxWidth: '5em' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          {!ctrErrorMessage && <Button
                            style={{ backgroundColor: 'transparent' }}
                            size={'small'}
                            variation='link'
                            onClick={() => {
                              const currentFormData = contractorFormData.find(el => el.id === contractor.id);
                              if (currentFormData) {
                                currentFormData.name = currentFormData.name?.toUpperCase();
                                currentFormData.billing_max = Number.isNaN(currentFormData.billing_max) ? null : currentFormData.billing_max;
                                updateContractor(currentFormData);
                                setContractorFormData(contractorFormData.filter(el => el.id !== currentFormData.id));
                              }
                            } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <MdDone
                            />
                          </Button>}
                          <Button
                            style={{ backgroundColor: 'transparent' }}
                            size={'small'}
                            variation='link'
                            onClick={() => {
                              setCtrErrorMessage('');
                              const currentFormData = contractorFormData.find(el => el.id === contractor.id);
                              if (currentFormData) {
                                setContractorFormData(contractorFormData.filter(el => el.id !== currentFormData.id));
                              }
                            } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <IoClose />
                          </Button>
                        </div>
                      </div>
                      :
                      <div style={{ display: 'inline-block', width: '5em', maxWidth: '5em' }}>
                        <Button
                          style={{ backgroundColor: 'transparent' }}
                          size={'small'}
                          variation='link'
                          onClick={() => {
                            setContractorFormData([...contractorFormData, { id: contractor.id }]);
                          } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                          <HiPencil />
                        </Button>
                      </div>
                    }
                  </TableCell>
                </TableRow>
              )}
              {editable &&
                <TableRow placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <TableCell placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <TextField
                      size='small'
                      variation='quiet'
                      label={undefined}
                      defaultValue={newContractor?.number || ''}
                      onChange={(e: any) => {
                        setCtrErrorMessage(checkNumber(e.target.value))
                        setNewContractor({ ...newContractor, name: newContractor?.name || '', number: e.currentTarget.value })
                      }}
                    ></TextField>
                  </TableCell>
                  <TableCell placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <TextField
                      size='small'
                      variation='quiet'
                      label={undefined}
                      defaultValue={newContractor?.name || ''}
                      onChange={(e: any) => {
                        setCtrErrorMessage(checkName(e.target.value))
                        setNewContractor({ ...newContractor, number: newContractor?.number || '', name: e.currentTarget.value.toUpperCase() })
                      }}
                    ></TextField>
                  </TableCell>
                  <TableCell placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <TextField
                      size='small'
                      variation='quiet'
                      type='number'
                      label={undefined}
                      defaultValue={newContractor?.billing_rate || ''}
                      onChange={(e: any) => {
                        setNewContractor({ ...newContractor, number: newContractor?.number || '', name: newContractor?.name || '', billing_rate: e.currentTarget.value })
                      }}
                    ></TextField>
                  </TableCell>
                  <TableCell placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <TextField
                      size='small'
                      variation='quiet'
                      type='number'
                      label={undefined}
                      defaultValue={newContractor?.billing_max || ''}
                      onChange={(e: any) => {  
                        setNewContractor({ ...newContractor, number: newContractor?.number || '', name: newContractor?.name || '', billing_max: e.currentTarget.value })
                      }}
                    ></TextField>
                  </TableCell>
                  <TableCell placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {!ctrErrorMessage && newContractor?.name && newContractor?.number && <Button
                        style={{ backgroundColor: 'transparent' }}
                        size={'small'}
                        variation='link'
                        onClick={() => {
                          createContractor();
                          setEditable(false);
                        } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <MdDone
                        />
                      </Button>}
                      <Button
                        style={{ backgroundColor: 'transparent' }}
                        size={'small'}
                        variation='link'
                        onClick={() => {
                          setCtrErrorMessage('');
                          setEditable(false);
                          setNewContractor(undefined);
                        } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <IoClose />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>}
            </TableBody>
          </Table>
          <div style={{ height: 5 }} />
          {ctrErrorMessage && <Alert
            variation='error' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            {ctrErrorMessage}
          </Alert>}
          {!editable &&
            <div
              style={{ display: 'flex', flexDirection: 'row', color: Color.orange, cursor: 'pointer' }}
              onClick={() => setEditable(true)}>
              <div style={{ padding: '0.5em' }}>
                <IoAddCircleOutline />
              </div>
              <div style={{ padding: '0.5em' }}>Fertiger hinzufügen</div>
            </div>
          }
          <div style={{ display: 'flex', gap: '5em' }}>
            <div><Heading level={4} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Auftragsimport</Heading></div>
            <input
              style={{ color: Color.blue }}
              id="order-file-import"
              type="file"
              accept=".csv"
              multiple
              onClick={(e) => {
                // remove file to make sure that changes from the same file are recognized
                e.currentTarget.value = ''
                setFileErrorMessage(undefined)
              }}
              onChange={handleFileChange} />
          </div>
          <div style={{ marginTop: '1em', marginBottom: '1em' }}>
            Hinweise:
            <li>In der Vorlage sind die Pflichtfelder mit * gekennzeichnet.</li>
            <li>Listen (wie z.B. Aufgaben) müssen durch Komma getrennt werden (z.B zuschneiden, kanten, entgraten).</li>
            <li> Zahlen müssen mit Punkt getrennt werden (z.B. 1.5). Die Spaltenüberschriften dürfen nicht verändert werden.</li>
            <li>In der Spalte Fertiger muss eine der oben aufgeführten Fertigernummern stehen.</li>
          </div>
          {renderOrderTable()}
          {fileErrorMessage && <Alert
            variation='error' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            {fileErrorMessage.split('\n').map((line, index) => <div key={index}>{line}</div>)}
          </Alert>}
          <Button
            style={{ marginRight: '1em' }}
            onClick={downloadTemplate}
            size='small' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            Vorlage herunterladen
          </Button>
          <Button
            variation='primary'
            size='small'
            isDisabled={!inputData || inputData.length === 0 || fileErrorMessage !== undefined}
            onClick={createOrders} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}          >
            Aufträge importieren
          </Button>
        </div>

      </ScrollView>
    </ThemeProvider>
  );
}

export default ConfigPage;

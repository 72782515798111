export function getArticleNumber(variation_id: string | null | undefined, contractor_number: string) {
    switch (contractor_number) {
        case 'F004':
            return f004articelNumbers(variation_id)
        default:
            return variation_id
    }
}

function f004articelNumbers(variation_id: string | null | undefined) {
    switch (variation_id) {
        case '9217':
            return '150001'
        case '9218':
            return '150002'
        case '9219':
            return '150264'
        case '538504':
            return '104796'
        case '538505':
            return '100031'
        case '538506':
            return '100033'
        case '9222':
            return '103445'
        case '9223':
            return '100046'
        case '9224':
            return '104923'
        case '538509':
            return '100049'
        case '17894':
            return '150076'
        case '17895':
            return '150080'
        case '17896':
            return '150763'
        case '539222':
            return '151131'
        case '539223':
            return '150110'
        case '539224':
            return '150115'
        case '539225':
            return '150116'
        case '17887':
            return '100302'
        case '17888':
            return '100307'
        case '17889':
            return '100311'
        case '538541':
            return '100314'
        case '539212':
            return '100361'
        case '539213':
            return '100365'
        case '539214':
            return '100370'
        case '539215':
            return '100372'
        case '541574':
            return '250767'
        case '548080':
            return '106554'
        case '541485':
            return '250048'
        case '541486':
            return '250049'
        case '551316':
            return '800081'
        case '3186':
            return '800429'
        case '3187':
            return '800822'
        case '2706':
            return '800124'
        case '2707':
            return '800013'
        case '2708':
            return '800018'
        case '2723':
            return '150016'
        case '17461':
            return '150691'
        case '538513':
            return '150548'
        case '538519':
            return '100106'
        case '538520':
            return '100112'
        case '538521':
            return '100116'
        case '538522':
            return '100117'
        case '538515':
            return '101274'
        case '2721':
            return '100130'
        case '17463':
            return '100135'
        case '17462':
            return '100141'
        case '538514':
            return '100143'
        case '9203':
            return '150040'
        case '9204':
            return '150042'
        case '9205':
            return '151112'
        case '9154':
            return '100217'
        case '9193':
            return '100219'
        case '9192':
            return '100222'
        case '538542':
            return '100621'
        case '539229':
            return '150565'
        case '539230':
            return '150253'
        case '539231':
            return '150843'
        case '539232':
            return '152679'
        case '541571':
            return '251186'
        case '541567':
            return '250019'
        case '2711':
            return '800031'
        case '2712':
            return '800032'
        case '2631':
            return '250195'
        case '2632':
            return '250002'
        case '539217':
            return '100235'
        case '539218':
            return '100236'
        case '539219':
            return '100237'
        case '539220':
            return '100240'
        default:
            return variation_id
    }
}
import { configureStore } from '@reduxjs/toolkit'
import wooReducer from './woocommerceSlice'
import labelReducer from './printDocumentsSlice'
import configReducer from './configSlice'
import shippingReducer from './shippingSlice'
import shippingLabelReducer from './shippingLabelSlice'
import userReducer from './userSlice'
import itemsReducer from './itemsSlice'
import tourReducer from './tourSlice'
import mailReducer from './mailSlice'
import deskReducer from './deskSlice'

export const store = configureStore({
  reducer: {
    itemsState: itemsReducer,
    wooState: wooReducer,
    documentState: labelReducer,
    configState: configReducer,
    shippingState: shippingReducer,
    shippingLabelState: shippingLabelReducer,
    userState: userReducer,
    tourState: tourReducer,
    mailState: mailReducer,
    deskState: deskReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};
export const customOrdersByShippingMethod = /* GraphQL */ `query CustomOrdersByShippingMethod(
    $shipping_method: String!
    $state: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByShippingMethod(
      shipping_method: $shipping_method
      state: $state
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        products {
            items {
              id
              position
              typename
              name
              sku
              product_id
              variation_id
              quantity
              length
              width
              height
              area
              weight
              categories
              variations
              options
              extraOptions {
                name
                value
                imagesp
                price
                __typename
              }
              tasks
              currentTask
              contractor_currentTask
              productContractorId
              contractor {
                id
                number
                name
                billing_rate
                billing_max
                createdAt
                updatedAt
                __typename
              }
              status
              contractor_status
              shipping_label_printed
              orderNumber
              order {
                id
                typename
                orderNumber
                date_created
                date_paid
                total
                cart_tax
                total_tax
                discount_total
                discount_tax
                shipping_total
                shipping_tax
                fees {
                  name
                  amount
                  total
                  total_tax
                  __typename
                }
                weight
                customer {
                  first_name
                  last_name
                  street
                  house_number
                  zip
                  city
                  country
                  email
                  phone
                  company
                  add
                  __typename
                }
                shipping_method
                delivery_date
                status
                state
                source
                in_progressAt
                finishedAt
                updatedAt
                retoure_shipping_costs
                createdAt
                __typename
              }
              productOrderId
              backoffice_note
              total_before_discount
              total_net_price
              total_tax
              nipsild_fixed_costs
              delivery_time
              delivery_date
              shipment {
                id
                typename
                length
                width
                height
                gurtMass
                weight
                serviceProvider
                costs
                calculatedCosts
                shippedAt
                edited
                parcelNumber
                trackID
                suggest_splitting
                overrule_splitting_user
                labelData {
                  base64
                  format
                  __typename
                }
                updatedAt
                createdAt
                __typename
              }
              tour {
                id
                typename
                date
                products {
                  nextToken
                  __typename
                }
                deliveryNotificationSentTo
                info
                driver
                createdAt
                updatedAt
                __typename
              }
              declinedTourDates
              billed_amount
              in_progressAt
              ready_to_packAt
              finishedAt
              updatedAt
              billedAt
              recievedReturnAt
              reklaOrderNumber
              retoure_type
              retoure_reason
              retoure_manufacturing_fee
              retoure_refund_amount
              retoure_refund_contractors_share
              retoure_shipment {
                id
                typename
                length
               width
               height
               gurtMass
               weight
               serviceProvider
               costs
               calculatedCosts
               shippedAt
               edited
               parcelNumber
               trackID
               suggest_splitting
               overrule_splitting_user
               labelData {
                 base64
                 format
                 __typename
               }
               updatedAt
               createdAt
               __typename
             }
             createdAt
             orderProductsId
             tourProductsId
             productShipmentId
             productRetoure_shipmentId
             __typename
           }
          nextToken
          __typename
        }
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
  ` as GeneratedQuery<
    APITypes.OrdersByShippingMethodQueryVariables,
    APITypes.OrdersByShippingMethodQuery
  >;
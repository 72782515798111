import { useState } from 'react';
import { MdOutlineRadioButtonUnchecked } from 'react-icons/md';
import { RxCrossCircled } from 'react-icons/rx';
import styled from "styled-components";
import { Color } from '../../theme';

export const HeaderDiv = styled.div`
  background-color: white;
  height: 5rem;
  width: 100%;
  flex-direction: row;
  display: flex;
  border-bottom: solid ${ Color.blue };
  color: ${ Color.blue };
`;

export const StickyDiv = styled.div`
  position: sticky; 
  top: 0;
  background-color: 'white';
  z-index: 1;
`

export const OrderCardDiv = styled.div`
    border: solid ${ Color.blue };
    border-radius: 0.5em; 
    /* margin: 1rem; */
    padding: 0.2em;
    color: ${ Color.blue };
    width: '100vw';
`

export const ShipmentsDiv = styled.div`
    border: solid ${ Color.blue };
    border-radius: 0.5em; 
    color: ${ Color.blue };
    width: 20rem;
`

export const OrderStatusContainer = styled.div`
    margin-left: 1em;
    margin-right: 1em;
    display: flex; 
    flex-direction: row; 
    gap: 0.2em;
    color: ${ Color.blue };
`
export const OrderInfos = styled.div`
    gap: 1em;
    display: flex; 
    flex-direction: row; 
    color: ${ Color.blue };
`
export const OrderNumber = styled.div`
    margin-left: 1%;
`
export const OrderItems = styled.div`
    display: flex; 
    flex-direction: column;
    margin-right: 1em;
`
export const Sidebar = styled.div`
height: 100%; /* 100% Full-height */
width: 0; /* 0 width - change this with JavaScript */
position: fixed; /* Stay in place */
z-index: 1; /* Stay on top */
background-color: rgba(226, 217, 217, 0.95); 
top: 0;
left: 0;
overflow-x: hidden; /* Disable horizontal scroll */
transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
`
export function NumberSelectIcon(props?: any) {
  const [hover, setHover] = useState(false)
  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...props}
      style={{ display: 'grid', alignItems: 'center' }}
      >
      {hover ? <RxCrossCircled style={{color: Color.lightgrey}}/> : <MdOutlineRadioButtonUnchecked  />}
    </div>
  )
}
import { Product, ProductStatus, RetoureType } from '../API';
import { ShippingMethods } from './Constants';
import { LabelMessage } from '../store/printDocumentsSlice';

export async function createLabelMessages(products: Product[], allItemsOfSameContractor: Product[]) {
    const messages: LabelMessage[] = []
    let data = {
        messages
    }
    for (const product of products) {
        if (!product.contractor) {
            console.log('für dieses Produkt ist der Fertiger nicht gesetzt: ', product.name, '.')
            continue
        }
        if (product.status === ProductStatus.cancelled || product.status === ProductStatus.billed || product.status === ProductStatus.packing_confirmed) {
            continue
        }
        const shippingSymbol = product.order?.shipping_method?.includes('Abholung') ? 'A' : product.order?.shipping_method === ShippingMethods.EIGENAUSFAHRT ? 'ZP' : 'P'
        const productOptions = product.options && product.options.length > 0 ? product.options : product.extraOptions && product.extraOptions.map(o => o?.name ? `${ o?.name } ${ o?.value }` : `${ o?.value }`)

        let tasks = product.tasks

        // 3 rows on each label under length and width, option4 stands next to one and is optional option5 stands next to 3 and is optional
        let option1 = ''; let option4 = '';
        let option2 = '';
        let option3 = ''; let option5 = '';

        // option1 Ölen, Entgraten, Ecken abrunden
        if (tasks?.includes('ölen')) {
            option1 = 'Ölen: Klar '
            if (productOptions?.find(el => el?.includes('Dunkelbraun'))) option1 = 'Ölen: braun '
            if (productOptions?.find(el => el?.includes('Gekälkt'))) option1 = 'Ölen: Gekälkt '
        }
        if (product.product_id === '110859') option1 += '   Abstandshalter'

        if (tasks?.includes('entgraten')) option1 = 'Entgraten '
        if (productOptions?.find(el => el?.includes('Alle Ecken abrunden'))) option1 += 'Ecken abr. '

        // option2 Bohren, Fräsen, Schleifen, Kantenform, Umrandung
        if (tasks?.includes('bohren')) option2 = 'Bohren'
        // for now option2 will be overwritten if bohren AND schleifen exists and that is okay because bohren is for metall and schleifen is for wood
        if (tasks?.includes('fräsen')) option2 = 'fräsen    '
        const polishToDo = tasks?.find(el => el === 'schleifen' || el === 'bürsten')
        if (polishToDo) option2 += `${ polishToDo } `
        if (productOptions?.find(el => el?.includes('Abgerundete Kanten Rundum auf der Sichtseite'))) {
            option2 += '\u2463'
        }
        if (productOptions?.find(el => el?.includes('Abgerundete Kanten Rundum auf beiden Seiten'))) {
            option2 += '\u2467'
        }
        if (productOptions?.find(el => el?.includes('Mit umlaufendem Rand'))) {
            option2 += '\u2B1C'
        }

        // option3 Lamellen, Baumkante, Füllen, Kanten, Schweißen
        if (tasks?.includes('Baumkante schleifen')) {
            option3 = '1 BK \u2193'
            if (productOptions?.find(el => el?.includes('Baumkante an beiden Seiten der Länge, handbearbeitet'))) {
                option3 = '2 BK \u2193 \u2191'
            }
        }
        if (productOptions?.find(el => el?.includes('Lamellen entlang der kürzeren Seite') || el?.includes('Richtung  Entlang der kürzeren Seite'))) {
            option3 += ' Lam. kurz S.'
        }
        if (productOptions?.find(el => el?.includes('Lamellen entlang der längeren Seite') || el?.includes('Richtung  Entlang der längeren Seite'))) {
            option3 += ' Lam. lang S.'
        }
        if (productOptions?.find(el => el?.includes('Astlöcher und Spalten auf der Sichtseite verfüllen')) || product.tasks?.includes('Astlöcher einseitig verfüllen')) {
            option3 += ' F1'
        }
        if (productOptions?.find(el => el?.includes('Astlöcher und Spalten beidseitig verfüllen')) || product.tasks?.includes('Astlöcher beidseitig verfüllen')) {
            option3 += ' F2'
        }
        if (tasks?.includes('abkanten')) option3 = 'Kanten'
        if (tasks?.includes('schweißen')) option3 += ' Schweißen'

        //option4 Schliff / Ausschnitt, Schrägschnitt, Überstand
        if (productOptions?.find(el => el?.includes('Schliff an der Länge entlang'))) option4 = 'Schliff: Länge'
        if (productOptions?.find(el => el?.includes('Schliff an der Breite entlang'))) option4 = 'Schliff: Breite'
        if (productOptions?.find(el => el?.includes('Ausschnitte') && !el?.includes('Ausschnitte Kein Ausschnitt'))) option4 = 'mit Ausschnitt'
        if (productOptions?.find(el => el?.includes('Schrägschnitt') && !el?.includes('Kein Schrägschnitt'))) option4 = 'mit Schrägschnitt'
        if (productOptions?.find(el => el?.includes('Überstand') && !el?.includes('Ohne seitlichen Überstand'))) option4 = 'mit Überstand'

        //option5 Aufdopplung
        const optionsWithoutSchliff = productOptions?.filter(el => !el?.includes('Rundum Geschliffene Oberfläche & Kanten'))
        if (optionsWithoutSchliff?.includes('Rundum')) {
            option5 = ' \u2B1C'
        }
        if (optionsWithoutSchliff?.includes('An einer Seite der Länge')) {
            option5 = '__'
        }
        if (optionsWithoutSchliff?.includes('An einer Seite der Breite')) {
            option5 = '|'
        }
        if (optionsWithoutSchliff?.includes('An beiden Seiten der Länge')) {
            option5 = '='
        }
        if (optionsWithoutSchliff?.includes('An beiden Seiten der Breite')) {
            option5 = '||'
        }
        if (optionsWithoutSchliff?.includes('L-Form (eine Seite der Länge, linke Seite der Breite)')) {
            option5 = '|__'
        }
        if (optionsWithoutSchliff?.includes('L-Form (eine Seite der Länge, rechte Seite der Breite)')) {
            option5 = '__|'
        }
        if (optionsWithoutSchliff?.includes('U-Form (eine Länge, beide Seiten der Breite)')) {
            option5 = '\u2294'
        }
        if (optionsWithoutSchliff?.includes('U-Form (eine Breite, beide Seiten der Länge)')) {
            option5 = '\u228F'
        }

        const quantity = product.quantity ? product.quantity : 1
        let allProductsCount = 0
        allItemsOfSameContractor.forEach((product: Product) => allProductsCount += product.quantity ? product.quantity * 1 : 1)
        const currentProduct = allItemsOfSameContractor.find((el: Product) => el.id === product.id)
        const position = currentProduct ? allItemsOfSameContractor.indexOf(currentProduct) + 1 : 'na'

        let warning = ''

        // print only backoffice note on label (without exclamation) for touch up rekla items
        if (product.retoure_type && product.retoure_type === RetoureType.touch_up) {
            option1 = product.backoffice_note ? product.backoffice_note : 'Nachbesserung'
            option2 = ''
            option3 = ''
            option4 = ''
            option5 = ''
        }
        if (product.backoffice_note) warning = '\u26A0'

        // when option1 and option2 are empty option5 will appear at the edge of the label and is not visible when printed, therefore we print a space
        for (let count = 1; count <= quantity; count++) {
            data.messages.push(
                {
                    material: `${ product.name } (${ shippingSymbol })`,
                    //Beispiel msg: 533569-23Tl-1v1-21/23
                    msg: `${ warning }${ product.orderNumber }-${ allProductsCount }Tl-${ position }v${ allItemsOfSameContractor.length }-${ count }/${ quantity }`,
                    laenge: product.length,
                    breite: product.width,
                    option1: !option1 ? ' ' : option1,
                    option2: !option2 ? ' ' : option2,
                    option3,
                    option4,
                    option5
                }
            )
        }
    };
    return data
}
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Contractor } from '../API';

interface UserState {
    infoMsg?: string
    errorMsg?: string
    email: string
    usergroups: string[]
    permittedContractors?: Contractor[] 
}

const initialUserState = {
    errorMsg: '',
    infoMsg: '',
    email: '',
    usergroups: [''],
    permittedContractors: [] as Contractor[]
}

export const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        setInfoMsg: (state, action: PayloadAction<string>) => {
            state.infoMsg = action.payload
        },
        setErrorMsg: (state, action: PayloadAction<string>) => {
            state.errorMsg = action.payload
        },
        resetUserState: (state) => {
            state.email = ''
            state.usergroups = ['']
            state.permittedContractors = [] as Contractor[]
        },
        setUserInfos: (state, action: PayloadAction<UserState>) => {
            state.email = action.payload.email
            state.usergroups = action.payload.usergroups
            state.permittedContractors = action.payload.permittedContractors || [] as Contractor[]
        }
    }
})

export const { setUserInfos, resetUserState, setInfoMsg, setErrorMsg } = userSlice.actions

export default userSlice.reducer
// Zone 1 20, 21, 22
// Zone 2 19, 23, 27, 28
// Zone 3 18, 24, 25, 30, 31, 32, 33, 39
// Zone 4 26, 34, 37, 38, 48, 49, 58, 59
// Zone 5 04, 06, 07, 29, 42, 44, 45, 46, 47, 98, 99
// Zone 6 01, 08, 09, 35, 36, 95, 96, 97
// Zone 7 02, 17, 40, 41, 50, 51, 53, 56, 57, 60, 61, 63, 64, 65
// Zone 8 10, 12, 13, 14, 15, 16, 90, 91
// Zone 9 03, 52, 54, 55, 66, 67, 68, 69, 70, 71, 73, 74, 75, 76, 92, 93
// Zone 10 72, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 94

// max Kg   Zone 1      Zone 2      Zone 3      Zone 4      Zone 5      Zone 6      Zone 7      Zone 8      Zone 9      Zone 10 
// 50 kg    36,57 €     40,65 €     41,15 €     41,18 €     41,22 €     41,28 €     41,29 €     42,17 €     42,19 €     42,55 €
// 100 kg   43,21 €     46,95 €     47,62 €     47,67 €     47,69 €     47,73 €     47,98 €     48,98 €     49,17 €     49,64 €
// 150 kg   53,93 €     61,07 €     61,95 €     62,00 €     62,06 €     62,17 €     62,43 €     62,68 €     62,94 €     62,96 €
// 200 kg   64,54 €     76,13 €     76,81 €     76,84 €     76,88 €     76,91 €     76,99 €     77,89 €     78,00 €     78,76 €
// 250 kg   72,05 €     85,77 €     86,94 €     87,00 €     87,02 €     87,09 €     87,20 €     88,89 €     89,26 €     89,41 €
// 300 kg   81,50 €     96,31 €     97,72 €     97,75 €     97,79 €     97,84 €     97,91 €     99,66 €     99,75 €     100,80 €
// 350 kg   88,31 €     105,64 €    107,27 €    107,31 €    107,38 €    107,47 €    107,60 €    109,19 €    109,27 €    111,23 €
// 400 kg   97,99 €     117,44 €    119,29 €    119,33 €    119,38 €    119,47 €    119,51 €    121,30 €    121,52 €    123,99 €
// 450 kg   106,00 €    127,48 €    129,54 €    129,61 €    129,71 €    129,80 €    129,82 €    131,58 €    131,64 €    135,06 €
// 500 kg   112,55 €    135,79 €    138,10 €    138,14 €    138,23 €    138,36 €    138,50 €    140,13 €    140,61 €    144,51 €
// 600 kg   116,79 €    142,25 €    144,84 €    144,89 €    145,00 €    145,11 €    145,25 €    145,98 €    147,41 €    151,97 €
// 700 kg   124,66 €    153,28 €    156,38 €    157,06 €    157,14 €    157,26 €    157,32 €    157,90 €    160,71 €    166,44 €
// 800 kg   132,22 €    163,88 €    167,49 €    168,62 €    168,82 €    168,94 €    169,06 €    169,15 €    172,98 €    179,75 €
// 900 kg   140,51 €    175,40 €    179,46 €    181,11 €    181,22 €    181,29 €    181,37 €    184,98 €    186,07 €    193,86 €
// 1.000 kg 148,72 €    187,21 €    192,11 €    194,24 €    194,29 €    194,80 €    194,90 €    197,86 €    199,81 €    208,64 €
// 1.250 kg 163,16 €    207,18 €    213,03 €    215,97 €    216,15 €    217,32 €    217,39 €    219,09 €    222,68 €    233,33 €
// 1.500 kg 178,23 €    229,69 €    236,81 €    241,21 €    241,36 €    243,89 €    243,97 €    244,11 €    249,46 €    262,82 €
// 1.750 kg 199,29 €    258,90 €    267,42 €    273,32 €    273,75 €    277,71 €    277,84 €    275,57 €    284,65 €    300,90 €
// 2.000 kg 215,50 €    282,68 €    292,59 €    300,21 €    301,41 €    306,53 €    306,58 €    302,47 €    314,65 €    333,86 €
// 2.250 kg 230,47 €    306,65 €    318,15 €    326,87 €    328,34 €    334,42 €    334,82 €    329,35 €    343,81 €    365,71 €
// 2.500 kg 236,35 €    318,99 €    331,68 €    342,47 €    344,93 €    352,25 €    352,52 €    343,81 €    362,19 €    387,16 €
// 3.000 kg 242,37 €    329,65 €    342,82 €    353,85 €    355,43 €    362,25 €    362,58 €    348,09 €    368,07 €    393,99 €

export function calculateShippingCostsBursped(length: number, width: number, height: number, weight: number, zip: string) {
    // Bursped Lademeter = length * width / 2,4
    // Wenn die Grundabmessungen eines Packstückes 0,8 Lademeter überschreiten, werden alle Packstücke der Sendung nach Lademeter
    // berechnet. In allen anderen Fällen erfolgt die Abrechnung im Stückgutversand aus Basis der Kubikmeter.
    // Diese Faktoren werden mit der Abrechnungsbasis multipliziert und ergeben das Volumengewicht, welches mit dem Effektivgewicht
    // verglichen wird. Zur Berechnung gelangt immer das höhere der beiden Gewichte.
    const lademeter = length / 100 * width / 100 / 2.4
    const kubikmeter = length / 100 * width / 100 * height / 100
    const volumeweight = lademeter <= 0.8 ? kubikmeter * 150 : lademeter * 1000
    const baseWeight = weight > 30 ? Math.max(weight, volumeweight) : weight
    const baseprice = burspedBasePrice(baseWeight, zip)
    let oversizeCharge = 0
    let mautzuschlag = 3.4

    if (weight < 30) {
        if (lademeter > 0.8) {
            oversizeCharge = 45
        }
        if (lademeter > 1.2) {
            oversizeCharge = 65
        }
    }

    //Mautzuschlag calculated for weights within 1 - 600 (common weights) with kilometers within 701-800
    if (weight >= 1 && weight <= 50) {
        mautzuschlag = 4.67
    }
    if (weight >= 51 && weight <= 100) {
        mautzuschlag = 6.02
    }
    if (weight >= 101 && weight <= 200) {
        mautzuschlag = 9.91
    }
    if (weight >= 201 && weight <= 300) {
        mautzuschlag = 15.15
    }
    if (weight >= 301 && weight <= 400) {
        mautzuschlag = 20.37
    }
    if (weight >= 401 && weight <= 500) {
        mautzuschlag = 25.60
    }
    if (weight >= 501 && weight <= 600) {
        mautzuschlag = 30.81
    }

    const privatzustellung = 25
    const costs = baseprice + privatzustellung + oversizeCharge + mautzuschlag
    return costs.toFixed(2)
}


function burspedBasePrice(weight: number, zip: string) {
    const zone1 = zip.startsWith('20') || zip.startsWith('21') || zip.startsWith('22')
    const zone2 = zip.startsWith('19') || zip.startsWith('23') || zip.startsWith('27') || zip.startsWith('28')
    const zone3 = zip.startsWith('18') || zip.startsWith('24') || zip.startsWith('25') || zip.startsWith('30') || zip.startsWith('31') || zip.startsWith('32') || zip.startsWith('33') || zip.startsWith('39')
    const zone4 = zip.startsWith('26') || zip.startsWith('34') || zip.startsWith('37') || zip.startsWith('38') || zip.startsWith('48') || zip.startsWith('49') || zip.startsWith('58') || zip.startsWith('59')
    const zone5 = zip.startsWith('04') || zip.startsWith('06') || zip.startsWith('07') || zip.startsWith('29') || zip.startsWith('42') || zip.startsWith('44') || zip.startsWith('45') || zip.startsWith('46') || zip.startsWith('47') || zip.startsWith('98') || zip.startsWith('99')
    const zone6 = zip.startsWith('01') || zip.startsWith('08') || zip.startsWith('09') || zip.startsWith('35') || zip.startsWith('36') || zip.startsWith('95') || zip.startsWith('96') || zip.startsWith('97')
    const zone7 = zip.startsWith('02') || zip.startsWith('17') || zip.startsWith('40') || zip.startsWith('41') || zip.startsWith('50') || zip.startsWith('51') || zip.startsWith('53') || zip.startsWith('56') || zip.startsWith('57') || zip.startsWith('60') || zip.startsWith('61') || zip.startsWith('63') || zip.startsWith('64') || zip.startsWith('65')
    const zone8 = zip.startsWith('10') || zip.startsWith('12') || zip.startsWith('13') || zip.startsWith('14') || zip.startsWith('15') || zip.startsWith('16') || zip.startsWith('90') || zip.startsWith('91')
    const zone9 = zip.startsWith('03') || zip.startsWith('52') || zip.startsWith('54') || zip.startsWith('55') || zip.startsWith('66') || zip.startsWith('67') || zip.startsWith('68') || zip.startsWith('69') || zip.startsWith('70') || zip.startsWith('71') || zip.startsWith('73') || zip.startsWith('74') || zip.startsWith('75') || zip.startsWith('76') || zip.startsWith('92') || zip.startsWith('93')
    const zone10 = zip.startsWith('72') || zip.startsWith('77') || zip.startsWith('78') || zip.startsWith('79') || zip.startsWith('80') || zip.startsWith('81') || zip.startsWith('82') || zip.startsWith('83') || zip.startsWith('84') || zip.startsWith('85') || zip.startsWith('86') || zip.startsWith('87') || zip.startsWith('88') || zip.startsWith('89') || zip.startsWith('94')

    if (weight <= 50) {
        if (zone1) {
            return 36.57
        }
        if (zone2) {
            return 40.65
        }
        if (zone3) {
            return 41.15
        }
        if (zone4) {
            return 41.18
        }
        if (zone5) {
            return 41.22
        }
        if (zone6) {
            return 41.28
        }
        if (zone7) {
            return 41.29
        }
        if (zone8) {
            return 42.17
        }
        if (zone9) {
            return 42.19
        }
        if (zone10) {
            return 42.55
        }
    }
    if (weight <= 100) {
        if (zone1) {
            return 43.21
        }
        if (zone2) {
            return 46.95
        }
        if (zone3) {
            return 47.62
        }
        if (zone4) {
            return 47.67
        }
        if (zone5) {
            return 47.69
        }
        if (zone6) {
            return 47.73
        }
        if (zone7) {
            return 47.98
        }
        if (zone8) {
            return 48.98
        }
        if (zone9) {
            return 49.17
        }
        if (zone10) {
            return 49.64
        }
    }
    if (weight <= 150) {
        if (zone1) {
            return 53.93
        }
        if (zone2) {
            return 61.07
        }
        if (zone3) {
            return 61.95
        }
        if (zone4) {
            return 62.00
        }
        if (zone5) {
            return 62.06
        }
        if (zone6) {
            return 62.17
        }
        if (zone7) {
            return 62.43
        }
        if (zone8) {
            return 62.68
        }
        if (zone9) {
            return 62.94
        }
        if (zone10) {
            return 62.96
        }
    }
    if (weight <= 200) {
        if (zone1) {
            return 64.54
        }
        if (zone2) {
            return 76.13
        }
        if (zone3) {
            return 76.81
        }
        if (zone4) {
            return 76.84
        }
        if (zone5) {
            return 76.88
        }
        if (zone6) {
            return 76.91
        }
        if (zone7) {
            return 76.99
        }
        if (zone8) {
            return 77.89
        }
        if (zone9) {
            return 78.00
        }
        if (zone10) {
            return 78.76
        }
    }
    if (weight <= 250) {
        if (zone1) {
            return 72.05
        }
        if (zone2) {
            return 85.77
        }
        if (zone3) {
            return 86.94
        }
        if (zone4) {
            return 87.00
        }
        if (zone5) {
            return 87.02
        }
        if (zone6) {
            return 87.09
        }
        if (zone7) {
            return 87.20
        }
        if (zone8) {
            return 88.89
        }
        if (zone9) {
            return 89.26
        }
        if (zone10) {
            return 89.41
        }
    }
    if (weight <= 300) {
        if (zone1) {
            return 81.50
        }
        if (zone2) {
            return 96.31
        }
        if (zone3) {
            return 97.72
        }
        if (zone4) {
            return 97.75
        }
        if (zone5) {
            return 97.79
        }
        if (zone6) {
            return 97.84
        }
        if (zone7) {
            return 97.91
        }
        if (zone8) {
            return 99.66
        }
        if (zone9) {
            return 99.75
        }
        if (zone10) {
            return 100.80
        }
    }
    if (weight <= 350) {
        if (zone1) {
            return 88.31
        }
        if (zone2) {
            return 105.64
        }
        if (zone3) {
            return 107.27
        }
        if (zone4) {
            return 107.31
        }
        if (zone5) {
            return 107.38
        }
        if (zone6) {
            return 107.47
        }
        if (zone7) {
            return 107.60
        }
        if (zone8) {
            return 109.19
        }
        if (zone9) {
            return 109.27
        }
        if (zone10) {
            return 111.23
        }
    }
    if (weight <= 400) {
        if (zone1) {
            return 97.99
        }
        if (zone2) {
            return 117.44
        }
        if (zone3) {
            return 119.29
        }
        if (zone4) {
            return 119.33
        }
        if (zone5) {
            return 119.38
        }
        if (zone6) {
            return 119.47
        }
        if (zone7) {
            return 119.51
        }
        if (zone8) {
            return 121.30
        }
        if (zone9) {
            return 121.52
        }
        if (zone10) {
            return 123.99
        }
    }
    if (weight <= 450) {
        if (zone1) {
            return 106.00
        }
        if (zone2) {
            return 127.48
        }
        if (zone3) {
            return 129.54
        }
        if (zone4) {
            return 129.61
        }
        if (zone5) {
            return 129.71
        }
        if (zone6) {
            return 129.80
        }
        if (zone7) {
            return 129.82
        }
        if (zone8) {
            return 131.58
        }
        if (zone9) {
            return 131.64
        }
        if (zone10) {
            return 135.06
        }
    }
    if (weight <= 500) {
        if (zone1) {
            return 112.55
        }
        if (zone2) {
            return 135.79
        }
        if (zone3) {
            return 138.10
        }
        if (zone4) {
            return 138.14
        }
        if (zone5) {
            return 138.23
        }
        if (zone6) {
            return 138.36
        }
        if (zone7) {
            return 138.50
        }
        if (zone8) {
            return 140.13
        }
        if (zone9) {
            return 140.61
        }
        if (zone10) {
            return 144.51
        }
    }
    if (weight <= 600) {
        if (zone1) {
            return 116.79
        }
        if (zone2) {
            return 142.25
        }
        if (zone3) {
            return 144.84
        }
        if (zone4) {
            return 144.89
        }
        if (zone5) {
            return 145.00
        }
        if (zone6) {
            return 145.11
        }
        if (zone7) {
            return 145.25
        }
        if (zone8) {
            return 145.98
        }
        if (zone9) {
            return 147.41
        }
        if (zone10) {
            return 151.97
        }
    }
    if (weight <= 700) {
        if (zone1) {
            return 124.66
        }
        if (zone2) {
            return 153.28
        }
        if (zone3) {
            return 156.38
        }
        if (zone4) {
            return 157.06
        }
        if (zone5) {
            return 157.14
        }
        if (zone6) {
            return 157.26
        }
        if (zone7) {
            return 157.32
        }
        if (zone8) {
            return 157.90
        }
        if (zone9) {
            return 160.71
        }
        if (zone10) {
            return 166.44
        }
    }
    if (weight <= 800) {
        if (zone1) {
            return 132.22
        }
        if (zone2) {
            return 163.88
        }
        if (zone3) {
            return 167.49
        }
        if (zone4) {
            return 168.62
        }
        if (zone5) {
            return 168.82
        }
        if (zone6) {
            return 168.94
        }
        if (zone7) {
            return 169.06
        }
        if (zone8) {
            return 169.15
        }
        if (zone9) {
            return 172.98
        }
        if (zone10) {
            return 179.75
        }
    }
    if (weight <= 900) {
        if (zone1) {
            return 140.51
        }
        if (zone2) {
            return 175.40
        }
        if (zone3) {
            return 179.46
        }
        if (zone4) {
            return 181.11
        }
        if (zone5) {
            return 181.22
        }
        if (zone6) {
            return 181.29
        }
        if (zone7) {
            return 181.37
        }
        if (zone8) {
            return 184.98
        }
        if (zone9) {
            return 186.07
        }
        if (zone10) {
            return 193.86
        }
    }
    if (weight <= 1000) {
        if (zone1) {
            return 148.72
        }
        if (zone2) {
            return 187.21
        }
        if (zone3) {
            return 192.11
        }
        if (zone4) {
            return 194.24
        }
        if (zone5) {
            return 194.29
        }
        if (zone6) {
            return 194.80
        }
        if (zone7) {
            return 194.90
        }
        if (zone8) {
            return 197.86
        }
        if (zone9) {
            return 199.81
        }
        if (zone10) {
            return 208.64
        }
    }
    if (weight <= 1250) {
        if (zone1) {
            return 163.16
        }
        if (zone2) {
            return 207.18
        }
        if (zone3) {
            return 213.03
        }
        if (zone4) {
            return 215.97
        }
        if (zone5) {
            return 216.15
        }
        if (zone6) {
            return 217.32
        }
        if (zone7) {
            return 217.39
        }
        if (zone8) {
            return 219.09
        }
        if (zone9) {
            return 222.68
        }
        if (zone10) {
            return 233.33
        }
    }
    if (weight <= 1500) {
        if (zone1) {
            return 178.23
        }
        if (zone2) {
            return 229.69
        }
        if (zone3) {
            return 236.81
        }
        if (zone4) {
            return 241.21
        }
        if (zone5) {
            return 241.36
        }
        if (zone6) {
            return 243.89
        }
        if (zone7) {
            return 243.97
        }
        if (zone8) {
            return 244.11
        }
        if (zone9) {
            return 249.46
        }
        if (zone10) {
            return 262.82
        }
    }
    if (weight <= 1750) {
        if (zone1) {
            return 199.29
        }
        if (zone2) {
            return 258.90
        }
        if (zone3) {
            return 267.42
        }
        if (zone4) {
            return 273.32
        }
        if (zone5) {
            return 273.75
        }
        if (zone6) {
            return 277.71
        }
        if (zone7) {
            return 277.84
        }
        if (zone8) {
            return 275.57
        }
        if (zone9) {
            return 284.65
        }
        if (zone10) {
            return 300.90
        }
    }
    if (weight <= 2000) {
        if (zone1) {
            return 215.50
        }
        if (zone2) {
            return 282.68
        }
        if (zone3) {
            return 292.59
        }
        if (zone4) {
            return 300.21
        }
        if (zone5) {
            return 301.41
        }
        if (zone6) {
            return 306.53
        }
        if (zone7) {
            return 306.58
        }
        if (zone8) {
            return 302.47
        }
        if (zone9) {
            return 314.65
        }
        if (zone10) {
            return 333.86
        }
    }
    if (weight <= 2250) {
        if (zone1) {
            return 230.47
        }
        if (zone2) {
            return 306.65
        }
        if (zone3) {
            return 318.15
        }
        if (zone4) {
            return 326.87
        }
        if (zone5) {
            return 328.34
        }
        if (zone6) {
            return 334.42
        }
        if (zone7) {
            return 334.82
        }
        if (zone8) {
            return 329.35
        }
        if (zone9) {
            return 343.81
        }
        if (zone10) {
            return 365.71
        }
    }
    if (weight <= 2500) {
        if (zone1) {
            return 236.35
        }
        if (zone2) {
            return 318.99
        }
        if (zone3) {
            return 331.68
        }
        if (zone4) {
            return 342.47
        }
        if (zone5) {
            return 344.93
        }
        if (zone6) {
            return 352.25
        }
        if (zone7) {
            return 352.52
        }
        if (zone8) {
            return 343.81
        }
        if (zone9) {
            return 362.19
        }
        if (zone10) {
            return 387.16
        }
    }
    if (weight <= 3000) {
        if (zone1) {
            return 242.37
        }
        if (zone2) {
            return 329.65
        }
        if (zone3) {
            return 342.82
        }
        if (zone4) {
            return 353.85
        }
        if (zone5) {
            return 355.43
        }
        if (zone6) {
            return 362.25
        }
        if (zone7) {
            return 362.58
        }
        if (zone8) {
            return 348.09
        }
        if (zone9) {
            return 368.07
        }
        if (zone10) {
            return 393.99
        }
    }
    return 10000000
}
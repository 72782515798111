import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useEffect } from 'react';
import ShipmentCard from './ShipmentCard';
import { OrderState, OrderStatus, Product, Shipment } from '../../API';
import { setShipments } from '../../store/shippingSlice';
import { useAppDispatch } from '../../store/hooks';
import { finishedItem } from '../../utils';

function ShipmentsRow() {
    const dispatch = useAppDispatch()
    const { items, filters } = useSelector((state: RootState) => state.itemsState)
    const { shipments } = useSelector((state: RootState) => state.shippingState)

    function filterItems(item: Product) {
        return ((item.name === filters.productName || !filters.productName) &&
            (item.orderNumber?.startsWith(filters.orderNumber) || !filters.orderNumber))
    }
    const filteredItems = items.filter(filterItems)

    useEffect(() => {
        const shipmentsHere: Shipment[] = []
        for (const item of filteredItems) {
            // we need all shipments in state because we also use them in the order card to have the same data
            if (item?.shipment && !shipmentsHere.find(s => s?.id === item?.shipment?.id)) {
                shipmentsHere.push(item.shipment)
            }
            if (item.retoure_shipment && !shipmentsHere.find(s => s?.id === item?.retoure_shipment?.id)) {
                shipmentsHere.push(item.retoure_shipment)
            }
        }
        dispatch(setShipments(shipmentsHere))
        return () => {
            dispatch(setShipments([]))
        }
    }, [items, filters])

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', margin: '1rem', gap: '1em' }}>
            {shipments && shipments.map((shipment) => {
                // return null for completly finished shipments, just need them in state
                const shipmentItems = items.filter(el => el.productShipmentId === shipment.id || el.productRetoure_shipmentId === shipment.id)
                if (shipmentItems.every(i =>
                    finishedItem(i) &&
                    (i.order?.status === OrderStatus.finished ||
                    i.order?.status === OrderStatus.cancelled ||
                    i.order?.state === OrderState.finished)
                )) {
                    return null
                }

                // don't show retoure shipments if they are finished even when items are not finished
                if (shipment.typename === 'Retoure' && shipment.shippedAt) {
                    return null
                }
                if (shipment) {
                    return <div id={shipment.id} key={shipment.id} style={{ flexBasis: 'auto' }}><ShipmentCard shipment={shipment} /></div>
                }
            })}
        </div>
    )
}

export default ShipmentsRow;
import { API, Amplify, graphqlOperation } from "aws-amplify";
import { GraphQLSubscription } from '@aws-amplify/api';
import awsconfig from "./aws-exports";
import Homepage from "./routes/Homepage";
import { Route, Routes, useLocation } from "react-router-dom";
import { ThemeProvider } from '@aws-amplify/ui-react';
import { zptheme } from './theme';
import ConfigPage from './routes/ConfigPage';
import { useAppDispatch } from './store/hooks';
import { setUserInfos } from './store/userSlice';
import FinishedItemsPage from './routes/FinishedItemsPage';
import BilledItemsPage from './routes/BilledItemsPage';
import CancelledItemsPage from './routes/CancelledItemsPage';
import NewItemsPage from './routes/NewItemsPage';
import OngoingItemsPage from './routes/OngoingItemsPage'
import ReadyForPackagingItemsPage from './routes/ReadyForPackagingItemsPage';
import { useEffect } from 'react';
import { fetchContractorsAsync } from './store/configSlice';
import AllItemsPage from './routes/AllItemsPage';
import { Contractor, OnUpdateOrderSubscription, OnUpdateProductSubscription } from './API';
import { onUpdateOrder, onUpdateProduct } from './graphql/subscriptions';
import { updateProductInState, updateOrderInState, filterByContractor } from './store/itemsSlice';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import Spinner from './components/Spinner';
import ProdAdditionalItemsPage from './routes/ProdAdditionalItemsPage';
import PendingReturnItemsPage from './routes/PendingReturnItemsPage';
import TourPage from './routes/TourPage';
import OrderDetailsPage from './routes/OrderDetailsPage';
import OngoingItemsByTaskPage from './routes/OngoingItemsByTaskPage';
import TourItemsPage from './routes/TourItemsPage';

Amplify.configure(awsconfig);

function App({ user }: any) {
  const dispatch = useAppDispatch()
  const location = useLocation();
  const previousLocation = location.state?.previousLocation;
  const isLoading = useSelector((state: RootState) => state.wooState.isLoading || state.itemsState.isLoading || state.shippingLabelState.isLoading || state.documentState.isLoading || state.tourState.isLoading || state.deskState.isLoading || state.mailState.isLoading)

  const usergroups = user.signInUserSession.accessToken.payload['cognito:groups']
  const defaultContractor = user.attributes['preferred_username']

  useEffect(() => {
    setFilter()
  }, [user])

  async function setFilter() {
    const fetchContractor = await dispatch(fetchContractorsAsync())
    const allContractors = fetchContractor.payload
    const userContractors = usergroups.filter((g: string) => g.startsWith('F'))
    const permittedContractors = usergroups.includes('managers') ? allContractors : allContractors.filter((c: Contractor) => userContractors.includes(c.number))
    dispatch(setUserInfos({ email: user.attributes.email, usergroups, permittedContractors }))
    if (permittedContractors.length === 1) {
      dispatch(filterByContractor(permittedContractors[0].id))
    }
    if (permittedContractors.length > 1) {
      if (defaultContractor) {
        const defaultId = allContractors.find((c: Contractor) => c.number === defaultContractor)?.id
        dispatch(filterByContractor(defaultId))
      } else {
        dispatch(filterByContractor('Alle'))
      }
    }
  }

  useEffect(() => {
    const sub = API.graphql<GraphQLSubscription<OnUpdateProductSubscription>>(graphqlOperation(onUpdateProduct))
      .subscribe({
        next: ({ provider, value }) => { dispatch(updateProductInState(value.data?.onUpdateProduct)) },
        error: (error) => console.warn('an error occured in subscription: ', error)
      });
    const orderSub = API.graphql<GraphQLSubscription<OnUpdateOrderSubscription>>(graphqlOperation(onUpdateOrder)).subscribe({
      next: ({ provider, value }) => {
        dispatch(updateOrderInState(value.data?.onUpdateOrder))
      },
      error: (err: any) => console.warn('an error occured in subscription: ', err)
    })
    return () => {
      sub.unsubscribe()
      orderSub.unsubscribe()
    }
  }, [])

  if (!user) {
    return (
      <div>keine Berechtigung</div>
    )
  }

  return (
    <ThemeProvider theme={zptheme}>
      <Routes location={previousLocation || location}>
        <Route path="/" element={<Homepage />} />
        <Route path='orders' element={<AllItemsPage />} />
        <Route path='orders/newItems' element={<NewItemsPage />} />
        <Route path='orders/ongoingItems' element={<OngoingItemsPage />} />
        <Route path='orders/ongoingItems/:task' element={<OngoingItemsByTaskPage />} />
        <Route path='orders/prodAdditionalItems' element={<ProdAdditionalItemsPage />} />
        <Route path='orders/readyForPackagingItems' element={<ReadyForPackagingItemsPage />} />
        <Route path='orders/finishedItems' element={<FinishedItemsPage />} />
        <Route path='orders/billedItems' element={<BilledItemsPage />} />
        <Route path='orders/cancelledItems' element={<CancelledItemsPage />} />
        <Route path='orders/pendingReturnItems' element={<PendingReturnItemsPage />} />
        <Route path='orders/:orderNumber' element={<OrderDetailsPage />} />
        <Route path="*" element={<div > 404 </div>} />
        {usergroups && usergroups.includes('configurators') &&
          <Route path='config' element={<ConfigPage />} />}
        {usergroups && (usergroups.includes('viewTours') || usergroups.includes('managers')) &&
          <Route path='tours' element={<TourPage />} />}
        {usergroups && (usergroups.includes('viewTours') || usergroups.includes('managers')) &&
          <Route path='tours/tourItems' element={<TourItemsPage />} />}
      </Routes>
      {isLoading && <Spinner />}
    </ThemeProvider>
  );
}

export default App;

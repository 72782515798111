import { Alert } from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StickyDiv } from '../helper-components';
import { RootState } from '../../store';
import { useAppDispatch } from '../../store/hooks';
import { resetDocumentState } from '../../store/printDocumentsSlice';
import { setShippingLabelSuccess, setshippingLabelError } from '../../store/shippingLabelSlice';
import { setShipmentsSuccessMessage, setShipmetsErrorMessage } from '../../store/shippingSlice';
import { resetWooState } from '../../store/woocommerceSlice';
import HeaderMenu from './HeaderMenu';
import { setItemsError, setItemsInfo } from '../../store/itemsSlice';
import { setDeskError } from '../../store/deskSlice';
import { setMailError } from '../../store/mailSlice';
import { useLocation } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../utils';
import { taskNames } from '../../utils/Constants';

function StickyHeader(props: any) {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [scrollPadding, setScrollPadding] = useState('0rem')
  const [taskName, setTaskName] = useState('')
  const shippingLabelErrors = useSelector((state: RootState) => state.shippingLabelState.error)
  const wooError = useSelector((state: RootState) => state.wooState.error)
  const wooStatusText = useSelector((state: RootState) => state.wooState.statusText)
  const docError = useSelector((state: RootState) => state.documentState.error)
  const shippingError = useSelector((state: RootState) => state.shippingState.error)
  const shippingSuccessMessage = useSelector((state: RootState) => state.shippingState.successMessage)
  const itemsError = useSelector((state: RootState) => state.itemsState.error)
  const itemsInfo = useSelector((state: RootState) => state.itemsState.info)
  const shippingLabelSuccess = useSelector((state: RootState) => state.shippingLabelState.success)
  const { errorCodes } = useSelector((state: RootState) => state.shippingLabelState)
  const { deskError } = useSelector((state: RootState) => state.deskState)
  const { mailError } = useSelector((state: RootState) => state.mailState)

  useEffect(() => {
    const handleScroll = (event: any) => {
      if (event.currentTarget.scrollY === 0) {
        setScrollPadding('0rem')
      } else {
        setScrollPadding('1rem')
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (location.pathname.match(/orders\/ongoingItems\/\w+/)) {
      const taskNameFromKey = Object.values(taskNames).find(t => t.key === location.pathname.split('/')[3])
      if (!taskNameFromKey) return
      const capitalizedName = capitalizeFirstLetter(taskNameFromKey.name)
      setTaskName(capitalizedName)
    }
  }, [location.pathname])

  // show only other errors here, because for address errors a popup appears
  const dpdAdressError = errorCodes && JSON.stringify(errorCodes).includes('CLOUD_ADDRESS')

  return (
    <StickyDiv id={'sticky-header'}>
      <HeaderMenu />
      <div style={{ height: '1rem', backgroundColor: 'white' }}></div>
      {!dpdAdressError && shippingLabelErrors &&
        <div id='shipping-label-error'>
          {shippingLabelErrors.map(
            (error: String) =>
              <Alert
                style={{ marginBottom: '1em' }}

                id='shipping-label-error'
                variation='error'
                isDismissible={true}
                onDismiss={() => dispatch(setshippingLabelError(undefined))} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                {error}
              </Alert>)}
        </div>}
      {docError &&
        <Alert
          style={{ marginBottom: '1em' }}
          variation='error'
          isDismissible={true}
          onDismiss={() => dispatch(resetDocumentState())} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {docError}
        </Alert>}
      {shippingError &&
        <Alert
          style={{ marginBottom: '1em' }}
          variation='error'
          isDismissible={true}
          onDismiss={() => dispatch(setShipmetsErrorMessage(undefined))} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}        >
          {shippingError}
        </Alert>}
      {itemsError &&
        <Alert
          style={{ marginBottom: '1em' }}
          variation='error'
          isDismissible={true}
          onDismiss={() => dispatch(setItemsError(undefined))} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}        >
          {itemsError}
        </Alert>}
      {itemsInfo &&
        <Alert
          style={{ marginBottom: '1em' }}
          variation='info'
          isDismissible={true}
          onDismiss={() => dispatch(setItemsInfo(undefined))} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}        >
          {itemsInfo}
        </Alert>}
      {wooError &&
        <Alert
          style={{ marginBottom: '1em' }}
          id='woo-error'
          variation='error'
          isDismissible={true}
          onDismiss={() => dispatch(resetWooState())} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {wooError}
        </Alert>}
      {wooStatusText &&
        <Alert
          style={{ marginBottom: '1em' }}
          variation='info'
          isDismissible={true}
          onDismiss={() => dispatch(resetWooState())} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {wooStatusText}
        </Alert>}
      {shippingSuccessMessage &&
        <Alert
          style={{ marginBottom: '1em' }}
          variation='success'
          isDismissible={true}
          onDismiss={() => dispatch(setShipmentsSuccessMessage(''))} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}        >
          {shippingSuccessMessage}
        </Alert>}
      {shippingLabelSuccess &&
        <Alert
          style={{ marginBottom: '1em' }}
          variation='success'
          isDismissible={true}
          onDismiss={() => dispatch(setShippingLabelSuccess(''))} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}        >
          {shippingLabelSuccess}
        </Alert>}
      {deskError &&
        <Alert
          style={{ marginBottom: '1em' }}
          variation='error'
          isDismissible={true}
          onDismiss={() => dispatch(setDeskError(''))} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {deskError}
        </Alert>}
      {mailError && <Alert
        style={{ marginBottom: '1em' }}
        variation='error'
        onDismiss={() => dispatch(setMailError(''))}
        isDismissible={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        {mailError}
      </Alert>}
      <div style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: scrollPadding, backgroundColor: 'white' }}>
        {props.children}
      </div>
      {location.pathname.match(/orders\/ongoingItems\/\w+/) && <Alert
        style={{ marginTop: '1em' }}
        variation='info'
        hasIcon={false}
        isDismissible={false}
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}>
        <div style={{ paddingLeft: '1em', paddingRight: '1em' }}>
          Aufträge / in Arbeit / {taskName}
        </div>
      </Alert>}
    </StickyDiv>
  )
}

export default StickyHeader;
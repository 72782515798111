export const specialChar = /[ `!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~0-9]/;
export const numbersChar = /^\d+$/;
export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const ServiceProvider = {
  DPD: "DPD",
  INLINE: "Inline",
  UPS: "UPS",
  GLS: "GLS",
  BLANKO: "Blanko",
  BURSPED: "Bursped",
  DHL: "DHL",
}

export const spEdgeValues = {
  DPD: {
    weight: 31,
    length: 175,
    gurtMaß: 300
  },
  GLS: {
    weight: 40,
    // actually the max length is 200 but GLS has a tolerance of 3 cm which we use here
    length: 203,
    width: 100,
    gurtMaß: 310
  },
  UPS: {
    weight: 40,
    length: 250,
    gurtMaß: 300
  },
  DHL: {
    weight: 31.5,
    length: 120,
    width: 60,
    height: 60,
    minLength: 15,
    minWidth: 11,
    minHeight: 1,
  },
  BURSPED: {
    length: 240,
    width: 200,
    height: 220
  }
}

export const Senders = [
  'ZP-LURUP',
  'ZP-SCHENEFELD',
  'HAMBURGER-HOLZMANUFAKTUR',
  'ALPERS-VERPACKUNG',
  'ALPERS',
  'SCHALLER',
  'SCHRÖTER',
  'HHM-VERPACKUNG'
]

export const ShippingMethods = {
  VERSAND: 'Standardversand',
  EXPRESS: 'Express (3 Werktage schneller als angegeben)',
  ABHOLUNGHH: 'Abholung im Ladengeschäft (Hamburg): 0,00 €',
  EXPRESSABHOLUNG: 'Abholung in Hamburg (Express, 3 Werktage schneller als angegeben)',
  EIGENAUSFAHRT: 'Ausfahrt durch Zuschnittprofi'
}

export const retoureDetailReasons = {
  TRANSPORTDEMAGE: { key: 'transportdemage', name: 'Transportschaden' },
  SHIPMENTLOSS: { key: 'shipmentloss', name: 'kompletter Sendungsverlust' },
  DISLIKE: { key: 'dislike', name: 'Optik gefällt nicht' },
  ABMESSUNG: { key: 'abmessung', name: 'Abmessung falsch' },
  ASTLÖCHER: { key: 'astlöcher', name: 'Astlöcher unsauber verfüllt' },
  AUFDOPPLUNG: { key: 'aufdopplung', name: 'Aufdopplung falsch / vergessen' },
  WURMLÖCHER: { key: 'wurmlöcher', name: 'Wurmlöcher (zu viele)' },
  ÖLEN: { key: 'ölen', name: 'Ölen falsch / vergessen' },
  BOHRLÖCHER: { key: 'bohrlöcher', name: 'Bohrlöcher falsch / vergessen' },
  KANTUNGUNMÖGLICH: { key: 'kantung_unmöglich', name: 'Kantung unmöglich' },
  KANTUNGFALSCH: { key: 'kantung_falsch', name: 'Kantung falsch' },
  KANTUNGFALSCHESMAß: { key: 'kantung_falsches_maß', name: 'Kantung falsches Maß' },
  POSITIONEN: { key: 'positionen', name: 'Position(en) falsch, fehlen, zu viel' },
  SCHLIFF: { key: 'schliff', name: 'Schliff falsch / vergessen' },
  SCHWEIßEN: { key: 'schweißen', name: 'Schweißen falsch / vergessen' },
  FREMDLIEFERUNG: { key: 'fremdlieferung', name: 'Fremdlieferung erhalten' },
  BAUMKANTE: { key: 'baumkante', name: 'Baumkante vergessen' },
  VERPACKUNG: { key: 'verpackung', name: 'Verpackungsfehler' },
  RISSE: { key: 'risse', name: 'Riss(e) im Brett' },
  KANTENSCHADEN: { key: 'kantenschaden', name: 'Kante beschädigt' },
  ECKENSCHADEN: { key: 'eckenschaden', name: 'Ecke beschädigt' },
  KANTENUNDECKENSCHADEN: { key: 'kantenundeckenschaden', name: 'Kante und Ecke beschädigt' },
  OBERFLAECHENSCHADEN: { key: 'oberflächenschaden', name: 'Oberfläche beschädigt - Kratzer/ Delle' },
  VERBOGEN: { key: 'verbogen', name: 'Blech verbogen' },
  VERMESSGARANTIE: { key: 'vermessgarantie', name: 'Vermessgarantie' }
}

export const retoureReasons = {
  PRODUCTIONFAULT: { key: 'productionfault', name: 'Produktionsfehler' },
  TRANSPORTDEMAGE: { key: 'transportdemage', name: 'Transportschaden' },
  DISLIKE: { key: 'dislike', name: 'Nichtgefallen' },
  SHIPMENTLOSS: { key: 'shipmentloss', name: 'Sendungsverlust' },
  OFFICE: { key: 'office', name: 'Büro' },
}

export const taskNames = {
  NACHBESSERN: { key: 'nachbessern', name: 'nachbessern' },
  ZUSCHNEIDEN: { key: 'zuschneiden', name: 'zuschneiden' },
  SCHLEIFEN: { key: 'schleifen', name: 'schleifen' },
  BOHREN: { key: 'bohren', name: 'bohren' },
  FRAESEN: { key: 'fraesen', name: 'fräsen' },
  BUERSTEN: { key: 'buersten', name: 'bürsten' },
  OELEN: { key: 'oelen', name: 'ölen' },
  ENTGRATEN: { key: 'entgraten', name: 'entgraten' },
  ABKANTEN: { key: 'abkanten', name: 'abkanten' },
  BAUMKANTE: { key: 'baumkante', name: 'Baumkante schleifen' },
  ASTLOECHER_EINSEITIG: { key: 'astloecher_einseitig', name: 'Astlöcher einseitig verfüllen' },
  ASTLOECHER_BEIDSEITIG: { key: 'astloecher_beidseitig', name: 'Astlöcher beidseitig verfüllen' },
  KANTEN_BRECHEN: { key: 'kanten_brechen', name: 'Kanten brechen' },
  FASEN: { key: 'fasen', name: 'fasen' },
  ECKEN_ABRUNDEN: { key: 'ecken_abrunden', name: 'Ecken abrunden' },
  SAEGEN: { key: 'saegen', name: 'sägen' },
  SCHWEISSEN: { key: 'schweissen', name: 'schweißen' },
  KANTEN_ABRUNDEN: { key: 'kanten_abrunden', name: 'Kanten abrunden' },
  PULVERN: { key: 'pulvern', name: 'pulvern' }
}

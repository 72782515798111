import styled from 'styled-components'
import { Color } from '../../theme';
import Popup from 'reactjs-popup';

const ToolTipBox = styled.div`
    background-color: #fff;
    color: ${Color.lightgrey};
    text-align: center;
    border-radius: 2px;
    box-shadow: 5px 5px 10px silver;
    padding: 0.2em 0.5em;
    z-index: 1;
    opacity: 1;
    transition: opacity 0.3s;
`

function ToolTipPopup({ tipText, trigger, attributes }: { tipText: any, trigger: any, attributes?: any}) {

    return (
        <Popup
            trigger={<div> {trigger} </div>}
            position={'top right'}
            closeOnDocumentClick
            on={['hover']}
            {...attributes}
        >
            <ToolTipBox>{tipText}</ToolTipBox>
        </Popup>
    )
}

export default ToolTipPopup
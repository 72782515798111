import { prettyDate } from '.'
import { Customer, Order, Product, Shipment } from '../API'
import { ServiceProvider, ShippingMethods, spEdgeValues } from './Constants'
import { calculateShippingCostsBursped } from './shipments-bursped-calc'

const today = new Date()

interface Provider {
    name: string,
    costs: number
}

// Wenn das Gewicht maximal 2 KG über einer 10er Grenze liegt, werden max 2.1 kg abgezogen
// Dieses Gewicht wird für die Ermittlung des Versanddienstleisters verwendet und auch bei Labelerstellung übertragen
export function getFakeWeight(weight: number) {
    if (weight > 10 && weight % 10 <= 2) {
        return weight - ((weight % 10) + 0.1)
    }
    return weight
}

export function getCheapestShippingServiceProvider(serviceProvider: Provider[]) {

    const costsArray = serviceProvider.map(provider => provider.costs)
    const minValue = Math.min(...costsArray)

    const cheapestProvider = serviceProvider.find(el => el.costs === minValue)

    return { ...cheapestProvider }
}

export function getPossibleShippingServiceProvider(length: number, width: number, weight: number, gurtMaß: number, height: number, itemCount: number, zip: string, shippingMethod?: string | null) {
    weight = getFakeWeight(weight)
    let serviceProvider = [
        {
            name: ServiceProvider.UPS,
            costs: parseFloat(calculateShippingCostsUPS(length, width, weight))
        },
        {
            name: ServiceProvider.DPD,
            costs: parseFloat(calculateShippingCostsDPD(length, width, weight))
        },
        {
            name: ServiceProvider.GLS,
            costs: parseFloat(calculateShippingCostsGLS(length, width, weight))
        },
        {
            // the default costs are 2.75, because most of the time it is a Maxibrief, but only a blank label will be printed and it can be used for other things too
            name: ServiceProvider.BLANKO,
            costs: 2.75
        },
        {
            name: ServiceProvider.DHL,
            costs: parseFloat(calculateShippingCostsDHL())
        },
        {
            name: ServiceProvider.INLINE,
            costs: parseFloat(calculateShippingCostsInline(weight, length, width, itemCount))
        },
        {
            name: ServiceProvider.BURSPED,
            costs: parseFloat(calculateShippingCostsBursped(length, width, height, weight, zip))
        }
    ]

    // remove service provider depending on edge values
    // DPD
    if (weight > spEdgeValues.DPD.weight || length > spEdgeValues.DPD.length || gurtMaß > spEdgeValues.DPD.gurtMaß) {
        serviceProvider = serviceProvider.filter(el => el.name !== ServiceProvider.DPD)
    }
    // GLS 
    if (weight > spEdgeValues.GLS.weight || length > spEdgeValues.GLS.length || gurtMaß > spEdgeValues.GLS.gurtMaß || (width >= 100 && length >= 100)) {
        serviceProvider = serviceProvider.filter(el => el.name !== ServiceProvider.GLS)
    }
    // UPS
    if (weight > spEdgeValues.UPS.weight || length > spEdgeValues.UPS.length || gurtMaß > spEdgeValues.UPS.gurtMaß) {
        serviceProvider = serviceProvider.filter(el => el.name !== ServiceProvider.UPS)
    }
    // DHL Maximalgröße
    if (weight > spEdgeValues.DHL.weight || length > spEdgeValues.DHL.length || width > spEdgeValues.DHL.width || height > spEdgeValues.DHL.height) {
        serviceProvider = serviceProvider.filter(el => el.name !== ServiceProvider.DHL)
    }
    // DHL Mindestgröße
    if (length < spEdgeValues.DHL.minLength || width < spEdgeValues.DHL.minWidth || height < spEdgeValues.DHL.minHeight) {
        serviceProvider = serviceProvider.filter(el => el.name !== ServiceProvider.DHL)
    }
    // Brief
    if (weight > 1) {
        serviceProvider = serviceProvider.filter(el => el.name !== ServiceProvider.BLANKO)
    }
    if (shippingMethod?.includes('Österreich')) {
        serviceProvider = serviceProvider.filter(el => el.name !== ServiceProvider.INLINE)
        serviceProvider = serviceProvider.filter(el => el.name !== ServiceProvider.DHL)
    }
    // Bursped Maximalgrößen, only for Germany
    if (!shippingMethod?.includes('Österreich')) {
        if (length > spEdgeValues.BURSPED.length || width > spEdgeValues.BURSPED.width || height > spEdgeValues.BURSPED.height) {
            serviceProvider = serviceProvider.filter(el => el.name !== ServiceProvider.BURSPED)
        }
    }

    return serviceProvider
}

export function calculateShipmentsMeasurements(products: Product[]) {
    const lengthArray = products.map(product => product.length ? product.length : 0)
    const widthArray = products.map(product => product.width ? product.width : 0)
    const heightArray = products.map(product => product.height ? product.height * (product?.quantity || 1) : 0)
    // add 1cm to length, width and height as a buffer for the packaging
    // length has to be the bigger one
    const maxLength = Math.max(Math.max(...lengthArray), Math.max(...widthArray)) + 3
    const maxWidth = Math.min(Math.max(...lengthArray), Math.max(...widthArray)) + 2
    // height is the sum of the heights of all products
    const height = heightArray.reduce((acc, value) => acc + value, 0) + 2
    const gurtMass = (height + Math.min(maxLength, maxWidth)) * 2 + Math.max(maxLength, maxWidth) // gemäß Anleitung Ascan
    return { maxLength, maxWidth, gurtMass, height }
}

export function calculateShipmentsWeight(products: Product[]) {
    const weight = products.reduce((accumulator: number, value: Product) => {
        const productWeight = value.weight ? parseFloat(value.weight) : 0
        const productQuantity = value.quantity || 1
        return accumulator + productWeight * productQuantity
    }, 0)

    return weight
}

export function calculateWrapper(product: Product) {
    const length = product.length ? product.length : 0
    const width = product.width ? product.width : 0
    const maxValue = Math.max(length, width)
    const minValue = Math.min(length, width)

    let wrapper = 'MDF'

    if ((maxValue <= 90 && minValue <= 90) || (maxValue <= 150 && minValue <= 50) || (maxValue <= 270 && minValue <= 30)) {
        wrapper = 'Wellpappe mit Ecken- und Kantenschutz'
    }
    if (maxValue > 150 && minValue <= 80) {
        wrapper += ' und Umreifung'
    }
    // Versandtaschen
    if (maxValue <= 76.5 && minValue <= 51) {
        wrapper = 'Versandtasche XL'
    }
    if (maxValue <= 62.5 && minValue <= 42.5) {
        wrapper = 'Versandtasche L'
    }
    if (maxValue <= 46 && minValue <= 32.5) {
        wrapper = 'Versandtasche M'
    }
    if (maxValue <= 34 && minValue <= 27.5) {
        wrapper = 'Versandtasche S'
    }
    if (product.order && product.order.shipping_method === ShippingMethods.EIGENAUSFAHRT) {
        wrapper = 'für Selbstausfahrt'
    }
    if (product.order && product.order.shipping_method?.includes('Abholung')) {
        wrapper = 'für Abholer'
    }
    // Inline Palette
    if (product.weight && parseFloat(product.weight) > 101 && product.quantity && product.quantity > 1) {
        wrapper = 'Inline Palette'
    }
    return wrapper
}

function dpdBasePrice(weight: number) {
    if (weight <= 3) {
        return 3.3
    }
    if (weight <= 5) {
        return 3.7
    }
    if (weight <= 10) {
        return 4.25
    }
    if (weight <= 15) {
        return 4.75
    }
    if (weight <= 20) {
        return 4.95
    }
    if (weight <= 25) {
        return 5.2
    }
    if (weight <= 31) {
        return 5.5
    }
    return 100000
}

function glsBasePrice(weight: number) {

    if (weight <= 3) {
        return 4.46
    }
    if (weight <= 5) {
        return 4.82
    }
    if (weight <= 8) {
        return 5.1
    }
    if (weight <= 10) {
        return 5.31
    }
    if (weight <= 15) {
        return 5.79

    }
    if (weight <= 20) {
        return 6.15
    }
    if (weight <= 25) {
        return 6.7
    }
    if (weight <= 31.5) {
        return 10.05
    }
    if (weight <= 40) {
        return 33.01
    }
    return 100000
}

function upsBasePrice(weight: number) {
    // yearly + 4,9% increase until 2030
    const yearlyIncreaseFactor = 1.049
    // starting from 2023
    const pastYears = today.getFullYear() - 2023

    if (weight <= 2) {
        return 3.2 * Math.pow(yearlyIncreaseFactor, pastYears)
    }
    if (weight <= 5) {
        return 3.55 * Math.pow(yearlyIncreaseFactor, pastYears)
    }
    if (weight <= 10) {
        return 3.85 * Math.pow(yearlyIncreaseFactor, pastYears)
    }
    if (weight <= 15) {
        return 4.7 * Math.pow(yearlyIncreaseFactor, pastYears)
    }
    if (weight <= 20) {
        return 5.9 * Math.pow(yearlyIncreaseFactor, pastYears)
    }
    if (weight <= 40) {
        return weight * (0.31 * Math.pow(yearlyIncreaseFactor, pastYears))
    }
    return 100000
}

function getInlineBaseCosts(weight: number) {
    weight = getFakeWeight(weight)
    if (weight <= 20) {
        return 31.66
    }
    if (weight <= 30) {
        return 41.42

    }
    if (weight <= 40) {
        return 49.1
    }
    if (weight <= 50) {
        return 56.76
    }
    if (weight <= 60) {
        return 64.45
    }
    if (weight <= 70) {
        return 72.1
    }
    if (weight <= 80) {
        return 79.77
    }
    if (weight <= 90) {
        return 87.43
    }
    if (weight <= 100) {
        return 92.1
    }
    if (weight <= 110) {
        return 102.76
    }
    if (weight <= 120) {
        return 110.44
    }
    if (weight <= 130) {
        return 118.1
    }
    if (weight <= 140) {
        return 125.78
    }
    if (weight <= 150) {
        return 133.44
    }
    if (weight <= 160) {
        return 141.11
    }
    if (weight <= 170) {
        return 148.77
    }
    if (weight <= 180) {
        return 156.44
    }
    if (weight <= 190) {
        return 164.1
    }
    if (weight <= 200) {
        return 171.78
    }
    // weight over 200 minimum 0 
    const weightOver200 = Math.max(weight - 200, 0)
    return 171.78 + weightOver200 * 0.77
}

export function inlinePalettePossible(weight: number, length: number, width: number, itemsCount: number) {
    weight = getFakeWeight(weight)
    if (itemsCount === 1) {
        return false
    }
    if (weight < 101) {
        return false
    }
    if (width < 80 && length < 225) {
        return true
    }
    if (width < 120 && length < 205) {
        return true
    }
    return false
}

function getInlinePalletteCosts(weight: number) {
    // sonderkonditionen für Paletten (nur wenn inlinePalettePossible)
    // ab 101 KG 0,75 EUR/KG
    // ab 201 KG 0,66 EUR/KG
    // ab 501 KG 0,63 EUR/KG
    if (weight >= 101) {
        return weight * 0.75
    }
    if (weight >= 201) {
        return weight * 0.66
    }
    if (weight >= 501) {
        return weight * 0.63
    }
    return null
}

export function calculateShippingCostsDPD(length: number, width: number, weight: number) {
    weight = getFakeWeight(weight)
    const basispreis = dpdBasePrice(weight)
    const mautzuschlag = 0.35
    const sicherheitsgebuehr = 0.15
    const nichtSortierfaehig = (length > 120 || width > 80) ? 6 : 0
    const privatzustellung = 0

    const treibstofffaktor = 1.31

    const costs = (basispreis + mautzuschlag + sicherheitsgebuehr + nichtSortierfaehig + privatzustellung) * treibstofffaktor
    return costs.toFixed(2);
}

export function calculateShippingCostsGLS(length: number, width: number, weight: number) {
    weight = getFakeWeight(weight)
    const basispreis = glsBasePrice(weight)
    const mautzuschlag = 0.5
    const sicherheitsgebuehr = 0
    // GLS definiert Paket als nicht sortierfähig, wenn Länge > 120 oder Höhe < 3. Da Höhe < 3 nicht vorkommt, wird es hier vernachlässigt.
    const nichtSortierfaehig = length > 120 ? 7 : 0

    const privatzustellung = 0.22

    const treibstofffaktor = 1.25

    const costs = (basispreis + mautzuschlag + sicherheitsgebuehr + nichtSortierfaehig + privatzustellung) * treibstofffaktor
    return costs.toFixed(2);
}

export function calculateShippingCostsUPS(length: number, width: number, weight: number, height?: number) {
    weight = getFakeWeight(weight)
    const heightValue = height ? height : 6
    const basispreis = upsBasePrice(weight)
    const mautzuschlag = 0
    const sicherheitsgebuehr = 0
    const nichtSortierfaehig = length > 100 ? 8.5 : 0
    const privatzustellung = 1.45
    const grossesPaket = length + (2 * width) + (2 * heightValue) > 300 && length + (2 * width) + (2 * heightValue) < 400
    const zusHandhabung = Math.max(length, width) > 100 || Math.min(length, width) > 76 || weight > 25 ? 20.15 : 0
    // demand zuschläge gelten vom 01.10.2023 - 14.01.2024
    const demandZuschlagPeriode = today >= new Date('2023-10-01') && today <= new Date('2024-01-14')
    const demandzuschlagGrossesPaket = demandZuschlagPeriode && grossesPaket ? 67.1 : 0

    // see https://www.ups.com/de/de/support/shipping-support/shipping-costs-rates/fuel-surcharges.page
    // Treibstofffaktor ändert sich jeden Montag, vom 11.9 - 4.12.2023 ist er um die 20% gewesen
    // Ab dem 3. Dezember 2023 wird der Treibstoffzuschlag für den Standardservice in Deutschland auch Mauterhöhungen enthalten, 
    // da das Bundesfernstraßenmautgesetz sich ändert und UPS zusätzliche Betriebskosten hat. 
    // Diese Anpassung ist in der Dieselkraftstoffpreistabelle auf UPS.com nachzulesen.
    // --> daher gehen wir erstmal von 21% aus
    // UPS gewährt uns 25% Rabatt auf die Treibstoffzuschläge 21 * 0.75 = 15.75
    const treibstofffaktor = 1.1575 // 15.75%

    const costs = (basispreis + mautzuschlag + sicherheitsgebuehr + nichtSortierfaehig + privatzustellung) * treibstofffaktor + demandzuschlagGrossesPaket + zusHandhabung
    return costs.toFixed(2);
}

export function calculateShippingCostsDHL() {
    const basispreis = 4.64 // baseprice for all packages up to 31,5kg
    const energyCostsFaktor = 1.0125 // 1,25%
    const mautzuschlag = 0.18

    const todayIsInPeakSeason = today.getMonth() === 10 || today.getMonth() === 11
    const peakSeasonZuschlag = todayIsInPeakSeason ? 0.19 : 0 // 19cent im November und Dezember

    const costs = (basispreis + mautzuschlag + peakSeasonZuschlag) * energyCostsFaktor
    return costs.toFixed(2);
}

export function calculateShippingCostsInline(weight: number, length: number, width: number, itemsCount: number) {
    weight = getFakeWeight(weight)
    const paletteCosts = getInlinePalletteCosts(weight)
    const baseCosts = inlinePalettePossible(weight, length, width, itemsCount) && paletteCosts ? paletteCosts : getInlineBaseCosts(weight)
    // 5 EUR Kosten für Avis Sendungen (Pflicht bei Privatzustellung) kommen immer hinzu 
    const privatZustellung = 5
    // 0.95 % Treibstoffzuschlag (individuelle Vereinbarung zwischen ZP und Inline)
    const treibstofffaktor = 1.095 // 9,5%
    const costs = (baseCosts + privatZustellung) * treibstofffaktor
    return costs.toFixed(2)
}

export function createInlineLink(customer: Customer, length: number, width: number, height: number, weight: number, orderNumber: string) {
    const fullName = `${ customer.first_name } ${ customer.last_name }`.replace(/&/g, '%26')
    const fullStreet = `${ customer.street } ${ customer.house_number } ${ customer.add }`.replace(/&/g, '%26')
    const urlCompany = customer.company?.replace(/&/g, '%26')

    // IMPORTANT the link only works with this extension https://chrome.google.com/webstore/detail/user-javascript-and-css/nbhcbdghjpllgmfilhnhkllmkecfmpld
    const speditionLink = `https://www.service.equicon.de/gel/customer/shipment_aquisition.jsp?#p_customer_reference=${ orderNumber }&p_receiver_name1=${ fullName }&p_receiver_name2=${ urlCompany }&p_receiver_street=${ fullStreet }&p_receiver_zip_code=${ customer.zip }&p_receiver_city=${ customer.city }&p_roll_hint=${ customer.phone }&p_xcontact=${ customer.email }&p_service=2&p_extra_service_at=21&p_extra_service_a=${ customer.email }&p_colli_weight_0=${ getFakeWeight(weight).toFixed(0) }&p_colli_height_0=${ width }&p_colli_length_0=${ length }&p_colli_width_0=${ height }&p_internal_info=Holzzuschnitte`.replace(/ /g, `%20`)
    return speditionLink
}

export function getTrackingLink(shipment: Shipment) {
    switch (shipment.serviceProvider) {
        case ServiceProvider.DPD:
            return shipment.parcelNumber ? `https://tracking.dpd.de/status/de_DE/parcel/${ shipment.parcelNumber }` : ''
        case ServiceProvider.GLS:
            return shipment.trackID ? `https://www.gls-pakete.de/sendungsverfolgung?trackingNumber=${ shipment.trackID }` : ''
        case ServiceProvider.UPS:
            return shipment.trackID ? `https://www.ups.com/track?track.x=0&track.y=0&InquiryNumber1=${ shipment.trackID }/trackdetails` : ''
        case ServiceProvider.DHL:
            return shipment.parcelNumber ? `https://nolp.dhl.de/nextt-online-public/set_identcodes.do?lang=de&idc=${ shipment.parcelNumber }` : ''
        default:
            return ''
    }
}

export function getUniqueShipmentsOfOrder(order: Order) {
    const shipmentsOfOrder: any[] = []
    // @ts-ignore
    for (const item of order.products.items) {
        if (!item?.finishedAt) continue
        const finishedDate = prettyDate(item.finishedAt)
        if (item?.shipment && !shipmentsOfOrder.find(s => s.id === item.shipment?.id)) {
            shipmentsOfOrder.push({
                id: item.shipment?.id,
                trackinglink: item.shipment ? getTrackingLink(item.shipment) : null,
                service_provider: item.shipment?.serviceProvider,
                tracking_number: item.shipment?.trackID || item.shipment?.parcelNumber,
                shippedAt: item.shipment?.shippedAt ? prettyDate(item.shipment?.shippedAt) : null,
            })
        }
        if ((order.shipping_method === ShippingMethods.ABHOLUNGHH || order.shipping_method === ShippingMethods.EXPRESSABHOLUNG) && !item?.shipment?.id && item?.finishedAt) {
            if (!shipmentsOfOrder.find(s => s.shippedAt === finishedDate && s.service_provider === `Abholung-${ item.contractor?.name }`)) {
                shipmentsOfOrder.push({
                    trackinglink: null,
                    service_provider: `Abholung-${ item.contractor?.name }`,
                    tracking_number: null,
                    shippedAt: finishedDate,
                })
            }
        }
        if (order.shipping_method === ShippingMethods.EIGENAUSFAHRT && !item?.shipment?.id && item?.finishedAt) {
            if (!shipmentsOfOrder.find(s => s.shippedAt === finishedDate && s.service_provider === 'Eigenausfahrt')) {
                shipmentsOfOrder.push({
                    trackinglink: null,
                    service_provider: 'Eigenausfahrt',
                    tracking_number: null,
                    shippedAt: finishedDate,
                })
            }
        }
    }
    return shipmentsOfOrder
}

export function getDPDShippingDate(noPickupDays?: string) {
    let dpdShippingDate = new Date()
    while (noPickupDays?.includes(prettyDate(dpdShippingDate.toISOString())) || dpdShippingDate.getDay() === 6 || dpdShippingDate.getDay() === 0) {
        dpdShippingDate = new Date(dpdShippingDate.valueOf() + 1000 * 3600 * 24)
    }
    return dpdShippingDate.toISOString().split('T')[0]
}
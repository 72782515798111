import { Button, TextField } from '@aws-amplify/ui-react'
import { HiOutlineTrash, HiPencil } from 'react-icons/hi2'
import { useEffect, useState } from 'react'
import { IoMdSave } from 'react-icons/io'
import { IoClose } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { useAppDispatch } from '../../store/hooks'
import { deleteTourAsync, setTourError, updateTourAsync } from '../../store/tourSlice'
import { Color } from '../../theme'
import { dayOfDate, prettyDate } from '../../utils'
import ToolTipPopup from '../helper-components/ToolTipPopup'

function TourHeader({ tourId }: { tourId: string }) {
    const dispatch = useAppDispatch()
    const { futureTours, pastTours } = useSelector((state: RootState) => state.tourState)
    const tour = futureTours.find(tour => tour.id === tourId) || pastTours.find(tour => tour.id === tourId)
    const [newInfo, setNewInfo] = useState<string | undefined>(undefined)
    const [newDate, setNewDate] = useState<string | undefined>(undefined)
    const [newDriver, setNewDriver] = useState<string | undefined>(undefined)
    const [editable, setEditable] = useState(false)
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)

    useEffect(() => {
        // discard changes on press escape
        const keyDownFunction = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                resetLocalState()
            }
        }
        document.addEventListener("keydown", keyDownFunction, false)
        return () => {
            document.removeEventListener("keydown", keyDownFunction, false)
        }
    }, [])

    if (!tour) return null

    const pastTour = new Date(tour.date) < yesterday

    async function updateTour() {
        if (!tour) return
        if (!newInfo && !newDate && !newDriver) {
            resetLocalState()
            return
        }
        const variables = {
            input: {
                id: tourId,
                info: newInfo || tour.info,
                date: newDate || tour.date,
                driver: newDriver || tour.driver
            }
        }
        await dispatch(updateTourAsync(variables))
        resetLocalState()
    }

    function deleteTour() {
        if (!tour) return
        if (tour.products && tour.products?.items?.length > 0) {
            dispatch(setTourError('Tour kann nicht gelöscht werden, da sie noch Positionen enthält'))
            return
        }
        const variables = {
            input: {
                id: tour.id
            }
        }
        dispatch(deleteTourAsync(variables))
    }

    function resetLocalState() {
        setNewInfo(undefined)
        setNewDate(undefined)
        setNewDriver(undefined)
        setEditable(false)
    }

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {editable ?
                    <div style={{ display: 'flex', gap: '0.2em', flex: 1, alignItems: 'center' }}>
                        {pastTour ?
                            <h3 style={{ color: Color.lightgrey }} >
                                {dayOfDate(tour.date)} {prettyDate(tour.date)}
                            </h3>
                            :
                            <h3 style={{ color: Color.blue }} >
                                <TextField
                                    size='small'
                                    type='date' label={undefined}
                                    defaultValue={tour.date}
                                    onKeyDown={(e: any) => {
                                        if (e.key === 'Enter') updateTour()
                                    }}
                                    onChange={(e: any) => setNewDate(e.target.value)} />
                            </h3>}
                        <TextField
                            size='small'
                            defaultValue={tour.info || ''}
                            onChange={(e: any) => setNewInfo(e.currentTarget.value)}
                            onKeyDown={(e: any) => {
                                if (e.key === 'Enter') updateTour()
                            }}
                            label={undefined} />
                        <Button
                            backgroundColor={'transparent'}
                            style={{ border: 'none', borderColor: 'transparent' }}
                            border={'none'}
                            size={'small'}
                            variation='link'
                            onClick={updateTour}
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}>
                            <IoMdSave />
                        </Button>
                        <Button
                            backgroundColor={'transparent'}
                            style={{ borderColor: 'transparent' }}
                            size={'small'}
                            variation={'link'}
                            onClick={resetLocalState}
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}>
                            <IoClose />
                        </Button>
                    </div> :
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em', flex: 1 }}>
                        <h3 style={{ color: pastTour ? Color.lightgrey : Color.blue }} >
                            {dayOfDate(tour.date)} {prettyDate(tour.date)}
                        </h3>
                        <div style={{ display: 'flex', gap: '0.2em' }}>
                            {tour?.info}
                            <ToolTipPopup tipText={'Tourdaten bearbeiten'}
                                trigger={
                                    <Button
                                        backgroundColor={'transparent'}
                                        size='small'
                                        variation='link'
                                        onClick={() => setEditable(true)}
                                        placeholder={undefined}
                                        onPointerEnterCapture={undefined}
                                        onPointerLeaveCapture={undefined}>
                                        <HiPencil />
                                    </Button>} />
                        </div>
                    </div>}
                {tour.products?.items?.length === 0 && <ToolTipPopup tipText='Tour löschen'
                    trigger={
                        <Button
                            onClick={deleteTour}
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}>
                            <HiOutlineTrash />
                        </Button>}
                />}
                {tour.products?.items && tour.products.items.length > 0 && tour.calculated_shipping_costs &&
                    <div>
                        kalkulierte Versandkosten: {tour.calculated_shipping_costs.toFixed(2)} €
                    </div>}
            </div>
            {editable && !pastTour ?
                <div style={{ fontWeight: 'bolder', display: 'flex', alignItems: 'center', gap: '0.5em' }}>
                    Fahrer*in:
                    <TextField
                        size='small'
                        defaultValue={tour.driver || ''}
                        onChange={(e: any) => setNewDriver(e.currentTarget.value)}
                        onKeyDown={(e: any) => {
                            if (e.key === 'Enter') updateTour()
                        }}
                        label={undefined} />
                </div>
                :
                <div>
                    {tour.driver && <div style={{ fontWeight: 'bolder' }}>Fahrer*in: {tour.driver}</div>}
                </div>}
        </div>
    )
}

export default TourHeader
import { AiOutlineLink } from 'react-icons/ai'
import { Color } from '../../theme'
import { prettyDate } from '../../utils'
import { Order, Shipment } from '../../API'
import { getTrackingLink } from '../../utils/shipments-calc'
import { GoPackage } from 'react-icons/go'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { Suspense, lazy, useEffect, useState } from 'react'
import ToolTipPopup from '../helper-components/ToolTipPopup'
const ShipmentCardPopup = lazy(() => import('../shipment/ShipmentCardPopup'))


function ShipmentInfos({ order }: { order: Order }) {
    const { shipments } = useSelector((state: RootState) => state.shippingState)
    const { minimize } = useSelector((state: RootState) => state.itemsState)
    const items = useSelector((state: RootState) => state.itemsState.items)
    const orderItems = items.filter(i => i.productOrderId === order.id)
    const [itemShipments, setItemShipments] = useState<Shipment[]>([])


    useEffect(() => {
        const shipmentIds = orderItems.map(i => i.shipment?.id)
        const shipmentsHere: Shipment[] = []
        const filteredShipments = shipments.filter(s => shipmentIds.includes(s.id))
        //  somewhere there are double shipments
        for (const shipment of filteredShipments) {
            if (!shipmentsHere.find(s => s?.id === shipment?.id) && shipmentIds.includes(shipment.id)) {
                shipmentsHere.push(shipment)
            }
        }
        for (const item of orderItems) {
            if (item?.shipment && !shipmentsHere.find(s => s?.id === item?.shipment?.id)) {
                shipmentsHere.push(item.shipment)
            }
        }
        setItemShipments(shipmentsHere)
    }, [shipments, minimize])

    // const uniqueShipments = shipments.filter(s => shipmentIds.includes(s.id))
    const uniqueShipments = itemShipments
    const shippingCostSum = uniqueShipments.reduce((acc, cur) => acc + cur.costs, 0)
    if (uniqueShipments.length < 1 && order?.finishedAt && order?.shipping_method?.includes('Abholung')) {
        return (
            <div>
                <div>bereit gestellt am:</div>
                <div>{prettyDate(order.finishedAt)}</div>
            </div>
        )
    }

    if (uniqueShipments.length < 1) {
        return null
    }

    return (
        <div >
            <div style={{ height: 15 }} />
            <div style={{ color: Color.red, display: 'flex', alignItems: 'center', gap: '0.2em' }}>
                <GoPackage /><div style={{ fontWeight: 'bold' }}>Sendungen</div>
            </div>
            {uniqueShipments.map(shipment =>
                <div key={shipment.id}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ textDecoration: 'underline' }}>
                            {shipment.serviceProvider}
                        </div>
                        <div style={{ marginLeft: '0.5em' }}>
                            <Suspense fallback={'Versandkarte'}>
                                <ShipmentCardPopup shipment={shipment} />
                            </Suspense>
                        </div>
                    </div>
                    {getTrackingLink(shipment) ?
                        <ToolTipPopup tipText={getTrackingLink(shipment)}
                            trigger={<div
                                style={{ color: Color.lightblue, textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => window.open(getTrackingLink(shipment))}>
                                <AiOutlineLink />{shipment.parcelNumber}
                            </div>}
                        />
                        :
                        <ToolTipPopup tipText={shipment.id}
                            trigger={<div
                                onClick={() => navigator.clipboard.writeText(shipment.id)}>#{shipment.id.split('-')[0]}...</div>}
                        />
                    }
                    {shipment.costs && <div>{shipment.costs} €</div>}
                    {shipment.shippedAt &&
                        <div>
                            <div>verschickt am:</div>
                            <div>{prettyDate(shipment.shippedAt)}</div>
                        </div>}
                    <div style={{ height: '0.5em' }} />
                </div>
            )}
            {shippingCostSum > 0 && uniqueShipments.length > 1 && <div style={{ fontWeight: 'bold' }}>Gesamt: {shippingCostSum} €</div>}
        </div>

    )
}

export default ShipmentInfos;
import { useEffect, useRef, useState } from "react";
import Zuschnittprofi_Logo from "../../assets/Favicon_Z.png";
import testbild from "../../assets/testbild.webp";
import { Color, zptheme } from "../../theme";
import { HeaderDiv, Sidebar } from "../helper-components";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { AiOutlineUser } from "react-icons/ai";
import { Button, ThemeProvider, withAuthenticator } from '@aws-amplify/ui-react';
import Modal from '../helper-components/Modal';
import { GiHamburgerMenu } from 'react-icons/gi'
import styled from 'styled-components';
import { useAppDispatch } from '../../store/hooks';
import { resetDocumentState } from '../../store/printDocumentsSlice';
import { resetWooState } from '../../store/woocommerceSlice';
import { fetchCurrentTasksForSubMenuAsync, filterByContractor, filterByOrderNumber, filterByProductName, resetItemsState } from '../../store/itemsSlice';
import { resetConfigState } from '../../store/configSlice';
import { resetShippingLabelState } from '../../store/shippingLabelSlice';
import { resetShippingState } from '../../store/shippingSlice';
import { resetUserState } from '../../store/userSlice';
import Popup from 'reactjs-popup';
import { PopupActions } from 'reactjs-popup/dist/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Contractor } from '../../API';
import { taskNames } from '../../utils/Constants';
import { capitalizeFirstLetter } from '../../utils';
import './HeaderMenuStyle.css';

const Headline3 = styled.div`
  font-size: large;
  font-weight: bold;
`

const SubMenue = styled.div`
    background-color: #fff;
    text-align: center;
    border-radius: 2px;
    box-shadow: 5px 5px 10px silver;
    padding: 1.5em 1em;
    opacity: 1;
    transition: opacity 0.3s;
    display: grid;
    gap: 0.5em;
`

const MenuDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto); /* Three columns, adjust based on content */
  column-gap: 0.5em; /* Optional gap between columns */
  padding-top: 0.5em;
  `

const Column = styled.div`
  display: grid;
  grid-template-rows: auto auto; /* Two rows per column */
  `
const MenuRow = styled.div`
    text-align: center; /* Center the first row content */
`

const SubMenuRow = styled.div`
    white-space: nowrap;
    display: flex; /* Use Flexbox for centering the divs horizontally */
    justify-content: center; /* Centers the inner divs horizontally */
    align-items: center; /* Align items vertically */
    gap: 0.5em; /* Optional gap between inner divs */
`

function HeaderMenu({ user }: any) {
  const dispatch = useAppDispatch()
  const { filters, subMenuTasks, items } = useSelector((state: RootState) => state.itemsState)
  const { permittedContractors } = useSelector((state: RootState) => state.userState)
  const ref = useRef<PopupActions | null>(null);
  const usergroups = user.signInUserSession.accessToken.payload['cognito:groups']
  const headerImage = process.env.REACT_APP_ENV === 'dev' ? testbild : Zuschnittprofi_Logo
  const [smallWindow, setSmallWindow] = useState(window.innerWidth <= 1300)
  const closeTooltip = () => ref.current?.close();

  useEffect(() => {
    const handleResize = (event: any) => {
      if (window.innerWidth <= 1300) {
        setSmallWindow(true)
      } else {
        setSmallWindow(false)
      }
    }
    window.addEventListener('resize', handleResize)
  })

  useEffect(() => {
    // filter should always be set on mounting app but if it is not yet we don't want to fetch any items
    if (filters.contractor) {
      if (filters.contractor === 'Alle') {
        // user filter for permitted contractors
        const filter = {
          or: permittedContractors.map((c: Contractor) => { return { productContractorId: { eq: c.id } } }),
        }
        dispatch(fetchCurrentTasksForSubMenuAsync({ filter }))
      } else {
        dispatch(fetchCurrentTasksForSubMenuAsync({ productContractorId: filters.contractor }))

      }
    }
  }, [items])

  async function onclickUserIcon() {
    try {
      await Auth.signOut({ global: true });
      // reset states
      dispatch(resetUserState())
      dispatch(resetConfigState())
      dispatch(resetDocumentState())
      dispatch(resetShippingLabelState())
      dispatch(resetShippingState())
      dispatch(resetWooState())
      dispatch(resetItemsState())
      dispatch(filterByOrderNumber(''))
      dispatch(filterByContractor(' '))
      dispatch(filterByProductName(''))
    } catch (error) {
      console.warn('error signing out: ', error);
    }
  }

  function openMenu() {
    //@ts-ignore
    document.getElementById("mySidebar").style.width = "250px";
  }

  function closeMenu() {
    //@ts-ignore
    document.getElementById("mySidebar").style.width = "0";
    closeTooltip()
  }

  function renderNavLinks() {
    return (
      <div className='header'>
        <div className='column'>
          <div className='row center'>
            <NavLink
              to="/orders"
              style={({ isActive }) =>
                isActive ? { color: `${ Color.orange }`, textDecoration: 'none' } : { color: `${ Color.blue }`, textDecoration: 'none' }
              }>
              <Headline3>AUFTRÄGE</Headline3>
            </NavLink>
          </div>
          <div className='row'>
            <NavLink to="/orders/newItems" style={({ isActive }) =>
              isActive ? { color: `${ Color.orange }`, textDecoration: 'none' } : { color: `${ Color.blue }`, textDecoration: 'none' }
            }>
              <div style={{ textAlign: 'center' }}>Neu</div>
            </NavLink>
            {!smallWindow && <div>|</div>}
            <Popup
              ref={ref}
              trigger={
                <NavLink to="/orders/ongoingItems"
                  style={({ isActive }) =>
                    isActive ? { color: `${ Color.orange }`, textDecoration: 'none' } : { color: `${ Color.blue }`, textDecoration: 'none' }
                  }>
                  <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>In Arbeit</div>
                </NavLink>
              }
              position={smallWindow ? "right top" : "bottom center"}
              on="hover"
              closeOnDocumentClick
              closeOnEscape
              mouseLeaveDelay={300}
              mouseEnterDelay={0}
              // TODO make the arrow visible
              arrow={true}
            >
              {subMenuTasks.length > 0 && <SubMenue>
                {Object.values(taskNames).map((taskName, index) => {
                  if (subMenuTasks.includes(taskName.name)) {
                    return (
                      <NavLink key={index} to={`/orders/ongoingItems/${ taskName.key }`}
                        onClick={closeMenu}
                        style={({ isActive }) =>
                          isActive ? { color: `${ Color.orange }`, textDecoration: 'none' } : { color: `${ Color.lightgrey }`, textDecoration: 'none' }
                        }>
                        <div style={{ textAlign: 'center' }}>{capitalizeFirstLetter(taskName.name)}</div>
                      </NavLink>
                    )
                  }
                })
                }
              </SubMenue>}
            </Popup>
            {!smallWindow && <div>|</div>}
            <NavLink to="/orders/prodAdditionalItems" style={({ isActive }) =>
              isActive ? { color: `${ Color.orange }`, textDecoration: 'none' } : { color: `${ Color.blue }`, textDecoration: 'none' }
            }>
              <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Weiterverarbeitung</div>
            </NavLink>
            {!smallWindow && <div>|</div>}
            <NavLink to="/orders/readyForPackagingItems" style={({ isActive }) =>
              isActive ? { color: `${ Color.orange }`, textDecoration: 'none' } : { color: `${ Color.blue }`, textDecoration: 'none' }
            }>
              <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Packbereit</div>
            </NavLink>
            {!smallWindow && <div>|</div>}
            <NavLink to="/orders/finishedItems" style={({ isActive }) =>
              isActive ? { color: `${ Color.orange }`, textDecoration: 'none' } : { color: `${ Color.blue }`, textDecoration: 'none' }
            }>
              <div style={{ textAlign: 'center' }}>Fertig</div>
            </NavLink>
            {!smallWindow && <div>|</div>}
            <NavLink to="/orders/billedItems" style={({ isActive }) =>
              isActive ? { color: `${ Color.orange }`, textDecoration: 'none' } : { color: `${ Color.blue }`, textDecoration: 'none' }
            }>
              <div style={{ textAlign: 'center' }}>Abgerechnet</div>
            </NavLink>
            {!smallWindow && <div>|</div>}
            <NavLink to="/orders/cancelledItems" style={({ isActive }) =>
              isActive ? { color: `${ Color.orange }`, textDecoration: 'none' } : { color: `${ Color.blue }`, textDecoration: 'none' }
            }>
              <div style={{ textAlign: 'center' }}>Storniert</div>
            </NavLink>
            {!smallWindow && <div>|</div>}
            <NavLink to="/orders/pendingReturnItems" style={({ isActive }) =>
              isActive ? { color: `${ Color.orange }`, textDecoration: 'none' } : { color: `${ Color.blue }`, textDecoration: 'none' }
            }>
              <div style={{ textAlign: 'center' }}>Retoure</div>
            </NavLink>
          </div>
        </div>
        <div className='column'>
          <div className='row center'>
            {usergroups && usergroups.includes('configurators') &&
              <NavLink
                to="/config"
                style={({ isActive }) =>
                  isActive ? { color: `${ Color.orange }`, textDecoration: 'none' } : { color: `${ Color.blue }`, textDecoration: 'none' }
                }>
                <Headline3>EINSTELLUNGEN</Headline3>
              </NavLink>
            }
          </div>
        </div>
        <div className='column'>
          <div className='row center'>
            {usergroups && (usergroups.includes('viewTours') || usergroups.includes('managers')) &&
              <NavLink
                to="/tours"
                style={({ isActive }) =>
                  isActive ? { color: `${ Color.orange }`, textDecoration: 'none' } : { color: `${ Color.blue }`, textDecoration: 'none' }
                }>
                <Headline3>TOUREN</Headline3>
              </NavLink>
            }
          </div>
          <div className='row'>
            <NavLink to="/tours/tourItems" style={({ isActive }) =>
              isActive ? { color: `${ Color.orange }`, textDecoration: 'none' } : { color: `${ Color.blue }`, textDecoration: 'none' }
            }>
              <div style={{ textAlign: 'center' }}>Tour-Aufträge</div>
            </NavLink>
          </div>
        </div>
      </div>
    )
  }

  return (
    <ThemeProvider theme={zptheme}>
      <Sidebar id='mySidebar'>
        <div style={{ textAlign: 'right', margin: '1em' }}>
          <Button
            onClick={closeMenu}
            variation='link' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>X</Button>
        </div>
        <div style={{ padding: '1em' }}>
          {renderNavLinks()}
        </div>
      </Sidebar>
      {smallWindow ?
        <HeaderDiv>
          <div style={{ flex: 1, display: 'grid', alignItems: 'center' }}>
            <div>
              <Button
                variation='link'
                onClick={openMenu} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <GiHamburgerMenu /> <div style={{ paddingLeft: '0.5em' }}>Menü</div>
              </Button>
            </div>
          </div>
          <Link to='/' style={{ margin: "4px", flex: 1 }}>
            <img src={headerImage} alt="logo" style={{ maxHeight: '4rem' }} id={'logo-img'} />
          </Link>
          <div style={{ display: 'grid', alignItems: 'center' }}>
            <Modal
              triggerButton={<Button variation='link' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <AiOutlineUser style={{ fontSize: '3vw' }} />
                  <div style={{ fontSize: '0.8vw' }}>{user.attributes.email}</div>
                </div>
              </Button>}
              action={onclickUserIcon}
              modal={true}>
              <div>Möchtest du dich wirklich abmelden?</div>
            </Modal>
          </div>
        </HeaderDiv>
        :
      <HeaderDiv>
        <Link to='/' style={{ margin: "4px" }}>
          <img src={headerImage} alt="logo" style={{ maxHeight: '4rem' }} />
        </Link>
        <div style={{ flex: 7, color: `${ Color.blue }`, textAlign: "center" }}>
          <nav>
            {renderNavLinks()}
          </nav>
        </div>
        <div style={{ display: 'grid', alignItems: 'center' }}>
          <Modal
            triggerButton={<Button variation='link' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: '4rem' }}>
                <AiOutlineUser
                  style={{ fontSize: '2vw' }} />
                <div style={{ fontSize: '0.8vw' }}>{user.attributes.email}</div>
              </div>
            </Button>}
            action={onclickUserIcon}
            modal={true}>
            <div>Möchtest du dich wirklich abmelden?</div>
          </Modal>
        </div>
      </HeaderDiv>
      }
    </ThemeProvider>
  );
}

export default withAuthenticator(HeaderMenu);

import { Button, ScrollView, SelectField, ThemeProvider, withAuthenticator } from '@aws-amplify/ui-react';
import { Color, zptheme } from '../theme';
import { Contractor, ModelSortDirection, Product, ProductStatus, Shipment } from '../API';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../store/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { fetchFinishedItemsByContractorByStatusAsync, fetchFinishedItemsByStatusAsync, resetItemsState, toggleMinimize } from '../store/itemsSlice';
import StickyHeader from '../components/header/StickyHeader';
import { FilterByContractorSelecField } from '../components/header/FilterByContractorSelectField';
import { HiCloudDownload, HiOutlineDocumentDownload } from 'react-icons/hi';
import ToolTip from '../components/helper-components/ToolTip';
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi';
import OrderItemsCard from '../components/order/OrderItemsCard';
import { BillingTableData, createDocument } from '../store/printDocumentsSlice';
import { prettyDate } from '../utils';
import SearchOrderField from '../components/header/SearchOrderField';
import { FaFilter } from 'react-icons/fa';
import { fetchFutureToursAsync, resetTourState } from '../store/tourSlice';
import { setShipments } from '../store/shippingSlice';

// page for items in status billed
function BilledItemsPage({ user }: any) {
    const dispatch = useAppDispatch()
    const { items, filters, nextToken, minimize, isLoading } = useSelector((state: RootState) => state.itemsState)
    const documentIsLoading = useSelector((state: RootState) => state.documentState.isLoading)
    const { contractors } = useSelector((state: RootState) => state.configState)
    const [smallWindow, setSmallWindow] = useState(window.innerWidth <= 1000)
    const uniqueBillingDates = [...new Set(items.map(i => i.billedAt?.split('T')[0]))].filter(d => d !== undefined)
    const [selectedBillingDate, setSelectedBillingDate] = useState(uniqueBillingDates[0])
    const { permittedContractors } = useSelector((state: RootState) => state.userState)
    const [year, setYear] = useState<number>()
    const [month, setMonth] = useState<number>()
    const [day, setDay] = useState<number>()
    const pageStatus = ProductStatus.billed

    function filterItems(item: Product) {
        return ((item.name === filters.productName || !filters.productName) &&
            (item.orderNumber?.startsWith(filters.orderNumber) || !filters.orderNumber))
    }
    const filteredItems = items.filter(filterItems)
    const uniqueOrderId = [...new Set(filteredItems.map(item => item?.productOrderId))];

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1000) {
                setSmallWindow(true)
            } else {
                setSmallWindow(false)
            }
        }
        window.addEventListener('resize', handleResize)
    })

    useEffect(() => {
        fetchItems(null)
        return () => {
            dispatch(resetItemsState())
        }
    }, [filters.contractor])

    function fetchItems(nextToken: string | null) {
        let finishedAt = null
        if (year || month || day) {
            let dateString = ''
            if (year) dateString += year
            if (month) month < 10 ? dateString += '-0' + month : dateString += '-' + month
            if (day) day < 10 ? dateString += '-0' + day : dateString += '-' + day
            finishedAt = { beginsWith: dateString }
        }
        // filter should always be set on mounting app but if it is not yet we don't want to fetch any items
        if (filters.contractor) {
            if (filters.contractor === 'Alle') {
                // user filter for permitted contractors
                const filter = {
                    or: permittedContractors.map((c: Contractor) => { return { productContractorId: { eq: c.id } } })
                }
                dispatch(fetchFinishedItemsByStatusAsync({ status: pageStatus, nextToken, sortDirection: ModelSortDirection.DESC, filter, finishedAt }))
            } else {
                dispatch(fetchFinishedItemsByContractorByStatusAsync({ contractor_status: `${ filters.contractor }-${ pageStatus }`, nextToken, sortDirection: ModelSortDirection.DESC, finishedAt }))
            }
        }
    }

    useEffect(() => {
        if (selectedBillingDate) return
        setSelectedBillingDate(uniqueBillingDates[0])
    }, [items])

    // fetch tours in case the shipping method is changed to 'Ausfahrt durch Zuschnittprofi' and the itemsCard is maximized here
    // cannot fetch it in the tourSelectField because the tours will be loaded multiple times if there is more than 1 item
    useEffect(() => {
        dispatch(fetchFutureToursAsync({ nextToken: null }))
        return () => {
            dispatch(resetTourState())
        }
    }, [])

    // fetch shipments because we want to show them in the orderItemsCard but have no shipmentsRow where they are fetched
    useEffect(() => {
        const shipmentsHere: Shipment[] = []
        for (const item of filteredItems) {
            if (item?.shipment && !shipmentsHere.find(s => s?.id === item?.shipment?.id)) {
                shipmentsHere.push(item.shipment)
            }
            if (item.retoure_shipment && !shipmentsHere.find(s => s?.id === item?.retoure_shipment?.id)) {
                shipmentsHere.push(item.retoure_shipment)
            }
        }
        dispatch(setShipments(shipmentsHere))
        return () => {
            dispatch(setShipments([]))
        }
    }, [minimize])

    async function exportBillingTableDuplicate() {
        const contractorName = contractors.find(el => el.id === filters.contractor)?.name
        const fileName = `${ contractorName }-${ selectedBillingDate }-Duplikat`
        const contractor = contractors.find(el => el.id === filters.contractor)
        if (!contractor || !selectedBillingDate) {
            return
        }

        let sum = 0
        const BillingTableData = {
            orders: [],
            billableSum: 0,
            headline: `Abrechnungstabelle - Duplikat vom ${ prettyDate(selectedBillingDate) }`,
        } as unknown as BillingTableData

        const billedItems = filteredItems.filter(i => i.billedAt && i.billedAt.startsWith(selectedBillingDate))
        for (const item of billedItems) {
            let billablePerItem = item.billed_amount ? item.billed_amount : 0;
            const price = item.total_net_price ? item.total_net_price : 0;
            const date = item.order?.finishedAt ? prettyDate(item.order.finishedAt) : prettyDate(item.order?.updatedAt || item.finishedAt || item.order?.createdAt || item.createdAt)
            const AllItemsOfOrder = items.filter(i => i.productOrderId === item.productOrderId)
            const pos = AllItemsOfOrder.indexOf(item) + 1
            BillingTableData.orders.push({
                date,
                orderNumber: item.order?.orderNumber,
                pos,
                id: item.id,
                material: item.name,
                quantity: item.quantity,
                length: item.length,
                width: item.width,
                tasks: item.tasks ? item.tasks.toString() : '',
                netPrice: item.total_net_price,
                billablePerItem
            })
            BillingTableData.billableSum += billablePerItem
            sum += price
        }
        // find unique order numbers and substitute the retoure shipping costs if there are any
        const retoureitems = billedItems.filter(i => i.order?.retoure_shipping_costs)
        const uniqueRetoureOrders = retoureitems.map(i => i.order?.orderNumber).filter((value, index, self) => self.indexOf(value) === index)
        for (const orderNumber of uniqueRetoureOrders) {
            // find complete order and push shipping costs as net price to billing table data
            const order = items.find(i => i.order?.orderNumber === orderNumber)?.order
            if (!order) continue
            const shippingCosts = order?.retoure_shipping_costs
            const date = order?.finishedAt ? prettyDate(order.finishedAt) : prettyDate(order?.updatedAt || order.createdAt)
            if (shippingCosts) {
                BillingTableData.orders.push({
                    date,
                    orderNumber: order.orderNumber,
                    pos: 0,
                    id: '',
                    material: 'Retoure Versandkosten',
                    quantity: 1,
                    length: 0,
                    width: 0,
                    tasks: '',
                    netPrice: 0,
                    billablePerItem: shippingCosts * (-1)
                })
                BillingTableData.billableSum -= shippingCosts
            }
        }
        BillingTableData.billableSum = parseFloat(BillingTableData.billableSum.toFixed(2))
        dispatch(createDocument({ data: BillingTableData, outputName: `${ fileName }.pdf`, templateName: 'samples/Billing_Table_Template.docx' }))
    }

    return (
        <ThemeProvider theme={zptheme}>
            <StickyHeader>
                <div style={{ display: 'flex', gap: '1em', flexWrap: 'wrap' }}>
                    <div style={{ display: 'flex', gap: '1em', flex: 1, flexWrap: 'wrap' }}>
                        <SearchOrderField pageStatus={pageStatus} onClear={() => fetchItems(null)} />
                        <FilterByContractorSelecField />
                        {uniqueBillingDates &&
                            <SelectField
                                maxWidth={'15rem'}
                                label={'billingDateSelectField'}
                                labelHidden={true}
                                value={selectedBillingDate}
                                onChange={(e) => setSelectedBillingDate(e.target.value)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                            >
                                {uniqueBillingDates.map((date, index) => (date && <option key={index} value={date}>{prettyDate(date)}</option>)
                                )}
                            </SelectField>}
                        {filters.contractor !== 'Alle' && selectedBillingDate &&
                            <Button
                                isLoading={documentIsLoading}
                                onClick={exportBillingTableDuplicate} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                <HiOutlineDocumentDownload />
                                {!smallWindow && `Abrechnung vom ${ prettyDate(selectedBillingDate) }`}
                            </Button>}
                        <div style={{ display: 'flex', gap: '0.2em' }}>
                            <input
                                id='year-input'
                                placeholder='jjjj'
                                style={{ border: `1px solid ${ Color.blue }`, borderRadius: '0.3em', padding: '0.5em', color: Color.blue }}
                                type='number'
                                min={2022}
                                max={new Date().getFullYear()}
                                step={1}
                                value={year || ''}
                                onChange={(e: any) => setYear(e.target.value)}
                            />
                            <input
                                id='month-input'
                                placeholder='mm'
                                style={{ border: `1px solid ${ Color.blue }`, borderRadius: '0.3em', padding: '0.5em', backgroundColor: year === undefined ? Color.transparentgrey : 'white', color: Color.blue }}
                                type='number'
                                min={1}
                                max={12}
                                step={1}
                                value={(month && month < 10) ? `0${ month }` : (`${ month }` || '')}
                                disabled={year === undefined}
                                onChange={(e: any) => setMonth(parseFloat(e.target.value))}
                            />
                            <input
                                id='day-input'
                                placeholder='tt'
                                style={{ border: `1px solid ${ Color.blue }`, borderRadius: '0.3em', padding: '0.5em', backgroundColor: month === undefined ? Color.transparentgrey : 'white', color: Color.blue }}
                                type='number'
                                min={1}
                                max={31}
                                step={1}
                                value={day || ''}
                                disabled={month === undefined || month === null}
                                onChange={(e: any) => { console.log(parseFloat(e.target.value)); setDay(parseFloat(e.target.value)) }}
                            />
                            <Button
                                style={{ height: '2.5em', marginLeft: '0.2em' }}
                                type='submit'
                                onClick={() => {
                                    dispatch(resetItemsState());
                                    fetchItems(null);
                                } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                                >
                                <FaFilter />
                            </Button>
                        </div>
                    </div>
                    <div>
                        <div style={{ display: 'flex' }}>
                            {nextToken &&
                                <ToolTip tipText={'weitere Aufträge laden'}>
                                    <Button
                                        id='load-more-billed-items-button'
                                        variation='primary'
                                        isDisabled={isLoading}
                                        onClick={() => fetchItems(nextToken)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                        <HiCloudDownload />
                                    </Button>
                                </ToolTip>}
                            <Button
                                style={{ marginRight: '1rem', marginLeft: '1em' }}
                                onClick={() => dispatch(toggleMinimize())} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                            >
                                {minimize ?
                                    <FiMaximize2 /> :
                                    <FiMinimize2 />
                                }
                            </Button>
                        </div>
                    </div>
                </div>
            </StickyHeader>
            <ScrollView style={{ margin: '1rem', display: 'flex', flexDirection: 'column', gap: '1em' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                {uniqueOrderId.map(orderId => <OrderItemsCard key={orderId} orderId={orderId}></OrderItemsCard>)}
            </ScrollView>
        </ThemeProvider>
    )
}

export default withAuthenticator(BilledItemsPage);


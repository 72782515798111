import { Alert, Button, ScrollView, TextField, ThemeProvider, withAuthenticator } from '@aws-amplify/ui-react';
import { Color, zptheme } from '../theme';
import { ModelSortDirection, ProductStatus, Contractor, Product, OrderStatus, Shipment } from '../API';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../store/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { fetchFinishedItemsByContractorByStatusAsync, fetchFinishedItemsByStatusAsync, resetItemsState, setItemsInfo, toggleMinimize, updateItemAsync } from '../store/itemsSlice';
import StickyHeader from '../components/header/StickyHeader';
import { FilterByContractorSelecField } from '../components/header/FilterByContractorSelectField';
import { HiCloudDownload, HiOutlineDocumentDownload } from 'react-icons/hi';
import ToolTip from '../components/helper-components/ToolTip';
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi';
import OrderItemsCard from '../components/order/OrderItemsCard';
import { createDocument, setDocumentError, setLoading } from '../store/printDocumentsSlice';
import SearchOrderField from '../components/header/SearchOrderField';
import { getBillingTableData } from '../utils/create-billing-table-data';
import SimpleModal from '../components/helper-components/SimpleModal';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import ToolTipPopup from '../components/helper-components/ToolTipPopup';
import { FaFilter } from 'react-icons/fa';
import { fetchFutureToursAsync, resetTourState } from '../store/tourSlice';
import { setShipments } from '../store/shippingSlice';

// page for items in status packing_confirmed
function FinishedItemsPage({ user }: any) {
    const dispatch = useAppDispatch()
    const { items, filters, nextToken, minimize } = useSelector((state: RootState) => state.itemsState)
    const isLoading = useSelector((state: RootState) => state.itemsState.isLoading || state.documentState.isLoading)
    const { contractors } = useSelector((state: RootState) => state.configState)
    const { permittedContractors } = useSelector((state: RootState) => state.userState)
    const today = new Date()
    const [fromDate, setFromDate] = useState(today)
    const [toDate, setToDate] = useState(today)
    const [btModalOpen, setBtModalOpen] = useState(false)
    const [olderOrdersExist, setOlderOrdersExist] = useState(false)
    // filter by year, month and day
    const [year, setYear] = useState<number | undefined>(undefined)
    const [month, setMonth] = useState<number | undefined>(undefined)
    const [day, setDay] = useState<number | undefined>(undefined)
    const pageStatus = ProductStatus.packing_confirmed

    function filterItems(item: Product) {
        return ((item.name === filters.productName || !filters.productName) &&
            (item.orderNumber?.startsWith(filters.orderNumber) || !filters.orderNumber))
    }
    const filteredItems = items.filter(filterItems)
    const uniqueOrderId = [...new Set(filteredItems.map(item => item?.productOrderId))];
    const billableItems = filteredItems.filter(item => item.status === ProductStatus.packing_confirmed && item.order?.status === OrderStatus.finished)

    useEffect(() => {
        const finishedAtDates = billableItems.map(item => item.order?.finishedAt ? new Date(item.order.finishedAt).getTime() : item.order?.updatedAt ? new Date(item.order?.updatedAt).getTime() : new Date(item.createdAt).getTime())
        const oldestFinishedAtDate = new Date(Math.min(...finishedAtDates))
        const youngestFinishedAtDate = new Date(Math.max(...finishedAtDates))
        // set the to date to the youngest finished date if is a valid date
        if (youngestFinishedAtDate instanceof Date && !isNaN(youngestFinishedAtDate.getTime())) {
            setToDate(youngestFinishedAtDate)
        }
        // set from date to oldest finished date if is a valid date
        if (oldestFinishedAtDate instanceof Date && !isNaN(oldestFinishedAtDate.getTime())) {
            setFromDate(oldestFinishedAtDate)
            // set from date to the first of this month if it is not in the same month as today
            const utcDate = new Date(Date.UTC(youngestFinishedAtDate.getUTCFullYear(), youngestFinishedAtDate.getUTCMonth(), 1));
            if (oldestFinishedAtDate.getUTCMonth() !== youngestFinishedAtDate.getUTCMonth() || oldestFinishedAtDate.getUTCFullYear() !== youngestFinishedAtDate.getUTCFullYear()) {
                setFromDate(utcDate)
                setOlderOrdersExist(true)
            }
        }
        return () => {
            setFromDate(today)
            setToDate(today)
            setOlderOrdersExist(false)
        }
    }, [btModalOpen])

    useEffect(() => {
        fetchItems(null)
        return () => {
            dispatch(resetItemsState())
        }
    }, [filters.contractor])

    function fetchItems(nextToken: string | null) {
        let finishedAt = null
        if (year || month || day) {
            let dateString = ''
            if (year) dateString += year
            if (month) month < 10 ? dateString += '-0' + month : dateString += '-' + month
            if (day) day < 10 ? dateString += '-0' + day : dateString += '-' + day
            finishedAt = { beginsWith: dateString }
        }
        // filter should always be set on mounting app but if it is not yet we don't want to fetch any items
        if (filters.contractor) {
            if (filters.contractor === 'Alle') {
                // user filter for permitted contractors
                const filter = {
                    or: permittedContractors.map((c: Contractor) => { return { productContractorId: { eq: c.id } } })
                }
                dispatch(fetchFinishedItemsByStatusAsync({ status: pageStatus, nextToken, sortDirection: ModelSortDirection.DESC, filter, finishedAt }))
            } else {
                dispatch(fetchFinishedItemsByContractorByStatusAsync({ contractor_status: `${ filters.contractor }-${ pageStatus }`, nextToken, sortDirection: ModelSortDirection.DESC, finishedAt }))
            }
        }
    }

    // fetch tours in case the shipping method is changed to 'Ausfahrt durch Zuschnittprofi' and the itemsCard is maximized here
    // cannot fetch it in the tourSelectField because the tours will be loaded multiple times if there is more than 1 item
    useEffect(() => {
        dispatch(fetchFutureToursAsync({ nextToken: null }))
        return () => {
            dispatch(resetTourState())
        }
    }, [])

    // fetch shipments because we want to show them in the orderItemsCard but have no shipmentsRow where they are fetched
    useEffect(() => {
        const shipmentsHere: Shipment[] = []
        for (const item of filteredItems) {
            if (item?.shipment && !shipmentsHere.find(s => s?.id === item?.shipment?.id)) {
                shipmentsHere.push(item.shipment)
            }
            if (item.retoure_shipment && !shipmentsHere.find(s => s?.id === item?.retoure_shipment?.id)) {
                shipmentsHere.push(item.retoure_shipment)
            }
        }
        dispatch(setShipments(shipmentsHere))
        return () => {
            dispatch(setShipments([]))
        }
    }, [minimize])

    async function exportBillingTableToExcel() {
        dispatch(setLoading(true))
        if (fromDate.getMonth() !== toDate.getMonth() || fromDate.getFullYear() !== toDate.getFullYear()) {
            dispatch(setLoading(false))
            return dispatch(setDocumentError('Bitte wähle einen Zeitraum innerhalb eines Monats aus'))
        }
        const today = new Date()
        const dateString = today.toISOString().split('.')[0]
        const dateWithoutTime = dateString.split('T')[0]
        const contractor = contractors.find(el => el.id === filters.contractor)
        const fileName = `${ contractor?.name }-${ dateWithoutTime }`
        if (!contractor) {
            // this is not possible because the button is only shown if a contractor is selected
            dispatch(setLoading(false))
            return
        }
        const itemsBetweenDates = billableItems.filter(item => {
            const finishedDate = item.order?.finishedAt ? new Date(item.order.finishedAt) : item.order?.updatedAt ? new Date(item.order?.updatedAt) : new Date(item.createdAt)
            // the stored data is in a different timezone so we compare year, month and date (without time)
            const smallerOrEqualthanToDate = finishedDate.getFullYear() === toDate.getFullYear() && finishedDate.getMonth() === toDate.getMonth() && finishedDate.getDate() <= toDate.getDate()
            const biggerOrEqualthanFromDate = finishedDate.getFullYear() === fromDate.getFullYear() && finishedDate.getMonth() === fromDate.getMonth() && finishedDate.getDate() >= fromDate.getDate()
            dispatch(setLoading(false))
            return biggerOrEqualthanFromDate && smallerOrEqualthanToDate
        })

        const BillingTableData = getBillingTableData(itemsBetweenDates)
        if (!BillingTableData || BillingTableData.orders.length < 1) {
            const contractor = contractors.find(el => el.id === filters.contractor)
            dispatch(setItemsInfo(`Es gibt keine fertigen Aufträge in diesem Zeitraum, die abgerechnet werden können für ${ contractor?.name }`))
            dispatch(setLoading(false))
            return
        }

        for (const pos of BillingTableData.orders) {
            // entries for retoure shipping costs have no id
            if (pos.id) {
                const variables = {
                    input: {
                        id: pos.id,
                        status: ProductStatus.billed,
                        contractor_status: `${ contractor.id }-${ ProductStatus.billed }`,
                        billedAt: today.toISOString(),
                        billed_amount: pos.billablePerItem
                    }
                }
                const updateResponse = await dispatch(updateItemAsync(variables))
                if (updateResponse.type !== 'items/updateItem/fulfilled') {
                    // remove position from billingtable and continue
                    BillingTableData.orders = BillingTableData.orders.filter(el => el.id !== pos.id)
                    BillingTableData.billableSum = parseFloat((BillingTableData.billableSum - pos.billablePerItem).toFixed(2))
                }
            }
        }
        dispatch(createDocument({ data: BillingTableData, outputName: `${ fileName }.pdf`, templateName: 'samples/Billing_Table_Template.docx' }))
        dispatch(setLoading(false))
    }

    return (
        <ThemeProvider theme={zptheme}>
            <StickyHeader>
                <div style={{ display: 'flex', gap: '1em', flexWrap: 'wrap' }}>
                    <div style={{ display: 'flex', gap: '1em', flex: 1, flexWrap: 'wrap' }}>
                        <SearchOrderField pageStatus={pageStatus} onClear={() => fetchItems(null)} />
                        <div style={{ display: 'flex', gap: '1em' }}>
                            <FilterByContractorSelecField />
                            {filters.contractor !== 'Alle' &&
                                <Button
                                    isLoading={isLoading}
                                    onClick={() => setBtModalOpen(true)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                    <HiOutlineDocumentDownload />
                                </Button>}
                            <SimpleModal
                                style={{ width: '40em' }}
                                confirmBtnDisabled={fromDate.getMonth() !== toDate.getMonth() || fromDate.getFullYear() !== toDate.getFullYear() || billableItems.length === 0}
                                confirmAction={() => { exportBillingTableToExcel(); setBtModalOpen(false) }}
                                dismissAction={() => setBtModalOpen(false)}
                                open={btModalOpen}>
                                <div style={{ textAlign: 'center', marginBottom: '0.5em' }}>Abrechnungstabelle für {contractors.find(el => el.id === filters.contractor)?.name} drucken</div>
                                {billableItems.length > 0 ?
                                    <div style={{ display: 'flex', gap: '1em', alignItems: 'center', justifyContent: 'center' }}>
                                        <div>von</div>{olderOrdersExist && <ToolTipPopup
                                            trigger={<AiOutlineInfoCircle />}
                                            tipText={'Achtung: Es sind ältere Aufträge vorhanden'} />}
                                        <div><TextField
                                            label={'fromDate'}
                                            labelHidden={true}
                                            type={'date'}
                                            size='small'
                                            value={fromDate.toISOString().split('T')[0]}
                                            onChange={(e: any) => { console.log(new Date(e.target.value)); if (e.target.value) setFromDate(new Date(e.target.value)) }}
                                            onInput={(e: any) => { console.log('input:', new Date(e.target.value)); if (e.target.value) setFromDate(new Date(e.target.value)) }}
                                            hasError={fromDate.getMonth() !== toDate.getMonth() || fromDate.getFullYear() !== toDate.getFullYear()} /></div>
                                        <div>bis</div>
                                        <div><TextField
                                            label={'toDate'}
                                            labelHidden={true}
                                            type={'date'}
                                            size='small'
                                            value={toDate.toISOString().split('T')[0]}
                                            onChange={(e: any) => { console.log(new Date(e.target.value)); if (e.target.value) setToDate(new Date(e.target.value)) }}
                                            hasError={fromDate.getMonth() !== toDate.getMonth() || fromDate.getFullYear() !== toDate.getFullYear()} /></div>
                                    </div>
                                    :
                                    <Alert
                                        variation='info' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                        Es gibt aktuell keine abgeschlossenen Aufträge.
                                    </Alert>}
                            </SimpleModal>
                            <div style={{ display: 'flex', gap: '0.2em' }}>
                                <input
                                    id='year-input'
                                    placeholder='jjjj'
                                    style={{ border: `1px solid ${ Color.blue }`, borderRadius: '0.3em', padding: '0.5em', color: Color.blue }}
                                    type='number'
                                    min={2022}
                                    max={new Date().getFullYear()}
                                    step={1}
                                    value={year || ''}
                                    onChange={(e: any) => setYear(e.target.value)}
                                />
                                <input
                                    id='month-input'
                                    placeholder='mm'
                                    style={{ border: `1px solid ${ Color.blue }`, borderRadius: '0.3em', padding: '0.5em', backgroundColor: year === undefined ? Color.transparentgrey : 'white', color: Color.blue }}
                                    type='number'
                                    min={1}
                                    max={12}
                                    step={1}
                                    value={(month && month < 10) ? `0${ month }` : (`${ month }` || '')}
                                    disabled={year === undefined}
                                    onChange={(e: any) => setMonth(parseFloat(e.target.value))}
                                />
                                <input
                                    id='day-input'
                                    placeholder='tt'
                                    style={{ border: `1px solid ${ Color.blue }`, borderRadius: '0.3em', padding: '0.5em', backgroundColor: month === undefined ? Color.transparentgrey : 'white', color: Color.blue }}
                                    type='number'
                                    min={1}
                                    max={31}
                                    step={1}
                                    value={day || ''}
                                    disabled={month === undefined || month === null}
                                    onChange={(e: any) => { console.log(parseFloat(e.target.value)); setDay(parseFloat(e.target.value)) }}
                                />

                                <Button
                                    style={{ height: '2.5em', marginLeft: '0.2em' }}
                                    type='submit'
                                    onClick={() => {
                                        dispatch(resetItemsState());
                                        fetchItems(null);
                                    } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                                >
                                    <FaFilter />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ display: 'flex' }}>
                            {nextToken &&
                                <ToolTip tipText={'weitere Aufträge laden'}>
                                    <Button
                                        variation='primary'
                                        isDisabled={isLoading}
                                        onClick={() => fetchItems(nextToken)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                        <HiCloudDownload />
                                    </Button>
                                </ToolTip>}
                            <Button
                                style={{ marginRight: '1rem', marginLeft: '1em' }}
                                onClick={() => dispatch(toggleMinimize())} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                            >
                                {minimize ?
                                    <FiMaximize2 /> :
                                    <FiMinimize2 />
                                }
                            </Button>

                        </div>
                    </div>
                </div>
            </StickyHeader>
            <ScrollView style={{ margin: '1rem', display: 'flex', flexDirection: 'column', gap: '1em' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                {uniqueOrderId.map(orderId => <OrderItemsCard key={orderId} orderId={orderId}></OrderItemsCard>)}
            </ScrollView>
        </ThemeProvider>
    )
}

export default withAuthenticator(FinishedItemsPage);


import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API, graphqlOperation } from 'aws-amplify'
import { Contractor, CreateContractorMutationVariables, UpdateContractorMutationVariables } from '../API'
import { createContractor, updateContractor } from '../graphql/mutations'
import { listContractors } from '../graphql/queries'

interface configState {
    contractors: Contractor[]
    isLoading: boolean
    error: undefined | string
}

const initialConfigState = {
    contractors: [],
    isLoading: false,
    error: undefined
} as configState

export const fetchContractorsAsync = createAsyncThunk(
    'config/fetchContractors',
    async () => {
        const response = await API.graphql(graphqlOperation(listContractors))
        //@ts-ignore
        return response.data.listContractors.items
    }
)

export const createContractorAsync = createAsyncThunk(
    'config/createContractor',
    async (variables: CreateContractorMutationVariables) => {
        try {
            const response = await API.graphql(graphqlOperation(createContractor, variables))
            //@ts-ignore
            return { contractors: response.data.createContractor }
        } catch (error) {
            //@ts-ignore
            return { error: error.errors.map(error => error.message) }

        }

    }
)

export const updateContractorAsync = createAsyncThunk(
    'config/updateContractor',
    async (variables: UpdateContractorMutationVariables) => {
        try {
            const response = await API.graphql(graphqlOperation(updateContractor, variables))
            //@ts-ignore
            return { contractor: response.data.updateContractor }
        } catch (error) {
            //@ts-ignore
            return { error: error.errors.map(error => error.message) }
        }
    }
)

const configSlice = createSlice({
    name: 'config',
    initialState: initialConfigState,
    reducers: {
        resetConfigState: (state) => {
            state = initialConfigState
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchContractorsAsync.fulfilled, (state, action) => {
            state.contractors = action.payload
        })
        builder.addCase(fetchContractorsAsync.rejected, (state, action) => {
            const errorMessage = `Die Fertiger konnten nicht geladen werden: ${ JSON.stringify(action.error) }`
            state.error = errorMessage

        })
        builder.addCase(createContractorAsync.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(createContractorAsync.fulfilled, (state, action) => {
            state.isLoading = false
            if (action.payload.contractors) {
                state.contractors.push(action.payload.contractors)
            }
            if (action.payload.error) {
                state.error = `Es ist ein Fehler beim Erstellen des Fertigers aufgetreten: ${ action.payload.error }`
            }
        })
        builder.addCase(createContractorAsync.rejected, (state, action) => {
            const errorMessage = `Beim Erstellen des Fertigers ist ein Fehler aufgetreten: ${ JSON.stringify(action.error) }`
            state.isLoading = false
            state.error = errorMessage
        })
        builder.addCase(updateContractorAsync.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(updateContractorAsync.fulfilled, (state, action) => {
            state.isLoading = false
            const { contractor } = action.payload
            if (contractor) {
                const updatedContractor = state.contractors.find(el => el.id === contractor.id)
                if (updatedContractor) {
                  //@ts-ignore
                  const index = state.contractors.indexOf(updatedContractor)
                  state.contractors[index] = contractor
                }
            }
            if (action.payload.error) {
                state.error = `Es ist ein Fehler beim Ändern des Fertigers aufgetreten: ${ action.payload.error }`
            }
        })
        builder.addCase(updateContractorAsync.rejected, (state, action) => {
            const errorMessage = `Beim Ändern des Fertigers ist ein Fehler aufgetreten: ${ JSON.stringify(action.error) }`
            state.isLoading = false
            state.error = errorMessage
        })
    }
})

export const { resetConfigState } = configSlice.actions

export default configSlice.reducer
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';

interface MailState {
    isLoading: boolean;
    mailError: string;
    mailInfo: string;
}

interface MailData {
    template_key: string;
    template_name: string;
    subject: string;
    email: string;
    merge_info: any;
}

const initialMailState = {
    isLoading: false,
    mailError: '',
    mailInfo: '',
};


export const sendMailTemplate = createAsyncThunk(
    'mail/sendMailTemplate',
    async (mailData: MailData) => {
        const apiName = 'mail';
        const path = '/deliverynotice';
        const init = {
            body: mailData
        }
        const response = await API.post(apiName, path, init)
        return { response, template_name: mailData.template_name, email: mailData.email}
    }
);

export const mailSlice = createSlice({
    name: 'mail',
    initialState: initialMailState as MailState,
    reducers: {
        resetMailState: (state) => {
            state.mailError = '';
            state.mailInfo = '';
        },
        setMailInfo: (state, action) => {
            state.mailInfo = action.payload;
        },
        setMailError: (state, action) => {
            state.mailError = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(sendMailTemplate.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(sendMailTemplate.fulfilled, (state, action) => {
            state.isLoading = false;
            // @ts-ignore
            state.mailInfo = `${ action.payload.template_name } wurde an ${ action.payload.email } gesendet.`;
        });
        builder.addCase(sendMailTemplate.rejected, (state, action) => {
            state.isLoading = false;
            console.log('rejected action', JSON.stringify(action))
            const error = `Beim Senden der Mail ist ein Fehler aufgetreten: ${ JSON.stringify(action.error.message) }`;
            state.mailError = error;
        });
    },
});

export const { resetMailState, setMailInfo, setMailError } = mailSlice.actions;

export default mailSlice.reducer;
import { Product } from '../API'
import { ShippingMethods, ServiceProvider } from './Constants'
import { calculateShipmentsMeasurements, calculateShipmentsWeight, getPossibleShippingServiceProvider, getCheapestShippingServiceProvider, calculateShippingCostsGLS } from './shipments-calc'

export function createNewShipmentVars(products: Product[], shipping_method: string | null | undefined, typename?: string | null | undefined) {
    const { maxLength, maxWidth, gurtMass, height } = calculateShipmentsMeasurements(products)
    let suggest_splitting = false
    const weight = calculateShipmentsWeight(products)
    const itemsCount = products.reduce((acc: number, item: Product) => acc + (item.quantity || 1), 0)
    const possibleServiceProviders = getPossibleShippingServiceProvider(maxLength, maxWidth, weight, gurtMass, height, itemsCount, products[0].order?.customer?.zip || '', shipping_method)
    let serviceProvider = shipping_method === ShippingMethods.EIGENAUSFAHRT ?
        {
            name: 'Blanko',
            costs: 2.75
        }
        : getCheapestShippingServiceProvider(possibleServiceProviders)

    // Testweise für 4 Wochen GLS ggü. DPD bevorzugen, weil die Transportschäden bei Holz zunehmen
    const firstContractor = products[0]?.contractor?.name
    if (firstContractor === 'HAMBURGER-HOLZMANUFAKTUR' || firstContractor === 'ZP-SCHENEFELD') {
        if (serviceProvider.name === ServiceProvider.DPD) {
            const maxValue = Math.max(maxLength, maxWidth)
            const minValue = Math.min(maxLength, maxWidth)
            if (maxValue >= 76.5 && minValue >= 51) {
                serviceProvider = { name: ServiceProvider.GLS, costs: parseFloat(calculateShippingCostsGLS(maxLength, maxWidth, weight)) }
            }
        }
    }
    if (!serviceProvider.name || !serviceProvider.costs) {
        throw new Error('No shipping service provider found')
    }
    if (serviceProvider.name === ServiceProvider.INLINE) {
        if (products.length > 1 || (products[0].quantity && products[0].quantity > 1)) {
            suggest_splitting = true
        }
    }
    return {
        input: {
            typename: typename || 'Shipment',
            length: maxLength,
            width: maxWidth,
            height: height,
            gurtMass,
            weight,
            serviceProvider: serviceProvider.name,
            costs: serviceProvider.costs,
            suggest_splitting
        }
    }
}
import { useState } from 'react'
import styled from 'styled-components'
import { Color } from '../../theme';

const ToolTipBox = styled.div`
    background-color: #fff;
    color: ${Color.lightgrey};
    text-align: center;
    border-radius: 2px;
    box-shadow: 5px 5px 10px silver;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 1;
    transition: opacity 0.3s;
`

function ToolTip({ tipText, children, style }: { tipText: any, children?: any, style?: any }) {
    const [tipVisibility, setTipVisibility] = useState<'hidden' | 'visible'>('hidden')

    return (
        <div
            style={{ position: 'relative', ...style }}
            onMouseEnter={() => setTipVisibility('visible')}
            onMouseLeave={() => setTipVisibility('hidden')}>
            {children}
            <ToolTipBox style={{ visibility: tipVisibility, ...style }}>{tipText}</ToolTipBox>
        </div>
    )
}

export default ToolTip
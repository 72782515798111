import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';

interface DeskState {
    isLoading: boolean;
    deskError: string;
    deskInfo: string;
}

interface DeskTicketData {
    firstName: string;
    lastName: string;
    email: string;
    orderNumber: string;
    user: string;
}

const initialDeskState = {
    isLoading: false,
    deskError: '',
    deskInfo: '',
};


export const createDeskTicketAsync = createAsyncThunk(
    'desk/createDeskTicket',
    async (data: DeskTicketData) => {
        const apiName = 'zohoapi';
        const path = '/desk';
        const init = {
            body: data
        }
        const response = await API.post(apiName, path, init)
        return { response }
    }
);

export const deskSlice = createSlice({
    name: 'desk',
    initialState: initialDeskState as DeskState,
    reducers: {
        resetDeskState: (state) => {
            state.deskError = '';
            state.deskInfo = '';
        },
        setDeskInfo: (state, action) => {
            state.deskInfo = action.payload;
        },
        setDeskError: (state, action) => {
            state.deskError = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createDeskTicketAsync.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createDeskTicketAsync.fulfilled, (state, action) => {
            state.isLoading = false;
            const url = `https://desk.zoho.eu/agent/nipsild/service/tickets/details/${ action.payload.response }`
            state.deskInfo = url;
        });
        builder.addCase(createDeskTicketAsync.rejected, (state, action) => {
            state.isLoading = false;
            console.log('rejected action', JSON.stringify(action))
            const error = `Beim Erstellen des Tickets ist ein Fehler aufgetreten: ${ JSON.stringify(action.error.message) }`;
            state.deskError = error;
        });
    },
});

export const { resetDeskState, setDeskInfo, setDeskError } = deskSlice.actions;

export default deskSlice.reducer;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API, graphqlOperation } from 'aws-amplify'
import { CreateTourMutationVariables, DeleteTourMutationVariables, ModelSortDirection, Tour, UpdateTourMutationVariables } from '../API'
import { createTour, deleteTour, updateTour } from '../graphql/mutations'
import { getTour, toursByDate } from '../graphql/queries'
import { prettyDate } from '../utils'

interface tourState {
    futureTours: Tour[]
    pastTours: Tour[]
    isLoading: boolean
    error: undefined | string
    nextTokenFutureTours: string | undefined
    nextTokenPastTours: string | undefined
    infoMsg: string | undefined
}

const initialTourState = {
    futureTours: [],
    pastTours: [],
    isLoading: false,
    error: undefined,
    nextTokenFutureTours: undefined,
    nextTokenPastTours: undefined,
    infoMsg: undefined
} as tourState

export const fetchFutureToursAsync = createAsyncThunk(
    'tours/fetchFutureTours',
    async ({ nextToken }: { nextToken?: string | null }) => {
        const yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1)
        const variables = {
            typename: 'Tour',
            date: {
                ge: yesterday
            },
            nextToken
        }
        const response = await API.graphql(graphqlOperation(toursByDate, variables))
        //@ts-ignore
        return { tours: response.data.toursByDate.items, newNextToken: response.data.toursByDate.nextToken }
    }
)

export const fetchPastToursAsync = createAsyncThunk(
    'tours/fetchPastTours',
    async ({ nextToken }: { nextToken?: string | null }) => {
        const yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1)
        const variables = {
            typename: 'Tour',
            date: {
                lt: yesterday
            },
            sortDirection: ModelSortDirection.DESC,
            nextToken
        }
        const response = await API.graphql(graphqlOperation(toursByDate, variables))
        //@ts-ignore
        return { tours: response.data.toursByDate.items, newNextToken: response.data.toursByDate.nextToken }
    }
)

export const fetchTourByIdAsync = createAsyncThunk(
    'tours/fetchTourById',
    async (id: string) => {
        const response = await API.graphql(graphqlOperation(getTour, { id }))
        //@ts-ignore
        return response.data.getTour
    }
)

export const createTourAsync = createAsyncThunk(
    'tours/createTour',
    async (variables: CreateTourMutationVariables) => {
        try {
            const response = await API.graphql(graphqlOperation(createTour, variables))
            //@ts-ignore
            return { tours: response.data.createTour }
        } catch (error) {
            //@ts-ignore
            return { error: error.errors.map(error => error.message) }

        }

    }
)

export const updateTourAsync = createAsyncThunk(
    'tours/updateTour',
    async (variables: UpdateTourMutationVariables) => {
        try {
            const response = await API.graphql(graphqlOperation(updateTour, variables))
            //@ts-ignore
            return { tour: response.data.updateTour }
        } catch (error: any) {
            let errorString = JSON.stringify(error)
            if (error.errors.find((e: any) => e.massage)) {
                errorString = error.errors.map((e: any) => e.message).join(', ')
            }
            throw new Error(errorString)
        }
    }
)

export const deleteTourAsync = createAsyncThunk(
    'tours/deleteTour',
    async (variables: DeleteTourMutationVariables) => {
        try {
            const response = await API.graphql(graphqlOperation(deleteTour, variables))
            //@ts-ignore
            return { tour: response.data.deleteTour }
        } catch (error: any) {
            let errorString = JSON.stringify(error)
            if (error.errors.find((e: any) => e.massage)) {
                errorString = error.errors.map((e: any) => e.message).join(', ')
            }
            throw new Error(errorString)
        }
    }
)

const tourSlice = createSlice({
    name: 'tours',
    initialState: initialTourState,
    reducers: {
        resetTourState: (state) => {
            state.futureTours = []
            state.error = undefined
            state.isLoading = false
            state.nextTokenFutureTours = undefined
            state.nextTokenPastTours = undefined
            state.pastTours = []
        },
        setTourError: (state, action) => {
            state.error = action.payload
        },
        setTourInfo: (state, action) => {
            state.infoMsg = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFutureToursAsync.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(fetchFutureToursAsync.fulfilled, (state, action) => {
            state.futureTours.push(...action.payload.tours)
            state.nextTokenFutureTours = action.payload.newNextToken
            state.isLoading = false
        })
        builder.addCase(fetchFutureToursAsync.rejected, (state, action) => {
            const errorMessage = `Die Touren konnten nicht geladen werden: ${ JSON.stringify(action.error) }`
            state.error = errorMessage
        })
        builder.addCase(fetchPastToursAsync.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(fetchPastToursAsync.fulfilled, (state, action) => {
            state.pastTours.push(...action.payload.tours)
            state.nextTokenPastTours = action.payload.newNextToken
            state.isLoading = false
        })
        builder.addCase(fetchPastToursAsync.rejected, (state, action) => {
            const errorMessage = `Die Touren konnten nicht geladen werden: ${ JSON.stringify(action.error) }`
            state.error = errorMessage
        })
        builder.addCase(fetchTourByIdAsync.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(fetchTourByIdAsync.fulfilled, (state, action) => {
            state.isLoading = false
        })
        builder.addCase(fetchTourByIdAsync.rejected, (state, action) => {
            const errorMessage = `Die Tour konnte nicht geladen werden: ${ JSON.stringify(action.error) }`
            state.error = errorMessage
        })
        builder.addCase(createTourAsync.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(createTourAsync.fulfilled, (state, action) => {
            state.isLoading = false
            if (action.payload.tours) {
                state.futureTours.push(action.payload.tours)
            }
            if (!action.payload.error) {
                state.infoMsg = 'Die Tour wurde erfolgreich erstellt'
            }
            if (action.payload.error) {
                state.error = `Es ist ein Fehler beim Erstellen der Tour aufgetreten: ${ action.payload.error }`
            }
        })
        builder.addCase(createTourAsync.rejected, (state, action) => {
            const errorMessage = `Beim Erstellen der Tour ist ein Fehler aufgetreten: ${ JSON.stringify(action.error) }`
            state.isLoading = false
            state.error = errorMessage
        })
        builder.addCase(updateTourAsync.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(updateTourAsync.fulfilled, (state, action) => {
            state.isLoading = false
            if (action.payload.tour) {
                const index = state.futureTours.findIndex(tour => tour.id === action.payload.tour.id)
                state.futureTours[index] = action.payload.tour
            }
        })
        builder.addCase(updateTourAsync.rejected, (state, action) => {
            state.isLoading = false
            const errorMessage = `Beim Aktualisieren der Tour ist ein Fehler aufgetreten: ${ JSON.stringify(action.error.message) }`
            state.error = errorMessage
        })
        builder.addCase(deleteTourAsync.pending, (state, action) => {
            state.infoMsg = undefined
            state.isLoading = true
        })
        builder.addCase(deleteTourAsync.fulfilled, (state, action) => {
            state.isLoading = false
            if (action.payload.tour) {
                state.futureTours = state.futureTours.filter(tour => tour.id !== action.payload.tour.id)
            }
            state.infoMsg = `Die Tour vom ${ prettyDate(action.payload.tour.date) } wurde erfolgreich gelöscht`
        })
        builder.addCase(deleteTourAsync.rejected, (state, action) => {
            state.isLoading = false
            const errorMessage = `Beim Löschen der Tour ist ein Fehler aufgetreten: ${ JSON.stringify(action.error.message) }`
            state.error = errorMessage
        })
    }
})

export const { resetTourState, setTourError, setTourInfo } = tourSlice.actions

export default tourSlice.reducer
import { prettyDate } from '.';
import { Product } from '../API';
import { BillingTableData } from '../store/printDocumentsSlice';

export function getBillingTableData(items: Product[]) {
    const BillingTableData = {
        orders: [],
        billableSum: 0,
        headline: 'Abrechnungstabelle'
    } as unknown as BillingTableData


    for (const item of items) {
        if (!item.order) continue
        let billablePerItem = 0;
        let price = item.total_net_price ? item.total_net_price : 0;
        if (item.nipsild_fixed_costs) price -= (item.nipsild_fixed_costs / 1.19)
        const billingRate = item.contractor?.billing_rate ? item.contractor.billing_rate : 0
        const billingMax = item.contractor?.billing_max ? item.contractor.billing_max : 0
        if (billingRate) {
            billablePerItem = price * billingRate / 100
            billablePerItem = parseFloat(billablePerItem.toFixed(2))
            if (item.retoure_manufacturing_fee) billablePerItem = item.retoure_manufacturing_fee
            if (billingMax > 0) {
                // find items belonging to the same order in billing Table
                // add all amounts and check if they are bigger than billingMax
                // set billablePerItem to billingMax or the difference between billingMax and the sum of all items
                const itemsInBillingTable = BillingTableData.orders.filter(o => o.orderNumber === item.orderNumber)
                const sumOfItemsInBillingTable = itemsInBillingTable.reduce((acc, cur) => acc + cur.billablePerItem, 0)
                const difference = billingMax - sumOfItemsInBillingTable
                if (billablePerItem > difference) {
                    billablePerItem = difference
                }
            }
        }
        if (item.retoure_refund_amount) billablePerItem = item.retoure_refund_amount * (-1)
        if (item.retoure_refund_amount && item.retoure_refund_contractors_share) billablePerItem = billablePerItem * item.retoure_refund_contractors_share / 100
        const date = item.order && item.order.finishedAt ? prettyDate(item.order.finishedAt) : prettyDate(item.order?.updatedAt || item.order.createdAt)
        const AllItemsOfOrder = items.filter(i => i.productOrderId === item.productOrderId)
        // position is not correct if we filter the items by date, maybe we need to fetch the order or all items by order Number ?
        // I think it is ok, to just show the position of the order in this billing table
        const pos = AllItemsOfOrder.indexOf(item) + 1
        BillingTableData.orders.push({
            date,
            orderNumber: item.order?.orderNumber,
            pos,
            id: item.id,
            material: item.name,
            quantity: item.quantity,
            length: item.length,
            width: item.width,
            tasks: item.tasks ? item.tasks.toString() : '',
            netPrice: parseFloat(price.toFixed(2)),
            billablePerItem
        })
        BillingTableData.billableSum += billablePerItem
    }
    // find unique order numbers and substitute the retoure shipping costs if there are any
    const retoureitems = items.filter(i => i.order?.retoure_shipping_costs)
    const uniqueRetoureOrders = retoureitems.map(i => i.order?.orderNumber).filter((value, index, self) => self.indexOf(value) === index)
    
    for (const orderNumber of uniqueRetoureOrders) {
        // find complete order and push shipping costs as net price to billing table data
        const order = items.find(i => i.order?.orderNumber === orderNumber)?.order
        if (!order) continue
        const shippingCosts = order?.retoure_shipping_costs
        const date = order?.finishedAt ? prettyDate(order.finishedAt) : prettyDate(order?.updatedAt || order.createdAt)
        if (shippingCosts) {
            BillingTableData.orders.push({
                date,
                orderNumber: order.orderNumber,
                pos: 0,
                id: '',
                material: 'Retoure Versandkosten',
                quantity: 1,
                length: 0,
                width: 0,
                tasks: '',
                netPrice: 0,
                billablePerItem: shippingCosts * (-1)
            })
            BillingTableData.billableSum -= shippingCosts
        }
    }    
    BillingTableData.billableSum = parseFloat(BillingTableData.billableSum.toFixed(2))

    return BillingTableData
}
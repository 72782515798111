import { Button, Card, ThemeProvider } from '@aws-amplify/ui-react';
import { MouseEventHandler } from 'react';
import Popup from 'reactjs-popup';
import { zptheme } from '../../theme';


const contentStyle = { background: 'transparent' }
const overlayStyle = { background: 'rgba(0,0,0,0.5)' }

function Modal({ triggerButton, action, modal, children }: { triggerButton: JSX.Element, action: any, modal?: boolean, children?: any }) {
    return (
        <ThemeProvider theme={zptheme}>
            <Popup
                trigger={triggerButton}
                position='center center'
                closeOnDocumentClick
                arrow={false}
                modal={modal}
                {...{ contentStyle, overlayStyle }}
            >
                {/* @ts-ignore */}
                {(close: MouseEventHandler<HTMLButtonElement>) => (
                    <ThemeProvider theme={zptheme}>
                        {/* <div style={{ textAlign: 'end' }}>
                            <button style={{borderRadius: '1em', border: Color.blue}} onClick={close}>
                                <GrFormClose />
                            </button>
                        </div> */}
                        <Card style={{ width: '25rem', borderRadius: '0.5em' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <div style={{marginTop: '1em'}}>
                                {children}
                            </div>
                            <div style={{height: 20}}/>
                            <div style={{ textAlign: 'center' }}>
                                <Button
                                    style={{ marginRight: '1rem' }}
                                    size='small'
                                    variation='primary'
                                    onClick={() => {
                                        action();
                                        //@ts-ignore
                                        close();
                                    } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                                >
                                    OK
                                </Button>
                                <Button
                                    size='small'
                                    variation='primary'
                                    onClick={close} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                                >
                                    Abbrechen
                                </Button>
                            </div>
                        </Card>
                    </ThemeProvider>
                )}
            </Popup>
        </ThemeProvider>
    )
}

export default Modal;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export interface LabelData {
    messages: LabelMessage[]
}

export interface LabelMessage {
    material?: String,
    msg?: String,
    laenge?: Number | null,
    breite?: Number | null,
    option1?: String,
    option2?: String,
    option3?: String
    option4?: String
    option5?: String
}

export interface UnstampedLetterData {
    sender: string,
    first_name: string,
    last_name: string,
    address_1: string,
    address_2?: string,
    postcode: string,
    city: string,
    country: string,
    order_number: string
}

export interface BillingTableData {
    orders: OrderData[],
    billableSum: number
}

export interface OrderData {
    date: string,
    orderNumber?: string | null,
    pos?: number | null,
    id: string,
    material?: string | null,
    quantity?: number | null,
    length?: number | null,
    width?: number | null,
    tasks: string,
    netPrice?: number | null,
    billablePerItem: number
}

interface DocumentState {
    isLoading: boolean,
    statusCode: number | undefined,
    error: string | undefined,
    selectedOrderIds: string[]
}

const initialDocumentState = {
    isLoading: false,
    statusCode: undefined,
    error: undefined,
    selectedOrderIds: []
} as DocumentState

export const createDocument = createAsyncThunk(
    'documents/createDocument',
    async ({data, outputName, templateName}: {data: LabelData | BillingTableData | UnstampedLetterData, outputName: string, templateName: string}) => {
        try {
            const response = await fetch(`https://eu.dws3.docmosis.com/api/render`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    method: "POST",
                    body: JSON.stringify({
                        accessKey: 'YjdhMDE2N2YtYzg5YS00MjhiLTgwNWUtOTgxOTQwOTg0YjFjOjk1MTQ1NzI',
                        templateName,
                        outputName,
                        data
                    })
                })
            if (response.status === 200) {
                // this opens the nativ download alert to save the label
                const blob = await response.blob();
                const newBlob = new Blob([blob]);
                const newUrl = window.URL.createObjectURL(newBlob);
                const link = document.createElement('a');
                link.href = newUrl;
                link.setAttribute('download', outputName);
                document.body.appendChild(link);
                link.click();
                if (link && link.parentNode) {
                    link.parentNode.removeChild(link);
                    //@ts-ignore
                    window.URL.revokeObjectURL(newBlob);
                }
                return { statusCode: response.status }
            } else {
                const errorResponse = await response.json()
                const error = 'Es ist ein Fehler aufgetreten, bitte kontaktiere die Administratorin mit dieser Fehlermeldung: \n' + errorResponse.shortMsg
                return { statusCode: response.status, error }
            }
        } catch (error) {
            const errorMessage = 'Es ist ein Fehler aufgetreten, bitte kontaktiere die Administratorin mit dieser Fehlermeldung: \n' + error
            return { error: errorMessage }
        }
    }
)

export const labelSlice = createSlice({
    name: 'documents',
    initialState: initialDocumentState,
    reducers: {
        resetDocumentState: (state) => {
            state.error = undefined
            state.statusCode = undefined
            state.selectedOrderIds = []
        },
        setDocumentError: (state, action) => {
            state.error = action.payload
        },
        addSelectedOrderId: (state, action) => {
            state.selectedOrderIds.push(action.payload)
        },
        removeSelectedOrderId: (state, action) => {
            state.selectedOrderIds = state.selectedOrderIds.filter(el => el !== action.payload)
        },
        resetSelectedOrderIds: (state) => {
            state.selectedOrderIds = []
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload
        }
    }, 
    extraReducers: (builder) => {
        builder.addCase(createDocument.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(createDocument.fulfilled, (state, action) => {
            const {statusCode, error } = action.payload
            state.statusCode = statusCode
            state.error = error
            state.isLoading = false
        })
        builder.addCase(createDocument.rejected, (state, action) => {
            state.error = 'Es ist ein Fehler beim Erstellen der Labels aufgetreten'
            state.isLoading = false
        })
    }
})

export const { resetDocumentState, setDocumentError, addSelectedOrderId, removeSelectedOrderId, resetSelectedOrderIds, setLoading } = labelSlice.actions

export default labelSlice.reducer
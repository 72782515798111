import { SelectField } from '@aws-amplify/ui-react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { useAppDispatch } from '../../store/hooks'
import { filterByContractor } from '../../store/itemsSlice'
import { Color } from '../../theme'

export const FilterByContractorSelecField = () => {
  const dispatch = useAppDispatch()
  const { contractor } = useSelector((state: RootState) => state.itemsState.filters)
  const { permittedContractors } = useSelector((state: RootState) => state.userState)
  
  return (
    <SelectField
      label={'contractor'}
      labelHidden={true}
      width={'20rem'}
      value={contractor}
      onChange={(e) => dispatch(filterByContractor(e.target.value))}
      id={'contractor-select-flied'}
      style={permittedContractors.length < 2 ? { background: Color.transparentgrey } : { color: Color.blue }}
      isDisabled={permittedContractors.length < 2} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}    >
      <option value="Alle">Alle Fertiger</option>
      {permittedContractors && permittedContractors.map((possibleContractor) => (
        <option key={possibleContractor.id} value={possibleContractor.id} id={'contractor-select-field-option'}>{possibleContractor.name}</option>
      ))
      }
    </SelectField>
  )
}
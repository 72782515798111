import { SearchField } from '@aws-amplify/ui-react';
import { useSelector } from 'react-redux';
import { fetchItemsByOrderNumberAsync, filterByOrderNumber, resetItemsState, setItemsInfo, setMinimize } from '../../store/itemsSlice';
import { ProductStatus } from '../../API';
import { RootState } from '../../store';
import { useAppDispatch } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';

function SearchOrderField({ pageStatus, onClear }: { pageStatus?: ProductStatus | null, onClear: any }) {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { filters } = useSelector((state: RootState) => state.itemsState)
    const { permittedContractors } = useSelector((state: RootState) => state.userState)

    function searchOrder() {
        const orderNumber = filters.orderNumber
        let variables
        if (pageStatus === ProductStatus.in_progress) {
            // it might be that we are on the /:task page so we go back to the ongoingItems page
            navigate('/orders/ongoingItems')
        }
        if (!orderNumber) {
            dispatch(resetItemsState());
            return onClear()
        }
        // 1. filter.contractor === 'Alle' && pageStatus === null search for all items of the permitted contractors
        if (filters.contractor === 'Alle' && !pageStatus) {
            variables = {
                orderNumber,
                filter: {
                    or: permittedContractors.map((c) => ({ productContractorId: { eq: c.id } }))
                }
            }
        }
        // 2. filter.contractor === 'Alle' && pageStatus !== null search for all items of the permitted contractors with specific status
        if (filters.contractor === 'Alle' && pageStatus) {
            variables = {
                orderNumber,
                filter: {
                    status: {
                        eq: pageStatus
                    },
                    or: permittedContractors.map((c) => ({ productContractorId: { eq: c.id } }))
                }
            }
        }
        // 3. filter.contractor !== 'Alle' && pageStatus === null search for all items of the specific contractor
        if (filters.contractor !== 'Alle' && !pageStatus) {
            variables = {
                orderNumber,
                filter: {
                    productContractorId: {
                        eq: filters.contractor
                    }
                }
            }
        }
        // 4. filter.contractor !== 'Alle' && pageStatus !== null search for all items of the specific contractor with specific status
        if (filters.contractor !== 'Alle' && pageStatus) {
            variables = {
                orderNumber,
                filter: {
                    and: [{
                        status: {
                            eq: pageStatus
                        },
                        productContractorId: {
                            eq: filters.contractor
                        }
                    }]
                }
            }
        }
        if (variables) {
            dispatch(setMinimize(false))
            dispatch(fetchItemsByOrderNumberAsync(variables))
        } else {
            dispatch(resetItemsState());
        }
    }

    {/* without the flex item the search button disappears to the right side of the screen */ }
    return (
        < div style={{ display: 'flex' }}>
            <SearchField
                label=""
                labelHidden={true}
                placeholder="Auftragsnummer"
                width={'20rem'}
                datatype={'Number'}
                value={filters.orderNumber}
                onChange={event => {
                    dispatch(setItemsInfo(''));
                    dispatch(filterByOrderNumber(`${ event.target.value }`));
                } }
                onClear={() => {
                    dispatch(filterByOrderNumber(''));
                    dispatch(resetItemsState());
                    onClear();
                } }
                onSubmit={() => searchOrder()}
                id="search-order-field"
                crossOrigin={'anonymous'} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            />
        </div >
    )
}

export default SearchOrderField;
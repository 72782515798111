import { useSelector } from 'react-redux';
import { Product, Tour as Tour } from '../../API';
import { RootState } from '../../store';
import { Color, zptheme } from '../../theme';
import { Expander, ExpanderItem, ThemeProvider, Button } from '@aws-amplify/ui-react';
import { useAppDispatch } from '../../store/hooks';
import { FcCancel } from 'react-icons/fc';
import { RiArrowRightDoubleFill, RiMailSendLine } from 'react-icons/ri';
import { statusColor, statusIcon, statusName } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { getOrder } from '../../graphql/queries';
import { resetMailState, sendMailTemplate } from '../../store/mailSlice';
import SimpleModal from '../helper-components/SimpleModal';
import { useState } from 'react';
import { updateTourAsync } from '../../store/tourSlice';
import { MdMarkEmailRead } from 'react-icons/md';
import TourHeader from './TourHeader';

function TourCard({ tour }: { tour: Tour }) {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [mailModalOpen, setMailModalOpen] = useState(false)
    const [time, setTime] = useState<string | undefined>(undefined)
    const [selectedItem, setSelectedItem] = useState<Product | null>(null)
    const { usergroups } = useSelector((state: RootState) => state.userState)
    const { filters } = useSelector((state: RootState) => state.itemsState)
    const { isLoading } = useSelector((state: RootState) => state.mailState)
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    const filteredTourItems = usergroups.includes('managers') ? tour.products?.items : tour.products?.items.filter(i => i?.productContractorId === filters.contractor)
    let tourTitle = filteredTourItems && (filteredTourItems.length > 1 ? `${ filteredTourItems.length } Positionen` : `${ filteredTourItems.length } Position`)
    const tourIncludesDecliedItems = filteredTourItems && filteredTourItems.some(i => i?.declinedTourDates && i.declinedTourDates.includes(tour.date))

    function declinedTour(tour: Tour, item?: Product | null) {
        if (!item) return true
        return item.declinedTourDates && item.declinedTourDates.includes(tour.date)
    }

    async function sendDeliveryNotification() {
        if (!selectedItem || !time) return
        dispatch(resetMailState())
        // get order data
        const orderResponse = await API.graphql(graphqlOperation(getOrder, { id: selectedItem.productOrderId }))
        //@ts-ignore
        const order = orderResponse.data.getOrder
        const eta = new Date(tour.date).setHours(parseInt(time?.split(':')[0]), parseInt(time?.split(':')[1]))
        // Set earliest and latest and calculate the eta range here because the server is in a different timezone
        const earliestETA = new Date(eta - 30 * 60 * 1000)
        const latestETA = new Date(eta + 150 * 60 * 1000)
        const etaRange = `${ new Date(earliestETA).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' }) } - ${ new Date(latestETA).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' }) }`;
        const germanDate = new Date(tour.date).toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit' })

        const data = {
            template_key: '13ef.53e3308d7370c518.k1.d5c86a70-cc9c-11ee-824f-52540063e0e7.18db0d02297',
            template_name: 'Paketankündigung',
            // subject is required but not used because subject is defined in the template
            subject: `Deine Zuschnittprofi.de Bestellung #${ selectedItem.orderNumber } kommt bald`,
            email: order.customer.email,
            merge_info: {
                orderNumber: selectedItem.orderNumber,
                first_name: order.customer.first_name,
                last_name: order.customer.last_name,
                date: germanDate,
                etaRange,
            }
        }
        // send email
        const sendMailAction = await dispatch(sendMailTemplate(data))
        setMailModalOpen(false)
        setSelectedItem(null)
        if (sendMailAction.type === 'mail/sendMailTemplate/fulfilled') {
            const deliveryNotificationSentTo = tour.deliveryNotificationSentTo && tour.deliveryNotificationSentTo.length > 0 ? [...tour.deliveryNotificationSentTo, order.orderNumber] : [order.orderNumber]
            const variables = {
                input: {
                    id: tour.id,
                    deliveryNotificationSentTo
                }
            }
            dispatch(updateTourAsync(variables))
        }
    }

    return (
        <ThemeProvider theme={zptheme}>
            <div style={{
                border: `solid ${ Color.blue }`,
                borderRadius: '0.5em',
                color: `${ Color.blue }`,
                padding: '0.5em',
            }}
                key={tour.id} >
                <SimpleModal open={mailModalOpen} confirmAction={sendDeliveryNotification} dismissAction={() => { setMailModalOpen(false); setSelectedItem(null) }} okBtnText='Akündigung senden'>
                    <div>Wann wird der Auftrag #{selectedItem?.orderNumber} voraussichtlich ausgeliefert ?</div>
                    <input
                        type="time"
                        onInput={(e) => setTime(e.currentTarget.value)}
                        onChange={(e) => setTime(e.target.value)} />
                </SimpleModal>
                <TourHeader tourId={tour.id} />
                {filteredTourItems && filteredTourItems.length > 0 &&
                    <Expander
                        isCollapsible={true}
                        type="single" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <ExpanderItem
                            title={<div style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>{tourTitle}{tourIncludesDecliedItems && <FcCancel />}</div>}
                            value="demo-item-1" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            {filteredTourItems.map((item, index) =>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div key={index} style={{ flex: 1, display: 'flex', gap: '0.5em', color: declinedTour(tour, item) ? Color.red : Color.blue }}>
                                        <div
                                            onClick={() => { navigate(`/orders/${ item?.orderNumber }`) }}
                                            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                            <RiArrowRightDoubleFill />
                                            {declinedTour(tour, item) && <FcCancel style={{ fontWeight: 'bold' }} />}
                                            {item?.orderNumber}:
                                        </div>
                                        <div>{item?.name}</div>
                                        <div style={{ display: 'flex', alignItems: 'center', color: statusColor(item?.status) }}>
                                            {statusIcon(item?.status)} {statusName(item?.status)}
                                        </div>
                                    </div>
                                    {usergroups.includes('managers') && <div>
                                        <Button
                                            style={{ margin: '0.1em' }}
                                            size="small"
                                            placeholder={'sendMailButton'}
                                            onClick={() => {
                                                dispatch(resetMailState());
                                                setMailModalOpen(true);
                                                setSelectedItem(item);
                                            }}
                                            isLoading={isLoading}
                                            isDisabled={declinedTour(tour, item) || (item?.orderNumber && tour.deliveryNotificationSentTo?.includes(item.orderNumber)) || new Date(tour.date) < new Date() ? true : false} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                                        >
                                            {item?.orderNumber && tour.deliveryNotificationSentTo?.includes(item.orderNumber) ?
                                                <MdMarkEmailRead style={{ color: Color.green }} />
                                                :
                                                <RiMailSendLine />
                                            }
                                        </Button>

                                    </div>}
                                </div>
                            )}
                        </ExpanderItem>
                    </Expander>
                }
            </div>
        </ThemeProvider >
    )
}

export default TourCard
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API, graphqlOperation } from 'aws-amplify'
import { finishedItemsByContractorByStatus, finishedItemsByStatus, itemsByContractorTask, itemsByCurrentTask, itemsByOrderNumber, itemsByUpdateDate, ongoingItemsByContractorByStatus, ongoingItemsByStatus } from '../graphql/queries'
import { CreateProductMutationVariables, ModelProductFilterInput, ModelSortDirection, ModelStringKeyConditionInput, Product, UpdateOrderMutationVariables, UpdateProductInput, UpdateProductMutationVariables } from '../API'
import { createProduct, updateOrder, updateProduct } from '../graphql/mutations'
import { taskNames } from '../utils/Constants'
import { customOrdersByShippingMethod } from '../graphql/customQueries'

export interface ItemsState {
  items: Product[],
  editable: string, // productOrderId
  updateItemInputs: UpdateProductInput[],
  nextToken: string | null,
  filters: Filters,
  subMenuTasks: string[],
  minimize: boolean
  isLoading: boolean,
  error: string | undefined
  info: string | undefined
}
export interface Filters {
  orderNumber: string
  contractor: string
  productName: string
}

export const initialItemsState = {
  items: [],
  editable: '',
  updateItemInputs: [],
  nextToken: null,
  filters: {
    orderNumber: '',
    contractor: '',
    productName: '',
  },
  subMenuTasks: [],
  minimize: true,
  isLoading: false,
  error: undefined,
  info: undefined
} as ItemsState

export const fetchFinishedItemsByStatusAsync = createAsyncThunk(
  'items/fetchFinishedItemsByStatusAsync',
  async ({ status, nextToken, sortDirection, filter, finishedAt }: { status: string | null, nextToken?: string | null, sortDirection?: ModelSortDirection | null, filter?: ModelProductFilterInput | null, finishedAt?: ModelStringKeyConditionInput | null }) => {
    const variables = {
      status,
      nextToken,
      sortDirection,
      filter,
      finishedAt,
      limit: 50
    }
    const response = await API.graphql(graphqlOperation(finishedItemsByStatus, variables))
    //@ts-ignore
    const items = response.data.finishedItemsByStatus.items
    //@ts-ignore
    const newNextToken = response.data.finishedItemsByStatus.nextToken
    return { items, newNextToken }
  }
)

export const fetchOngoingItemsByStatusAsync = createAsyncThunk(
  'items/fetchOnGoingItemsByStatusAsync',
  async ({ status, nextToken, sortDirection, filter }: { status: string | null, nextToken?: string | null, sortDirection?: ModelSortDirection | null, filter?: ModelProductFilterInput | null }) => {
    const variables = {
      status,
      nextToken,
      sortDirection,
      filter,
      limit: 50
    }
    const response = await API.graphql(graphqlOperation(ongoingItemsByStatus, variables))
    //@ts-ignore
    const items = response.data.ongoingItemsByStatus.items
    //@ts-ignore
    const newNextToken = response.data.ongoingItemsByStatus.nextToken
    return { items, newNextToken }
  }
)

export const fetchFinishedItemsByContractorByStatusAsync = createAsyncThunk(
  'items/fetchFinishedItemsByContractorByStatusAsync',
  async ({ contractor_status, nextToken, sortDirection, filter, finishedAt }: { contractor_status: string | null, nextToken?: string | null, sortDirection?: ModelSortDirection | null, filter?: ModelProductFilterInput | null, finishedAt?: ModelStringKeyConditionInput | null }) => {
    const variables = {
      contractor_status,
      nextToken,
      sortDirection,
      filter,
      finishedAt,
      limit: 50
    }
    const response = await API.graphql(graphqlOperation(finishedItemsByContractorByStatus, variables))
    //@ts-ignore
    const items = response.data.finishedItemsByContractorByStatus.items
    //@ts-ignore
    const newNextToken = response.data.finishedItemsByContractorByStatus.nextToken
    return { items, newNextToken }
  }
)

export const fetchOngoingItemsByContractorByStatusAsync = createAsyncThunk(
  'items/fetchOngoingItemsByContractorByStatusAsync',
  async ({ contractor_status, nextToken, sortDirection, filter }: { contractor_status: string | null, nextToken?: string | null, sortDirection?: ModelSortDirection | null, filter?: ModelProductFilterInput | null }) => {
    const variables = {
      contractor_status,
      nextToken,
      sortDirection,
      filter,
      limit: 50
    }
    const response = await API.graphql(graphqlOperation(ongoingItemsByContractorByStatus, variables))
    //@ts-ignore
    const items = response.data.ongoingItemsByContractorByStatus.items
    //@ts-ignore
    const newNextToken = response.data.ongoingItemsByContractorByStatus.nextToken
    return { items, newNextToken }
  }
)

export const fetchItemsByCurrentTaskAsync = createAsyncThunk(
  'items/fetchItemsByCurrentTaskAsync',
  async ({ currentTask, nextToken, sortDirection, filter }: { currentTask: string, nextToken?: string | null, sortDirection?: ModelSortDirection | null, filter?: ModelProductFilterInput | null }) => {
    const variables = {
      currentTask,
      nextToken,
      sortDirection,
      filter,
      limit: 50
    }
    try {
      const response = await API.graphql(graphqlOperation(itemsByCurrentTask, variables))
      //@ts-ignore
      const items = response.data.itemsByCurrentTask.items
      //@ts-ignore
      const newNextToken = response.data.itemsByCurrentTask.nextToken
      return { items, newNextToken, error: null }
    } catch (error) {
      // @ts-ignore
      const errorMsg = error.errors?.map((e: any) => e.message).join(', ') || JSON.stringify(error)
      return { items: [], newNextToken: null, error: `Es ist ein Fehler beim Laden der Werkstücke im Arbeitsschritt ${ currentTask } aufgetreten: ${ errorMsg }` }
    }
  }
)

export const fetchItemsByContractorTaskAsync = createAsyncThunk(
  'items/fetchItemsByContractorTaskAsync',
  async ({ contractorTask, nextToken, sortDirection, filter }: { contractorTask: string, nextToken?: string | null, sortDirection?: ModelSortDirection | null, filter?: ModelProductFilterInput | null }) => {
    const variables = {
      contractor_currentTask: contractorTask,
      nextToken,
      sortDirection,
      filter,
      limit: 50
    }
    try {
      const response = await API.graphql(graphqlOperation(itemsByContractorTask, variables))
      //@ts-ignore
      const items = response.data.itemsByContractorTask.items
      //@ts-ignore
      const newNextToken = response.data.itemsByContractorTask.nextToken
      return { items, newNextToken, error: null }
    } catch (error) {
      // @ts-ignore
      const errorMsg = error.errors?.map((e: any) => e.message).join(', ') || JSON.stringify(error)
      return { items: [], newNextToken: null, error: `Es ist ein Fehler beim Laden der Werkstücke im Arbeitsschritt ${ contractorTask.split('-')[1] } aufgetreten: ${ errorMsg }` }
    }
  }
)

export const fetchItemsByOrderNumberAsync = createAsyncThunk(
  'items/fetchItemsByOrderNumberAsync',
  async ({ orderNumber, filter, contractor_status }: { orderNumber: String, filter?: ModelProductFilterInput | null, contractor_status?: ModelStringKeyConditionInput | null }) => {
    const variables = {
      orderNumber,
      filter,
      contractor_status
    }
    const response = await API.graphql(graphqlOperation(itemsByOrderNumber, variables))
    //@ts-ignore
    const items = response.data.itemsByOrderNumber.items
    return { items }
  }
)

export const fetchAllItemsAsync = createAsyncThunk(
  'items/fetchAllItemsAsync',
  async ({ filter, nextToken, sortDirection }: { filter?: ModelProductFilterInput | null, nextToken?: string | null, sortDirection?: ModelSortDirection | null }) => {
    const variables = {
      typename: 'Product',
      filter,
      limit: 50,
      nextToken,
      sortDirection
    }
    const response = await API.graphql(graphqlOperation(itemsByUpdateDate, variables))
    //@ts-ignore
    const items = response.data.itemsByUpdateDate.items
    //@ts-ignore
    const newNextToken = response.data.itemsByUpdateDate.nextToken
    return { items, newNextToken }
  }
)

export const fetchItemsByShippingMethodAsync = createAsyncThunk(
  'items/fetchItemsByShippingMethodAsync',
  async ({ shipping_method, contractorIds, state, nextToken, sortDirection, filter }: { shipping_method: string, contractorIds: string[], state: ModelStringKeyConditionInput | null, nextToken?: string | null, sortDirection?: ModelSortDirection | null, filter?: ModelProductFilterInput | null }) => {
    const variables = {
      shipping_method,
      state,
      nextToken,
      sortDirection,
      filter,
      limit: 50
    }
    const response = await API.graphql(graphqlOperation(customOrdersByShippingMethod, variables))
    // we only need items which are not yet assigned to a tour, also we need to filter by contractor because it cannot be done in the query (as it is done in the other queries)
    //@ts-ignore
    const items = response.data.ordersByShippingMethod.items.map(o => o.products.items.filter((p: Product) => !p.tourProductsId)).flat().filter(p => contractorIds.includes(p.productContractorId))
    //@ts-ignore
    const newNextToken = response.data.ordersByShippingMethod.nextToken
    return { items, newNextToken }
  })

export const updateItemAsync = createAsyncThunk(
  'items/updateItem',
  async (variables: UpdateProductMutationVariables) => {
    try {
      const response = await API.graphql(graphqlOperation(updateProduct, variables))
      //@ts-ignore
      return response.data.updateProduct
    } catch (error: any) {
      const message = error && error.errors ? error.errors?.map((e: any) => e.message).join(', ') : JSON.stringify(error)
      throw new Error(message)
    }
  }
)

export const updateOrderAsync = createAsyncThunk(
  'items/updateOrder',
  async (variables: UpdateOrderMutationVariables) => {
    const response = await API.graphql(graphqlOperation(updateOrder, variables))
    //@ts-ignore
    return response.data.updateOrder
  }
)

export const createItemAsync = createAsyncThunk(
  'items/createItem',
  async (variables: CreateProductMutationVariables) => {
    try {
      const response = await API.graphql(graphqlOperation(createProduct, variables))
      //@ts-ignore
      return { createProduct: response.data.createProduct }
    } catch (error) {
      return { error }
    }
  }
)

export const fetchCurrentTasksForSubMenuAsync = createAsyncThunk(
  'items/fetchCurrentTasksForSubMenuAsync',
  async ({ productContractorId, filter }: { productContractorId?: string, filter?: ModelProductFilterInput }) => {
    const tasks = []
    for (const taskName of Object.values(taskNames)) {
      if (productContractorId) {
        const response = await API.graphql(graphqlOperation(itemsByContractorTask, {
          contractor_currentTask: `${ productContractorId }-${ taskName.name }`,
          limit: 1
        }))
        //@ts-ignore
        const items = response.data.itemsByContractorTask.items
        if (items.length > 0) {
          tasks.push(taskName.name)
        }
      } else {
        const response = await API.graphql(graphqlOperation(itemsByCurrentTask, {
          currentTask: taskName.name,
          filter
        }))
        //@ts-ignore
        const items = response.data.itemsByCurrentTask.items
        if (items.length > 0) {
          tasks.push(taskName.name)
        }
      }
    }
    return tasks
  }
)

export const itemsSlice = createSlice({
  name: 'items',
  initialState: initialItemsState,
  reducers: {
    filterByOrderNumber: (state, action: PayloadAction<string>) => {
      state.filters.orderNumber = action.payload
      state.nextToken = null
    },
    filterByContractor: (state, action: PayloadAction<string>) => {
      state.filters.contractor = action.payload
    },
    filterByProductName: (state, action: PayloadAction<string>) => {
      state.filters.productName = action.payload
    },
    setItemsError: (state, action) => {
      state.error = action.payload
    },
    setItemsInfo: (state, action) => {
      state.info = action.payload
    },
    toggleMinimize: (state) => {
      state.minimize = !state.minimize
    },
    setMinimize: (state, action) => {
      state.minimize = action.payload
    },
    resetItemsState: (state) => {
      state.items = []
      state.nextToken = null
      state.error = undefined
      state.info = undefined
      state.minimize = true
    },
    updateProductInState: (state, action) => {
      const updatedProduct = action.payload
      if (updatedProduct) {
        const productInState = state.items.find(i => i.id === updatedProduct.id)
        if (productInState) {
          const index = state.items.indexOf(productInState)
          state.items[index] = updatedProduct
        }
      }
    },
    updateOrderInState: (state, action) => {
      const updatedProducts = state.items.filter(i => i.productOrderId === action.payload.id)
      if (updatedProducts && updatedProducts.length > 0) {
        for (const product of updatedProducts) {
          const index = state.items.indexOf(product)
          state.items[index].order = action.payload
        }
      }
    },
    saveItemUpdate: (state, action) => {
      const changedItemInState = state.updateItemInputs.find(i => i.id === action.payload.id)
      if (changedItemInState) {
        const index = state.updateItemInputs.indexOf(changedItemInState)
        state.updateItemInputs[index] = { ...changedItemInState, ...action.payload }
      }
      else {
        state.updateItemInputs.push(action.payload)
      }
    },
    removeItemUpdate: (state, action) => {
      if (action.payload.length === 0) {
        state.updateItemInputs = []
      }
      state.updateItemInputs = state.updateItemInputs.filter(i => i.id !== action.payload.id)
    },
    setEditable: (state, action) => {
      state.editable = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllItemsAsync.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchAllItemsAsync.fulfilled, (state, action) => {
      state.items.push(...action.payload.items)
      state.nextToken = action.payload.newNextToken
      state.isLoading = false
    })
    builder.addCase(fetchAllItemsAsync.rejected, (state, action) => {
      state.isLoading = false
      console.warn(action)
      state.error = `Es ist ein Fehler beim Laden der Items aufgetreten, lade die Seite erneut. ${ JSON.stringify(action.error) }`
    })
    builder.addCase(fetchFinishedItemsByStatusAsync.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchFinishedItemsByStatusAsync.fulfilled, (state, action) => {
      state.items.push(...action.payload.items)
      state.nextToken = action.payload.newNextToken
      state.isLoading = false
    })
    builder.addCase(fetchFinishedItemsByStatusAsync.rejected, (state, action) => {
      state.isLoading = false
      console.warn(action)
      state.error = `Es ist ein Fehler beim Laden der Items aufgetreten, lade die Seite erneut. ${ JSON.stringify(action.error) }`
    })
    builder.addCase(fetchOngoingItemsByStatusAsync.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchOngoingItemsByStatusAsync.fulfilled, (state, action) => {
      state.items.push(...action.payload.items)
      //always show expressOrdersfirst for orders in progress
      const expressOrders = state.items.filter(item => item?.order?.shipping_method?.includes('Express'))
      const nonExpressOrders = state.items.filter(item => !item?.order?.shipping_method?.includes('Express'))
      state.items = [...expressOrders, ...nonExpressOrders]
      state.nextToken = action.payload.newNextToken
      state.isLoading = false
    })
    builder.addCase(fetchOngoingItemsByStatusAsync.rejected, (state, action) => {
      state.isLoading = false
      console.error(action)
      state.error = 'Es ist ein Fehler beim Laden der Items aufgetreten: ' + JSON.stringify(action.error)
    })
    builder.addCase(fetchFinishedItemsByContractorByStatusAsync.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchFinishedItemsByContractorByStatusAsync.fulfilled, (state, action) => {
      state.items.push(...action.payload.items)
      state.nextToken = action.payload.newNextToken
      state.isLoading = false
    })
    builder.addCase(fetchFinishedItemsByContractorByStatusAsync.rejected, (state, action) => {
      state.isLoading = false
      state.error = 'Es ist ein Fehler beim Laden der Items aufgetreten: ' + JSON.stringify(action.error)
    })
    builder.addCase(fetchOngoingItemsByContractorByStatusAsync.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchOngoingItemsByContractorByStatusAsync.fulfilled, (state, action) => {
      state.items.push(...action.payload.items)
      //always show expressOrdersfirst for orders in progress
      const expressOrders = state.items.filter(item => item?.order?.shipping_method?.includes('Express'))
      const nonExpressOrders = state.items.filter(item => !item?.order?.shipping_method?.includes('Express'))
      state.items = [...expressOrders, ...nonExpressOrders]
      state.nextToken = action.payload.newNextToken
      state.isLoading = false
    })
    builder.addCase(fetchOngoingItemsByContractorByStatusAsync.rejected, (state, action) => {
      state.isLoading = false
      console.error(action)
      state.error = 'Es ist ein Fehler beim Laden der Items aufgetreten: ' + JSON.stringify(action.error)
    })
    builder.addCase(fetchItemsByCurrentTaskAsync.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchItemsByCurrentTaskAsync.fulfilled, (state, action) => {
      if (action.payload.error) {
        state.error = action.payload.error
        state.isLoading = false
        return
      }
      state.items.push(...action.payload.items)
      state.nextToken = action.payload.newNextToken
      state.isLoading = false
    })
    builder.addCase(fetchItemsByCurrentTaskAsync.rejected, (state, action) => {
      state.isLoading = false
      console.error(action)
      state.error = 'Es ist ein Fehler beim Laden der Items aufgetreten: ' + JSON.stringify(action.error)
    })
    builder.addCase(fetchItemsByContractorTaskAsync.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchItemsByContractorTaskAsync.fulfilled, (state, action) => {
      if (action.payload.error) {
        state.error = action.payload.error
        state.isLoading = false
        return
      }
      state.items.push(...action.payload.items)
      state.nextToken = action.payload.newNextToken
      state.isLoading = false
    })
    builder.addCase(fetchItemsByContractorTaskAsync.rejected, (state, action) => {
      state.isLoading = false
      console.error(action)
      state.error = 'Es ist ein Fehler beim Laden der Items aufgetreten: ' + JSON.stringify(action.error)
    })
    builder.addCase(fetchItemsByOrderNumberAsync.pending, (state, action) => {
      state.info = undefined
      state.isLoading = true
    })
    builder.addCase(fetchItemsByOrderNumberAsync.fulfilled, (state, action) => {
      state.isLoading = false
      const { items } = action.payload
      state.nextToken = null
      if (items && items.length > 0) {
        state.items = items
      }
      else {
        state.info = 'Der Auftrag konnte nicht gefunden werden, ggf. ist er in einem anderen Status oder einem anderen Fertiger zugeordnet.'
        state.items = []
      }
    })
    builder.addCase(fetchItemsByOrderNumberAsync.rejected, (state, action) => {
      state.isLoading = false
      console.warn(JSON.stringify(action))
      state.error = `Es ist ein Fehler beim Suchen des Auftrags aufgetreten, bitte versuche es erneut`
    })
    builder.addCase(fetchItemsByShippingMethodAsync.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchItemsByShippingMethodAsync.fulfilled, (state, action) => {
      state.isLoading = false
      // sort items by delivery date because the order is not guaranteed by the query
      state.items = action.payload.items?.sort((a: Product, b: Product) => new Date(a.delivery_date) < new Date(b.delivery_date) ? -1 : 1)
      state.nextToken = action.payload.newNextToken
    })
    builder.addCase(fetchItemsByShippingMethodAsync.rejected, (state, action) => {
      state.isLoading = false
      console.warn(JSON.stringify(action))
      state.error = `Es ist ein Fehler beim Laden der Werkstücke aufgetreten, bitte lade die Seite erneut. ${ JSON.stringify(action.error) }`
    })
    builder.addCase(updateItemAsync.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(updateItemAsync.fulfilled, (state, action) => {
      state.isLoading = false
      const updatedProduct = action.payload
      if (updatedProduct) {
        const productInState = state.items.find(i => i.id === updatedProduct.id)
        if (productInState) {
          const index = state.items.indexOf(productInState)
          state.items[index] = updatedProduct
        }
      }
    })
    builder.addCase(updateItemAsync.rejected, (state, action) => {
      state.isLoading = false
      console.warn(JSON.stringify(action))
      state.error = `Es ist ein Fehler beim Aktualisieren des Werkstücks aufgetreten: ${ action.error.message }, bitte prüfe den Status und probiere es erneut oder kontaktiere die Administratorin`
    })
    builder.addCase(createItemAsync.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(createItemAsync.fulfilled, (state, action) => {
      state.isLoading = false
      const createdProduct = action.payload.createProduct
      if (createdProduct) {
        state.items.push(createdProduct)
      }
      if (action.payload.error) {
        state.error = `Das Produkt konnte nicht erstellt werden, bitte prüfe den Status und informiere ggf. Die Administratorin: ${ JSON.stringify(action.payload.error) }`
      }
    })
    builder.addCase(createItemAsync.rejected, (state, action) => {
      state.isLoading = false
      console.warn(JSON.stringify(action))
      state.error = `Das Produkt konnte nicht erstellt werden, bitte prüfe den Status und informiere ggf. Die Administratorin: ${ JSON.stringify(action.error) }`
    })
    builder.addCase(updateOrderAsync.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(updateOrderAsync.fulfilled, (state, action) => {
      state.isLoading = false
      const updatedProducts = state.items.filter(i => i.orderNumber === action.payload.orderNumber)
      if (updatedProducts && updatedProducts.length > 0) {
        for (const product of updatedProducts) {
          const index = state.items.indexOf(product)
          state.items[index].order = action.payload
        }
      }
    })
    builder.addCase(updateOrderAsync.rejected, (state, action) => {
      state.isLoading = false
      console.warn(JSON.stringify(action))
      state.error = `Der Auftrag konnte nicht geändert werden, bitte prüfe den Status und informiere ggf. Die Administratorin, ${ JSON.stringify(action.error) }`
    })
    builder.addCase(fetchCurrentTasksForSubMenuAsync.fulfilled, (state, action) => {
      state.subMenuTasks = action.payload
    })
    builder.addCase(fetchCurrentTasksForSubMenuAsync.rejected, (state, action) => {
      console.warn(JSON.stringify(action))
      state.error = `Es ist ein Fehler beim Laden der Aufgaben aufgetreten, bitte lade die Seite erneut. ${ JSON.stringify(action.error) }`
    })
  }
})

export const {
  setItemsError,
  setItemsInfo,
  resetItemsState,
  toggleMinimize,
  setMinimize,
  updateProductInState,
  filterByOrderNumber,
  updateOrderInState,
  filterByProductName,
  filterByContractor,
  saveItemUpdate,
  removeItemUpdate,
  setEditable } = itemsSlice.actions

export default itemsSlice.reducer
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface WooState {
    isLoading: boolean,
    statusCode: number | undefined,
    statusText: string | undefined
    error: string | undefined
}

export interface WooUpdateOrderInput {
    orderNumber: string
    data: {
        status?: string,
        shipping?: {
            first_name?: string,
            last_name?: string,
            company?: string | null,
            address_1?: string | null, // street and house number
            address_2?: string | null, // additional address info
            city?: string | null,
            postcode?: string | null,
        },
        meta_data?: [{
            key: string,
            value: any
        }]
    }
}

const initialWooState = {
    isLoading: false,
    statusCode: undefined,
    statusText: undefined,
    error: undefined,
} as WooState

const base_url = process.env.REACT_APP_WOO_BASE_URL

const headers = {
    'Authorization': `Basic ${ process.env.REACT_APP_WOO_BASIC_AUTH }`,
    'Content-Type': 'application/json'
}

export const wooUpdateOrderAsync = createAsyncThunk(
    'wc/orders',
    async (input: WooUpdateOrderInput) => {
        const { orderNumber, data } = input
        try {
            const response = await fetch(`${ base_url }/orders/${ orderNumber }`,
                {
                    method: 'PUT',
                    body: JSON.stringify(data),
                    headers
                })
            if (response.status !== 200) {
                const error = `Bei dem Auftrag ${ orderNumber } ist folgender Fehler in WooCommerce aufgetreten: '${ response.status }: ${ response.statusText }'`
                return { statusCode: response.status, error }
            }
            return { statusCode: response.status, statusText: `Änderung für #${ orderNumber }: ${ response.statusText }` }
        } catch (error) {
            const errorText = `Bei dem Auftrag ${ orderNumber } ist folgender Fehler in WooCommerce  aufgetreten: ` + JSON.stringify(error)
            return { error: errorText, statusText: orderNumber }
        }
    }
)

export const wooGetOrderAsync = createAsyncThunk(
    'wc/oderInfos',
    async (orderNumber: string) => {
        // because retoure orders are saved in db with a prefix and a appended suffix we need to remove the prefix and suffix
        const orderNumberWithoutPrefix = orderNumber.replace(/R/g, '').split('-')[0]
        try {
            const response = await fetch(`${ base_url }/orders/${ orderNumberWithoutPrefix }`, {
                method: 'GET',
                headers
            })
            if (response.status !== 200) {
                const error = `Bei dem Auftrag ${ orderNumber } ist folgender Fehler in WooCommerce  aufgetreten: '${ response.status }: ${ response.statusText }'`
                return { statusCode: response.status, error }
            }
            const orderInfosBody = await response.json()
            return orderInfosBody
        } catch (error) {
            const errorString = 'Es ist ein Fehler beim Abfragen der Auftragsinformationen in WooCommerce  aufgetreten: ' + JSON.stringify(error)
            return { error: errorString }
        }
    }
)

export const wooSlice = createSlice({
    name: 'wc',
    initialState: initialWooState,
    reducers: {
        resetWooState: (state) => {
            state.isLoading = false
            state.statusCode = undefined
            state.statusText = undefined
            state.error = undefined
        }
    },
    extraReducers: (builder) => {
        builder.addCase(wooUpdateOrderAsync.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(wooUpdateOrderAsync.fulfilled, (state, action) => {
            const { statusCode, statusText, error } = action.payload
            state.isLoading = false
            state.statusCode = statusCode
            state.statusText = statusText
            state.error = error ? error : undefined
        })
        builder.addCase(wooUpdateOrderAsync.rejected, (state, action) => {
            state.isLoading = false
            state.error = 'Es ist ein Fehler in WooCommerce aufgetreten'
        })
        builder.addCase(wooGetOrderAsync.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(wooGetOrderAsync.fulfilled, (state, action) => {
            state.isLoading = false
            const { error } = action.payload
            if (error) {
                state.error = error
            }
        })
        builder.addCase(wooGetOrderAsync.rejected, (state, action) => {
            state.isLoading = false
            state.error = 'Die Auftragsinformationen aus WooCommerce konnten nicht abgefragt werden'
        })
    }
})

export const { resetWooState } = wooSlice.actions

export default wooSlice.reducer
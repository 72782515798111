import { Alert, Button, ScrollView, SelectField, ThemeProvider, withAuthenticator } from '@aws-amplify/ui-react';
import styled from 'styled-components';
import background_image_typ from '../assets/background_image_typ.jpeg'
import HeaderMenu from '../components/header/HeaderMenu';
import { zptheme } from '../theme';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useAppDispatch } from '../store/hooks';
import { setErrorMsg, setInfoMsg } from '../store/userSlice';

const ImageContainer = styled.div`
background-image: url("${ background_image_typ }");
background-repeat: no-repeat;
background-size: cover;
height: 661px;
align-items: center;
outline: none;
text-align: center;
color: white;
`;

function Homepage({ user }: any) {
  const dispatch = useAppDispatch()
  const { permittedContractors } = useSelector((state: RootState) => state.userState)
  const userSuccessMsg = useSelector((state: RootState) => state.userState.infoMsg)
  const userErrorMsg = useSelector((state: RootState) => state.userState.errorMsg)
  const [chosenContractor, setChosenContractor] = useState<string>('');
  const defaultContractor = user.attributes['preferred_username'] ? permittedContractors.find((c) => c.number === user.attributes['preferred_username']) : undefined

  async function updateUser(contractorNumber: string) {
    const user = await Auth.currentAuthenticatedUser();
    const update = await Auth.updateUserAttributes(user, {
      // preferred_username ist a default attribute so we use that to set the default contractor number
      'preferred_username': contractorNumber
    });
    if (update === 'SUCCESS') {
      dispatch(setInfoMsg('Standard Fertiger gesetzt'))
      setChosenContractor('')
    }
    if (update !== 'SUCCESS') {
      dispatch(setInfoMsg('Standard Fertiger konnte nicht gesetzt werden'))
    }
  }

  return (
    <ThemeProvider theme={zptheme}>
      <HeaderMenu />
      {userSuccessMsg &&
        <Alert
          variation='info'
          isDismissible={true}
          onDismiss={() => dispatch(setInfoMsg(''))} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {userSuccessMsg}
        </Alert>}
      {userErrorMsg &&
        <Alert
          variation='error'
          isDismissible={true}
          onDismiss={() => dispatch(setErrorMsg(''))} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {userErrorMsg}
        </Alert>}
      <ScrollView placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <ImageContainer>
          Willkommen {user.attributes.email}
          {defaultContractor && <div> Dein Standardfertiger ist: {defaultContractor.name}</div>}
          {permittedContractors.length > 1 && <div>
            <div style={{ height: '10px' }} />
            <SelectField
              label={'default-contractor'}
              labelHidden={true}
              value={chosenContractor}
              onChange={(e) => setChosenContractor(e.target.value)}
              style={{ backgroundColor: 'white', width: '22rem' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            >
              <option value="">bitte wählen</option>
              {permittedContractors && permittedContractors.map((possibleContractor) => (
                <option key={possibleContractor.id} value={possibleContractor.number} id={'contractor-select-field-option'}>{possibleContractor.name}</option>
              ))
              }
            </SelectField>
            <div style={{ height: '10px' }} />
            <Button
              variation='primary'
              isDisabled={chosenContractor === '' || chosenContractor === defaultContractor?.number}
              style={{ width: '22rem' }}
              onClick={() => updateUser(chosenContractor)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              Standard Fertiger setzen
            </Button>
          </div>}
        </ImageContainer>
      </ScrollView>
    </ThemeProvider>
  )
}

export default withAuthenticator(Homepage);
import { Button, ScrollView, ThemeProvider, withAuthenticator } from '@aws-amplify/ui-react';
import { zptheme } from '../theme';
import { Contractor, ModelSortDirection, Product, ProductStatus } from '../API';
import { useEffect } from 'react';
import { useAppDispatch } from '../store/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { fetchOngoingItemsByContractorByStatusAsync, fetchOngoingItemsByStatusAsync, resetItemsState, toggleMinimize } from '../store/itemsSlice';
import StickyHeader from '../components/header/StickyHeader';
import { FilterByContractorSelecField } from '../components/header/FilterByContractorSelectField';
import { HiCloudDownload } from 'react-icons/hi';
import ToolTip from '../components/helper-components/ToolTip';
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi';
import OrderItemsCard from '../components/order/OrderItemsCard';
import FilterByProductNameSelectField from '../components/header/FilterByProductNameSelectField';
import SearchOrderField from '../components/header/SearchOrderField';
import ShipmentsRow from '../components/shipment/ShipmentsRow';
import { exportOrdersToCSV, statusName } from '../utils';
import { GrDocumentCsv } from 'react-icons/gr';
import { fetchFutureToursAsync, resetTourState } from '../store/tourSlice';

// page for items in status pending return
function OngoingItemsPage({ user }: any) {
    const dispatch = useAppDispatch()
    const { items, filters, nextToken, minimize, isLoading } = useSelector((state: RootState) => state.itemsState)
    const { permittedContractors } = useSelector((state: RootState) => state.userState)
    const pageStatus = ProductStatus.pending_return

    function filterItems(item: Product) {
        return ((item.orderNumber?.startsWith(filters.orderNumber) || !filters.orderNumber))
    }
    const filteredItems = items.filter(filterItems)
    const uniqueOrderIds = [...new Set(filteredItems.map(item => item?.productOrderId))];

    useEffect(() => {
        fetchItems(null)
        return () => {
            dispatch(resetItemsState())
        }
    }, [filters.contractor])

    function fetchItems(nextToken: string | null) {
        // filter should always be set on mounting app but if it is not yet we don't want to fetch any items
        if (filters.contractor) {
            if (filters.contractor === 'Alle') {
                // user filter for permitted contractors
                const filter = {
                    or: permittedContractors.map((c: Contractor) => { return { productContractorId: { eq: c.id } } })
                }
                dispatch(fetchOngoingItemsByStatusAsync({ status: pageStatus, nextToken, sortDirection: ModelSortDirection.ASC, filter }))
            } else {
                dispatch(fetchOngoingItemsByContractorByStatusAsync({ contractor_status: `${ filters.contractor }-${ pageStatus }`, nextToken, sortDirection: ModelSortDirection.ASC }))
            }
        }
    }

    // fetch tours in case the shipping method is changed to 'Ausfahrt durch Zuschnittprofi' and the itemsCard is maximized here
    // cannot fetch it in the tourSelectField because the tours will be loaded multiple times if there is more than 1 item
    useEffect(() => {
        dispatch(fetchFutureToursAsync({ nextToken: null }))
        return () => {
            dispatch(resetTourState())
        }
    }, [])

    return (
        <ThemeProvider theme={zptheme}>
            <StickyHeader>
                <div style={{ display: 'flex', gap: '1em', flexWrap: 'wrap' }}>
                    <div style={{ display: 'flex', gap: '1em', flex: 1, flexWrap: 'wrap' }}>
                        <SearchOrderField pageStatus={pageStatus} onClear={() => fetchItems(null)} />
                        <FilterByProductNameSelectField />
                        <div style={{ display: 'flex', gap: '1em' }}>
                            <FilterByContractorSelecField />
                        </div>
                        <ToolTip tipText={'Aufträge als csv Datei speichern'}>
                            <Button
                                style={{ fontSize: 'large' }}
                                onClick={() => exportOrdersToCSV(filteredItems, statusName(pageStatus))} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                <GrDocumentCsv />
                            </Button>
                        </ToolTip>
                    </div>
                    <div>
                        <div style={{ display: 'flex' }}>
                            {nextToken &&
                                <ToolTip tipText={'weitere Aufträge laden'}>
                                    <Button
                                        minHeight={'2.5rem'}
                                        variation='primary'
                                        isDisabled={isLoading}
                                        onClick={() => fetchItems(nextToken)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                        <HiCloudDownload />
                                    </Button>
                                </ToolTip>}
                            <Button
                                minHeight={'2.5rem'}
                                style={{ marginRight: '1rem', marginLeft: '1em' }}
                                onClick={() => dispatch(toggleMinimize())} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                            >
                                {minimize ?
                                    <FiMaximize2 /> :
                                    <FiMinimize2 />
                                }
                            </Button>
                        </div>
                    </div>
                </div>
            </StickyHeader>
            <ShipmentsRow />
            <ScrollView style={{ margin: '1rem', display: 'flex', flexDirection: 'column', gap: '1em' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                {uniqueOrderIds.map(orderId => <OrderItemsCard key={orderId} orderId={orderId}></OrderItemsCard>)}
            </ScrollView>
        </ThemeProvider>
    )
}

export default withAuthenticator(OngoingItemsPage);


import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import '@aws-amplify/ui-react/styles.css';
import { Provider } from 'react-redux';
import { store } from './store';
import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react';
import { loginFormFields, zptheme } from './theme';

const container = document.getElementById('root')!;
const root = ReactDOMClient.createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={zptheme}>
        <Authenticator variation="modal" hideSignUp={true} formFields={loginFormFields}>
          {({ user }: any) => (
            <App user={user} />
          )}
        </Authenticator>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
);


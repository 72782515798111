import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { API, graphqlOperation } from 'aws-amplify'
import { CreateShipmentMutationVariables, DeleteShipmentMutationVariables, Shipment, UpdateShipmentMutationVariables } from '../API'
import { createShipment, deleteShipment, updateShipment } from '../graphql/mutations'

interface shippingState {
    shipments: Shipment[],
    successMessage: string | undefined
    error: string | undefined
    isLoading: boolean
}

const intitialShippingState = {
    shipments: [],
    successMessage: undefined,
    error: undefined,
    isLoading: false
} as shippingState

export const createShipmentAsync = createAsyncThunk(
    'shipping/createShipment',
    async (variables: CreateShipmentMutationVariables) => {
        const response = await API.graphql(graphqlOperation(createShipment, variables))
        //@ts-ignore
        if (response.errors) {
            //@ts-ignore
            const errorString = `Es ist ein Fehler beim Erstellen der Sendung aufgetreten: ${ JSON.stringify(response.errors.map(e => e.message)) }`
            return { error: errorString }
        }
        //@ts-ignore
        return { shipment: response.data.createShipment }
    }
)

export const updateShipmentAsync = createAsyncThunk(
    'shipping/updateShipment',
    async (variables: UpdateShipmentMutationVariables) => {
        try {
        const response = await API.graphql(graphqlOperation(updateShipment, variables))
        //@ts-ignore
        if (response.errors) {
            //@ts-ignore
            const errorString = JSON.stringify(response.errors.map(e => e.message))
            throw new Error(errorString)
        }
        //@ts-ignore
        return { shipment: response.data.updateShipment }
        } catch (error: any) {
            const message = error && error.errors ? error.errors?.map((e: any) => e.message).join(', ') : JSON.stringify(error)
            throw new Error(message) 
        }
    }
)

export const deleteShipmentAsync = createAsyncThunk(
    'shipping/deleteShipment',
    async (variables: DeleteShipmentMutationVariables) => {
        const response = await API.graphql(graphqlOperation(deleteShipment, variables))
        //@ts-ignore
        if (response.errors) {
            //@ts-ignore
            const errorString = `Es ist ein Fehler beim Löschen der Sendung aufgetreten: ${ JSON.stringify(response.errors.map(e => e.message)) }`
            return { error: errorString }
        }
        //@ts-ignore
        return { shipment: response.data.updateShipment }
    }
)

export const shippingSlice = createSlice({
    name: 'shipping',
    initialState: intitialShippingState,
    reducers: {
        resetShippingState: (state) => {
            state.shipments = []
            state.successMessage = undefined
            state.error = undefined
        },
        setShipmentsSuccessMessage: (state, action: PayloadAction<string>) => {
            state.successMessage = action.payload
        },
        setShipmetsErrorMessage: (state, action: PayloadAction<string | undefined>) => {
            state.error = action.payload
        },
        setShipments: (state, action: PayloadAction<Shipment[]>) => {
            state.shipments = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createShipmentAsync.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(createShipmentAsync.fulfilled, (state, action) => {
            state.isLoading = false
            if (action.payload.error) {
                state.error = action.payload.error
            }
            if (action.payload.shipment) {
                state.shipments.push(action.payload.shipment)
            }
        })
        builder.addCase(createShipmentAsync.rejected, (state, action) => {
            state.isLoading = false
            state.error = `Es ist ein Fehler beim Erstellen der Sendung aufgetreten: ${ JSON.stringify(action) }`
        })
        builder.addCase(updateShipmentAsync.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(updateShipmentAsync.fulfilled, (state, action) => {
            state.isLoading = false
            const updatedShipment = action?.payload?.shipment
            if (updatedShipment) {
                const shipmentInState = state.shipments.find(s => s.id === updatedShipment.id)
                if (shipmentInState) {
                    const index = state.shipments.indexOf(shipmentInState)
                    state.shipments[index] = updatedShipment
                }
            }
        })
        builder.addCase(updateShipmentAsync.rejected, (state, action) => {
            state.isLoading = false
            state.error = `Es ist ein Fehler beim Aktualisieren der Sendung aufgetreten: ${ JSON.stringify(action.error.message) }. Bitte versuche es erneut`
        })
        builder.addCase(deleteShipmentAsync.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(deleteShipmentAsync.fulfilled, (state, action) => {
            const { error } = action.payload
            state.isLoading = false
            if (error) {
                state.error = error
            }
            const deletedShipment = action?.payload?.shipment
            if (deletedShipment) {
                state.shipments = state.shipments.filter(s => s.id !== deletedShipment.id)
            }
        })
        builder.addCase(deleteShipmentAsync.rejected, (state, action) => {
            state.isLoading = false
            state.error = `Es ist ein Fehler beim Löshen der Sendung aufgetreten: ${ JSON.stringify(action) }`
        })
    }
})

export const { setShipmentsSuccessMessage, setShipmetsErrorMessage, resetShippingState, setShipments } = shippingSlice.actions

export default shippingSlice.reducer
import { useParams, useNavigate } from 'react-router-dom';
import StickyHeader from '../components/header/StickyHeader';
import { ThemeProvider, ScrollView, Button } from '@aws-amplify/ui-react';
import { zptheme } from '../theme';
import ShipmentsRow from '../components/shipment/ShipmentsRow';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useEffect } from 'react';
import { fetchItemsByOrderNumberAsync, resetItemsState, setItemsError, setMinimize, toggleMinimize } from '../store/itemsSlice';
import { fetchFutureToursAsync, resetTourState } from '../store/tourSlice';
import { useAppDispatch } from '../store/hooks';
import OrderItemsCard from '../components/order/OrderItemsCard';
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi';

function OrderDetailsPage() {
    const dispatch = useAppDispatch();
    const { orderNumber } = useParams<{ orderNumber: string }>();
    const { items, minimize } = useSelector((state: RootState) => state.itemsState);
    const { permittedContractors } = useSelector((state: RootState) => state.userState);
    const navigate = useNavigate();

    const uniqueOrderId = [...new Set(items.map(item => item?.productOrderId))];

    // Function to fetch items based on filters
    async function fetchItemsByOrderNumber() {
        if (!orderNumber) {
            dispatch(setItemsError('Auftragsnummer fehlt oder ist ungültig.'))
            return;
        }
        const variables = {
            orderNumber,
            // only show items of the permitted contractors
            filter: {
                or: permittedContractors.map((c) => ({ productContractorId: { eq: c.id } }))
            }
        }

        await dispatch(fetchItemsByOrderNumberAsync(variables))
    }
    // Initial fetch of items
    useEffect(() => {
        fetchItemsByOrderNumber();
        dispatch(setMinimize(false));
        return () => {
            dispatch(resetItemsState());
        };
    }, [orderNumber, permittedContractors]);

    // Fetch future tours on component mount
    useEffect(() => {
        dispatch(fetchFutureToursAsync({ nextToken: null }));
        return () => {
            dispatch(resetTourState());
        };
    }, [dispatch]);

    return (
        <ThemeProvider theme={zptheme}>
            <StickyHeader>
                <div style={{ display: 'flex', gap: '1em', flexWrap: 'wrap' }}>
                    <div style={{ flex: 1 }}>
                        <Button
                        variation='primary'
                            onClick={() => navigate('/orders')}
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}                >
                            Alle Aufträge
                        </Button>
                    </div>
                    <Button
                        minHeight={'2.5rem'}
                        style={{ marginRight: '1rem', marginLeft: '1em' }}
                        onClick={() => dispatch(toggleMinimize())} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                            >
                        {minimize ?
                            <FiMaximize2 /> :
                            <FiMinimize2 />
                        }
                    </Button>
                </div>
            </StickyHeader>
            <ShipmentsRow />
            <ScrollView
                style={{ margin: '1rem', display: 'flex', flexDirection: 'column', gap: '1em' }}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
            >
                {uniqueOrderId.map(orderId => (
                    <OrderItemsCard key={orderId} orderId={orderId} />
                ))}
            </ScrollView>
        </ThemeProvider>
    );

}

export default OrderDetailsPage;

/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelOrderFilterInput = {
  id?: ModelIDInput | null,
  typename?: ModelStringInput | null,
  orderNumber?: ModelStringInput | null,
  date_created?: ModelStringInput | null,
  date_paid?: ModelStringInput | null,
  total?: ModelFloatInput | null,
  cart_tax?: ModelFloatInput | null,
  total_tax?: ModelFloatInput | null,
  discount_total?: ModelFloatInput | null,
  discount_tax?: ModelFloatInput | null,
  shipping_total?: ModelFloatInput | null,
  shipping_tax?: ModelFloatInput | null,
  weight?: ModelFloatInput | null,
  shipping_method?: ModelStringInput | null,
  delivery_date?: ModelStringInput | null,
  status?: ModelOrderStatusInput | null,
  state?: ModelOrderStateInput | null,
  source?: ModelOrderSourceInput | null,
  in_progressAt?: ModelStringInput | null,
  finishedAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  retoure_shipping_costs?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelOrderFilterInput | null > | null,
  or?: Array< ModelOrderFilterInput | null > | null,
  not?: ModelOrderFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelOrderStatusInput = {
  eq?: OrderStatus | null,
  ne?: OrderStatus | null,
};

export enum OrderStatus {
  new = "new",
  in_progress = "in_progress",
  prod_additional = "prod_additional",
  all_ready_to_pack = "all_ready_to_pack",
  finished = "finished",
  cancelled = "cancelled",
  failed = "failed",
}


export type ModelOrderStateInput = {
  eq?: OrderState | null,
  ne?: OrderState | null,
};

export enum OrderState {
  importing = "importing",
  finished = "finished",
  unfinished = "unfinished",
}


export type ModelOrderSourceInput = {
  eq?: OrderSource | null,
  ne?: OrderSource | null,
};

export enum OrderSource {
  woocommerce = "woocommerce",
  amazon = "amazon",
  etsy = "etsy",
  rekla = "rekla",
}


export type ModelOrderConnection = {
  __typename: "ModelOrderConnection",
  items:  Array<Order | null >,
  nextToken?: string | null,
};

export type Order = {
  __typename: "Order",
  id: string,
  typename?: string | null,
  orderNumber: string,
  date_created: string,
  date_paid: string,
  total?: number | null,
  cart_tax?: number | null,
  total_tax?: number | null,
  discount_total?: number | null,
  discount_tax?: number | null,
  shipping_total?: number | null,
  shipping_tax?: number | null,
  fees?:  Array<Fee | null > | null,
  weight?: number | null,
  customer: Customer,
  shipping_method?: string | null,
  delivery_date: string,
  status?: OrderStatus | null,
  state?: OrderState | null,
  source: OrderSource,
  in_progressAt?: string | null,
  finishedAt?: string | null,
  updatedAt?: string | null,
  retoure_shipping_costs?: number | null,
  products?: ModelProductConnection | null,
  createdAt: string,
};

export type Fee = {
  __typename: "Fee",
  name?: string | null,
  amount?: number | null,
  total?: number | null,
  total_tax?: number | null,
};

export type Customer = {
  __typename: "Customer",
  first_name: string,
  last_name: string,
  street?: string | null,
  house_number?: string | null,
  zip?: string | null,
  city?: string | null,
  country?: string | null,
  email?: string | null,
  phone?: string | null,
  company?: string | null,
  add?: string | null,
};

export type ModelProductConnection = {
  __typename: "ModelProductConnection",
  items:  Array<Product | null >,
  nextToken?: string | null,
};

export type Product = {
  __typename: "Product",
  id: string,
  position?: number | null,
  typename?: string | null,
  name: string,
  sku?: string | null,
  product_id?: string | null,
  variation_id?: string | null,
  quantity?: number | null,
  length?: number | null,
  width?: number | null,
  height?: number | null,
  area?: number | null,
  weight?: string | null,
  categories?: Array< string | null > | null,
  variations?: Array< string | null > | null,
  options?: Array< string | null > | null,
  extraOptions?:  Array<Option | null > | null,
  tasks?: Array< string | null > | null,
  currentTask?: string | null,
  contractor_currentTask?: string | null,
  productContractorId?: string | null,
  contractor?: Contractor | null,
  status?: ProductStatus | null,
  contractor_status: string,
  shipping_label_printed?: boolean | null,
  orderNumber?: string | null,
  order?: Order | null,
  productOrderId: string,
  backoffice_note?: string | null,
  total_before_discount?: number | null,
  total_net_price?: number | null,
  total_tax?: number | null,
  nipsild_fixed_costs?: number | null,
  delivery_time?: string | null,
  delivery_date: string,
  shipment?: Shipment | null,
  tour?: Tour | null,
  declinedTourDates?: Array< string | null > | null,
  billed_amount?: number | null,
  in_progressAt?: string | null,
  ready_to_packAt?: string | null,
  finishedAt?: string | null,
  updatedAt?: string | null,
  billedAt?: string | null,
  recievedReturnAt?: string | null,
  reklaOrderNumber?: string | null,
  retoure_type?: RetoureType | null,
  retoure_reason?: string | null,
  retoure_manufacturing_fee?: number | null,
  retoure_refund_amount?: number | null,
  retoure_refund_contractors_share?: number | null,
  retoure_shipment?: Shipment | null,
  createdAt: string,
  orderProductsId?: string | null,
  tourProductsId?: string | null,
  productShipmentId?: string | null,
  productRetoure_shipmentId?: string | null,
};

export type Option = {
  __typename: "Option",
  name?: string | null,
  value: string,
  imagesp?: string | null,
  price?: number | null,
};

export type Contractor = {
  __typename: "Contractor",
  id: string,
  number: string,
  name: string,
  billing_rate?: number | null,
  billing_max?: number | null,
  createdAt: string,
  updatedAt: string,
};

export enum ProductStatus {
  pending_return = "pending_return",
  new = "new",
  in_progress = "in_progress",
  prod_additional = "prod_additional",
  ready_to_pack = "ready_to_pack",
  packing_confirmed = "packing_confirmed",
  cancelled = "cancelled",
  billed = "billed",
}


export type Shipment = {
  __typename: "Shipment",
  id: string,
  typename?: string | null,
  length: number,
  width: number,
  height: number,
  gurtMass: number,
  weight: number,
  serviceProvider: string,
  costs: number,
  calculatedCosts?: number | null,
  shippedAt?: string | null,
  edited?: string | null,
  parcelNumber?: string | null,
  trackID?: string | null,
  suggest_splitting?: boolean | null,
  overrule_splitting_user?: string | null,
  labelData?: LabelData | null,
  updatedAt?: string | null,
  createdAt: string,
};

export type LabelData = {
  __typename: "LabelData",
  base64?: string | null,
  format?: LabelFormat | null,
};

export enum LabelFormat {
  pdf = "pdf",
  gif = "gif",
}


export type Tour = {
  __typename: "Tour",
  id: string,
  typename?: string | null,
  date: string,
  products?: ModelProductConnection | null,
  deliveryNotificationSentTo?: Array< string | null > | null,
  info?: string | null,
  driver?: string | null,
  calculated_shipping_costs?: number | null,
  createdAt: string,
  updatedAt: string,
};

export enum RetoureType {
  remanufacture = "remanufacture",
  touch_up = "touch_up",
  refund = "refund",
  return_and_refund = "return_and_refund",
}


export type CreateOrderInput = {
  id?: string | null,
  typename?: string | null,
  orderNumber: string,
  date_created: string,
  date_paid: string,
  total?: number | null,
  cart_tax?: number | null,
  total_tax?: number | null,
  discount_total?: number | null,
  discount_tax?: number | null,
  shipping_total?: number | null,
  shipping_tax?: number | null,
  fees?: Array< FeeInput | null > | null,
  weight?: number | null,
  customer: CustomerInput,
  shipping_method?: string | null,
  delivery_date: string,
  status?: OrderStatus | null,
  state?: OrderState | null,
  source: OrderSource,
  in_progressAt?: string | null,
  finishedAt?: string | null,
  updatedAt?: string | null,
  retoure_shipping_costs?: number | null,
};

export type FeeInput = {
  name?: string | null,
  amount?: number | null,
  total?: number | null,
  total_tax?: number | null,
};

export type CustomerInput = {
  first_name: string,
  last_name: string,
  street?: string | null,
  house_number?: string | null,
  zip?: string | null,
  city?: string | null,
  country?: string | null,
  email?: string | null,
  phone?: string | null,
  company?: string | null,
  add?: string | null,
};

export type ModelOrderConditionInput = {
  typename?: ModelStringInput | null,
  orderNumber?: ModelStringInput | null,
  date_created?: ModelStringInput | null,
  date_paid?: ModelStringInput | null,
  total?: ModelFloatInput | null,
  cart_tax?: ModelFloatInput | null,
  total_tax?: ModelFloatInput | null,
  discount_total?: ModelFloatInput | null,
  discount_tax?: ModelFloatInput | null,
  shipping_total?: ModelFloatInput | null,
  shipping_tax?: ModelFloatInput | null,
  weight?: ModelFloatInput | null,
  shipping_method?: ModelStringInput | null,
  delivery_date?: ModelStringInput | null,
  status?: ModelOrderStatusInput | null,
  state?: ModelOrderStateInput | null,
  source?: ModelOrderSourceInput | null,
  in_progressAt?: ModelStringInput | null,
  finishedAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  retoure_shipping_costs?: ModelFloatInput | null,
  and?: Array< ModelOrderConditionInput | null > | null,
  or?: Array< ModelOrderConditionInput | null > | null,
  not?: ModelOrderConditionInput | null,
  createdAt?: ModelStringInput | null,
};

export type UpdateOrderInput = {
  id: string,
  typename?: string | null,
  orderNumber?: string | null,
  date_created?: string | null,
  date_paid?: string | null,
  total?: number | null,
  cart_tax?: number | null,
  total_tax?: number | null,
  discount_total?: number | null,
  discount_tax?: number | null,
  shipping_total?: number | null,
  shipping_tax?: number | null,
  fees?: Array< FeeInput | null > | null,
  weight?: number | null,
  customer?: CustomerInput | null,
  shipping_method?: string | null,
  delivery_date?: string | null,
  status?: OrderStatus | null,
  state?: OrderState | null,
  source?: OrderSource | null,
  in_progressAt?: string | null,
  finishedAt?: string | null,
  updatedAt?: string | null,
  retoure_shipping_costs?: number | null,
};

export type DeleteOrderInput = {
  id: string,
};

export type CreateProductInput = {
  id?: string | null,
  position?: number | null,
  typename?: string | null,
  name: string,
  sku?: string | null,
  product_id?: string | null,
  variation_id?: string | null,
  quantity?: number | null,
  length?: number | null,
  width?: number | null,
  height?: number | null,
  area?: number | null,
  weight?: string | null,
  categories?: Array< string | null > | null,
  variations?: Array< string | null > | null,
  options?: Array< string | null > | null,
  extraOptions?: Array< OptionInput | null > | null,
  tasks?: Array< string | null > | null,
  currentTask?: string | null,
  contractor_currentTask?: string | null,
  productContractorId?: string | null,
  status?: ProductStatus | null,
  contractor_status: string,
  shipping_label_printed?: boolean | null,
  orderNumber?: string | null,
  productOrderId: string,
  backoffice_note?: string | null,
  total_before_discount?: number | null,
  total_net_price?: number | null,
  total_tax?: number | null,
  nipsild_fixed_costs?: number | null,
  delivery_time?: string | null,
  delivery_date: string,
  declinedTourDates?: Array< string | null > | null,
  billed_amount?: number | null,
  in_progressAt?: string | null,
  ready_to_packAt?: string | null,
  finishedAt?: string | null,
  updatedAt?: string | null,
  billedAt?: string | null,
  recievedReturnAt?: string | null,
  reklaOrderNumber?: string | null,
  retoure_type?: RetoureType | null,
  retoure_reason?: string | null,
  retoure_manufacturing_fee?: number | null,
  retoure_refund_amount?: number | null,
  retoure_refund_contractors_share?: number | null,
  orderProductsId?: string | null,
  tourProductsId?: string | null,
  productShipmentId?: string | null,
  productRetoure_shipmentId?: string | null,
};

export type OptionInput = {
  name?: string | null,
  value: string,
  imagesp?: string | null,
  price?: number | null,
};

export type ModelProductConditionInput = {
  position?: ModelIntInput | null,
  typename?: ModelStringInput | null,
  name?: ModelStringInput | null,
  sku?: ModelStringInput | null,
  product_id?: ModelStringInput | null,
  variation_id?: ModelStringInput | null,
  quantity?: ModelIntInput | null,
  length?: ModelFloatInput | null,
  width?: ModelFloatInput | null,
  height?: ModelFloatInput | null,
  area?: ModelFloatInput | null,
  weight?: ModelStringInput | null,
  categories?: ModelStringInput | null,
  variations?: ModelStringInput | null,
  options?: ModelStringInput | null,
  tasks?: ModelStringInput | null,
  currentTask?: ModelStringInput | null,
  contractor_currentTask?: ModelStringInput | null,
  productContractorId?: ModelIDInput | null,
  status?: ModelProductStatusInput | null,
  contractor_status?: ModelStringInput | null,
  shipping_label_printed?: ModelBooleanInput | null,
  orderNumber?: ModelStringInput | null,
  productOrderId?: ModelIDInput | null,
  backoffice_note?: ModelStringInput | null,
  total_before_discount?: ModelFloatInput | null,
  total_net_price?: ModelFloatInput | null,
  total_tax?: ModelFloatInput | null,
  nipsild_fixed_costs?: ModelFloatInput | null,
  delivery_time?: ModelStringInput | null,
  delivery_date?: ModelStringInput | null,
  declinedTourDates?: ModelStringInput | null,
  billed_amount?: ModelFloatInput | null,
  in_progressAt?: ModelStringInput | null,
  ready_to_packAt?: ModelStringInput | null,
  finishedAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  billedAt?: ModelStringInput | null,
  recievedReturnAt?: ModelStringInput | null,
  reklaOrderNumber?: ModelStringInput | null,
  retoure_type?: ModelRetoureTypeInput | null,
  retoure_reason?: ModelStringInput | null,
  retoure_manufacturing_fee?: ModelFloatInput | null,
  retoure_refund_amount?: ModelFloatInput | null,
  retoure_refund_contractors_share?: ModelIntInput | null,
  and?: Array< ModelProductConditionInput | null > | null,
  or?: Array< ModelProductConditionInput | null > | null,
  not?: ModelProductConditionInput | null,
  createdAt?: ModelStringInput | null,
  orderProductsId?: ModelIDInput | null,
  tourProductsId?: ModelIDInput | null,
  productShipmentId?: ModelIDInput | null,
  productRetoure_shipmentId?: ModelIDInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelProductStatusInput = {
  eq?: ProductStatus | null,
  ne?: ProductStatus | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelRetoureTypeInput = {
  eq?: RetoureType | null,
  ne?: RetoureType | null,
};

export type UpdateProductInput = {
  id: string,
  position?: number | null,
  typename?: string | null,
  name?: string | null,
  sku?: string | null,
  product_id?: string | null,
  variation_id?: string | null,
  quantity?: number | null,
  length?: number | null,
  width?: number | null,
  height?: number | null,
  area?: number | null,
  weight?: string | null,
  categories?: Array< string | null > | null,
  variations?: Array< string | null > | null,
  options?: Array< string | null > | null,
  extraOptions?: Array< OptionInput | null > | null,
  tasks?: Array< string | null > | null,
  currentTask?: string | null,
  contractor_currentTask?: string | null,
  productContractorId?: string | null,
  status?: ProductStatus | null,
  contractor_status?: string | null,
  shipping_label_printed?: boolean | null,
  orderNumber?: string | null,
  productOrderId?: string | null,
  backoffice_note?: string | null,
  total_before_discount?: number | null,
  total_net_price?: number | null,
  total_tax?: number | null,
  nipsild_fixed_costs?: number | null,
  delivery_time?: string | null,
  delivery_date?: string | null,
  declinedTourDates?: Array< string | null > | null,
  billed_amount?: number | null,
  in_progressAt?: string | null,
  ready_to_packAt?: string | null,
  finishedAt?: string | null,
  updatedAt?: string | null,
  billedAt?: string | null,
  recievedReturnAt?: string | null,
  reklaOrderNumber?: string | null,
  retoure_type?: RetoureType | null,
  retoure_reason?: string | null,
  retoure_manufacturing_fee?: number | null,
  retoure_refund_amount?: number | null,
  retoure_refund_contractors_share?: number | null,
  orderProductsId?: string | null,
  tourProductsId?: string | null,
  productShipmentId?: string | null,
  productRetoure_shipmentId?: string | null,
};

export type DeleteProductInput = {
  id: string,
};

export type CreateShipmentInput = {
  id?: string | null,
  typename?: string | null,
  length: number,
  width: number,
  height: number,
  gurtMass: number,
  weight: number,
  serviceProvider: string,
  costs: number,
  calculatedCosts?: number | null,
  shippedAt?: string | null,
  edited?: string | null,
  parcelNumber?: string | null,
  trackID?: string | null,
  suggest_splitting?: boolean | null,
  overrule_splitting_user?: string | null,
  labelData?: LabelDataInput | null,
  updatedAt?: string | null,
};

export type LabelDataInput = {
  base64?: string | null,
  format?: LabelFormat | null,
};

export type ModelShipmentConditionInput = {
  typename?: ModelStringInput | null,
  length?: ModelFloatInput | null,
  width?: ModelFloatInput | null,
  height?: ModelFloatInput | null,
  gurtMass?: ModelFloatInput | null,
  weight?: ModelFloatInput | null,
  serviceProvider?: ModelStringInput | null,
  costs?: ModelFloatInput | null,
  calculatedCosts?: ModelFloatInput | null,
  shippedAt?: ModelStringInput | null,
  edited?: ModelStringInput | null,
  parcelNumber?: ModelStringInput | null,
  trackID?: ModelStringInput | null,
  suggest_splitting?: ModelBooleanInput | null,
  overrule_splitting_user?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelShipmentConditionInput | null > | null,
  or?: Array< ModelShipmentConditionInput | null > | null,
  not?: ModelShipmentConditionInput | null,
  createdAt?: ModelStringInput | null,
};

export type UpdateShipmentInput = {
  id: string,
  typename?: string | null,
  length?: number | null,
  width?: number | null,
  height?: number | null,
  gurtMass?: number | null,
  weight?: number | null,
  serviceProvider?: string | null,
  costs?: number | null,
  calculatedCosts?: number | null,
  shippedAt?: string | null,
  edited?: string | null,
  parcelNumber?: string | null,
  trackID?: string | null,
  suggest_splitting?: boolean | null,
  overrule_splitting_user?: string | null,
  labelData?: LabelDataInput | null,
  updatedAt?: string | null,
};

export type DeleteShipmentInput = {
  id: string,
};

export type CreateTourInput = {
  id?: string | null,
  typename?: string | null,
  date: string,
  deliveryNotificationSentTo?: Array< string | null > | null,
  info?: string | null,
  driver?: string | null,
  calculated_shipping_costs?: number | null,
};

export type ModelTourConditionInput = {
  typename?: ModelStringInput | null,
  date?: ModelStringInput | null,
  deliveryNotificationSentTo?: ModelStringInput | null,
  info?: ModelStringInput | null,
  driver?: ModelStringInput | null,
  calculated_shipping_costs?: ModelFloatInput | null,
  and?: Array< ModelTourConditionInput | null > | null,
  or?: Array< ModelTourConditionInput | null > | null,
  not?: ModelTourConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateTourInput = {
  id: string,
  typename?: string | null,
  date?: string | null,
  deliveryNotificationSentTo?: Array< string | null > | null,
  info?: string | null,
  driver?: string | null,
  calculated_shipping_costs?: number | null,
};

export type DeleteTourInput = {
  id: string,
};

export type CreateContractorInput = {
  id?: string | null,
  number: string,
  name: string,
  billing_rate?: number | null,
  billing_max?: number | null,
};

export type ModelContractorConditionInput = {
  number?: ModelIDInput | null,
  name?: ModelStringInput | null,
  billing_rate?: ModelFloatInput | null,
  billing_max?: ModelFloatInput | null,
  and?: Array< ModelContractorConditionInput | null > | null,
  or?: Array< ModelContractorConditionInput | null > | null,
  not?: ModelContractorConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateContractorInput = {
  id: string,
  number?: string | null,
  name?: string | null,
  billing_rate?: number | null,
  billing_max?: number | null,
};

export type DeleteContractorInput = {
  id: string,
};

export type ModelProductFilterInput = {
  id?: ModelIDInput | null,
  position?: ModelIntInput | null,
  typename?: ModelStringInput | null,
  name?: ModelStringInput | null,
  sku?: ModelStringInput | null,
  product_id?: ModelStringInput | null,
  variation_id?: ModelStringInput | null,
  quantity?: ModelIntInput | null,
  length?: ModelFloatInput | null,
  width?: ModelFloatInput | null,
  height?: ModelFloatInput | null,
  area?: ModelFloatInput | null,
  weight?: ModelStringInput | null,
  categories?: ModelStringInput | null,
  variations?: ModelStringInput | null,
  options?: ModelStringInput | null,
  tasks?: ModelStringInput | null,
  currentTask?: ModelStringInput | null,
  contractor_currentTask?: ModelStringInput | null,
  productContractorId?: ModelIDInput | null,
  status?: ModelProductStatusInput | null,
  contractor_status?: ModelStringInput | null,
  shipping_label_printed?: ModelBooleanInput | null,
  orderNumber?: ModelStringInput | null,
  productOrderId?: ModelIDInput | null,
  backoffice_note?: ModelStringInput | null,
  total_before_discount?: ModelFloatInput | null,
  total_net_price?: ModelFloatInput | null,
  total_tax?: ModelFloatInput | null,
  nipsild_fixed_costs?: ModelFloatInput | null,
  delivery_time?: ModelStringInput | null,
  delivery_date?: ModelStringInput | null,
  declinedTourDates?: ModelStringInput | null,
  billed_amount?: ModelFloatInput | null,
  in_progressAt?: ModelStringInput | null,
  ready_to_packAt?: ModelStringInput | null,
  finishedAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  billedAt?: ModelStringInput | null,
  recievedReturnAt?: ModelStringInput | null,
  reklaOrderNumber?: ModelStringInput | null,
  retoure_type?: ModelRetoureTypeInput | null,
  retoure_reason?: ModelStringInput | null,
  retoure_manufacturing_fee?: ModelFloatInput | null,
  retoure_refund_amount?: ModelFloatInput | null,
  retoure_refund_contractors_share?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelProductFilterInput | null > | null,
  or?: Array< ModelProductFilterInput | null > | null,
  not?: ModelProductFilterInput | null,
  orderProductsId?: ModelIDInput | null,
  tourProductsId?: ModelIDInput | null,
  productShipmentId?: ModelIDInput | null,
  productRetoure_shipmentId?: ModelIDInput | null,
};

export type ModelShipmentFilterInput = {
  id?: ModelIDInput | null,
  typename?: ModelStringInput | null,
  length?: ModelFloatInput | null,
  width?: ModelFloatInput | null,
  height?: ModelFloatInput | null,
  gurtMass?: ModelFloatInput | null,
  weight?: ModelFloatInput | null,
  serviceProvider?: ModelStringInput | null,
  costs?: ModelFloatInput | null,
  calculatedCosts?: ModelFloatInput | null,
  shippedAt?: ModelStringInput | null,
  edited?: ModelStringInput | null,
  parcelNumber?: ModelStringInput | null,
  trackID?: ModelStringInput | null,
  suggest_splitting?: ModelBooleanInput | null,
  overrule_splitting_user?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelShipmentFilterInput | null > | null,
  or?: Array< ModelShipmentFilterInput | null > | null,
  not?: ModelShipmentFilterInput | null,
};

export type ModelShipmentConnection = {
  __typename: "ModelShipmentConnection",
  items:  Array<Shipment | null >,
  nextToken?: string | null,
};

export type ModelTourFilterInput = {
  id?: ModelIDInput | null,
  typename?: ModelStringInput | null,
  date?: ModelStringInput | null,
  deliveryNotificationSentTo?: ModelStringInput | null,
  info?: ModelStringInput | null,
  driver?: ModelStringInput | null,
  calculated_shipping_costs?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTourFilterInput | null > | null,
  or?: Array< ModelTourFilterInput | null > | null,
  not?: ModelTourFilterInput | null,
};

export type ModelTourConnection = {
  __typename: "ModelTourConnection",
  items:  Array<Tour | null >,
  nextToken?: string | null,
};

export type ModelContractorFilterInput = {
  id?: ModelIDInput | null,
  number?: ModelIDInput | null,
  name?: ModelStringInput | null,
  billing_rate?: ModelFloatInput | null,
  billing_max?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelContractorFilterInput | null > | null,
  or?: Array< ModelContractorFilterInput | null > | null,
  not?: ModelContractorFilterInput | null,
};

export type ModelContractorConnection = {
  __typename: "ModelContractorConnection",
  items:  Array<Contractor | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionOrderFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  typename?: ModelSubscriptionStringInput | null,
  orderNumber?: ModelSubscriptionStringInput | null,
  date_created?: ModelSubscriptionStringInput | null,
  date_paid?: ModelSubscriptionStringInput | null,
  total?: ModelSubscriptionFloatInput | null,
  cart_tax?: ModelSubscriptionFloatInput | null,
  total_tax?: ModelSubscriptionFloatInput | null,
  discount_total?: ModelSubscriptionFloatInput | null,
  discount_tax?: ModelSubscriptionFloatInput | null,
  shipping_total?: ModelSubscriptionFloatInput | null,
  shipping_tax?: ModelSubscriptionFloatInput | null,
  weight?: ModelSubscriptionFloatInput | null,
  shipping_method?: ModelSubscriptionStringInput | null,
  delivery_date?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  state?: ModelSubscriptionStringInput | null,
  source?: ModelSubscriptionStringInput | null,
  in_progressAt?: ModelSubscriptionStringInput | null,
  finishedAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  retoure_shipping_costs?: ModelSubscriptionFloatInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrderFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrderFilterInput | null > | null,
  orderProductsId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionProductFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  position?: ModelSubscriptionIntInput | null,
  typename?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  sku?: ModelSubscriptionStringInput | null,
  product_id?: ModelSubscriptionStringInput | null,
  variation_id?: ModelSubscriptionStringInput | null,
  quantity?: ModelSubscriptionIntInput | null,
  length?: ModelSubscriptionFloatInput | null,
  width?: ModelSubscriptionFloatInput | null,
  height?: ModelSubscriptionFloatInput | null,
  area?: ModelSubscriptionFloatInput | null,
  weight?: ModelSubscriptionStringInput | null,
  categories?: ModelSubscriptionStringInput | null,
  variations?: ModelSubscriptionStringInput | null,
  options?: ModelSubscriptionStringInput | null,
  tasks?: ModelSubscriptionStringInput | null,
  currentTask?: ModelSubscriptionStringInput | null,
  contractor_currentTask?: ModelSubscriptionStringInput | null,
  productContractorId?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  contractor_status?: ModelSubscriptionStringInput | null,
  shipping_label_printed?: ModelSubscriptionBooleanInput | null,
  orderNumber?: ModelSubscriptionStringInput | null,
  productOrderId?: ModelSubscriptionIDInput | null,
  backoffice_note?: ModelSubscriptionStringInput | null,
  total_before_discount?: ModelSubscriptionFloatInput | null,
  total_net_price?: ModelSubscriptionFloatInput | null,
  total_tax?: ModelSubscriptionFloatInput | null,
  nipsild_fixed_costs?: ModelSubscriptionFloatInput | null,
  delivery_time?: ModelSubscriptionStringInput | null,
  delivery_date?: ModelSubscriptionStringInput | null,
  declinedTourDates?: ModelSubscriptionStringInput | null,
  billed_amount?: ModelSubscriptionFloatInput | null,
  in_progressAt?: ModelSubscriptionStringInput | null,
  ready_to_packAt?: ModelSubscriptionStringInput | null,
  finishedAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  billedAt?: ModelSubscriptionStringInput | null,
  recievedReturnAt?: ModelSubscriptionStringInput | null,
  reklaOrderNumber?: ModelSubscriptionStringInput | null,
  retoure_type?: ModelSubscriptionStringInput | null,
  retoure_reason?: ModelSubscriptionStringInput | null,
  retoure_manufacturing_fee?: ModelSubscriptionFloatInput | null,
  retoure_refund_amount?: ModelSubscriptionFloatInput | null,
  retoure_refund_contractors_share?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProductFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductFilterInput | null > | null,
  productShipmentId?: ModelSubscriptionIDInput | null,
  productRetoure_shipmentId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionShipmentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  typename?: ModelSubscriptionStringInput | null,
  length?: ModelSubscriptionFloatInput | null,
  width?: ModelSubscriptionFloatInput | null,
  height?: ModelSubscriptionFloatInput | null,
  gurtMass?: ModelSubscriptionFloatInput | null,
  weight?: ModelSubscriptionFloatInput | null,
  serviceProvider?: ModelSubscriptionStringInput | null,
  costs?: ModelSubscriptionFloatInput | null,
  calculatedCosts?: ModelSubscriptionFloatInput | null,
  shippedAt?: ModelSubscriptionStringInput | null,
  edited?: ModelSubscriptionStringInput | null,
  parcelNumber?: ModelSubscriptionStringInput | null,
  trackID?: ModelSubscriptionStringInput | null,
  suggest_splitting?: ModelSubscriptionBooleanInput | null,
  overrule_splitting_user?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionShipmentFilterInput | null > | null,
  or?: Array< ModelSubscriptionShipmentFilterInput | null > | null,
};

export type ModelSubscriptionTourFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  typename?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  deliveryNotificationSentTo?: ModelSubscriptionStringInput | null,
  info?: ModelSubscriptionStringInput | null,
  driver?: ModelSubscriptionStringInput | null,
  calculated_shipping_costs?: ModelSubscriptionFloatInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTourFilterInput | null > | null,
  or?: Array< ModelSubscriptionTourFilterInput | null > | null,
  tourProductsId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionContractorFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  number?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  billing_rate?: ModelSubscriptionFloatInput | null,
  billing_max?: ModelSubscriptionFloatInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionContractorFilterInput | null > | null,
  or?: Array< ModelSubscriptionContractorFilterInput | null > | null,
};

export type CustomOrdersByShippingMethodQueryVariables = {
  shipping_method: string,
  state?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomOrdersByShippingMethodQuery = {
  ordersByShippingMethod?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      products?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          position?: number | null,
          typename?: string | null,
          name: string,
          sku?: string | null,
          product_id?: string | null,
          variation_id?: string | null,
          quantity?: number | null,
          length?: number | null,
          width?: number | null,
          height?: number | null,
          area?: number | null,
          weight?: string | null,
          categories?: Array< string | null > | null,
          variations?: Array< string | null > | null,
          options?: Array< string | null > | null,
          extraOptions?:  Array< {
            __typename: "Option",
            name?: string | null,
            value: string,
            imagesp?: string | null,
            price?: number | null,
          } | null > | null,
          tasks?: Array< string | null > | null,
          currentTask?: string | null,
          contractor_currentTask?: string | null,
          productContractorId?: string | null,
          contractor?:  {
            __typename: "Contractor",
            id: string,
            number: string,
            name: string,
            billing_rate?: number | null,
            billing_max?: number | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          status?: ProductStatus | null,
          contractor_status: string,
          shipping_label_printed?: boolean | null,
          orderNumber?: string | null,
          order?:  {
            __typename: "Order",
            id: string,
            typename?: string | null,
            orderNumber: string,
            date_created: string,
            date_paid: string,
            total?: number | null,
            cart_tax?: number | null,
            total_tax?: number | null,
            discount_total?: number | null,
            discount_tax?: number | null,
            shipping_total?: number | null,
            shipping_tax?: number | null,
            fees?:  Array< {
              __typename: "Fee",
              name?: string | null,
              amount?: number | null,
              total?: number | null,
              total_tax?: number | null,
            } | null > | null,
            weight?: number | null,
            customer:  {
              __typename: "Customer",
              first_name: string,
              last_name: string,
              street?: string | null,
              house_number?: string | null,
              zip?: string | null,
              city?: string | null,
              country?: string | null,
              email?: string | null,
              phone?: string | null,
              company?: string | null,
              add?: string | null,
            },
            shipping_method?: string | null,
            delivery_date: string,
            status?: OrderStatus | null,
            state?: OrderState | null,
            source: OrderSource,
            in_progressAt?: string | null,
            finishedAt?: string | null,
            updatedAt?: string | null,
            retoure_shipping_costs?: number | null,
            createdAt: string,
          } | null,
          productOrderId: string,
          backoffice_note?: string | null,
          total_before_discount?: number | null,
          total_net_price?: number | null,
          total_tax?: number | null,
          nipsild_fixed_costs?: number | null,
          delivery_time?: string | null,
          delivery_date: string,
          shipment?:  {
            __typename: "Shipment",
            id: string,
            typename?: string | null,
            length: number,
            width: number,
            height: number,
            gurtMass: number,
            weight: number,
            serviceProvider: string,
            costs: number,
            calculatedCosts?: number | null,
            shippedAt?: string | null,
            edited?: string | null,
            parcelNumber?: string | null,
            trackID?: string | null,
            suggest_splitting?: boolean | null,
            overrule_splitting_user?: string | null,
            labelData?:  {
              __typename: "LabelData",
              base64?: string | null,
              format?: LabelFormat | null,
            } | null,
            updatedAt?: string | null,
            createdAt: string,
          } | null,
          tour?:  {
            __typename: "Tour",
            id: string,
            typename?: string | null,
            date: string,
            products?:  {
              __typename: "ModelProductConnection",
              nextToken?: string | null,
            } | null,
            deliveryNotificationSentTo?: Array< string | null > | null,
            info?: string | null,
            driver?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          declinedTourDates?: Array< string | null > | null,
          billed_amount?: number | null,
          in_progressAt?: string | null,
          ready_to_packAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          billedAt?: string | null,
          recievedReturnAt?: string | null,
          reklaOrderNumber?: string | null,
          retoure_type?: RetoureType | null,
          retoure_reason?: string | null,
          retoure_manufacturing_fee?: number | null,
          retoure_refund_amount?: number | null,
          retoure_refund_contractors_share?: number | null,
          retoure_shipment?:  {
            __typename: "Shipment",
            id: string,
            typename?: string | null,
            length: number,
            width: number,
            height: number,
            gurtMass: number,
            weight: number,
            serviceProvider: string,
            costs: number,
            calculatedCosts?: number | null,
            shippedAt?: string | null,
            edited?: string | null,
            parcelNumber?: string | null,
            trackID?: string | null,
            suggest_splitting?: boolean | null,
            overrule_splitting_user?: string | null,
            labelData?:  {
              __typename: "LabelData",
              base64?: string | null,
              format?: LabelFormat | null,
            } | null,
            updatedAt?: string | null,
            createdAt: string,
          } | null,
          createdAt: string,
          orderProductsId?: string | null,
          tourProductsId?: string | null,
          productShipmentId?: string | null,
          productRetoure_shipmentId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CreateOrderMutationVariables = {
  input: CreateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type CreateOrderMutation = {
  createOrder?:  {
    __typename: "Order",
    id: string,
    typename?: string | null,
    orderNumber: string,
    date_created: string,
    date_paid: string,
    total?: number | null,
    cart_tax?: number | null,
    total_tax?: number | null,
    discount_total?: number | null,
    discount_tax?: number | null,
    shipping_total?: number | null,
    shipping_tax?: number | null,
    fees?:  Array< {
      __typename: "Fee",
      name?: string | null,
      amount?: number | null,
      total?: number | null,
      total_tax?: number | null,
    } | null > | null,
    weight?: number | null,
    customer:  {
      __typename: "Customer",
      first_name: string,
      last_name: string,
      street?: string | null,
      house_number?: string | null,
      zip?: string | null,
      city?: string | null,
      country?: string | null,
      email?: string | null,
      phone?: string | null,
      company?: string | null,
      add?: string | null,
    },
    shipping_method?: string | null,
    delivery_date: string,
    status?: OrderStatus | null,
    state?: OrderState | null,
    source: OrderSource,
    in_progressAt?: string | null,
    finishedAt?: string | null,
    updatedAt?: string | null,
    retoure_shipping_costs?: number | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        position?: number | null,
        typename?: string | null,
        name: string,
        sku?: string | null,
        product_id?: string | null,
        variation_id?: string | null,
        quantity?: number | null,
        length?: number | null,
        width?: number | null,
        height?: number | null,
        area?: number | null,
        weight?: string | null,
        categories?: Array< string | null > | null,
        variations?: Array< string | null > | null,
        options?: Array< string | null > | null,
        extraOptions?:  Array< {
          __typename: "Option",
          name?: string | null,
          value: string,
          imagesp?: string | null,
          price?: number | null,
        } | null > | null,
        tasks?: Array< string | null > | null,
        currentTask?: string | null,
        contractor_currentTask?: string | null,
        productContractorId?: string | null,
        contractor?:  {
          __typename: "Contractor",
          id: string,
          number: string,
          name: string,
          billing_rate?: number | null,
          billing_max?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        status?: ProductStatus | null,
        contractor_status: string,
        shipping_label_printed?: boolean | null,
        orderNumber?: string | null,
        order?:  {
          __typename: "Order",
          id: string,
          typename?: string | null,
          orderNumber: string,
          date_created: string,
          date_paid: string,
          total?: number | null,
          cart_tax?: number | null,
          total_tax?: number | null,
          discount_total?: number | null,
          discount_tax?: number | null,
          shipping_total?: number | null,
          shipping_tax?: number | null,
          weight?: number | null,
          shipping_method?: string | null,
          delivery_date: string,
          status?: OrderStatus | null,
          state?: OrderState | null,
          source: OrderSource,
          in_progressAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          retoure_shipping_costs?: number | null,
          createdAt: string,
        } | null,
        productOrderId: string,
        backoffice_note?: string | null,
        total_before_discount?: number | null,
        total_net_price?: number | null,
        total_tax?: number | null,
        nipsild_fixed_costs?: number | null,
        delivery_time?: string | null,
        delivery_date: string,
        shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        tour?:  {
          __typename: "Tour",
          id: string,
          typename?: string | null,
          date: string,
          deliveryNotificationSentTo?: Array< string | null > | null,
          info?: string | null,
          driver?: string | null,
          calculated_shipping_costs?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        declinedTourDates?: Array< string | null > | null,
        billed_amount?: number | null,
        in_progressAt?: string | null,
        ready_to_packAt?: string | null,
        finishedAt?: string | null,
        updatedAt?: string | null,
        billedAt?: string | null,
        recievedReturnAt?: string | null,
        reklaOrderNumber?: string | null,
        retoure_type?: RetoureType | null,
        retoure_reason?: string | null,
        retoure_manufacturing_fee?: number | null,
        retoure_refund_amount?: number | null,
        retoure_refund_contractors_share?: number | null,
        retoure_shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        createdAt: string,
        orderProductsId?: string | null,
        tourProductsId?: string | null,
        productShipmentId?: string | null,
        productRetoure_shipmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
  } | null,
};

export type UpdateOrderMutationVariables = {
  input: UpdateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type UpdateOrderMutation = {
  updateOrder?:  {
    __typename: "Order",
    id: string,
    typename?: string | null,
    orderNumber: string,
    date_created: string,
    date_paid: string,
    total?: number | null,
    cart_tax?: number | null,
    total_tax?: number | null,
    discount_total?: number | null,
    discount_tax?: number | null,
    shipping_total?: number | null,
    shipping_tax?: number | null,
    fees?:  Array< {
      __typename: "Fee",
      name?: string | null,
      amount?: number | null,
      total?: number | null,
      total_tax?: number | null,
    } | null > | null,
    weight?: number | null,
    customer:  {
      __typename: "Customer",
      first_name: string,
      last_name: string,
      street?: string | null,
      house_number?: string | null,
      zip?: string | null,
      city?: string | null,
      country?: string | null,
      email?: string | null,
      phone?: string | null,
      company?: string | null,
      add?: string | null,
    },
    shipping_method?: string | null,
    delivery_date: string,
    status?: OrderStatus | null,
    state?: OrderState | null,
    source: OrderSource,
    in_progressAt?: string | null,
    finishedAt?: string | null,
    updatedAt?: string | null,
    retoure_shipping_costs?: number | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        position?: number | null,
        typename?: string | null,
        name: string,
        sku?: string | null,
        product_id?: string | null,
        variation_id?: string | null,
        quantity?: number | null,
        length?: number | null,
        width?: number | null,
        height?: number | null,
        area?: number | null,
        weight?: string | null,
        categories?: Array< string | null > | null,
        variations?: Array< string | null > | null,
        options?: Array< string | null > | null,
        extraOptions?:  Array< {
          __typename: "Option",
          name?: string | null,
          value: string,
          imagesp?: string | null,
          price?: number | null,
        } | null > | null,
        tasks?: Array< string | null > | null,
        currentTask?: string | null,
        contractor_currentTask?: string | null,
        productContractorId?: string | null,
        contractor?:  {
          __typename: "Contractor",
          id: string,
          number: string,
          name: string,
          billing_rate?: number | null,
          billing_max?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        status?: ProductStatus | null,
        contractor_status: string,
        shipping_label_printed?: boolean | null,
        orderNumber?: string | null,
        order?:  {
          __typename: "Order",
          id: string,
          typename?: string | null,
          orderNumber: string,
          date_created: string,
          date_paid: string,
          total?: number | null,
          cart_tax?: number | null,
          total_tax?: number | null,
          discount_total?: number | null,
          discount_tax?: number | null,
          shipping_total?: number | null,
          shipping_tax?: number | null,
          weight?: number | null,
          shipping_method?: string | null,
          delivery_date: string,
          status?: OrderStatus | null,
          state?: OrderState | null,
          source: OrderSource,
          in_progressAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          retoure_shipping_costs?: number | null,
          createdAt: string,
        } | null,
        productOrderId: string,
        backoffice_note?: string | null,
        total_before_discount?: number | null,
        total_net_price?: number | null,
        total_tax?: number | null,
        nipsild_fixed_costs?: number | null,
        delivery_time?: string | null,
        delivery_date: string,
        shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        tour?:  {
          __typename: "Tour",
          id: string,
          typename?: string | null,
          date: string,
          deliveryNotificationSentTo?: Array< string | null > | null,
          info?: string | null,
          driver?: string | null,
          calculated_shipping_costs?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        declinedTourDates?: Array< string | null > | null,
        billed_amount?: number | null,
        in_progressAt?: string | null,
        ready_to_packAt?: string | null,
        finishedAt?: string | null,
        updatedAt?: string | null,
        billedAt?: string | null,
        recievedReturnAt?: string | null,
        reklaOrderNumber?: string | null,
        retoure_type?: RetoureType | null,
        retoure_reason?: string | null,
        retoure_manufacturing_fee?: number | null,
        retoure_refund_amount?: number | null,
        retoure_refund_contractors_share?: number | null,
        retoure_shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        createdAt: string,
        orderProductsId?: string | null,
        tourProductsId?: string | null,
        productShipmentId?: string | null,
        productRetoure_shipmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
  } | null,
};

export type DeleteOrderMutationVariables = {
  input: DeleteOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type DeleteOrderMutation = {
  deleteOrder?:  {
    __typename: "Order",
    id: string,
    typename?: string | null,
    orderNumber: string,
    date_created: string,
    date_paid: string,
    total?: number | null,
    cart_tax?: number | null,
    total_tax?: number | null,
    discount_total?: number | null,
    discount_tax?: number | null,
    shipping_total?: number | null,
    shipping_tax?: number | null,
    fees?:  Array< {
      __typename: "Fee",
      name?: string | null,
      amount?: number | null,
      total?: number | null,
      total_tax?: number | null,
    } | null > | null,
    weight?: number | null,
    customer:  {
      __typename: "Customer",
      first_name: string,
      last_name: string,
      street?: string | null,
      house_number?: string | null,
      zip?: string | null,
      city?: string | null,
      country?: string | null,
      email?: string | null,
      phone?: string | null,
      company?: string | null,
      add?: string | null,
    },
    shipping_method?: string | null,
    delivery_date: string,
    status?: OrderStatus | null,
    state?: OrderState | null,
    source: OrderSource,
    in_progressAt?: string | null,
    finishedAt?: string | null,
    updatedAt?: string | null,
    retoure_shipping_costs?: number | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        position?: number | null,
        typename?: string | null,
        name: string,
        sku?: string | null,
        product_id?: string | null,
        variation_id?: string | null,
        quantity?: number | null,
        length?: number | null,
        width?: number | null,
        height?: number | null,
        area?: number | null,
        weight?: string | null,
        categories?: Array< string | null > | null,
        variations?: Array< string | null > | null,
        options?: Array< string | null > | null,
        extraOptions?:  Array< {
          __typename: "Option",
          name?: string | null,
          value: string,
          imagesp?: string | null,
          price?: number | null,
        } | null > | null,
        tasks?: Array< string | null > | null,
        currentTask?: string | null,
        contractor_currentTask?: string | null,
        productContractorId?: string | null,
        contractor?:  {
          __typename: "Contractor",
          id: string,
          number: string,
          name: string,
          billing_rate?: number | null,
          billing_max?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        status?: ProductStatus | null,
        contractor_status: string,
        shipping_label_printed?: boolean | null,
        orderNumber?: string | null,
        order?:  {
          __typename: "Order",
          id: string,
          typename?: string | null,
          orderNumber: string,
          date_created: string,
          date_paid: string,
          total?: number | null,
          cart_tax?: number | null,
          total_tax?: number | null,
          discount_total?: number | null,
          discount_tax?: number | null,
          shipping_total?: number | null,
          shipping_tax?: number | null,
          weight?: number | null,
          shipping_method?: string | null,
          delivery_date: string,
          status?: OrderStatus | null,
          state?: OrderState | null,
          source: OrderSource,
          in_progressAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          retoure_shipping_costs?: number | null,
          createdAt: string,
        } | null,
        productOrderId: string,
        backoffice_note?: string | null,
        total_before_discount?: number | null,
        total_net_price?: number | null,
        total_tax?: number | null,
        nipsild_fixed_costs?: number | null,
        delivery_time?: string | null,
        delivery_date: string,
        shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        tour?:  {
          __typename: "Tour",
          id: string,
          typename?: string | null,
          date: string,
          deliveryNotificationSentTo?: Array< string | null > | null,
          info?: string | null,
          driver?: string | null,
          calculated_shipping_costs?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        declinedTourDates?: Array< string | null > | null,
        billed_amount?: number | null,
        in_progressAt?: string | null,
        ready_to_packAt?: string | null,
        finishedAt?: string | null,
        updatedAt?: string | null,
        billedAt?: string | null,
        recievedReturnAt?: string | null,
        reklaOrderNumber?: string | null,
        retoure_type?: RetoureType | null,
        retoure_reason?: string | null,
        retoure_manufacturing_fee?: number | null,
        retoure_refund_amount?: number | null,
        retoure_refund_contractors_share?: number | null,
        retoure_shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        createdAt: string,
        orderProductsId?: string | null,
        tourProductsId?: string | null,
        productShipmentId?: string | null,
        productRetoure_shipmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
  } | null,
};

export type CreateProductMutationVariables = {
  input: CreateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type CreateProductMutation = {
  createProduct?:  {
    __typename: "Product",
    id: string,
    position?: number | null,
    typename?: string | null,
    name: string,
    sku?: string | null,
    product_id?: string | null,
    variation_id?: string | null,
    quantity?: number | null,
    length?: number | null,
    width?: number | null,
    height?: number | null,
    area?: number | null,
    weight?: string | null,
    categories?: Array< string | null > | null,
    variations?: Array< string | null > | null,
    options?: Array< string | null > | null,
    extraOptions?:  Array< {
      __typename: "Option",
      name?: string | null,
      value: string,
      imagesp?: string | null,
      price?: number | null,
    } | null > | null,
    tasks?: Array< string | null > | null,
    currentTask?: string | null,
    contractor_currentTask?: string | null,
    productContractorId?: string | null,
    contractor?:  {
      __typename: "Contractor",
      id: string,
      number: string,
      name: string,
      billing_rate?: number | null,
      billing_max?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: ProductStatus | null,
    contractor_status: string,
    shipping_label_printed?: boolean | null,
    orderNumber?: string | null,
    order?:  {
      __typename: "Order",
      id: string,
      typename?: string | null,
      orderNumber: string,
      date_created: string,
      date_paid: string,
      total?: number | null,
      cart_tax?: number | null,
      total_tax?: number | null,
      discount_total?: number | null,
      discount_tax?: number | null,
      shipping_total?: number | null,
      shipping_tax?: number | null,
      fees?:  Array< {
        __typename: "Fee",
        name?: string | null,
        amount?: number | null,
        total?: number | null,
        total_tax?: number | null,
      } | null > | null,
      weight?: number | null,
      customer:  {
        __typename: "Customer",
        first_name: string,
        last_name: string,
        street?: string | null,
        house_number?: string | null,
        zip?: string | null,
        city?: string | null,
        country?: string | null,
        email?: string | null,
        phone?: string | null,
        company?: string | null,
        add?: string | null,
      },
      shipping_method?: string | null,
      delivery_date: string,
      status?: OrderStatus | null,
      state?: OrderState | null,
      source: OrderSource,
      in_progressAt?: string | null,
      finishedAt?: string | null,
      updatedAt?: string | null,
      retoure_shipping_costs?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          position?: number | null,
          typename?: string | null,
          name: string,
          sku?: string | null,
          product_id?: string | null,
          variation_id?: string | null,
          quantity?: number | null,
          length?: number | null,
          width?: number | null,
          height?: number | null,
          area?: number | null,
          weight?: string | null,
          categories?: Array< string | null > | null,
          variations?: Array< string | null > | null,
          options?: Array< string | null > | null,
          tasks?: Array< string | null > | null,
          currentTask?: string | null,
          contractor_currentTask?: string | null,
          productContractorId?: string | null,
          status?: ProductStatus | null,
          contractor_status: string,
          shipping_label_printed?: boolean | null,
          orderNumber?: string | null,
          productOrderId: string,
          backoffice_note?: string | null,
          total_before_discount?: number | null,
          total_net_price?: number | null,
          total_tax?: number | null,
          nipsild_fixed_costs?: number | null,
          delivery_time?: string | null,
          delivery_date: string,
          declinedTourDates?: Array< string | null > | null,
          billed_amount?: number | null,
          in_progressAt?: string | null,
          ready_to_packAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          billedAt?: string | null,
          recievedReturnAt?: string | null,
          reklaOrderNumber?: string | null,
          retoure_type?: RetoureType | null,
          retoure_reason?: string | null,
          retoure_manufacturing_fee?: number | null,
          retoure_refund_amount?: number | null,
          retoure_refund_contractors_share?: number | null,
          createdAt: string,
          orderProductsId?: string | null,
          tourProductsId?: string | null,
          productShipmentId?: string | null,
          productRetoure_shipmentId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
    } | null,
    productOrderId: string,
    backoffice_note?: string | null,
    total_before_discount?: number | null,
    total_net_price?: number | null,
    total_tax?: number | null,
    nipsild_fixed_costs?: number | null,
    delivery_time?: string | null,
    delivery_date: string,
    shipment?:  {
      __typename: "Shipment",
      id: string,
      typename?: string | null,
      length: number,
      width: number,
      height: number,
      gurtMass: number,
      weight: number,
      serviceProvider: string,
      costs: number,
      calculatedCosts?: number | null,
      shippedAt?: string | null,
      edited?: string | null,
      parcelNumber?: string | null,
      trackID?: string | null,
      suggest_splitting?: boolean | null,
      overrule_splitting_user?: string | null,
      labelData?:  {
        __typename: "LabelData",
        base64?: string | null,
        format?: LabelFormat | null,
      } | null,
      updatedAt?: string | null,
      createdAt: string,
    } | null,
    tour?:  {
      __typename: "Tour",
      id: string,
      typename?: string | null,
      date: string,
      products?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          position?: number | null,
          typename?: string | null,
          name: string,
          sku?: string | null,
          product_id?: string | null,
          variation_id?: string | null,
          quantity?: number | null,
          length?: number | null,
          width?: number | null,
          height?: number | null,
          area?: number | null,
          weight?: string | null,
          categories?: Array< string | null > | null,
          variations?: Array< string | null > | null,
          options?: Array< string | null > | null,
          tasks?: Array< string | null > | null,
          currentTask?: string | null,
          contractor_currentTask?: string | null,
          productContractorId?: string | null,
          status?: ProductStatus | null,
          contractor_status: string,
          shipping_label_printed?: boolean | null,
          orderNumber?: string | null,
          productOrderId: string,
          backoffice_note?: string | null,
          total_before_discount?: number | null,
          total_net_price?: number | null,
          total_tax?: number | null,
          nipsild_fixed_costs?: number | null,
          delivery_time?: string | null,
          delivery_date: string,
          declinedTourDates?: Array< string | null > | null,
          billed_amount?: number | null,
          in_progressAt?: string | null,
          ready_to_packAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          billedAt?: string | null,
          recievedReturnAt?: string | null,
          reklaOrderNumber?: string | null,
          retoure_type?: RetoureType | null,
          retoure_reason?: string | null,
          retoure_manufacturing_fee?: number | null,
          retoure_refund_amount?: number | null,
          retoure_refund_contractors_share?: number | null,
          createdAt: string,
          orderProductsId?: string | null,
          tourProductsId?: string | null,
          productShipmentId?: string | null,
          productRetoure_shipmentId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      deliveryNotificationSentTo?: Array< string | null > | null,
      info?: string | null,
      driver?: string | null,
      calculated_shipping_costs?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    declinedTourDates?: Array< string | null > | null,
    billed_amount?: number | null,
    in_progressAt?: string | null,
    ready_to_packAt?: string | null,
    finishedAt?: string | null,
    updatedAt?: string | null,
    billedAt?: string | null,
    recievedReturnAt?: string | null,
    reklaOrderNumber?: string | null,
    retoure_type?: RetoureType | null,
    retoure_reason?: string | null,
    retoure_manufacturing_fee?: number | null,
    retoure_refund_amount?: number | null,
    retoure_refund_contractors_share?: number | null,
    retoure_shipment?:  {
      __typename: "Shipment",
      id: string,
      typename?: string | null,
      length: number,
      width: number,
      height: number,
      gurtMass: number,
      weight: number,
      serviceProvider: string,
      costs: number,
      calculatedCosts?: number | null,
      shippedAt?: string | null,
      edited?: string | null,
      parcelNumber?: string | null,
      trackID?: string | null,
      suggest_splitting?: boolean | null,
      overrule_splitting_user?: string | null,
      labelData?:  {
        __typename: "LabelData",
        base64?: string | null,
        format?: LabelFormat | null,
      } | null,
      updatedAt?: string | null,
      createdAt: string,
    } | null,
    createdAt: string,
    orderProductsId?: string | null,
    tourProductsId?: string | null,
    productShipmentId?: string | null,
    productRetoure_shipmentId?: string | null,
  } | null,
};

export type UpdateProductMutationVariables = {
  input: UpdateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type UpdateProductMutation = {
  updateProduct?:  {
    __typename: "Product",
    id: string,
    position?: number | null,
    typename?: string | null,
    name: string,
    sku?: string | null,
    product_id?: string | null,
    variation_id?: string | null,
    quantity?: number | null,
    length?: number | null,
    width?: number | null,
    height?: number | null,
    area?: number | null,
    weight?: string | null,
    categories?: Array< string | null > | null,
    variations?: Array< string | null > | null,
    options?: Array< string | null > | null,
    extraOptions?:  Array< {
      __typename: "Option",
      name?: string | null,
      value: string,
      imagesp?: string | null,
      price?: number | null,
    } | null > | null,
    tasks?: Array< string | null > | null,
    currentTask?: string | null,
    contractor_currentTask?: string | null,
    productContractorId?: string | null,
    contractor?:  {
      __typename: "Contractor",
      id: string,
      number: string,
      name: string,
      billing_rate?: number | null,
      billing_max?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: ProductStatus | null,
    contractor_status: string,
    shipping_label_printed?: boolean | null,
    orderNumber?: string | null,
    order?:  {
      __typename: "Order",
      id: string,
      typename?: string | null,
      orderNumber: string,
      date_created: string,
      date_paid: string,
      total?: number | null,
      cart_tax?: number | null,
      total_tax?: number | null,
      discount_total?: number | null,
      discount_tax?: number | null,
      shipping_total?: number | null,
      shipping_tax?: number | null,
      fees?:  Array< {
        __typename: "Fee",
        name?: string | null,
        amount?: number | null,
        total?: number | null,
        total_tax?: number | null,
      } | null > | null,
      weight?: number | null,
      customer:  {
        __typename: "Customer",
        first_name: string,
        last_name: string,
        street?: string | null,
        house_number?: string | null,
        zip?: string | null,
        city?: string | null,
        country?: string | null,
        email?: string | null,
        phone?: string | null,
        company?: string | null,
        add?: string | null,
      },
      shipping_method?: string | null,
      delivery_date: string,
      status?: OrderStatus | null,
      state?: OrderState | null,
      source: OrderSource,
      in_progressAt?: string | null,
      finishedAt?: string | null,
      updatedAt?: string | null,
      retoure_shipping_costs?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          position?: number | null,
          typename?: string | null,
          name: string,
          sku?: string | null,
          product_id?: string | null,
          variation_id?: string | null,
          quantity?: number | null,
          length?: number | null,
          width?: number | null,
          height?: number | null,
          area?: number | null,
          weight?: string | null,
          categories?: Array< string | null > | null,
          variations?: Array< string | null > | null,
          options?: Array< string | null > | null,
          tasks?: Array< string | null > | null,
          currentTask?: string | null,
          contractor_currentTask?: string | null,
          productContractorId?: string | null,
          status?: ProductStatus | null,
          contractor_status: string,
          shipping_label_printed?: boolean | null,
          orderNumber?: string | null,
          productOrderId: string,
          backoffice_note?: string | null,
          total_before_discount?: number | null,
          total_net_price?: number | null,
          total_tax?: number | null,
          nipsild_fixed_costs?: number | null,
          delivery_time?: string | null,
          delivery_date: string,
          declinedTourDates?: Array< string | null > | null,
          billed_amount?: number | null,
          in_progressAt?: string | null,
          ready_to_packAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          billedAt?: string | null,
          recievedReturnAt?: string | null,
          reklaOrderNumber?: string | null,
          retoure_type?: RetoureType | null,
          retoure_reason?: string | null,
          retoure_manufacturing_fee?: number | null,
          retoure_refund_amount?: number | null,
          retoure_refund_contractors_share?: number | null,
          createdAt: string,
          orderProductsId?: string | null,
          tourProductsId?: string | null,
          productShipmentId?: string | null,
          productRetoure_shipmentId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
    } | null,
    productOrderId: string,
    backoffice_note?: string | null,
    total_before_discount?: number | null,
    total_net_price?: number | null,
    total_tax?: number | null,
    nipsild_fixed_costs?: number | null,
    delivery_time?: string | null,
    delivery_date: string,
    shipment?:  {
      __typename: "Shipment",
      id: string,
      typename?: string | null,
      length: number,
      width: number,
      height: number,
      gurtMass: number,
      weight: number,
      serviceProvider: string,
      costs: number,
      calculatedCosts?: number | null,
      shippedAt?: string | null,
      edited?: string | null,
      parcelNumber?: string | null,
      trackID?: string | null,
      suggest_splitting?: boolean | null,
      overrule_splitting_user?: string | null,
      labelData?:  {
        __typename: "LabelData",
        base64?: string | null,
        format?: LabelFormat | null,
      } | null,
      updatedAt?: string | null,
      createdAt: string,
    } | null,
    tour?:  {
      __typename: "Tour",
      id: string,
      typename?: string | null,
      date: string,
      products?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          position?: number | null,
          typename?: string | null,
          name: string,
          sku?: string | null,
          product_id?: string | null,
          variation_id?: string | null,
          quantity?: number | null,
          length?: number | null,
          width?: number | null,
          height?: number | null,
          area?: number | null,
          weight?: string | null,
          categories?: Array< string | null > | null,
          variations?: Array< string | null > | null,
          options?: Array< string | null > | null,
          tasks?: Array< string | null > | null,
          currentTask?: string | null,
          contractor_currentTask?: string | null,
          productContractorId?: string | null,
          status?: ProductStatus | null,
          contractor_status: string,
          shipping_label_printed?: boolean | null,
          orderNumber?: string | null,
          productOrderId: string,
          backoffice_note?: string | null,
          total_before_discount?: number | null,
          total_net_price?: number | null,
          total_tax?: number | null,
          nipsild_fixed_costs?: number | null,
          delivery_time?: string | null,
          delivery_date: string,
          declinedTourDates?: Array< string | null > | null,
          billed_amount?: number | null,
          in_progressAt?: string | null,
          ready_to_packAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          billedAt?: string | null,
          recievedReturnAt?: string | null,
          reklaOrderNumber?: string | null,
          retoure_type?: RetoureType | null,
          retoure_reason?: string | null,
          retoure_manufacturing_fee?: number | null,
          retoure_refund_amount?: number | null,
          retoure_refund_contractors_share?: number | null,
          createdAt: string,
          orderProductsId?: string | null,
          tourProductsId?: string | null,
          productShipmentId?: string | null,
          productRetoure_shipmentId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      deliveryNotificationSentTo?: Array< string | null > | null,
      info?: string | null,
      driver?: string | null,
      calculated_shipping_costs?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    declinedTourDates?: Array< string | null > | null,
    billed_amount?: number | null,
    in_progressAt?: string | null,
    ready_to_packAt?: string | null,
    finishedAt?: string | null,
    updatedAt?: string | null,
    billedAt?: string | null,
    recievedReturnAt?: string | null,
    reklaOrderNumber?: string | null,
    retoure_type?: RetoureType | null,
    retoure_reason?: string | null,
    retoure_manufacturing_fee?: number | null,
    retoure_refund_amount?: number | null,
    retoure_refund_contractors_share?: number | null,
    retoure_shipment?:  {
      __typename: "Shipment",
      id: string,
      typename?: string | null,
      length: number,
      width: number,
      height: number,
      gurtMass: number,
      weight: number,
      serviceProvider: string,
      costs: number,
      calculatedCosts?: number | null,
      shippedAt?: string | null,
      edited?: string | null,
      parcelNumber?: string | null,
      trackID?: string | null,
      suggest_splitting?: boolean | null,
      overrule_splitting_user?: string | null,
      labelData?:  {
        __typename: "LabelData",
        base64?: string | null,
        format?: LabelFormat | null,
      } | null,
      updatedAt?: string | null,
      createdAt: string,
    } | null,
    createdAt: string,
    orderProductsId?: string | null,
    tourProductsId?: string | null,
    productShipmentId?: string | null,
    productRetoure_shipmentId?: string | null,
  } | null,
};

export type DeleteProductMutationVariables = {
  input: DeleteProductInput,
  condition?: ModelProductConditionInput | null,
};

export type DeleteProductMutation = {
  deleteProduct?:  {
    __typename: "Product",
    id: string,
    position?: number | null,
    typename?: string | null,
    name: string,
    sku?: string | null,
    product_id?: string | null,
    variation_id?: string | null,
    quantity?: number | null,
    length?: number | null,
    width?: number | null,
    height?: number | null,
    area?: number | null,
    weight?: string | null,
    categories?: Array< string | null > | null,
    variations?: Array< string | null > | null,
    options?: Array< string | null > | null,
    extraOptions?:  Array< {
      __typename: "Option",
      name?: string | null,
      value: string,
      imagesp?: string | null,
      price?: number | null,
    } | null > | null,
    tasks?: Array< string | null > | null,
    currentTask?: string | null,
    contractor_currentTask?: string | null,
    productContractorId?: string | null,
    contractor?:  {
      __typename: "Contractor",
      id: string,
      number: string,
      name: string,
      billing_rate?: number | null,
      billing_max?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: ProductStatus | null,
    contractor_status: string,
    shipping_label_printed?: boolean | null,
    orderNumber?: string | null,
    order?:  {
      __typename: "Order",
      id: string,
      typename?: string | null,
      orderNumber: string,
      date_created: string,
      date_paid: string,
      total?: number | null,
      cart_tax?: number | null,
      total_tax?: number | null,
      discount_total?: number | null,
      discount_tax?: number | null,
      shipping_total?: number | null,
      shipping_tax?: number | null,
      fees?:  Array< {
        __typename: "Fee",
        name?: string | null,
        amount?: number | null,
        total?: number | null,
        total_tax?: number | null,
      } | null > | null,
      weight?: number | null,
      customer:  {
        __typename: "Customer",
        first_name: string,
        last_name: string,
        street?: string | null,
        house_number?: string | null,
        zip?: string | null,
        city?: string | null,
        country?: string | null,
        email?: string | null,
        phone?: string | null,
        company?: string | null,
        add?: string | null,
      },
      shipping_method?: string | null,
      delivery_date: string,
      status?: OrderStatus | null,
      state?: OrderState | null,
      source: OrderSource,
      in_progressAt?: string | null,
      finishedAt?: string | null,
      updatedAt?: string | null,
      retoure_shipping_costs?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          position?: number | null,
          typename?: string | null,
          name: string,
          sku?: string | null,
          product_id?: string | null,
          variation_id?: string | null,
          quantity?: number | null,
          length?: number | null,
          width?: number | null,
          height?: number | null,
          area?: number | null,
          weight?: string | null,
          categories?: Array< string | null > | null,
          variations?: Array< string | null > | null,
          options?: Array< string | null > | null,
          tasks?: Array< string | null > | null,
          currentTask?: string | null,
          contractor_currentTask?: string | null,
          productContractorId?: string | null,
          status?: ProductStatus | null,
          contractor_status: string,
          shipping_label_printed?: boolean | null,
          orderNumber?: string | null,
          productOrderId: string,
          backoffice_note?: string | null,
          total_before_discount?: number | null,
          total_net_price?: number | null,
          total_tax?: number | null,
          nipsild_fixed_costs?: number | null,
          delivery_time?: string | null,
          delivery_date: string,
          declinedTourDates?: Array< string | null > | null,
          billed_amount?: number | null,
          in_progressAt?: string | null,
          ready_to_packAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          billedAt?: string | null,
          recievedReturnAt?: string | null,
          reklaOrderNumber?: string | null,
          retoure_type?: RetoureType | null,
          retoure_reason?: string | null,
          retoure_manufacturing_fee?: number | null,
          retoure_refund_amount?: number | null,
          retoure_refund_contractors_share?: number | null,
          createdAt: string,
          orderProductsId?: string | null,
          tourProductsId?: string | null,
          productShipmentId?: string | null,
          productRetoure_shipmentId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
    } | null,
    productOrderId: string,
    backoffice_note?: string | null,
    total_before_discount?: number | null,
    total_net_price?: number | null,
    total_tax?: number | null,
    nipsild_fixed_costs?: number | null,
    delivery_time?: string | null,
    delivery_date: string,
    shipment?:  {
      __typename: "Shipment",
      id: string,
      typename?: string | null,
      length: number,
      width: number,
      height: number,
      gurtMass: number,
      weight: number,
      serviceProvider: string,
      costs: number,
      calculatedCosts?: number | null,
      shippedAt?: string | null,
      edited?: string | null,
      parcelNumber?: string | null,
      trackID?: string | null,
      suggest_splitting?: boolean | null,
      overrule_splitting_user?: string | null,
      labelData?:  {
        __typename: "LabelData",
        base64?: string | null,
        format?: LabelFormat | null,
      } | null,
      updatedAt?: string | null,
      createdAt: string,
    } | null,
    tour?:  {
      __typename: "Tour",
      id: string,
      typename?: string | null,
      date: string,
      products?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          position?: number | null,
          typename?: string | null,
          name: string,
          sku?: string | null,
          product_id?: string | null,
          variation_id?: string | null,
          quantity?: number | null,
          length?: number | null,
          width?: number | null,
          height?: number | null,
          area?: number | null,
          weight?: string | null,
          categories?: Array< string | null > | null,
          variations?: Array< string | null > | null,
          options?: Array< string | null > | null,
          tasks?: Array< string | null > | null,
          currentTask?: string | null,
          contractor_currentTask?: string | null,
          productContractorId?: string | null,
          status?: ProductStatus | null,
          contractor_status: string,
          shipping_label_printed?: boolean | null,
          orderNumber?: string | null,
          productOrderId: string,
          backoffice_note?: string | null,
          total_before_discount?: number | null,
          total_net_price?: number | null,
          total_tax?: number | null,
          nipsild_fixed_costs?: number | null,
          delivery_time?: string | null,
          delivery_date: string,
          declinedTourDates?: Array< string | null > | null,
          billed_amount?: number | null,
          in_progressAt?: string | null,
          ready_to_packAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          billedAt?: string | null,
          recievedReturnAt?: string | null,
          reklaOrderNumber?: string | null,
          retoure_type?: RetoureType | null,
          retoure_reason?: string | null,
          retoure_manufacturing_fee?: number | null,
          retoure_refund_amount?: number | null,
          retoure_refund_contractors_share?: number | null,
          createdAt: string,
          orderProductsId?: string | null,
          tourProductsId?: string | null,
          productShipmentId?: string | null,
          productRetoure_shipmentId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      deliveryNotificationSentTo?: Array< string | null > | null,
      info?: string | null,
      driver?: string | null,
      calculated_shipping_costs?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    declinedTourDates?: Array< string | null > | null,
    billed_amount?: number | null,
    in_progressAt?: string | null,
    ready_to_packAt?: string | null,
    finishedAt?: string | null,
    updatedAt?: string | null,
    billedAt?: string | null,
    recievedReturnAt?: string | null,
    reklaOrderNumber?: string | null,
    retoure_type?: RetoureType | null,
    retoure_reason?: string | null,
    retoure_manufacturing_fee?: number | null,
    retoure_refund_amount?: number | null,
    retoure_refund_contractors_share?: number | null,
    retoure_shipment?:  {
      __typename: "Shipment",
      id: string,
      typename?: string | null,
      length: number,
      width: number,
      height: number,
      gurtMass: number,
      weight: number,
      serviceProvider: string,
      costs: number,
      calculatedCosts?: number | null,
      shippedAt?: string | null,
      edited?: string | null,
      parcelNumber?: string | null,
      trackID?: string | null,
      suggest_splitting?: boolean | null,
      overrule_splitting_user?: string | null,
      labelData?:  {
        __typename: "LabelData",
        base64?: string | null,
        format?: LabelFormat | null,
      } | null,
      updatedAt?: string | null,
      createdAt: string,
    } | null,
    createdAt: string,
    orderProductsId?: string | null,
    tourProductsId?: string | null,
    productShipmentId?: string | null,
    productRetoure_shipmentId?: string | null,
  } | null,
};

export type CreateShipmentMutationVariables = {
  input: CreateShipmentInput,
  condition?: ModelShipmentConditionInput | null,
};

export type CreateShipmentMutation = {
  createShipment?:  {
    __typename: "Shipment",
    id: string,
    typename?: string | null,
    length: number,
    width: number,
    height: number,
    gurtMass: number,
    weight: number,
    serviceProvider: string,
    costs: number,
    calculatedCosts?: number | null,
    shippedAt?: string | null,
    edited?: string | null,
    parcelNumber?: string | null,
    trackID?: string | null,
    suggest_splitting?: boolean | null,
    overrule_splitting_user?: string | null,
    labelData?:  {
      __typename: "LabelData",
      base64?: string | null,
      format?: LabelFormat | null,
    } | null,
    updatedAt?: string | null,
    createdAt: string,
  } | null,
};

export type UpdateShipmentMutationVariables = {
  input: UpdateShipmentInput,
  condition?: ModelShipmentConditionInput | null,
};

export type UpdateShipmentMutation = {
  updateShipment?:  {
    __typename: "Shipment",
    id: string,
    typename?: string | null,
    length: number,
    width: number,
    height: number,
    gurtMass: number,
    weight: number,
    serviceProvider: string,
    costs: number,
    calculatedCosts?: number | null,
    shippedAt?: string | null,
    edited?: string | null,
    parcelNumber?: string | null,
    trackID?: string | null,
    suggest_splitting?: boolean | null,
    overrule_splitting_user?: string | null,
    labelData?:  {
      __typename: "LabelData",
      base64?: string | null,
      format?: LabelFormat | null,
    } | null,
    updatedAt?: string | null,
    createdAt: string,
  } | null,
};

export type DeleteShipmentMutationVariables = {
  input: DeleteShipmentInput,
  condition?: ModelShipmentConditionInput | null,
};

export type DeleteShipmentMutation = {
  deleteShipment?:  {
    __typename: "Shipment",
    id: string,
    typename?: string | null,
    length: number,
    width: number,
    height: number,
    gurtMass: number,
    weight: number,
    serviceProvider: string,
    costs: number,
    calculatedCosts?: number | null,
    shippedAt?: string | null,
    edited?: string | null,
    parcelNumber?: string | null,
    trackID?: string | null,
    suggest_splitting?: boolean | null,
    overrule_splitting_user?: string | null,
    labelData?:  {
      __typename: "LabelData",
      base64?: string | null,
      format?: LabelFormat | null,
    } | null,
    updatedAt?: string | null,
    createdAt: string,
  } | null,
};

export type CreateTourMutationVariables = {
  input: CreateTourInput,
  condition?: ModelTourConditionInput | null,
};

export type CreateTourMutation = {
  createTour?:  {
    __typename: "Tour",
    id: string,
    typename?: string | null,
    date: string,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        position?: number | null,
        typename?: string | null,
        name: string,
        sku?: string | null,
        product_id?: string | null,
        variation_id?: string | null,
        quantity?: number | null,
        length?: number | null,
        width?: number | null,
        height?: number | null,
        area?: number | null,
        weight?: string | null,
        categories?: Array< string | null > | null,
        variations?: Array< string | null > | null,
        options?: Array< string | null > | null,
        extraOptions?:  Array< {
          __typename: "Option",
          name?: string | null,
          value: string,
          imagesp?: string | null,
          price?: number | null,
        } | null > | null,
        tasks?: Array< string | null > | null,
        currentTask?: string | null,
        contractor_currentTask?: string | null,
        productContractorId?: string | null,
        contractor?:  {
          __typename: "Contractor",
          id: string,
          number: string,
          name: string,
          billing_rate?: number | null,
          billing_max?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        status?: ProductStatus | null,
        contractor_status: string,
        shipping_label_printed?: boolean | null,
        orderNumber?: string | null,
        order?:  {
          __typename: "Order",
          id: string,
          typename?: string | null,
          orderNumber: string,
          date_created: string,
          date_paid: string,
          total?: number | null,
          cart_tax?: number | null,
          total_tax?: number | null,
          discount_total?: number | null,
          discount_tax?: number | null,
          shipping_total?: number | null,
          shipping_tax?: number | null,
          weight?: number | null,
          shipping_method?: string | null,
          delivery_date: string,
          status?: OrderStatus | null,
          state?: OrderState | null,
          source: OrderSource,
          in_progressAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          retoure_shipping_costs?: number | null,
          createdAt: string,
        } | null,
        productOrderId: string,
        backoffice_note?: string | null,
        total_before_discount?: number | null,
        total_net_price?: number | null,
        total_tax?: number | null,
        nipsild_fixed_costs?: number | null,
        delivery_time?: string | null,
        delivery_date: string,
        shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        tour?:  {
          __typename: "Tour",
          id: string,
          typename?: string | null,
          date: string,
          deliveryNotificationSentTo?: Array< string | null > | null,
          info?: string | null,
          driver?: string | null,
          calculated_shipping_costs?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        declinedTourDates?: Array< string | null > | null,
        billed_amount?: number | null,
        in_progressAt?: string | null,
        ready_to_packAt?: string | null,
        finishedAt?: string | null,
        updatedAt?: string | null,
        billedAt?: string | null,
        recievedReturnAt?: string | null,
        reklaOrderNumber?: string | null,
        retoure_type?: RetoureType | null,
        retoure_reason?: string | null,
        retoure_manufacturing_fee?: number | null,
        retoure_refund_amount?: number | null,
        retoure_refund_contractors_share?: number | null,
        retoure_shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        createdAt: string,
        orderProductsId?: string | null,
        tourProductsId?: string | null,
        productShipmentId?: string | null,
        productRetoure_shipmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deliveryNotificationSentTo?: Array< string | null > | null,
    info?: string | null,
    driver?: string | null,
    calculated_shipping_costs?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTourMutationVariables = {
  input: UpdateTourInput,
  condition?: ModelTourConditionInput | null,
};

export type UpdateTourMutation = {
  updateTour?:  {
    __typename: "Tour",
    id: string,
    typename?: string | null,
    date: string,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        position?: number | null,
        typename?: string | null,
        name: string,
        sku?: string | null,
        product_id?: string | null,
        variation_id?: string | null,
        quantity?: number | null,
        length?: number | null,
        width?: number | null,
        height?: number | null,
        area?: number | null,
        weight?: string | null,
        categories?: Array< string | null > | null,
        variations?: Array< string | null > | null,
        options?: Array< string | null > | null,
        extraOptions?:  Array< {
          __typename: "Option",
          name?: string | null,
          value: string,
          imagesp?: string | null,
          price?: number | null,
        } | null > | null,
        tasks?: Array< string | null > | null,
        currentTask?: string | null,
        contractor_currentTask?: string | null,
        productContractorId?: string | null,
        contractor?:  {
          __typename: "Contractor",
          id: string,
          number: string,
          name: string,
          billing_rate?: number | null,
          billing_max?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        status?: ProductStatus | null,
        contractor_status: string,
        shipping_label_printed?: boolean | null,
        orderNumber?: string | null,
        order?:  {
          __typename: "Order",
          id: string,
          typename?: string | null,
          orderNumber: string,
          date_created: string,
          date_paid: string,
          total?: number | null,
          cart_tax?: number | null,
          total_tax?: number | null,
          discount_total?: number | null,
          discount_tax?: number | null,
          shipping_total?: number | null,
          shipping_tax?: number | null,
          weight?: number | null,
          shipping_method?: string | null,
          delivery_date: string,
          status?: OrderStatus | null,
          state?: OrderState | null,
          source: OrderSource,
          in_progressAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          retoure_shipping_costs?: number | null,
          createdAt: string,
        } | null,
        productOrderId: string,
        backoffice_note?: string | null,
        total_before_discount?: number | null,
        total_net_price?: number | null,
        total_tax?: number | null,
        nipsild_fixed_costs?: number | null,
        delivery_time?: string | null,
        delivery_date: string,
        shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        tour?:  {
          __typename: "Tour",
          id: string,
          typename?: string | null,
          date: string,
          deliveryNotificationSentTo?: Array< string | null > | null,
          info?: string | null,
          driver?: string | null,
          calculated_shipping_costs?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        declinedTourDates?: Array< string | null > | null,
        billed_amount?: number | null,
        in_progressAt?: string | null,
        ready_to_packAt?: string | null,
        finishedAt?: string | null,
        updatedAt?: string | null,
        billedAt?: string | null,
        recievedReturnAt?: string | null,
        reklaOrderNumber?: string | null,
        retoure_type?: RetoureType | null,
        retoure_reason?: string | null,
        retoure_manufacturing_fee?: number | null,
        retoure_refund_amount?: number | null,
        retoure_refund_contractors_share?: number | null,
        retoure_shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        createdAt: string,
        orderProductsId?: string | null,
        tourProductsId?: string | null,
        productShipmentId?: string | null,
        productRetoure_shipmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deliveryNotificationSentTo?: Array< string | null > | null,
    info?: string | null,
    driver?: string | null,
    calculated_shipping_costs?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTourMutationVariables = {
  input: DeleteTourInput,
  condition?: ModelTourConditionInput | null,
};

export type DeleteTourMutation = {
  deleteTour?:  {
    __typename: "Tour",
    id: string,
    typename?: string | null,
    date: string,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        position?: number | null,
        typename?: string | null,
        name: string,
        sku?: string | null,
        product_id?: string | null,
        variation_id?: string | null,
        quantity?: number | null,
        length?: number | null,
        width?: number | null,
        height?: number | null,
        area?: number | null,
        weight?: string | null,
        categories?: Array< string | null > | null,
        variations?: Array< string | null > | null,
        options?: Array< string | null > | null,
        extraOptions?:  Array< {
          __typename: "Option",
          name?: string | null,
          value: string,
          imagesp?: string | null,
          price?: number | null,
        } | null > | null,
        tasks?: Array< string | null > | null,
        currentTask?: string | null,
        contractor_currentTask?: string | null,
        productContractorId?: string | null,
        contractor?:  {
          __typename: "Contractor",
          id: string,
          number: string,
          name: string,
          billing_rate?: number | null,
          billing_max?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        status?: ProductStatus | null,
        contractor_status: string,
        shipping_label_printed?: boolean | null,
        orderNumber?: string | null,
        order?:  {
          __typename: "Order",
          id: string,
          typename?: string | null,
          orderNumber: string,
          date_created: string,
          date_paid: string,
          total?: number | null,
          cart_tax?: number | null,
          total_tax?: number | null,
          discount_total?: number | null,
          discount_tax?: number | null,
          shipping_total?: number | null,
          shipping_tax?: number | null,
          weight?: number | null,
          shipping_method?: string | null,
          delivery_date: string,
          status?: OrderStatus | null,
          state?: OrderState | null,
          source: OrderSource,
          in_progressAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          retoure_shipping_costs?: number | null,
          createdAt: string,
        } | null,
        productOrderId: string,
        backoffice_note?: string | null,
        total_before_discount?: number | null,
        total_net_price?: number | null,
        total_tax?: number | null,
        nipsild_fixed_costs?: number | null,
        delivery_time?: string | null,
        delivery_date: string,
        shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        tour?:  {
          __typename: "Tour",
          id: string,
          typename?: string | null,
          date: string,
          deliveryNotificationSentTo?: Array< string | null > | null,
          info?: string | null,
          driver?: string | null,
          calculated_shipping_costs?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        declinedTourDates?: Array< string | null > | null,
        billed_amount?: number | null,
        in_progressAt?: string | null,
        ready_to_packAt?: string | null,
        finishedAt?: string | null,
        updatedAt?: string | null,
        billedAt?: string | null,
        recievedReturnAt?: string | null,
        reklaOrderNumber?: string | null,
        retoure_type?: RetoureType | null,
        retoure_reason?: string | null,
        retoure_manufacturing_fee?: number | null,
        retoure_refund_amount?: number | null,
        retoure_refund_contractors_share?: number | null,
        retoure_shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        createdAt: string,
        orderProductsId?: string | null,
        tourProductsId?: string | null,
        productShipmentId?: string | null,
        productRetoure_shipmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deliveryNotificationSentTo?: Array< string | null > | null,
    info?: string | null,
    driver?: string | null,
    calculated_shipping_costs?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateContractorMutationVariables = {
  input: CreateContractorInput,
  condition?: ModelContractorConditionInput | null,
};

export type CreateContractorMutation = {
  createContractor?:  {
    __typename: "Contractor",
    id: string,
    number: string,
    name: string,
    billing_rate?: number | null,
    billing_max?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateContractorMutationVariables = {
  input: UpdateContractorInput,
  condition?: ModelContractorConditionInput | null,
};

export type UpdateContractorMutation = {
  updateContractor?:  {
    __typename: "Contractor",
    id: string,
    number: string,
    name: string,
    billing_rate?: number | null,
    billing_max?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteContractorMutationVariables = {
  input: DeleteContractorInput,
  condition?: ModelContractorConditionInput | null,
};

export type DeleteContractorMutation = {
  deleteContractor?:  {
    __typename: "Contractor",
    id: string,
    number: string,
    name: string,
    billing_rate?: number | null,
    billing_max?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetOrderQueryVariables = {
  id: string,
};

export type GetOrderQuery = {
  getOrder?:  {
    __typename: "Order",
    id: string,
    typename?: string | null,
    orderNumber: string,
    date_created: string,
    date_paid: string,
    total?: number | null,
    cart_tax?: number | null,
    total_tax?: number | null,
    discount_total?: number | null,
    discount_tax?: number | null,
    shipping_total?: number | null,
    shipping_tax?: number | null,
    fees?:  Array< {
      __typename: "Fee",
      name?: string | null,
      amount?: number | null,
      total?: number | null,
      total_tax?: number | null,
    } | null > | null,
    weight?: number | null,
    customer:  {
      __typename: "Customer",
      first_name: string,
      last_name: string,
      street?: string | null,
      house_number?: string | null,
      zip?: string | null,
      city?: string | null,
      country?: string | null,
      email?: string | null,
      phone?: string | null,
      company?: string | null,
      add?: string | null,
    },
    shipping_method?: string | null,
    delivery_date: string,
    status?: OrderStatus | null,
    state?: OrderState | null,
    source: OrderSource,
    in_progressAt?: string | null,
    finishedAt?: string | null,
    updatedAt?: string | null,
    retoure_shipping_costs?: number | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        position?: number | null,
        typename?: string | null,
        name: string,
        sku?: string | null,
        product_id?: string | null,
        variation_id?: string | null,
        quantity?: number | null,
        length?: number | null,
        width?: number | null,
        height?: number | null,
        area?: number | null,
        weight?: string | null,
        categories?: Array< string | null > | null,
        variations?: Array< string | null > | null,
        options?: Array< string | null > | null,
        extraOptions?:  Array< {
          __typename: "Option",
          name?: string | null,
          value: string,
          imagesp?: string | null,
          price?: number | null,
        } | null > | null,
        tasks?: Array< string | null > | null,
        currentTask?: string | null,
        contractor_currentTask?: string | null,
        productContractorId?: string | null,
        contractor?:  {
          __typename: "Contractor",
          id: string,
          number: string,
          name: string,
          billing_rate?: number | null,
          billing_max?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        status?: ProductStatus | null,
        contractor_status: string,
        shipping_label_printed?: boolean | null,
        orderNumber?: string | null,
        order?:  {
          __typename: "Order",
          id: string,
          typename?: string | null,
          orderNumber: string,
          date_created: string,
          date_paid: string,
          total?: number | null,
          cart_tax?: number | null,
          total_tax?: number | null,
          discount_total?: number | null,
          discount_tax?: number | null,
          shipping_total?: number | null,
          shipping_tax?: number | null,
          weight?: number | null,
          shipping_method?: string | null,
          delivery_date: string,
          status?: OrderStatus | null,
          state?: OrderState | null,
          source: OrderSource,
          in_progressAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          retoure_shipping_costs?: number | null,
          createdAt: string,
        } | null,
        productOrderId: string,
        backoffice_note?: string | null,
        total_before_discount?: number | null,
        total_net_price?: number | null,
        total_tax?: number | null,
        nipsild_fixed_costs?: number | null,
        delivery_time?: string | null,
        delivery_date: string,
        shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        tour?:  {
          __typename: "Tour",
          id: string,
          typename?: string | null,
          date: string,
          deliveryNotificationSentTo?: Array< string | null > | null,
          info?: string | null,
          driver?: string | null,
          calculated_shipping_costs?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        declinedTourDates?: Array< string | null > | null,
        billed_amount?: number | null,
        in_progressAt?: string | null,
        ready_to_packAt?: string | null,
        finishedAt?: string | null,
        updatedAt?: string | null,
        billedAt?: string | null,
        recievedReturnAt?: string | null,
        reklaOrderNumber?: string | null,
        retoure_type?: RetoureType | null,
        retoure_reason?: string | null,
        retoure_manufacturing_fee?: number | null,
        retoure_refund_amount?: number | null,
        retoure_refund_contractors_share?: number | null,
        retoure_shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        createdAt: string,
        orderProductsId?: string | null,
        tourProductsId?: string | null,
        productShipmentId?: string | null,
        productRetoure_shipmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
  } | null,
};

export type ListOrdersQueryVariables = {
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrdersQuery = {
  listOrders?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      typename?: string | null,
      orderNumber: string,
      date_created: string,
      date_paid: string,
      total?: number | null,
      cart_tax?: number | null,
      total_tax?: number | null,
      discount_total?: number | null,
      discount_tax?: number | null,
      shipping_total?: number | null,
      shipping_tax?: number | null,
      fees?:  Array< {
        __typename: "Fee",
        name?: string | null,
        amount?: number | null,
        total?: number | null,
        total_tax?: number | null,
      } | null > | null,
      weight?: number | null,
      customer:  {
        __typename: "Customer",
        first_name: string,
        last_name: string,
        street?: string | null,
        house_number?: string | null,
        zip?: string | null,
        city?: string | null,
        country?: string | null,
        email?: string | null,
        phone?: string | null,
        company?: string | null,
        add?: string | null,
      },
      shipping_method?: string | null,
      delivery_date: string,
      status?: OrderStatus | null,
      state?: OrderState | null,
      source: OrderSource,
      in_progressAt?: string | null,
      finishedAt?: string | null,
      updatedAt?: string | null,
      retoure_shipping_costs?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          position?: number | null,
          typename?: string | null,
          name: string,
          sku?: string | null,
          product_id?: string | null,
          variation_id?: string | null,
          quantity?: number | null,
          length?: number | null,
          width?: number | null,
          height?: number | null,
          area?: number | null,
          weight?: string | null,
          categories?: Array< string | null > | null,
          variations?: Array< string | null > | null,
          options?: Array< string | null > | null,
          tasks?: Array< string | null > | null,
          currentTask?: string | null,
          contractor_currentTask?: string | null,
          productContractorId?: string | null,
          status?: ProductStatus | null,
          contractor_status: string,
          shipping_label_printed?: boolean | null,
          orderNumber?: string | null,
          productOrderId: string,
          backoffice_note?: string | null,
          total_before_discount?: number | null,
          total_net_price?: number | null,
          total_tax?: number | null,
          nipsild_fixed_costs?: number | null,
          delivery_time?: string | null,
          delivery_date: string,
          declinedTourDates?: Array< string | null > | null,
          billed_amount?: number | null,
          in_progressAt?: string | null,
          ready_to_packAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          billedAt?: string | null,
          recievedReturnAt?: string | null,
          reklaOrderNumber?: string | null,
          retoure_type?: RetoureType | null,
          retoure_reason?: string | null,
          retoure_manufacturing_fee?: number | null,
          retoure_refund_amount?: number | null,
          retoure_refund_contractors_share?: number | null,
          createdAt: string,
          orderProductsId?: string | null,
          tourProductsId?: string | null,
          productShipmentId?: string | null,
          productRetoure_shipmentId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductQueryVariables = {
  id: string,
};

export type GetProductQuery = {
  getProduct?:  {
    __typename: "Product",
    id: string,
    position?: number | null,
    typename?: string | null,
    name: string,
    sku?: string | null,
    product_id?: string | null,
    variation_id?: string | null,
    quantity?: number | null,
    length?: number | null,
    width?: number | null,
    height?: number | null,
    area?: number | null,
    weight?: string | null,
    categories?: Array< string | null > | null,
    variations?: Array< string | null > | null,
    options?: Array< string | null > | null,
    extraOptions?:  Array< {
      __typename: "Option",
      name?: string | null,
      value: string,
      imagesp?: string | null,
      price?: number | null,
    } | null > | null,
    tasks?: Array< string | null > | null,
    currentTask?: string | null,
    contractor_currentTask?: string | null,
    productContractorId?: string | null,
    contractor?:  {
      __typename: "Contractor",
      id: string,
      number: string,
      name: string,
      billing_rate?: number | null,
      billing_max?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: ProductStatus | null,
    contractor_status: string,
    shipping_label_printed?: boolean | null,
    orderNumber?: string | null,
    order?:  {
      __typename: "Order",
      id: string,
      typename?: string | null,
      orderNumber: string,
      date_created: string,
      date_paid: string,
      total?: number | null,
      cart_tax?: number | null,
      total_tax?: number | null,
      discount_total?: number | null,
      discount_tax?: number | null,
      shipping_total?: number | null,
      shipping_tax?: number | null,
      fees?:  Array< {
        __typename: "Fee",
        name?: string | null,
        amount?: number | null,
        total?: number | null,
        total_tax?: number | null,
      } | null > | null,
      weight?: number | null,
      customer:  {
        __typename: "Customer",
        first_name: string,
        last_name: string,
        street?: string | null,
        house_number?: string | null,
        zip?: string | null,
        city?: string | null,
        country?: string | null,
        email?: string | null,
        phone?: string | null,
        company?: string | null,
        add?: string | null,
      },
      shipping_method?: string | null,
      delivery_date: string,
      status?: OrderStatus | null,
      state?: OrderState | null,
      source: OrderSource,
      in_progressAt?: string | null,
      finishedAt?: string | null,
      updatedAt?: string | null,
      retoure_shipping_costs?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          position?: number | null,
          typename?: string | null,
          name: string,
          sku?: string | null,
          product_id?: string | null,
          variation_id?: string | null,
          quantity?: number | null,
          length?: number | null,
          width?: number | null,
          height?: number | null,
          area?: number | null,
          weight?: string | null,
          categories?: Array< string | null > | null,
          variations?: Array< string | null > | null,
          options?: Array< string | null > | null,
          tasks?: Array< string | null > | null,
          currentTask?: string | null,
          contractor_currentTask?: string | null,
          productContractorId?: string | null,
          status?: ProductStatus | null,
          contractor_status: string,
          shipping_label_printed?: boolean | null,
          orderNumber?: string | null,
          productOrderId: string,
          backoffice_note?: string | null,
          total_before_discount?: number | null,
          total_net_price?: number | null,
          total_tax?: number | null,
          nipsild_fixed_costs?: number | null,
          delivery_time?: string | null,
          delivery_date: string,
          declinedTourDates?: Array< string | null > | null,
          billed_amount?: number | null,
          in_progressAt?: string | null,
          ready_to_packAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          billedAt?: string | null,
          recievedReturnAt?: string | null,
          reklaOrderNumber?: string | null,
          retoure_type?: RetoureType | null,
          retoure_reason?: string | null,
          retoure_manufacturing_fee?: number | null,
          retoure_refund_amount?: number | null,
          retoure_refund_contractors_share?: number | null,
          createdAt: string,
          orderProductsId?: string | null,
          tourProductsId?: string | null,
          productShipmentId?: string | null,
          productRetoure_shipmentId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
    } | null,
    productOrderId: string,
    backoffice_note?: string | null,
    total_before_discount?: number | null,
    total_net_price?: number | null,
    total_tax?: number | null,
    nipsild_fixed_costs?: number | null,
    delivery_time?: string | null,
    delivery_date: string,
    shipment?:  {
      __typename: "Shipment",
      id: string,
      typename?: string | null,
      length: number,
      width: number,
      height: number,
      gurtMass: number,
      weight: number,
      serviceProvider: string,
      costs: number,
      calculatedCosts?: number | null,
      shippedAt?: string | null,
      edited?: string | null,
      parcelNumber?: string | null,
      trackID?: string | null,
      suggest_splitting?: boolean | null,
      overrule_splitting_user?: string | null,
      labelData?:  {
        __typename: "LabelData",
        base64?: string | null,
        format?: LabelFormat | null,
      } | null,
      updatedAt?: string | null,
      createdAt: string,
    } | null,
    tour?:  {
      __typename: "Tour",
      id: string,
      typename?: string | null,
      date: string,
      products?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          position?: number | null,
          typename?: string | null,
          name: string,
          sku?: string | null,
          product_id?: string | null,
          variation_id?: string | null,
          quantity?: number | null,
          length?: number | null,
          width?: number | null,
          height?: number | null,
          area?: number | null,
          weight?: string | null,
          categories?: Array< string | null > | null,
          variations?: Array< string | null > | null,
          options?: Array< string | null > | null,
          tasks?: Array< string | null > | null,
          currentTask?: string | null,
          contractor_currentTask?: string | null,
          productContractorId?: string | null,
          status?: ProductStatus | null,
          contractor_status: string,
          shipping_label_printed?: boolean | null,
          orderNumber?: string | null,
          productOrderId: string,
          backoffice_note?: string | null,
          total_before_discount?: number | null,
          total_net_price?: number | null,
          total_tax?: number | null,
          nipsild_fixed_costs?: number | null,
          delivery_time?: string | null,
          delivery_date: string,
          declinedTourDates?: Array< string | null > | null,
          billed_amount?: number | null,
          in_progressAt?: string | null,
          ready_to_packAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          billedAt?: string | null,
          recievedReturnAt?: string | null,
          reklaOrderNumber?: string | null,
          retoure_type?: RetoureType | null,
          retoure_reason?: string | null,
          retoure_manufacturing_fee?: number | null,
          retoure_refund_amount?: number | null,
          retoure_refund_contractors_share?: number | null,
          createdAt: string,
          orderProductsId?: string | null,
          tourProductsId?: string | null,
          productShipmentId?: string | null,
          productRetoure_shipmentId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      deliveryNotificationSentTo?: Array< string | null > | null,
      info?: string | null,
      driver?: string | null,
      calculated_shipping_costs?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    declinedTourDates?: Array< string | null > | null,
    billed_amount?: number | null,
    in_progressAt?: string | null,
    ready_to_packAt?: string | null,
    finishedAt?: string | null,
    updatedAt?: string | null,
    billedAt?: string | null,
    recievedReturnAt?: string | null,
    reklaOrderNumber?: string | null,
    retoure_type?: RetoureType | null,
    retoure_reason?: string | null,
    retoure_manufacturing_fee?: number | null,
    retoure_refund_amount?: number | null,
    retoure_refund_contractors_share?: number | null,
    retoure_shipment?:  {
      __typename: "Shipment",
      id: string,
      typename?: string | null,
      length: number,
      width: number,
      height: number,
      gurtMass: number,
      weight: number,
      serviceProvider: string,
      costs: number,
      calculatedCosts?: number | null,
      shippedAt?: string | null,
      edited?: string | null,
      parcelNumber?: string | null,
      trackID?: string | null,
      suggest_splitting?: boolean | null,
      overrule_splitting_user?: string | null,
      labelData?:  {
        __typename: "LabelData",
        base64?: string | null,
        format?: LabelFormat | null,
      } | null,
      updatedAt?: string | null,
      createdAt: string,
    } | null,
    createdAt: string,
    orderProductsId?: string | null,
    tourProductsId?: string | null,
    productShipmentId?: string | null,
    productRetoure_shipmentId?: string | null,
  } | null,
};

export type ListProductsQueryVariables = {
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsQuery = {
  listProducts?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      position?: number | null,
      typename?: string | null,
      name: string,
      sku?: string | null,
      product_id?: string | null,
      variation_id?: string | null,
      quantity?: number | null,
      length?: number | null,
      width?: number | null,
      height?: number | null,
      area?: number | null,
      weight?: string | null,
      categories?: Array< string | null > | null,
      variations?: Array< string | null > | null,
      options?: Array< string | null > | null,
      extraOptions?:  Array< {
        __typename: "Option",
        name?: string | null,
        value: string,
        imagesp?: string | null,
        price?: number | null,
      } | null > | null,
      tasks?: Array< string | null > | null,
      currentTask?: string | null,
      contractor_currentTask?: string | null,
      productContractorId?: string | null,
      contractor?:  {
        __typename: "Contractor",
        id: string,
        number: string,
        name: string,
        billing_rate?: number | null,
        billing_max?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      status?: ProductStatus | null,
      contractor_status: string,
      shipping_label_printed?: boolean | null,
      orderNumber?: string | null,
      order?:  {
        __typename: "Order",
        id: string,
        typename?: string | null,
        orderNumber: string,
        date_created: string,
        date_paid: string,
        total?: number | null,
        cart_tax?: number | null,
        total_tax?: number | null,
        discount_total?: number | null,
        discount_tax?: number | null,
        shipping_total?: number | null,
        shipping_tax?: number | null,
        fees?:  Array< {
          __typename: "Fee",
          name?: string | null,
          amount?: number | null,
          total?: number | null,
          total_tax?: number | null,
        } | null > | null,
        weight?: number | null,
        customer:  {
          __typename: "Customer",
          first_name: string,
          last_name: string,
          street?: string | null,
          house_number?: string | null,
          zip?: string | null,
          city?: string | null,
          country?: string | null,
          email?: string | null,
          phone?: string | null,
          company?: string | null,
          add?: string | null,
        },
        shipping_method?: string | null,
        delivery_date: string,
        status?: OrderStatus | null,
        state?: OrderState | null,
        source: OrderSource,
        in_progressAt?: string | null,
        finishedAt?: string | null,
        updatedAt?: string | null,
        retoure_shipping_costs?: number | null,
        products?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
      } | null,
      productOrderId: string,
      backoffice_note?: string | null,
      total_before_discount?: number | null,
      total_net_price?: number | null,
      total_tax?: number | null,
      nipsild_fixed_costs?: number | null,
      delivery_time?: string | null,
      delivery_date: string,
      shipment?:  {
        __typename: "Shipment",
        id: string,
        typename?: string | null,
        length: number,
        width: number,
        height: number,
        gurtMass: number,
        weight: number,
        serviceProvider: string,
        costs: number,
        calculatedCosts?: number | null,
        shippedAt?: string | null,
        edited?: string | null,
        parcelNumber?: string | null,
        trackID?: string | null,
        suggest_splitting?: boolean | null,
        overrule_splitting_user?: string | null,
        labelData?:  {
          __typename: "LabelData",
          base64?: string | null,
          format?: LabelFormat | null,
        } | null,
        updatedAt?: string | null,
        createdAt: string,
      } | null,
      tour?:  {
        __typename: "Tour",
        id: string,
        typename?: string | null,
        date: string,
        products?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        deliveryNotificationSentTo?: Array< string | null > | null,
        info?: string | null,
        driver?: string | null,
        calculated_shipping_costs?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      declinedTourDates?: Array< string | null > | null,
      billed_amount?: number | null,
      in_progressAt?: string | null,
      ready_to_packAt?: string | null,
      finishedAt?: string | null,
      updatedAt?: string | null,
      billedAt?: string | null,
      recievedReturnAt?: string | null,
      reklaOrderNumber?: string | null,
      retoure_type?: RetoureType | null,
      retoure_reason?: string | null,
      retoure_manufacturing_fee?: number | null,
      retoure_refund_amount?: number | null,
      retoure_refund_contractors_share?: number | null,
      retoure_shipment?:  {
        __typename: "Shipment",
        id: string,
        typename?: string | null,
        length: number,
        width: number,
        height: number,
        gurtMass: number,
        weight: number,
        serviceProvider: string,
        costs: number,
        calculatedCosts?: number | null,
        shippedAt?: string | null,
        edited?: string | null,
        parcelNumber?: string | null,
        trackID?: string | null,
        suggest_splitting?: boolean | null,
        overrule_splitting_user?: string | null,
        labelData?:  {
          __typename: "LabelData",
          base64?: string | null,
          format?: LabelFormat | null,
        } | null,
        updatedAt?: string | null,
        createdAt: string,
      } | null,
      createdAt: string,
      orderProductsId?: string | null,
      tourProductsId?: string | null,
      productShipmentId?: string | null,
      productRetoure_shipmentId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetShipmentQueryVariables = {
  id: string,
};

export type GetShipmentQuery = {
  getShipment?:  {
    __typename: "Shipment",
    id: string,
    typename?: string | null,
    length: number,
    width: number,
    height: number,
    gurtMass: number,
    weight: number,
    serviceProvider: string,
    costs: number,
    calculatedCosts?: number | null,
    shippedAt?: string | null,
    edited?: string | null,
    parcelNumber?: string | null,
    trackID?: string | null,
    suggest_splitting?: boolean | null,
    overrule_splitting_user?: string | null,
    labelData?:  {
      __typename: "LabelData",
      base64?: string | null,
      format?: LabelFormat | null,
    } | null,
    updatedAt?: string | null,
    createdAt: string,
  } | null,
};

export type ListShipmentsQueryVariables = {
  filter?: ModelShipmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShipmentsQuery = {
  listShipments?:  {
    __typename: "ModelShipmentConnection",
    items:  Array< {
      __typename: "Shipment",
      id: string,
      typename?: string | null,
      length: number,
      width: number,
      height: number,
      gurtMass: number,
      weight: number,
      serviceProvider: string,
      costs: number,
      calculatedCosts?: number | null,
      shippedAt?: string | null,
      edited?: string | null,
      parcelNumber?: string | null,
      trackID?: string | null,
      suggest_splitting?: boolean | null,
      overrule_splitting_user?: string | null,
      labelData?:  {
        __typename: "LabelData",
        base64?: string | null,
        format?: LabelFormat | null,
      } | null,
      updatedAt?: string | null,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTourQueryVariables = {
  id: string,
};

export type GetTourQuery = {
  getTour?:  {
    __typename: "Tour",
    id: string,
    typename?: string | null,
    date: string,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        position?: number | null,
        typename?: string | null,
        name: string,
        sku?: string | null,
        product_id?: string | null,
        variation_id?: string | null,
        quantity?: number | null,
        length?: number | null,
        width?: number | null,
        height?: number | null,
        area?: number | null,
        weight?: string | null,
        categories?: Array< string | null > | null,
        variations?: Array< string | null > | null,
        options?: Array< string | null > | null,
        extraOptions?:  Array< {
          __typename: "Option",
          name?: string | null,
          value: string,
          imagesp?: string | null,
          price?: number | null,
        } | null > | null,
        tasks?: Array< string | null > | null,
        currentTask?: string | null,
        contractor_currentTask?: string | null,
        productContractorId?: string | null,
        contractor?:  {
          __typename: "Contractor",
          id: string,
          number: string,
          name: string,
          billing_rate?: number | null,
          billing_max?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        status?: ProductStatus | null,
        contractor_status: string,
        shipping_label_printed?: boolean | null,
        orderNumber?: string | null,
        order?:  {
          __typename: "Order",
          id: string,
          typename?: string | null,
          orderNumber: string,
          date_created: string,
          date_paid: string,
          total?: number | null,
          cart_tax?: number | null,
          total_tax?: number | null,
          discount_total?: number | null,
          discount_tax?: number | null,
          shipping_total?: number | null,
          shipping_tax?: number | null,
          weight?: number | null,
          shipping_method?: string | null,
          delivery_date: string,
          status?: OrderStatus | null,
          state?: OrderState | null,
          source: OrderSource,
          in_progressAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          retoure_shipping_costs?: number | null,
          createdAt: string,
        } | null,
        productOrderId: string,
        backoffice_note?: string | null,
        total_before_discount?: number | null,
        total_net_price?: number | null,
        total_tax?: number | null,
        nipsild_fixed_costs?: number | null,
        delivery_time?: string | null,
        delivery_date: string,
        shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        tour?:  {
          __typename: "Tour",
          id: string,
          typename?: string | null,
          date: string,
          deliveryNotificationSentTo?: Array< string | null > | null,
          info?: string | null,
          driver?: string | null,
          calculated_shipping_costs?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        declinedTourDates?: Array< string | null > | null,
        billed_amount?: number | null,
        in_progressAt?: string | null,
        ready_to_packAt?: string | null,
        finishedAt?: string | null,
        updatedAt?: string | null,
        billedAt?: string | null,
        recievedReturnAt?: string | null,
        reklaOrderNumber?: string | null,
        retoure_type?: RetoureType | null,
        retoure_reason?: string | null,
        retoure_manufacturing_fee?: number | null,
        retoure_refund_amount?: number | null,
        retoure_refund_contractors_share?: number | null,
        retoure_shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        createdAt: string,
        orderProductsId?: string | null,
        tourProductsId?: string | null,
        productShipmentId?: string | null,
        productRetoure_shipmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deliveryNotificationSentTo?: Array< string | null > | null,
    info?: string | null,
    driver?: string | null,
    calculated_shipping_costs?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListToursQueryVariables = {
  filter?: ModelTourFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListToursQuery = {
  listTours?:  {
    __typename: "ModelTourConnection",
    items:  Array< {
      __typename: "Tour",
      id: string,
      typename?: string | null,
      date: string,
      products?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          position?: number | null,
          typename?: string | null,
          name: string,
          sku?: string | null,
          product_id?: string | null,
          variation_id?: string | null,
          quantity?: number | null,
          length?: number | null,
          width?: number | null,
          height?: number | null,
          area?: number | null,
          weight?: string | null,
          categories?: Array< string | null > | null,
          variations?: Array< string | null > | null,
          options?: Array< string | null > | null,
          tasks?: Array< string | null > | null,
          currentTask?: string | null,
          contractor_currentTask?: string | null,
          productContractorId?: string | null,
          status?: ProductStatus | null,
          contractor_status: string,
          shipping_label_printed?: boolean | null,
          orderNumber?: string | null,
          productOrderId: string,
          backoffice_note?: string | null,
          total_before_discount?: number | null,
          total_net_price?: number | null,
          total_tax?: number | null,
          nipsild_fixed_costs?: number | null,
          delivery_time?: string | null,
          delivery_date: string,
          declinedTourDates?: Array< string | null > | null,
          billed_amount?: number | null,
          in_progressAt?: string | null,
          ready_to_packAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          billedAt?: string | null,
          recievedReturnAt?: string | null,
          reklaOrderNumber?: string | null,
          retoure_type?: RetoureType | null,
          retoure_reason?: string | null,
          retoure_manufacturing_fee?: number | null,
          retoure_refund_amount?: number | null,
          retoure_refund_contractors_share?: number | null,
          createdAt: string,
          orderProductsId?: string | null,
          tourProductsId?: string | null,
          productShipmentId?: string | null,
          productRetoure_shipmentId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      deliveryNotificationSentTo?: Array< string | null > | null,
      info?: string | null,
      driver?: string | null,
      calculated_shipping_costs?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetContractorQueryVariables = {
  id: string,
};

export type GetContractorQuery = {
  getContractor?:  {
    __typename: "Contractor",
    id: string,
    number: string,
    name: string,
    billing_rate?: number | null,
    billing_max?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListContractorsQueryVariables = {
  filter?: ModelContractorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContractorsQuery = {
  listContractors?:  {
    __typename: "ModelContractorConnection",
    items:  Array< {
      __typename: "Contractor",
      id: string,
      number: string,
      name: string,
      billing_rate?: number | null,
      billing_max?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrdersByUpdateDateQueryVariables = {
  typename: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrdersByUpdateDateQuery = {
  ordersByUpdateDate?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      typename?: string | null,
      orderNumber: string,
      date_created: string,
      date_paid: string,
      total?: number | null,
      cart_tax?: number | null,
      total_tax?: number | null,
      discount_total?: number | null,
      discount_tax?: number | null,
      shipping_total?: number | null,
      shipping_tax?: number | null,
      fees?:  Array< {
        __typename: "Fee",
        name?: string | null,
        amount?: number | null,
        total?: number | null,
        total_tax?: number | null,
      } | null > | null,
      weight?: number | null,
      customer:  {
        __typename: "Customer",
        first_name: string,
        last_name: string,
        street?: string | null,
        house_number?: string | null,
        zip?: string | null,
        city?: string | null,
        country?: string | null,
        email?: string | null,
        phone?: string | null,
        company?: string | null,
        add?: string | null,
      },
      shipping_method?: string | null,
      delivery_date: string,
      status?: OrderStatus | null,
      state?: OrderState | null,
      source: OrderSource,
      in_progressAt?: string | null,
      finishedAt?: string | null,
      updatedAt?: string | null,
      retoure_shipping_costs?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          position?: number | null,
          typename?: string | null,
          name: string,
          sku?: string | null,
          product_id?: string | null,
          variation_id?: string | null,
          quantity?: number | null,
          length?: number | null,
          width?: number | null,
          height?: number | null,
          area?: number | null,
          weight?: string | null,
          categories?: Array< string | null > | null,
          variations?: Array< string | null > | null,
          options?: Array< string | null > | null,
          tasks?: Array< string | null > | null,
          currentTask?: string | null,
          contractor_currentTask?: string | null,
          productContractorId?: string | null,
          status?: ProductStatus | null,
          contractor_status: string,
          shipping_label_printed?: boolean | null,
          orderNumber?: string | null,
          productOrderId: string,
          backoffice_note?: string | null,
          total_before_discount?: number | null,
          total_net_price?: number | null,
          total_tax?: number | null,
          nipsild_fixed_costs?: number | null,
          delivery_time?: string | null,
          delivery_date: string,
          declinedTourDates?: Array< string | null > | null,
          billed_amount?: number | null,
          in_progressAt?: string | null,
          ready_to_packAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          billedAt?: string | null,
          recievedReturnAt?: string | null,
          reklaOrderNumber?: string | null,
          retoure_type?: RetoureType | null,
          retoure_reason?: string | null,
          retoure_manufacturing_fee?: number | null,
          retoure_refund_amount?: number | null,
          retoure_refund_contractors_share?: number | null,
          createdAt: string,
          orderProductsId?: string | null,
          tourProductsId?: string | null,
          productShipmentId?: string | null,
          productRetoure_shipmentId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrdersByShippingMethodQueryVariables = {
  shipping_method: string,
  state?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrdersByShippingMethodQuery = {
  ordersByShippingMethod?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      typename?: string | null,
      orderNumber: string,
      date_created: string,
      date_paid: string,
      total?: number | null,
      cart_tax?: number | null,
      total_tax?: number | null,
      discount_total?: number | null,
      discount_tax?: number | null,
      shipping_total?: number | null,
      shipping_tax?: number | null,
      fees?:  Array< {
        __typename: "Fee",
        name?: string | null,
        amount?: number | null,
        total?: number | null,
        total_tax?: number | null,
      } | null > | null,
      weight?: number | null,
      customer:  {
        __typename: "Customer",
        first_name: string,
        last_name: string,
        street?: string | null,
        house_number?: string | null,
        zip?: string | null,
        city?: string | null,
        country?: string | null,
        email?: string | null,
        phone?: string | null,
        company?: string | null,
        add?: string | null,
      },
      shipping_method?: string | null,
      delivery_date: string,
      status?: OrderStatus | null,
      state?: OrderState | null,
      source: OrderSource,
      in_progressAt?: string | null,
      finishedAt?: string | null,
      updatedAt?: string | null,
      retoure_shipping_costs?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          position?: number | null,
          typename?: string | null,
          name: string,
          sku?: string | null,
          product_id?: string | null,
          variation_id?: string | null,
          quantity?: number | null,
          length?: number | null,
          width?: number | null,
          height?: number | null,
          area?: number | null,
          weight?: string | null,
          categories?: Array< string | null > | null,
          variations?: Array< string | null > | null,
          options?: Array< string | null > | null,
          tasks?: Array< string | null > | null,
          currentTask?: string | null,
          contractor_currentTask?: string | null,
          productContractorId?: string | null,
          status?: ProductStatus | null,
          contractor_status: string,
          shipping_label_printed?: boolean | null,
          orderNumber?: string | null,
          productOrderId: string,
          backoffice_note?: string | null,
          total_before_discount?: number | null,
          total_net_price?: number | null,
          total_tax?: number | null,
          nipsild_fixed_costs?: number | null,
          delivery_time?: string | null,
          delivery_date: string,
          declinedTourDates?: Array< string | null > | null,
          billed_amount?: number | null,
          in_progressAt?: string | null,
          ready_to_packAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          billedAt?: string | null,
          recievedReturnAt?: string | null,
          reklaOrderNumber?: string | null,
          retoure_type?: RetoureType | null,
          retoure_reason?: string | null,
          retoure_manufacturing_fee?: number | null,
          retoure_refund_amount?: number | null,
          retoure_refund_contractors_share?: number | null,
          createdAt: string,
          orderProductsId?: string | null,
          tourProductsId?: string | null,
          productShipmentId?: string | null,
          productRetoure_shipmentId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrdersByFinishingStateQueryVariables = {
  state: OrderState,
  delivery_date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrdersByFinishingStateQuery = {
  ordersByFinishingState?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      typename?: string | null,
      orderNumber: string,
      date_created: string,
      date_paid: string,
      total?: number | null,
      cart_tax?: number | null,
      total_tax?: number | null,
      discount_total?: number | null,
      discount_tax?: number | null,
      shipping_total?: number | null,
      shipping_tax?: number | null,
      fees?:  Array< {
        __typename: "Fee",
        name?: string | null,
        amount?: number | null,
        total?: number | null,
        total_tax?: number | null,
      } | null > | null,
      weight?: number | null,
      customer:  {
        __typename: "Customer",
        first_name: string,
        last_name: string,
        street?: string | null,
        house_number?: string | null,
        zip?: string | null,
        city?: string | null,
        country?: string | null,
        email?: string | null,
        phone?: string | null,
        company?: string | null,
        add?: string | null,
      },
      shipping_method?: string | null,
      delivery_date: string,
      status?: OrderStatus | null,
      state?: OrderState | null,
      source: OrderSource,
      in_progressAt?: string | null,
      finishedAt?: string | null,
      updatedAt?: string | null,
      retoure_shipping_costs?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          position?: number | null,
          typename?: string | null,
          name: string,
          sku?: string | null,
          product_id?: string | null,
          variation_id?: string | null,
          quantity?: number | null,
          length?: number | null,
          width?: number | null,
          height?: number | null,
          area?: number | null,
          weight?: string | null,
          categories?: Array< string | null > | null,
          variations?: Array< string | null > | null,
          options?: Array< string | null > | null,
          tasks?: Array< string | null > | null,
          currentTask?: string | null,
          contractor_currentTask?: string | null,
          productContractorId?: string | null,
          status?: ProductStatus | null,
          contractor_status: string,
          shipping_label_printed?: boolean | null,
          orderNumber?: string | null,
          productOrderId: string,
          backoffice_note?: string | null,
          total_before_discount?: number | null,
          total_net_price?: number | null,
          total_tax?: number | null,
          nipsild_fixed_costs?: number | null,
          delivery_time?: string | null,
          delivery_date: string,
          declinedTourDates?: Array< string | null > | null,
          billed_amount?: number | null,
          in_progressAt?: string | null,
          ready_to_packAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          billedAt?: string | null,
          recievedReturnAt?: string | null,
          reklaOrderNumber?: string | null,
          retoure_type?: RetoureType | null,
          retoure_reason?: string | null,
          retoure_manufacturing_fee?: number | null,
          retoure_refund_amount?: number | null,
          retoure_refund_contractors_share?: number | null,
          createdAt: string,
          orderProductsId?: string | null,
          tourProductsId?: string | null,
          productShipmentId?: string | null,
          productRetoure_shipmentId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrdersByFinishingStateByUpdateDateQueryVariables = {
  state: OrderState,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrdersByFinishingStateByUpdateDateQuery = {
  ordersByFinishingStateByUpdateDate?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      typename?: string | null,
      orderNumber: string,
      date_created: string,
      date_paid: string,
      total?: number | null,
      cart_tax?: number | null,
      total_tax?: number | null,
      discount_total?: number | null,
      discount_tax?: number | null,
      shipping_total?: number | null,
      shipping_tax?: number | null,
      fees?:  Array< {
        __typename: "Fee",
        name?: string | null,
        amount?: number | null,
        total?: number | null,
        total_tax?: number | null,
      } | null > | null,
      weight?: number | null,
      customer:  {
        __typename: "Customer",
        first_name: string,
        last_name: string,
        street?: string | null,
        house_number?: string | null,
        zip?: string | null,
        city?: string | null,
        country?: string | null,
        email?: string | null,
        phone?: string | null,
        company?: string | null,
        add?: string | null,
      },
      shipping_method?: string | null,
      delivery_date: string,
      status?: OrderStatus | null,
      state?: OrderState | null,
      source: OrderSource,
      in_progressAt?: string | null,
      finishedAt?: string | null,
      updatedAt?: string | null,
      retoure_shipping_costs?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          position?: number | null,
          typename?: string | null,
          name: string,
          sku?: string | null,
          product_id?: string | null,
          variation_id?: string | null,
          quantity?: number | null,
          length?: number | null,
          width?: number | null,
          height?: number | null,
          area?: number | null,
          weight?: string | null,
          categories?: Array< string | null > | null,
          variations?: Array< string | null > | null,
          options?: Array< string | null > | null,
          tasks?: Array< string | null > | null,
          currentTask?: string | null,
          contractor_currentTask?: string | null,
          productContractorId?: string | null,
          status?: ProductStatus | null,
          contractor_status: string,
          shipping_label_printed?: boolean | null,
          orderNumber?: string | null,
          productOrderId: string,
          backoffice_note?: string | null,
          total_before_discount?: number | null,
          total_net_price?: number | null,
          total_tax?: number | null,
          nipsild_fixed_costs?: number | null,
          delivery_time?: string | null,
          delivery_date: string,
          declinedTourDates?: Array< string | null > | null,
          billed_amount?: number | null,
          in_progressAt?: string | null,
          ready_to_packAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          billedAt?: string | null,
          recievedReturnAt?: string | null,
          reklaOrderNumber?: string | null,
          retoure_type?: RetoureType | null,
          retoure_reason?: string | null,
          retoure_manufacturing_fee?: number | null,
          retoure_refund_amount?: number | null,
          retoure_refund_contractors_share?: number | null,
          createdAt: string,
          orderProductsId?: string | null,
          tourProductsId?: string | null,
          productShipmentId?: string | null,
          productRetoure_shipmentId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ItemsByUpdateDateQueryVariables = {
  typename: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ItemsByUpdateDateQuery = {
  itemsByUpdateDate?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      position?: number | null,
      typename?: string | null,
      name: string,
      sku?: string | null,
      product_id?: string | null,
      variation_id?: string | null,
      quantity?: number | null,
      length?: number | null,
      width?: number | null,
      height?: number | null,
      area?: number | null,
      weight?: string | null,
      categories?: Array< string | null > | null,
      variations?: Array< string | null > | null,
      options?: Array< string | null > | null,
      extraOptions?:  Array< {
        __typename: "Option",
        name?: string | null,
        value: string,
        imagesp?: string | null,
        price?: number | null,
      } | null > | null,
      tasks?: Array< string | null > | null,
      currentTask?: string | null,
      contractor_currentTask?: string | null,
      productContractorId?: string | null,
      contractor?:  {
        __typename: "Contractor",
        id: string,
        number: string,
        name: string,
        billing_rate?: number | null,
        billing_max?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      status?: ProductStatus | null,
      contractor_status: string,
      shipping_label_printed?: boolean | null,
      orderNumber?: string | null,
      order?:  {
        __typename: "Order",
        id: string,
        typename?: string | null,
        orderNumber: string,
        date_created: string,
        date_paid: string,
        total?: number | null,
        cart_tax?: number | null,
        total_tax?: number | null,
        discount_total?: number | null,
        discount_tax?: number | null,
        shipping_total?: number | null,
        shipping_tax?: number | null,
        fees?:  Array< {
          __typename: "Fee",
          name?: string | null,
          amount?: number | null,
          total?: number | null,
          total_tax?: number | null,
        } | null > | null,
        weight?: number | null,
        customer:  {
          __typename: "Customer",
          first_name: string,
          last_name: string,
          street?: string | null,
          house_number?: string | null,
          zip?: string | null,
          city?: string | null,
          country?: string | null,
          email?: string | null,
          phone?: string | null,
          company?: string | null,
          add?: string | null,
        },
        shipping_method?: string | null,
        delivery_date: string,
        status?: OrderStatus | null,
        state?: OrderState | null,
        source: OrderSource,
        in_progressAt?: string | null,
        finishedAt?: string | null,
        updatedAt?: string | null,
        retoure_shipping_costs?: number | null,
        products?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
      } | null,
      productOrderId: string,
      backoffice_note?: string | null,
      total_before_discount?: number | null,
      total_net_price?: number | null,
      total_tax?: number | null,
      nipsild_fixed_costs?: number | null,
      delivery_time?: string | null,
      delivery_date: string,
      shipment?:  {
        __typename: "Shipment",
        id: string,
        typename?: string | null,
        length: number,
        width: number,
        height: number,
        gurtMass: number,
        weight: number,
        serviceProvider: string,
        costs: number,
        calculatedCosts?: number | null,
        shippedAt?: string | null,
        edited?: string | null,
        parcelNumber?: string | null,
        trackID?: string | null,
        suggest_splitting?: boolean | null,
        overrule_splitting_user?: string | null,
        labelData?:  {
          __typename: "LabelData",
          base64?: string | null,
          format?: LabelFormat | null,
        } | null,
        updatedAt?: string | null,
        createdAt: string,
      } | null,
      tour?:  {
        __typename: "Tour",
        id: string,
        typename?: string | null,
        date: string,
        products?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        deliveryNotificationSentTo?: Array< string | null > | null,
        info?: string | null,
        driver?: string | null,
        calculated_shipping_costs?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      declinedTourDates?: Array< string | null > | null,
      billed_amount?: number | null,
      in_progressAt?: string | null,
      ready_to_packAt?: string | null,
      finishedAt?: string | null,
      updatedAt?: string | null,
      billedAt?: string | null,
      recievedReturnAt?: string | null,
      reklaOrderNumber?: string | null,
      retoure_type?: RetoureType | null,
      retoure_reason?: string | null,
      retoure_manufacturing_fee?: number | null,
      retoure_refund_amount?: number | null,
      retoure_refund_contractors_share?: number | null,
      retoure_shipment?:  {
        __typename: "Shipment",
        id: string,
        typename?: string | null,
        length: number,
        width: number,
        height: number,
        gurtMass: number,
        weight: number,
        serviceProvider: string,
        costs: number,
        calculatedCosts?: number | null,
        shippedAt?: string | null,
        edited?: string | null,
        parcelNumber?: string | null,
        trackID?: string | null,
        suggest_splitting?: boolean | null,
        overrule_splitting_user?: string | null,
        labelData?:  {
          __typename: "LabelData",
          base64?: string | null,
          format?: LabelFormat | null,
        } | null,
        updatedAt?: string | null,
        createdAt: string,
      } | null,
      createdAt: string,
      orderProductsId?: string | null,
      tourProductsId?: string | null,
      productShipmentId?: string | null,
      productRetoure_shipmentId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ItemsByCurrentTaskQueryVariables = {
  currentTask: string,
  delivery_date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ItemsByCurrentTaskQuery = {
  itemsByCurrentTask?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      position?: number | null,
      typename?: string | null,
      name: string,
      sku?: string | null,
      product_id?: string | null,
      variation_id?: string | null,
      quantity?: number | null,
      length?: number | null,
      width?: number | null,
      height?: number | null,
      area?: number | null,
      weight?: string | null,
      categories?: Array< string | null > | null,
      variations?: Array< string | null > | null,
      options?: Array< string | null > | null,
      extraOptions?:  Array< {
        __typename: "Option",
        name?: string | null,
        value: string,
        imagesp?: string | null,
        price?: number | null,
      } | null > | null,
      tasks?: Array< string | null > | null,
      currentTask?: string | null,
      contractor_currentTask?: string | null,
      productContractorId?: string | null,
      contractor?:  {
        __typename: "Contractor",
        id: string,
        number: string,
        name: string,
        billing_rate?: number | null,
        billing_max?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      status?: ProductStatus | null,
      contractor_status: string,
      shipping_label_printed?: boolean | null,
      orderNumber?: string | null,
      order?:  {
        __typename: "Order",
        id: string,
        typename?: string | null,
        orderNumber: string,
        date_created: string,
        date_paid: string,
        total?: number | null,
        cart_tax?: number | null,
        total_tax?: number | null,
        discount_total?: number | null,
        discount_tax?: number | null,
        shipping_total?: number | null,
        shipping_tax?: number | null,
        fees?:  Array< {
          __typename: "Fee",
          name?: string | null,
          amount?: number | null,
          total?: number | null,
          total_tax?: number | null,
        } | null > | null,
        weight?: number | null,
        customer:  {
          __typename: "Customer",
          first_name: string,
          last_name: string,
          street?: string | null,
          house_number?: string | null,
          zip?: string | null,
          city?: string | null,
          country?: string | null,
          email?: string | null,
          phone?: string | null,
          company?: string | null,
          add?: string | null,
        },
        shipping_method?: string | null,
        delivery_date: string,
        status?: OrderStatus | null,
        state?: OrderState | null,
        source: OrderSource,
        in_progressAt?: string | null,
        finishedAt?: string | null,
        updatedAt?: string | null,
        retoure_shipping_costs?: number | null,
        products?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
      } | null,
      productOrderId: string,
      backoffice_note?: string | null,
      total_before_discount?: number | null,
      total_net_price?: number | null,
      total_tax?: number | null,
      nipsild_fixed_costs?: number | null,
      delivery_time?: string | null,
      delivery_date: string,
      shipment?:  {
        __typename: "Shipment",
        id: string,
        typename?: string | null,
        length: number,
        width: number,
        height: number,
        gurtMass: number,
        weight: number,
        serviceProvider: string,
        costs: number,
        calculatedCosts?: number | null,
        shippedAt?: string | null,
        edited?: string | null,
        parcelNumber?: string | null,
        trackID?: string | null,
        suggest_splitting?: boolean | null,
        overrule_splitting_user?: string | null,
        labelData?:  {
          __typename: "LabelData",
          base64?: string | null,
          format?: LabelFormat | null,
        } | null,
        updatedAt?: string | null,
        createdAt: string,
      } | null,
      tour?:  {
        __typename: "Tour",
        id: string,
        typename?: string | null,
        date: string,
        products?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        deliveryNotificationSentTo?: Array< string | null > | null,
        info?: string | null,
        driver?: string | null,
        calculated_shipping_costs?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      declinedTourDates?: Array< string | null > | null,
      billed_amount?: number | null,
      in_progressAt?: string | null,
      ready_to_packAt?: string | null,
      finishedAt?: string | null,
      updatedAt?: string | null,
      billedAt?: string | null,
      recievedReturnAt?: string | null,
      reklaOrderNumber?: string | null,
      retoure_type?: RetoureType | null,
      retoure_reason?: string | null,
      retoure_manufacturing_fee?: number | null,
      retoure_refund_amount?: number | null,
      retoure_refund_contractors_share?: number | null,
      retoure_shipment?:  {
        __typename: "Shipment",
        id: string,
        typename?: string | null,
        length: number,
        width: number,
        height: number,
        gurtMass: number,
        weight: number,
        serviceProvider: string,
        costs: number,
        calculatedCosts?: number | null,
        shippedAt?: string | null,
        edited?: string | null,
        parcelNumber?: string | null,
        trackID?: string | null,
        suggest_splitting?: boolean | null,
        overrule_splitting_user?: string | null,
        labelData?:  {
          __typename: "LabelData",
          base64?: string | null,
          format?: LabelFormat | null,
        } | null,
        updatedAt?: string | null,
        createdAt: string,
      } | null,
      createdAt: string,
      orderProductsId?: string | null,
      tourProductsId?: string | null,
      productShipmentId?: string | null,
      productRetoure_shipmentId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ItemsByContractorTaskQueryVariables = {
  contractor_currentTask: string,
  delivery_date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ItemsByContractorTaskQuery = {
  itemsByContractorTask?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      position?: number | null,
      typename?: string | null,
      name: string,
      sku?: string | null,
      product_id?: string | null,
      variation_id?: string | null,
      quantity?: number | null,
      length?: number | null,
      width?: number | null,
      height?: number | null,
      area?: number | null,
      weight?: string | null,
      categories?: Array< string | null > | null,
      variations?: Array< string | null > | null,
      options?: Array< string | null > | null,
      extraOptions?:  Array< {
        __typename: "Option",
        name?: string | null,
        value: string,
        imagesp?: string | null,
        price?: number | null,
      } | null > | null,
      tasks?: Array< string | null > | null,
      currentTask?: string | null,
      contractor_currentTask?: string | null,
      productContractorId?: string | null,
      contractor?:  {
        __typename: "Contractor",
        id: string,
        number: string,
        name: string,
        billing_rate?: number | null,
        billing_max?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      status?: ProductStatus | null,
      contractor_status: string,
      shipping_label_printed?: boolean | null,
      orderNumber?: string | null,
      order?:  {
        __typename: "Order",
        id: string,
        typename?: string | null,
        orderNumber: string,
        date_created: string,
        date_paid: string,
        total?: number | null,
        cart_tax?: number | null,
        total_tax?: number | null,
        discount_total?: number | null,
        discount_tax?: number | null,
        shipping_total?: number | null,
        shipping_tax?: number | null,
        fees?:  Array< {
          __typename: "Fee",
          name?: string | null,
          amount?: number | null,
          total?: number | null,
          total_tax?: number | null,
        } | null > | null,
        weight?: number | null,
        customer:  {
          __typename: "Customer",
          first_name: string,
          last_name: string,
          street?: string | null,
          house_number?: string | null,
          zip?: string | null,
          city?: string | null,
          country?: string | null,
          email?: string | null,
          phone?: string | null,
          company?: string | null,
          add?: string | null,
        },
        shipping_method?: string | null,
        delivery_date: string,
        status?: OrderStatus | null,
        state?: OrderState | null,
        source: OrderSource,
        in_progressAt?: string | null,
        finishedAt?: string | null,
        updatedAt?: string | null,
        retoure_shipping_costs?: number | null,
        products?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
      } | null,
      productOrderId: string,
      backoffice_note?: string | null,
      total_before_discount?: number | null,
      total_net_price?: number | null,
      total_tax?: number | null,
      nipsild_fixed_costs?: number | null,
      delivery_time?: string | null,
      delivery_date: string,
      shipment?:  {
        __typename: "Shipment",
        id: string,
        typename?: string | null,
        length: number,
        width: number,
        height: number,
        gurtMass: number,
        weight: number,
        serviceProvider: string,
        costs: number,
        calculatedCosts?: number | null,
        shippedAt?: string | null,
        edited?: string | null,
        parcelNumber?: string | null,
        trackID?: string | null,
        suggest_splitting?: boolean | null,
        overrule_splitting_user?: string | null,
        labelData?:  {
          __typename: "LabelData",
          base64?: string | null,
          format?: LabelFormat | null,
        } | null,
        updatedAt?: string | null,
        createdAt: string,
      } | null,
      tour?:  {
        __typename: "Tour",
        id: string,
        typename?: string | null,
        date: string,
        products?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        deliveryNotificationSentTo?: Array< string | null > | null,
        info?: string | null,
        driver?: string | null,
        calculated_shipping_costs?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      declinedTourDates?: Array< string | null > | null,
      billed_amount?: number | null,
      in_progressAt?: string | null,
      ready_to_packAt?: string | null,
      finishedAt?: string | null,
      updatedAt?: string | null,
      billedAt?: string | null,
      recievedReturnAt?: string | null,
      reklaOrderNumber?: string | null,
      retoure_type?: RetoureType | null,
      retoure_reason?: string | null,
      retoure_manufacturing_fee?: number | null,
      retoure_refund_amount?: number | null,
      retoure_refund_contractors_share?: number | null,
      retoure_shipment?:  {
        __typename: "Shipment",
        id: string,
        typename?: string | null,
        length: number,
        width: number,
        height: number,
        gurtMass: number,
        weight: number,
        serviceProvider: string,
        costs: number,
        calculatedCosts?: number | null,
        shippedAt?: string | null,
        edited?: string | null,
        parcelNumber?: string | null,
        trackID?: string | null,
        suggest_splitting?: boolean | null,
        overrule_splitting_user?: string | null,
        labelData?:  {
          __typename: "LabelData",
          base64?: string | null,
          format?: LabelFormat | null,
        } | null,
        updatedAt?: string | null,
        createdAt: string,
      } | null,
      createdAt: string,
      orderProductsId?: string | null,
      tourProductsId?: string | null,
      productShipmentId?: string | null,
      productRetoure_shipmentId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FinishedItemsByStatusQueryVariables = {
  status: ProductStatus,
  finishedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FinishedItemsByStatusQuery = {
  finishedItemsByStatus?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      position?: number | null,
      typename?: string | null,
      name: string,
      sku?: string | null,
      product_id?: string | null,
      variation_id?: string | null,
      quantity?: number | null,
      length?: number | null,
      width?: number | null,
      height?: number | null,
      area?: number | null,
      weight?: string | null,
      categories?: Array< string | null > | null,
      variations?: Array< string | null > | null,
      options?: Array< string | null > | null,
      extraOptions?:  Array< {
        __typename: "Option",
        name?: string | null,
        value: string,
        imagesp?: string | null,
        price?: number | null,
      } | null > | null,
      tasks?: Array< string | null > | null,
      currentTask?: string | null,
      contractor_currentTask?: string | null,
      productContractorId?: string | null,
      contractor?:  {
        __typename: "Contractor",
        id: string,
        number: string,
        name: string,
        billing_rate?: number | null,
        billing_max?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      status?: ProductStatus | null,
      contractor_status: string,
      shipping_label_printed?: boolean | null,
      orderNumber?: string | null,
      order?:  {
        __typename: "Order",
        id: string,
        typename?: string | null,
        orderNumber: string,
        date_created: string,
        date_paid: string,
        total?: number | null,
        cart_tax?: number | null,
        total_tax?: number | null,
        discount_total?: number | null,
        discount_tax?: number | null,
        shipping_total?: number | null,
        shipping_tax?: number | null,
        fees?:  Array< {
          __typename: "Fee",
          name?: string | null,
          amount?: number | null,
          total?: number | null,
          total_tax?: number | null,
        } | null > | null,
        weight?: number | null,
        customer:  {
          __typename: "Customer",
          first_name: string,
          last_name: string,
          street?: string | null,
          house_number?: string | null,
          zip?: string | null,
          city?: string | null,
          country?: string | null,
          email?: string | null,
          phone?: string | null,
          company?: string | null,
          add?: string | null,
        },
        shipping_method?: string | null,
        delivery_date: string,
        status?: OrderStatus | null,
        state?: OrderState | null,
        source: OrderSource,
        in_progressAt?: string | null,
        finishedAt?: string | null,
        updatedAt?: string | null,
        retoure_shipping_costs?: number | null,
        products?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
      } | null,
      productOrderId: string,
      backoffice_note?: string | null,
      total_before_discount?: number | null,
      total_net_price?: number | null,
      total_tax?: number | null,
      nipsild_fixed_costs?: number | null,
      delivery_time?: string | null,
      delivery_date: string,
      shipment?:  {
        __typename: "Shipment",
        id: string,
        typename?: string | null,
        length: number,
        width: number,
        height: number,
        gurtMass: number,
        weight: number,
        serviceProvider: string,
        costs: number,
        calculatedCosts?: number | null,
        shippedAt?: string | null,
        edited?: string | null,
        parcelNumber?: string | null,
        trackID?: string | null,
        suggest_splitting?: boolean | null,
        overrule_splitting_user?: string | null,
        labelData?:  {
          __typename: "LabelData",
          base64?: string | null,
          format?: LabelFormat | null,
        } | null,
        updatedAt?: string | null,
        createdAt: string,
      } | null,
      tour?:  {
        __typename: "Tour",
        id: string,
        typename?: string | null,
        date: string,
        products?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        deliveryNotificationSentTo?: Array< string | null > | null,
        info?: string | null,
        driver?: string | null,
        calculated_shipping_costs?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      declinedTourDates?: Array< string | null > | null,
      billed_amount?: number | null,
      in_progressAt?: string | null,
      ready_to_packAt?: string | null,
      finishedAt?: string | null,
      updatedAt?: string | null,
      billedAt?: string | null,
      recievedReturnAt?: string | null,
      reklaOrderNumber?: string | null,
      retoure_type?: RetoureType | null,
      retoure_reason?: string | null,
      retoure_manufacturing_fee?: number | null,
      retoure_refund_amount?: number | null,
      retoure_refund_contractors_share?: number | null,
      retoure_shipment?:  {
        __typename: "Shipment",
        id: string,
        typename?: string | null,
        length: number,
        width: number,
        height: number,
        gurtMass: number,
        weight: number,
        serviceProvider: string,
        costs: number,
        calculatedCosts?: number | null,
        shippedAt?: string | null,
        edited?: string | null,
        parcelNumber?: string | null,
        trackID?: string | null,
        suggest_splitting?: boolean | null,
        overrule_splitting_user?: string | null,
        labelData?:  {
          __typename: "LabelData",
          base64?: string | null,
          format?: LabelFormat | null,
        } | null,
        updatedAt?: string | null,
        createdAt: string,
      } | null,
      createdAt: string,
      orderProductsId?: string | null,
      tourProductsId?: string | null,
      productShipmentId?: string | null,
      productRetoure_shipmentId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OngoingItemsByStatusQueryVariables = {
  status: ProductStatus,
  delivery_date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OngoingItemsByStatusQuery = {
  ongoingItemsByStatus?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      position?: number | null,
      typename?: string | null,
      name: string,
      sku?: string | null,
      product_id?: string | null,
      variation_id?: string | null,
      quantity?: number | null,
      length?: number | null,
      width?: number | null,
      height?: number | null,
      area?: number | null,
      weight?: string | null,
      categories?: Array< string | null > | null,
      variations?: Array< string | null > | null,
      options?: Array< string | null > | null,
      extraOptions?:  Array< {
        __typename: "Option",
        name?: string | null,
        value: string,
        imagesp?: string | null,
        price?: number | null,
      } | null > | null,
      tasks?: Array< string | null > | null,
      currentTask?: string | null,
      contractor_currentTask?: string | null,
      productContractorId?: string | null,
      contractor?:  {
        __typename: "Contractor",
        id: string,
        number: string,
        name: string,
        billing_rate?: number | null,
        billing_max?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      status?: ProductStatus | null,
      contractor_status: string,
      shipping_label_printed?: boolean | null,
      orderNumber?: string | null,
      order?:  {
        __typename: "Order",
        id: string,
        typename?: string | null,
        orderNumber: string,
        date_created: string,
        date_paid: string,
        total?: number | null,
        cart_tax?: number | null,
        total_tax?: number | null,
        discount_total?: number | null,
        discount_tax?: number | null,
        shipping_total?: number | null,
        shipping_tax?: number | null,
        fees?:  Array< {
          __typename: "Fee",
          name?: string | null,
          amount?: number | null,
          total?: number | null,
          total_tax?: number | null,
        } | null > | null,
        weight?: number | null,
        customer:  {
          __typename: "Customer",
          first_name: string,
          last_name: string,
          street?: string | null,
          house_number?: string | null,
          zip?: string | null,
          city?: string | null,
          country?: string | null,
          email?: string | null,
          phone?: string | null,
          company?: string | null,
          add?: string | null,
        },
        shipping_method?: string | null,
        delivery_date: string,
        status?: OrderStatus | null,
        state?: OrderState | null,
        source: OrderSource,
        in_progressAt?: string | null,
        finishedAt?: string | null,
        updatedAt?: string | null,
        retoure_shipping_costs?: number | null,
        products?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
      } | null,
      productOrderId: string,
      backoffice_note?: string | null,
      total_before_discount?: number | null,
      total_net_price?: number | null,
      total_tax?: number | null,
      nipsild_fixed_costs?: number | null,
      delivery_time?: string | null,
      delivery_date: string,
      shipment?:  {
        __typename: "Shipment",
        id: string,
        typename?: string | null,
        length: number,
        width: number,
        height: number,
        gurtMass: number,
        weight: number,
        serviceProvider: string,
        costs: number,
        calculatedCosts?: number | null,
        shippedAt?: string | null,
        edited?: string | null,
        parcelNumber?: string | null,
        trackID?: string | null,
        suggest_splitting?: boolean | null,
        overrule_splitting_user?: string | null,
        labelData?:  {
          __typename: "LabelData",
          base64?: string | null,
          format?: LabelFormat | null,
        } | null,
        updatedAt?: string | null,
        createdAt: string,
      } | null,
      tour?:  {
        __typename: "Tour",
        id: string,
        typename?: string | null,
        date: string,
        products?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        deliveryNotificationSentTo?: Array< string | null > | null,
        info?: string | null,
        driver?: string | null,
        calculated_shipping_costs?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      declinedTourDates?: Array< string | null > | null,
      billed_amount?: number | null,
      in_progressAt?: string | null,
      ready_to_packAt?: string | null,
      finishedAt?: string | null,
      updatedAt?: string | null,
      billedAt?: string | null,
      recievedReturnAt?: string | null,
      reklaOrderNumber?: string | null,
      retoure_type?: RetoureType | null,
      retoure_reason?: string | null,
      retoure_manufacturing_fee?: number | null,
      retoure_refund_amount?: number | null,
      retoure_refund_contractors_share?: number | null,
      retoure_shipment?:  {
        __typename: "Shipment",
        id: string,
        typename?: string | null,
        length: number,
        width: number,
        height: number,
        gurtMass: number,
        weight: number,
        serviceProvider: string,
        costs: number,
        calculatedCosts?: number | null,
        shippedAt?: string | null,
        edited?: string | null,
        parcelNumber?: string | null,
        trackID?: string | null,
        suggest_splitting?: boolean | null,
        overrule_splitting_user?: string | null,
        labelData?:  {
          __typename: "LabelData",
          base64?: string | null,
          format?: LabelFormat | null,
        } | null,
        updatedAt?: string | null,
        createdAt: string,
      } | null,
      createdAt: string,
      orderProductsId?: string | null,
      tourProductsId?: string | null,
      productShipmentId?: string | null,
      productRetoure_shipmentId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FinishedItemsByContractorByStatusQueryVariables = {
  contractor_status: string,
  finishedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FinishedItemsByContractorByStatusQuery = {
  finishedItemsByContractorByStatus?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      position?: number | null,
      typename?: string | null,
      name: string,
      sku?: string | null,
      product_id?: string | null,
      variation_id?: string | null,
      quantity?: number | null,
      length?: number | null,
      width?: number | null,
      height?: number | null,
      area?: number | null,
      weight?: string | null,
      categories?: Array< string | null > | null,
      variations?: Array< string | null > | null,
      options?: Array< string | null > | null,
      extraOptions?:  Array< {
        __typename: "Option",
        name?: string | null,
        value: string,
        imagesp?: string | null,
        price?: number | null,
      } | null > | null,
      tasks?: Array< string | null > | null,
      currentTask?: string | null,
      contractor_currentTask?: string | null,
      productContractorId?: string | null,
      contractor?:  {
        __typename: "Contractor",
        id: string,
        number: string,
        name: string,
        billing_rate?: number | null,
        billing_max?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      status?: ProductStatus | null,
      contractor_status: string,
      shipping_label_printed?: boolean | null,
      orderNumber?: string | null,
      order?:  {
        __typename: "Order",
        id: string,
        typename?: string | null,
        orderNumber: string,
        date_created: string,
        date_paid: string,
        total?: number | null,
        cart_tax?: number | null,
        total_tax?: number | null,
        discount_total?: number | null,
        discount_tax?: number | null,
        shipping_total?: number | null,
        shipping_tax?: number | null,
        fees?:  Array< {
          __typename: "Fee",
          name?: string | null,
          amount?: number | null,
          total?: number | null,
          total_tax?: number | null,
        } | null > | null,
        weight?: number | null,
        customer:  {
          __typename: "Customer",
          first_name: string,
          last_name: string,
          street?: string | null,
          house_number?: string | null,
          zip?: string | null,
          city?: string | null,
          country?: string | null,
          email?: string | null,
          phone?: string | null,
          company?: string | null,
          add?: string | null,
        },
        shipping_method?: string | null,
        delivery_date: string,
        status?: OrderStatus | null,
        state?: OrderState | null,
        source: OrderSource,
        in_progressAt?: string | null,
        finishedAt?: string | null,
        updatedAt?: string | null,
        retoure_shipping_costs?: number | null,
        products?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
      } | null,
      productOrderId: string,
      backoffice_note?: string | null,
      total_before_discount?: number | null,
      total_net_price?: number | null,
      total_tax?: number | null,
      nipsild_fixed_costs?: number | null,
      delivery_time?: string | null,
      delivery_date: string,
      shipment?:  {
        __typename: "Shipment",
        id: string,
        typename?: string | null,
        length: number,
        width: number,
        height: number,
        gurtMass: number,
        weight: number,
        serviceProvider: string,
        costs: number,
        calculatedCosts?: number | null,
        shippedAt?: string | null,
        edited?: string | null,
        parcelNumber?: string | null,
        trackID?: string | null,
        suggest_splitting?: boolean | null,
        overrule_splitting_user?: string | null,
        labelData?:  {
          __typename: "LabelData",
          base64?: string | null,
          format?: LabelFormat | null,
        } | null,
        updatedAt?: string | null,
        createdAt: string,
      } | null,
      tour?:  {
        __typename: "Tour",
        id: string,
        typename?: string | null,
        date: string,
        products?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        deliveryNotificationSentTo?: Array< string | null > | null,
        info?: string | null,
        driver?: string | null,
        calculated_shipping_costs?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      declinedTourDates?: Array< string | null > | null,
      billed_amount?: number | null,
      in_progressAt?: string | null,
      ready_to_packAt?: string | null,
      finishedAt?: string | null,
      updatedAt?: string | null,
      billedAt?: string | null,
      recievedReturnAt?: string | null,
      reklaOrderNumber?: string | null,
      retoure_type?: RetoureType | null,
      retoure_reason?: string | null,
      retoure_manufacturing_fee?: number | null,
      retoure_refund_amount?: number | null,
      retoure_refund_contractors_share?: number | null,
      retoure_shipment?:  {
        __typename: "Shipment",
        id: string,
        typename?: string | null,
        length: number,
        width: number,
        height: number,
        gurtMass: number,
        weight: number,
        serviceProvider: string,
        costs: number,
        calculatedCosts?: number | null,
        shippedAt?: string | null,
        edited?: string | null,
        parcelNumber?: string | null,
        trackID?: string | null,
        suggest_splitting?: boolean | null,
        overrule_splitting_user?: string | null,
        labelData?:  {
          __typename: "LabelData",
          base64?: string | null,
          format?: LabelFormat | null,
        } | null,
        updatedAt?: string | null,
        createdAt: string,
      } | null,
      createdAt: string,
      orderProductsId?: string | null,
      tourProductsId?: string | null,
      productShipmentId?: string | null,
      productRetoure_shipmentId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OngoingItemsByContractorByStatusQueryVariables = {
  contractor_status: string,
  delivery_date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OngoingItemsByContractorByStatusQuery = {
  ongoingItemsByContractorByStatus?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      position?: number | null,
      typename?: string | null,
      name: string,
      sku?: string | null,
      product_id?: string | null,
      variation_id?: string | null,
      quantity?: number | null,
      length?: number | null,
      width?: number | null,
      height?: number | null,
      area?: number | null,
      weight?: string | null,
      categories?: Array< string | null > | null,
      variations?: Array< string | null > | null,
      options?: Array< string | null > | null,
      extraOptions?:  Array< {
        __typename: "Option",
        name?: string | null,
        value: string,
        imagesp?: string | null,
        price?: number | null,
      } | null > | null,
      tasks?: Array< string | null > | null,
      currentTask?: string | null,
      contractor_currentTask?: string | null,
      productContractorId?: string | null,
      contractor?:  {
        __typename: "Contractor",
        id: string,
        number: string,
        name: string,
        billing_rate?: number | null,
        billing_max?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      status?: ProductStatus | null,
      contractor_status: string,
      shipping_label_printed?: boolean | null,
      orderNumber?: string | null,
      order?:  {
        __typename: "Order",
        id: string,
        typename?: string | null,
        orderNumber: string,
        date_created: string,
        date_paid: string,
        total?: number | null,
        cart_tax?: number | null,
        total_tax?: number | null,
        discount_total?: number | null,
        discount_tax?: number | null,
        shipping_total?: number | null,
        shipping_tax?: number | null,
        fees?:  Array< {
          __typename: "Fee",
          name?: string | null,
          amount?: number | null,
          total?: number | null,
          total_tax?: number | null,
        } | null > | null,
        weight?: number | null,
        customer:  {
          __typename: "Customer",
          first_name: string,
          last_name: string,
          street?: string | null,
          house_number?: string | null,
          zip?: string | null,
          city?: string | null,
          country?: string | null,
          email?: string | null,
          phone?: string | null,
          company?: string | null,
          add?: string | null,
        },
        shipping_method?: string | null,
        delivery_date: string,
        status?: OrderStatus | null,
        state?: OrderState | null,
        source: OrderSource,
        in_progressAt?: string | null,
        finishedAt?: string | null,
        updatedAt?: string | null,
        retoure_shipping_costs?: number | null,
        products?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
      } | null,
      productOrderId: string,
      backoffice_note?: string | null,
      total_before_discount?: number | null,
      total_net_price?: number | null,
      total_tax?: number | null,
      nipsild_fixed_costs?: number | null,
      delivery_time?: string | null,
      delivery_date: string,
      shipment?:  {
        __typename: "Shipment",
        id: string,
        typename?: string | null,
        length: number,
        width: number,
        height: number,
        gurtMass: number,
        weight: number,
        serviceProvider: string,
        costs: number,
        calculatedCosts?: number | null,
        shippedAt?: string | null,
        edited?: string | null,
        parcelNumber?: string | null,
        trackID?: string | null,
        suggest_splitting?: boolean | null,
        overrule_splitting_user?: string | null,
        labelData?:  {
          __typename: "LabelData",
          base64?: string | null,
          format?: LabelFormat | null,
        } | null,
        updatedAt?: string | null,
        createdAt: string,
      } | null,
      tour?:  {
        __typename: "Tour",
        id: string,
        typename?: string | null,
        date: string,
        products?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        deliveryNotificationSentTo?: Array< string | null > | null,
        info?: string | null,
        driver?: string | null,
        calculated_shipping_costs?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      declinedTourDates?: Array< string | null > | null,
      billed_amount?: number | null,
      in_progressAt?: string | null,
      ready_to_packAt?: string | null,
      finishedAt?: string | null,
      updatedAt?: string | null,
      billedAt?: string | null,
      recievedReturnAt?: string | null,
      reklaOrderNumber?: string | null,
      retoure_type?: RetoureType | null,
      retoure_reason?: string | null,
      retoure_manufacturing_fee?: number | null,
      retoure_refund_amount?: number | null,
      retoure_refund_contractors_share?: number | null,
      retoure_shipment?:  {
        __typename: "Shipment",
        id: string,
        typename?: string | null,
        length: number,
        width: number,
        height: number,
        gurtMass: number,
        weight: number,
        serviceProvider: string,
        costs: number,
        calculatedCosts?: number | null,
        shippedAt?: string | null,
        edited?: string | null,
        parcelNumber?: string | null,
        trackID?: string | null,
        suggest_splitting?: boolean | null,
        overrule_splitting_user?: string | null,
        labelData?:  {
          __typename: "LabelData",
          base64?: string | null,
          format?: LabelFormat | null,
        } | null,
        updatedAt?: string | null,
        createdAt: string,
      } | null,
      createdAt: string,
      orderProductsId?: string | null,
      tourProductsId?: string | null,
      productShipmentId?: string | null,
      productRetoure_shipmentId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ItemsByOrderNumberQueryVariables = {
  orderNumber: string,
  contractor_status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ItemsByOrderNumberQuery = {
  itemsByOrderNumber?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      position?: number | null,
      typename?: string | null,
      name: string,
      sku?: string | null,
      product_id?: string | null,
      variation_id?: string | null,
      quantity?: number | null,
      length?: number | null,
      width?: number | null,
      height?: number | null,
      area?: number | null,
      weight?: string | null,
      categories?: Array< string | null > | null,
      variations?: Array< string | null > | null,
      options?: Array< string | null > | null,
      extraOptions?:  Array< {
        __typename: "Option",
        name?: string | null,
        value: string,
        imagesp?: string | null,
        price?: number | null,
      } | null > | null,
      tasks?: Array< string | null > | null,
      currentTask?: string | null,
      contractor_currentTask?: string | null,
      productContractorId?: string | null,
      contractor?:  {
        __typename: "Contractor",
        id: string,
        number: string,
        name: string,
        billing_rate?: number | null,
        billing_max?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      status?: ProductStatus | null,
      contractor_status: string,
      shipping_label_printed?: boolean | null,
      orderNumber?: string | null,
      order?:  {
        __typename: "Order",
        id: string,
        typename?: string | null,
        orderNumber: string,
        date_created: string,
        date_paid: string,
        total?: number | null,
        cart_tax?: number | null,
        total_tax?: number | null,
        discount_total?: number | null,
        discount_tax?: number | null,
        shipping_total?: number | null,
        shipping_tax?: number | null,
        fees?:  Array< {
          __typename: "Fee",
          name?: string | null,
          amount?: number | null,
          total?: number | null,
          total_tax?: number | null,
        } | null > | null,
        weight?: number | null,
        customer:  {
          __typename: "Customer",
          first_name: string,
          last_name: string,
          street?: string | null,
          house_number?: string | null,
          zip?: string | null,
          city?: string | null,
          country?: string | null,
          email?: string | null,
          phone?: string | null,
          company?: string | null,
          add?: string | null,
        },
        shipping_method?: string | null,
        delivery_date: string,
        status?: OrderStatus | null,
        state?: OrderState | null,
        source: OrderSource,
        in_progressAt?: string | null,
        finishedAt?: string | null,
        updatedAt?: string | null,
        retoure_shipping_costs?: number | null,
        products?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
      } | null,
      productOrderId: string,
      backoffice_note?: string | null,
      total_before_discount?: number | null,
      total_net_price?: number | null,
      total_tax?: number | null,
      nipsild_fixed_costs?: number | null,
      delivery_time?: string | null,
      delivery_date: string,
      shipment?:  {
        __typename: "Shipment",
        id: string,
        typename?: string | null,
        length: number,
        width: number,
        height: number,
        gurtMass: number,
        weight: number,
        serviceProvider: string,
        costs: number,
        calculatedCosts?: number | null,
        shippedAt?: string | null,
        edited?: string | null,
        parcelNumber?: string | null,
        trackID?: string | null,
        suggest_splitting?: boolean | null,
        overrule_splitting_user?: string | null,
        labelData?:  {
          __typename: "LabelData",
          base64?: string | null,
          format?: LabelFormat | null,
        } | null,
        updatedAt?: string | null,
        createdAt: string,
      } | null,
      tour?:  {
        __typename: "Tour",
        id: string,
        typename?: string | null,
        date: string,
        products?:  {
          __typename: "ModelProductConnection",
          nextToken?: string | null,
        } | null,
        deliveryNotificationSentTo?: Array< string | null > | null,
        info?: string | null,
        driver?: string | null,
        calculated_shipping_costs?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      declinedTourDates?: Array< string | null > | null,
      billed_amount?: number | null,
      in_progressAt?: string | null,
      ready_to_packAt?: string | null,
      finishedAt?: string | null,
      updatedAt?: string | null,
      billedAt?: string | null,
      recievedReturnAt?: string | null,
      reklaOrderNumber?: string | null,
      retoure_type?: RetoureType | null,
      retoure_reason?: string | null,
      retoure_manufacturing_fee?: number | null,
      retoure_refund_amount?: number | null,
      retoure_refund_contractors_share?: number | null,
      retoure_shipment?:  {
        __typename: "Shipment",
        id: string,
        typename?: string | null,
        length: number,
        width: number,
        height: number,
        gurtMass: number,
        weight: number,
        serviceProvider: string,
        costs: number,
        calculatedCosts?: number | null,
        shippedAt?: string | null,
        edited?: string | null,
        parcelNumber?: string | null,
        trackID?: string | null,
        suggest_splitting?: boolean | null,
        overrule_splitting_user?: string | null,
        labelData?:  {
          __typename: "LabelData",
          base64?: string | null,
          format?: LabelFormat | null,
        } | null,
        updatedAt?: string | null,
        createdAt: string,
      } | null,
      createdAt: string,
      orderProductsId?: string | null,
      tourProductsId?: string | null,
      productShipmentId?: string | null,
      productRetoure_shipmentId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ShipmentsByUpdateDateQueryVariables = {
  typename: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShipmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ShipmentsByUpdateDateQuery = {
  shipmentsByUpdateDate?:  {
    __typename: "ModelShipmentConnection",
    items:  Array< {
      __typename: "Shipment",
      id: string,
      typename?: string | null,
      length: number,
      width: number,
      height: number,
      gurtMass: number,
      weight: number,
      serviceProvider: string,
      costs: number,
      calculatedCosts?: number | null,
      shippedAt?: string | null,
      edited?: string | null,
      parcelNumber?: string | null,
      trackID?: string | null,
      suggest_splitting?: boolean | null,
      overrule_splitting_user?: string | null,
      labelData?:  {
        __typename: "LabelData",
        base64?: string | null,
        format?: LabelFormat | null,
      } | null,
      updatedAt?: string | null,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ToursByDateQueryVariables = {
  typename: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTourFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ToursByDateQuery = {
  toursByDate?:  {
    __typename: "ModelTourConnection",
    items:  Array< {
      __typename: "Tour",
      id: string,
      typename?: string | null,
      date: string,
      products?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          position?: number | null,
          typename?: string | null,
          name: string,
          sku?: string | null,
          product_id?: string | null,
          variation_id?: string | null,
          quantity?: number | null,
          length?: number | null,
          width?: number | null,
          height?: number | null,
          area?: number | null,
          weight?: string | null,
          categories?: Array< string | null > | null,
          variations?: Array< string | null > | null,
          options?: Array< string | null > | null,
          tasks?: Array< string | null > | null,
          currentTask?: string | null,
          contractor_currentTask?: string | null,
          productContractorId?: string | null,
          status?: ProductStatus | null,
          contractor_status: string,
          shipping_label_printed?: boolean | null,
          orderNumber?: string | null,
          productOrderId: string,
          backoffice_note?: string | null,
          total_before_discount?: number | null,
          total_net_price?: number | null,
          total_tax?: number | null,
          nipsild_fixed_costs?: number | null,
          delivery_time?: string | null,
          delivery_date: string,
          declinedTourDates?: Array< string | null > | null,
          billed_amount?: number | null,
          in_progressAt?: string | null,
          ready_to_packAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          billedAt?: string | null,
          recievedReturnAt?: string | null,
          reklaOrderNumber?: string | null,
          retoure_type?: RetoureType | null,
          retoure_reason?: string | null,
          retoure_manufacturing_fee?: number | null,
          retoure_refund_amount?: number | null,
          retoure_refund_contractors_share?: number | null,
          createdAt: string,
          orderProductsId?: string | null,
          tourProductsId?: string | null,
          productShipmentId?: string | null,
          productRetoure_shipmentId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      deliveryNotificationSentTo?: Array< string | null > | null,
      info?: string | null,
      driver?: string | null,
      calculated_shipping_costs?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContractorByNumberQueryVariables = {
  number: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContractorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContractorByNumberQuery = {
  contractorByNumber?:  {
    __typename: "ModelContractorConnection",
    items:  Array< {
      __typename: "Contractor",
      id: string,
      number: string,
      name: string,
      billing_rate?: number | null,
      billing_max?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOrderFilterInput | null,
};

export type OnCreateOrderSubscription = {
  onCreateOrder?:  {
    __typename: "Order",
    id: string,
    typename?: string | null,
    orderNumber: string,
    date_created: string,
    date_paid: string,
    total?: number | null,
    cart_tax?: number | null,
    total_tax?: number | null,
    discount_total?: number | null,
    discount_tax?: number | null,
    shipping_total?: number | null,
    shipping_tax?: number | null,
    fees?:  Array< {
      __typename: "Fee",
      name?: string | null,
      amount?: number | null,
      total?: number | null,
      total_tax?: number | null,
    } | null > | null,
    weight?: number | null,
    customer:  {
      __typename: "Customer",
      first_name: string,
      last_name: string,
      street?: string | null,
      house_number?: string | null,
      zip?: string | null,
      city?: string | null,
      country?: string | null,
      email?: string | null,
      phone?: string | null,
      company?: string | null,
      add?: string | null,
    },
    shipping_method?: string | null,
    delivery_date: string,
    status?: OrderStatus | null,
    state?: OrderState | null,
    source: OrderSource,
    in_progressAt?: string | null,
    finishedAt?: string | null,
    updatedAt?: string | null,
    retoure_shipping_costs?: number | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        position?: number | null,
        typename?: string | null,
        name: string,
        sku?: string | null,
        product_id?: string | null,
        variation_id?: string | null,
        quantity?: number | null,
        length?: number | null,
        width?: number | null,
        height?: number | null,
        area?: number | null,
        weight?: string | null,
        categories?: Array< string | null > | null,
        variations?: Array< string | null > | null,
        options?: Array< string | null > | null,
        extraOptions?:  Array< {
          __typename: "Option",
          name?: string | null,
          value: string,
          imagesp?: string | null,
          price?: number | null,
        } | null > | null,
        tasks?: Array< string | null > | null,
        currentTask?: string | null,
        contractor_currentTask?: string | null,
        productContractorId?: string | null,
        contractor?:  {
          __typename: "Contractor",
          id: string,
          number: string,
          name: string,
          billing_rate?: number | null,
          billing_max?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        status?: ProductStatus | null,
        contractor_status: string,
        shipping_label_printed?: boolean | null,
        orderNumber?: string | null,
        order?:  {
          __typename: "Order",
          id: string,
          typename?: string | null,
          orderNumber: string,
          date_created: string,
          date_paid: string,
          total?: number | null,
          cart_tax?: number | null,
          total_tax?: number | null,
          discount_total?: number | null,
          discount_tax?: number | null,
          shipping_total?: number | null,
          shipping_tax?: number | null,
          weight?: number | null,
          shipping_method?: string | null,
          delivery_date: string,
          status?: OrderStatus | null,
          state?: OrderState | null,
          source: OrderSource,
          in_progressAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          retoure_shipping_costs?: number | null,
          createdAt: string,
        } | null,
        productOrderId: string,
        backoffice_note?: string | null,
        total_before_discount?: number | null,
        total_net_price?: number | null,
        total_tax?: number | null,
        nipsild_fixed_costs?: number | null,
        delivery_time?: string | null,
        delivery_date: string,
        shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        tour?:  {
          __typename: "Tour",
          id: string,
          typename?: string | null,
          date: string,
          deliveryNotificationSentTo?: Array< string | null > | null,
          info?: string | null,
          driver?: string | null,
          calculated_shipping_costs?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        declinedTourDates?: Array< string | null > | null,
        billed_amount?: number | null,
        in_progressAt?: string | null,
        ready_to_packAt?: string | null,
        finishedAt?: string | null,
        updatedAt?: string | null,
        billedAt?: string | null,
        recievedReturnAt?: string | null,
        reklaOrderNumber?: string | null,
        retoure_type?: RetoureType | null,
        retoure_reason?: string | null,
        retoure_manufacturing_fee?: number | null,
        retoure_refund_amount?: number | null,
        retoure_refund_contractors_share?: number | null,
        retoure_shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        createdAt: string,
        orderProductsId?: string | null,
        tourProductsId?: string | null,
        productShipmentId?: string | null,
        productRetoure_shipmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
  } | null,
};

export type OnUpdateOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOrderFilterInput | null,
};

export type OnUpdateOrderSubscription = {
  onUpdateOrder?:  {
    __typename: "Order",
    id: string,
    typename?: string | null,
    orderNumber: string,
    date_created: string,
    date_paid: string,
    total?: number | null,
    cart_tax?: number | null,
    total_tax?: number | null,
    discount_total?: number | null,
    discount_tax?: number | null,
    shipping_total?: number | null,
    shipping_tax?: number | null,
    fees?:  Array< {
      __typename: "Fee",
      name?: string | null,
      amount?: number | null,
      total?: number | null,
      total_tax?: number | null,
    } | null > | null,
    weight?: number | null,
    customer:  {
      __typename: "Customer",
      first_name: string,
      last_name: string,
      street?: string | null,
      house_number?: string | null,
      zip?: string | null,
      city?: string | null,
      country?: string | null,
      email?: string | null,
      phone?: string | null,
      company?: string | null,
      add?: string | null,
    },
    shipping_method?: string | null,
    delivery_date: string,
    status?: OrderStatus | null,
    state?: OrderState | null,
    source: OrderSource,
    in_progressAt?: string | null,
    finishedAt?: string | null,
    updatedAt?: string | null,
    retoure_shipping_costs?: number | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        position?: number | null,
        typename?: string | null,
        name: string,
        sku?: string | null,
        product_id?: string | null,
        variation_id?: string | null,
        quantity?: number | null,
        length?: number | null,
        width?: number | null,
        height?: number | null,
        area?: number | null,
        weight?: string | null,
        categories?: Array< string | null > | null,
        variations?: Array< string | null > | null,
        options?: Array< string | null > | null,
        extraOptions?:  Array< {
          __typename: "Option",
          name?: string | null,
          value: string,
          imagesp?: string | null,
          price?: number | null,
        } | null > | null,
        tasks?: Array< string | null > | null,
        currentTask?: string | null,
        contractor_currentTask?: string | null,
        productContractorId?: string | null,
        contractor?:  {
          __typename: "Contractor",
          id: string,
          number: string,
          name: string,
          billing_rate?: number | null,
          billing_max?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        status?: ProductStatus | null,
        contractor_status: string,
        shipping_label_printed?: boolean | null,
        orderNumber?: string | null,
        order?:  {
          __typename: "Order",
          id: string,
          typename?: string | null,
          orderNumber: string,
          date_created: string,
          date_paid: string,
          total?: number | null,
          cart_tax?: number | null,
          total_tax?: number | null,
          discount_total?: number | null,
          discount_tax?: number | null,
          shipping_total?: number | null,
          shipping_tax?: number | null,
          weight?: number | null,
          shipping_method?: string | null,
          delivery_date: string,
          status?: OrderStatus | null,
          state?: OrderState | null,
          source: OrderSource,
          in_progressAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          retoure_shipping_costs?: number | null,
          createdAt: string,
        } | null,
        productOrderId: string,
        backoffice_note?: string | null,
        total_before_discount?: number | null,
        total_net_price?: number | null,
        total_tax?: number | null,
        nipsild_fixed_costs?: number | null,
        delivery_time?: string | null,
        delivery_date: string,
        shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        tour?:  {
          __typename: "Tour",
          id: string,
          typename?: string | null,
          date: string,
          deliveryNotificationSentTo?: Array< string | null > | null,
          info?: string | null,
          driver?: string | null,
          calculated_shipping_costs?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        declinedTourDates?: Array< string | null > | null,
        billed_amount?: number | null,
        in_progressAt?: string | null,
        ready_to_packAt?: string | null,
        finishedAt?: string | null,
        updatedAt?: string | null,
        billedAt?: string | null,
        recievedReturnAt?: string | null,
        reklaOrderNumber?: string | null,
        retoure_type?: RetoureType | null,
        retoure_reason?: string | null,
        retoure_manufacturing_fee?: number | null,
        retoure_refund_amount?: number | null,
        retoure_refund_contractors_share?: number | null,
        retoure_shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        createdAt: string,
        orderProductsId?: string | null,
        tourProductsId?: string | null,
        productShipmentId?: string | null,
        productRetoure_shipmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
  } | null,
};

export type OnDeleteOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOrderFilterInput | null,
};

export type OnDeleteOrderSubscription = {
  onDeleteOrder?:  {
    __typename: "Order",
    id: string,
    typename?: string | null,
    orderNumber: string,
    date_created: string,
    date_paid: string,
    total?: number | null,
    cart_tax?: number | null,
    total_tax?: number | null,
    discount_total?: number | null,
    discount_tax?: number | null,
    shipping_total?: number | null,
    shipping_tax?: number | null,
    fees?:  Array< {
      __typename: "Fee",
      name?: string | null,
      amount?: number | null,
      total?: number | null,
      total_tax?: number | null,
    } | null > | null,
    weight?: number | null,
    customer:  {
      __typename: "Customer",
      first_name: string,
      last_name: string,
      street?: string | null,
      house_number?: string | null,
      zip?: string | null,
      city?: string | null,
      country?: string | null,
      email?: string | null,
      phone?: string | null,
      company?: string | null,
      add?: string | null,
    },
    shipping_method?: string | null,
    delivery_date: string,
    status?: OrderStatus | null,
    state?: OrderState | null,
    source: OrderSource,
    in_progressAt?: string | null,
    finishedAt?: string | null,
    updatedAt?: string | null,
    retoure_shipping_costs?: number | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        position?: number | null,
        typename?: string | null,
        name: string,
        sku?: string | null,
        product_id?: string | null,
        variation_id?: string | null,
        quantity?: number | null,
        length?: number | null,
        width?: number | null,
        height?: number | null,
        area?: number | null,
        weight?: string | null,
        categories?: Array< string | null > | null,
        variations?: Array< string | null > | null,
        options?: Array< string | null > | null,
        extraOptions?:  Array< {
          __typename: "Option",
          name?: string | null,
          value: string,
          imagesp?: string | null,
          price?: number | null,
        } | null > | null,
        tasks?: Array< string | null > | null,
        currentTask?: string | null,
        contractor_currentTask?: string | null,
        productContractorId?: string | null,
        contractor?:  {
          __typename: "Contractor",
          id: string,
          number: string,
          name: string,
          billing_rate?: number | null,
          billing_max?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        status?: ProductStatus | null,
        contractor_status: string,
        shipping_label_printed?: boolean | null,
        orderNumber?: string | null,
        order?:  {
          __typename: "Order",
          id: string,
          typename?: string | null,
          orderNumber: string,
          date_created: string,
          date_paid: string,
          total?: number | null,
          cart_tax?: number | null,
          total_tax?: number | null,
          discount_total?: number | null,
          discount_tax?: number | null,
          shipping_total?: number | null,
          shipping_tax?: number | null,
          weight?: number | null,
          shipping_method?: string | null,
          delivery_date: string,
          status?: OrderStatus | null,
          state?: OrderState | null,
          source: OrderSource,
          in_progressAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          retoure_shipping_costs?: number | null,
          createdAt: string,
        } | null,
        productOrderId: string,
        backoffice_note?: string | null,
        total_before_discount?: number | null,
        total_net_price?: number | null,
        total_tax?: number | null,
        nipsild_fixed_costs?: number | null,
        delivery_time?: string | null,
        delivery_date: string,
        shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        tour?:  {
          __typename: "Tour",
          id: string,
          typename?: string | null,
          date: string,
          deliveryNotificationSentTo?: Array< string | null > | null,
          info?: string | null,
          driver?: string | null,
          calculated_shipping_costs?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        declinedTourDates?: Array< string | null > | null,
        billed_amount?: number | null,
        in_progressAt?: string | null,
        ready_to_packAt?: string | null,
        finishedAt?: string | null,
        updatedAt?: string | null,
        billedAt?: string | null,
        recievedReturnAt?: string | null,
        reklaOrderNumber?: string | null,
        retoure_type?: RetoureType | null,
        retoure_reason?: string | null,
        retoure_manufacturing_fee?: number | null,
        retoure_refund_amount?: number | null,
        retoure_refund_contractors_share?: number | null,
        retoure_shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        createdAt: string,
        orderProductsId?: string | null,
        tourProductsId?: string | null,
        productShipmentId?: string | null,
        productRetoure_shipmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
  } | null,
};

export type OnCreateProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
};

export type OnCreateProductSubscription = {
  onCreateProduct?:  {
    __typename: "Product",
    id: string,
    position?: number | null,
    typename?: string | null,
    name: string,
    sku?: string | null,
    product_id?: string | null,
    variation_id?: string | null,
    quantity?: number | null,
    length?: number | null,
    width?: number | null,
    height?: number | null,
    area?: number | null,
    weight?: string | null,
    categories?: Array< string | null > | null,
    variations?: Array< string | null > | null,
    options?: Array< string | null > | null,
    extraOptions?:  Array< {
      __typename: "Option",
      name?: string | null,
      value: string,
      imagesp?: string | null,
      price?: number | null,
    } | null > | null,
    tasks?: Array< string | null > | null,
    currentTask?: string | null,
    contractor_currentTask?: string | null,
    productContractorId?: string | null,
    contractor?:  {
      __typename: "Contractor",
      id: string,
      number: string,
      name: string,
      billing_rate?: number | null,
      billing_max?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: ProductStatus | null,
    contractor_status: string,
    shipping_label_printed?: boolean | null,
    orderNumber?: string | null,
    order?:  {
      __typename: "Order",
      id: string,
      typename?: string | null,
      orderNumber: string,
      date_created: string,
      date_paid: string,
      total?: number | null,
      cart_tax?: number | null,
      total_tax?: number | null,
      discount_total?: number | null,
      discount_tax?: number | null,
      shipping_total?: number | null,
      shipping_tax?: number | null,
      fees?:  Array< {
        __typename: "Fee",
        name?: string | null,
        amount?: number | null,
        total?: number | null,
        total_tax?: number | null,
      } | null > | null,
      weight?: number | null,
      customer:  {
        __typename: "Customer",
        first_name: string,
        last_name: string,
        street?: string | null,
        house_number?: string | null,
        zip?: string | null,
        city?: string | null,
        country?: string | null,
        email?: string | null,
        phone?: string | null,
        company?: string | null,
        add?: string | null,
      },
      shipping_method?: string | null,
      delivery_date: string,
      status?: OrderStatus | null,
      state?: OrderState | null,
      source: OrderSource,
      in_progressAt?: string | null,
      finishedAt?: string | null,
      updatedAt?: string | null,
      retoure_shipping_costs?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          position?: number | null,
          typename?: string | null,
          name: string,
          sku?: string | null,
          product_id?: string | null,
          variation_id?: string | null,
          quantity?: number | null,
          length?: number | null,
          width?: number | null,
          height?: number | null,
          area?: number | null,
          weight?: string | null,
          categories?: Array< string | null > | null,
          variations?: Array< string | null > | null,
          options?: Array< string | null > | null,
          tasks?: Array< string | null > | null,
          currentTask?: string | null,
          contractor_currentTask?: string | null,
          productContractorId?: string | null,
          status?: ProductStatus | null,
          contractor_status: string,
          shipping_label_printed?: boolean | null,
          orderNumber?: string | null,
          productOrderId: string,
          backoffice_note?: string | null,
          total_before_discount?: number | null,
          total_net_price?: number | null,
          total_tax?: number | null,
          nipsild_fixed_costs?: number | null,
          delivery_time?: string | null,
          delivery_date: string,
          declinedTourDates?: Array< string | null > | null,
          billed_amount?: number | null,
          in_progressAt?: string | null,
          ready_to_packAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          billedAt?: string | null,
          recievedReturnAt?: string | null,
          reklaOrderNumber?: string | null,
          retoure_type?: RetoureType | null,
          retoure_reason?: string | null,
          retoure_manufacturing_fee?: number | null,
          retoure_refund_amount?: number | null,
          retoure_refund_contractors_share?: number | null,
          createdAt: string,
          orderProductsId?: string | null,
          tourProductsId?: string | null,
          productShipmentId?: string | null,
          productRetoure_shipmentId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
    } | null,
    productOrderId: string,
    backoffice_note?: string | null,
    total_before_discount?: number | null,
    total_net_price?: number | null,
    total_tax?: number | null,
    nipsild_fixed_costs?: number | null,
    delivery_time?: string | null,
    delivery_date: string,
    shipment?:  {
      __typename: "Shipment",
      id: string,
      typename?: string | null,
      length: number,
      width: number,
      height: number,
      gurtMass: number,
      weight: number,
      serviceProvider: string,
      costs: number,
      calculatedCosts?: number | null,
      shippedAt?: string | null,
      edited?: string | null,
      parcelNumber?: string | null,
      trackID?: string | null,
      suggest_splitting?: boolean | null,
      overrule_splitting_user?: string | null,
      labelData?:  {
        __typename: "LabelData",
        base64?: string | null,
        format?: LabelFormat | null,
      } | null,
      updatedAt?: string | null,
      createdAt: string,
    } | null,
    tour?:  {
      __typename: "Tour",
      id: string,
      typename?: string | null,
      date: string,
      products?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          position?: number | null,
          typename?: string | null,
          name: string,
          sku?: string | null,
          product_id?: string | null,
          variation_id?: string | null,
          quantity?: number | null,
          length?: number | null,
          width?: number | null,
          height?: number | null,
          area?: number | null,
          weight?: string | null,
          categories?: Array< string | null > | null,
          variations?: Array< string | null > | null,
          options?: Array< string | null > | null,
          tasks?: Array< string | null > | null,
          currentTask?: string | null,
          contractor_currentTask?: string | null,
          productContractorId?: string | null,
          status?: ProductStatus | null,
          contractor_status: string,
          shipping_label_printed?: boolean | null,
          orderNumber?: string | null,
          productOrderId: string,
          backoffice_note?: string | null,
          total_before_discount?: number | null,
          total_net_price?: number | null,
          total_tax?: number | null,
          nipsild_fixed_costs?: number | null,
          delivery_time?: string | null,
          delivery_date: string,
          declinedTourDates?: Array< string | null > | null,
          billed_amount?: number | null,
          in_progressAt?: string | null,
          ready_to_packAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          billedAt?: string | null,
          recievedReturnAt?: string | null,
          reklaOrderNumber?: string | null,
          retoure_type?: RetoureType | null,
          retoure_reason?: string | null,
          retoure_manufacturing_fee?: number | null,
          retoure_refund_amount?: number | null,
          retoure_refund_contractors_share?: number | null,
          createdAt: string,
          orderProductsId?: string | null,
          tourProductsId?: string | null,
          productShipmentId?: string | null,
          productRetoure_shipmentId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      deliveryNotificationSentTo?: Array< string | null > | null,
      info?: string | null,
      driver?: string | null,
      calculated_shipping_costs?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    declinedTourDates?: Array< string | null > | null,
    billed_amount?: number | null,
    in_progressAt?: string | null,
    ready_to_packAt?: string | null,
    finishedAt?: string | null,
    updatedAt?: string | null,
    billedAt?: string | null,
    recievedReturnAt?: string | null,
    reklaOrderNumber?: string | null,
    retoure_type?: RetoureType | null,
    retoure_reason?: string | null,
    retoure_manufacturing_fee?: number | null,
    retoure_refund_amount?: number | null,
    retoure_refund_contractors_share?: number | null,
    retoure_shipment?:  {
      __typename: "Shipment",
      id: string,
      typename?: string | null,
      length: number,
      width: number,
      height: number,
      gurtMass: number,
      weight: number,
      serviceProvider: string,
      costs: number,
      calculatedCosts?: number | null,
      shippedAt?: string | null,
      edited?: string | null,
      parcelNumber?: string | null,
      trackID?: string | null,
      suggest_splitting?: boolean | null,
      overrule_splitting_user?: string | null,
      labelData?:  {
        __typename: "LabelData",
        base64?: string | null,
        format?: LabelFormat | null,
      } | null,
      updatedAt?: string | null,
      createdAt: string,
    } | null,
    createdAt: string,
    orderProductsId?: string | null,
    tourProductsId?: string | null,
    productShipmentId?: string | null,
    productRetoure_shipmentId?: string | null,
  } | null,
};

export type OnUpdateProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
};

export type OnUpdateProductSubscription = {
  onUpdateProduct?:  {
    __typename: "Product",
    id: string,
    position?: number | null,
    typename?: string | null,
    name: string,
    sku?: string | null,
    product_id?: string | null,
    variation_id?: string | null,
    quantity?: number | null,
    length?: number | null,
    width?: number | null,
    height?: number | null,
    area?: number | null,
    weight?: string | null,
    categories?: Array< string | null > | null,
    variations?: Array< string | null > | null,
    options?: Array< string | null > | null,
    extraOptions?:  Array< {
      __typename: "Option",
      name?: string | null,
      value: string,
      imagesp?: string | null,
      price?: number | null,
    } | null > | null,
    tasks?: Array< string | null > | null,
    currentTask?: string | null,
    contractor_currentTask?: string | null,
    productContractorId?: string | null,
    contractor?:  {
      __typename: "Contractor",
      id: string,
      number: string,
      name: string,
      billing_rate?: number | null,
      billing_max?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: ProductStatus | null,
    contractor_status: string,
    shipping_label_printed?: boolean | null,
    orderNumber?: string | null,
    order?:  {
      __typename: "Order",
      id: string,
      typename?: string | null,
      orderNumber: string,
      date_created: string,
      date_paid: string,
      total?: number | null,
      cart_tax?: number | null,
      total_tax?: number | null,
      discount_total?: number | null,
      discount_tax?: number | null,
      shipping_total?: number | null,
      shipping_tax?: number | null,
      fees?:  Array< {
        __typename: "Fee",
        name?: string | null,
        amount?: number | null,
        total?: number | null,
        total_tax?: number | null,
      } | null > | null,
      weight?: number | null,
      customer:  {
        __typename: "Customer",
        first_name: string,
        last_name: string,
        street?: string | null,
        house_number?: string | null,
        zip?: string | null,
        city?: string | null,
        country?: string | null,
        email?: string | null,
        phone?: string | null,
        company?: string | null,
        add?: string | null,
      },
      shipping_method?: string | null,
      delivery_date: string,
      status?: OrderStatus | null,
      state?: OrderState | null,
      source: OrderSource,
      in_progressAt?: string | null,
      finishedAt?: string | null,
      updatedAt?: string | null,
      retoure_shipping_costs?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          position?: number | null,
          typename?: string | null,
          name: string,
          sku?: string | null,
          product_id?: string | null,
          variation_id?: string | null,
          quantity?: number | null,
          length?: number | null,
          width?: number | null,
          height?: number | null,
          area?: number | null,
          weight?: string | null,
          categories?: Array< string | null > | null,
          variations?: Array< string | null > | null,
          options?: Array< string | null > | null,
          tasks?: Array< string | null > | null,
          currentTask?: string | null,
          contractor_currentTask?: string | null,
          productContractorId?: string | null,
          status?: ProductStatus | null,
          contractor_status: string,
          shipping_label_printed?: boolean | null,
          orderNumber?: string | null,
          productOrderId: string,
          backoffice_note?: string | null,
          total_before_discount?: number | null,
          total_net_price?: number | null,
          total_tax?: number | null,
          nipsild_fixed_costs?: number | null,
          delivery_time?: string | null,
          delivery_date: string,
          declinedTourDates?: Array< string | null > | null,
          billed_amount?: number | null,
          in_progressAt?: string | null,
          ready_to_packAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          billedAt?: string | null,
          recievedReturnAt?: string | null,
          reklaOrderNumber?: string | null,
          retoure_type?: RetoureType | null,
          retoure_reason?: string | null,
          retoure_manufacturing_fee?: number | null,
          retoure_refund_amount?: number | null,
          retoure_refund_contractors_share?: number | null,
          createdAt: string,
          orderProductsId?: string | null,
          tourProductsId?: string | null,
          productShipmentId?: string | null,
          productRetoure_shipmentId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
    } | null,
    productOrderId: string,
    backoffice_note?: string | null,
    total_before_discount?: number | null,
    total_net_price?: number | null,
    total_tax?: number | null,
    nipsild_fixed_costs?: number | null,
    delivery_time?: string | null,
    delivery_date: string,
    shipment?:  {
      __typename: "Shipment",
      id: string,
      typename?: string | null,
      length: number,
      width: number,
      height: number,
      gurtMass: number,
      weight: number,
      serviceProvider: string,
      costs: number,
      calculatedCosts?: number | null,
      shippedAt?: string | null,
      edited?: string | null,
      parcelNumber?: string | null,
      trackID?: string | null,
      suggest_splitting?: boolean | null,
      overrule_splitting_user?: string | null,
      labelData?:  {
        __typename: "LabelData",
        base64?: string | null,
        format?: LabelFormat | null,
      } | null,
      updatedAt?: string | null,
      createdAt: string,
    } | null,
    tour?:  {
      __typename: "Tour",
      id: string,
      typename?: string | null,
      date: string,
      products?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          position?: number | null,
          typename?: string | null,
          name: string,
          sku?: string | null,
          product_id?: string | null,
          variation_id?: string | null,
          quantity?: number | null,
          length?: number | null,
          width?: number | null,
          height?: number | null,
          area?: number | null,
          weight?: string | null,
          categories?: Array< string | null > | null,
          variations?: Array< string | null > | null,
          options?: Array< string | null > | null,
          tasks?: Array< string | null > | null,
          currentTask?: string | null,
          contractor_currentTask?: string | null,
          productContractorId?: string | null,
          status?: ProductStatus | null,
          contractor_status: string,
          shipping_label_printed?: boolean | null,
          orderNumber?: string | null,
          productOrderId: string,
          backoffice_note?: string | null,
          total_before_discount?: number | null,
          total_net_price?: number | null,
          total_tax?: number | null,
          nipsild_fixed_costs?: number | null,
          delivery_time?: string | null,
          delivery_date: string,
          declinedTourDates?: Array< string | null > | null,
          billed_amount?: number | null,
          in_progressAt?: string | null,
          ready_to_packAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          billedAt?: string | null,
          recievedReturnAt?: string | null,
          reklaOrderNumber?: string | null,
          retoure_type?: RetoureType | null,
          retoure_reason?: string | null,
          retoure_manufacturing_fee?: number | null,
          retoure_refund_amount?: number | null,
          retoure_refund_contractors_share?: number | null,
          createdAt: string,
          orderProductsId?: string | null,
          tourProductsId?: string | null,
          productShipmentId?: string | null,
          productRetoure_shipmentId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      deliveryNotificationSentTo?: Array< string | null > | null,
      info?: string | null,
      driver?: string | null,
      calculated_shipping_costs?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    declinedTourDates?: Array< string | null > | null,
    billed_amount?: number | null,
    in_progressAt?: string | null,
    ready_to_packAt?: string | null,
    finishedAt?: string | null,
    updatedAt?: string | null,
    billedAt?: string | null,
    recievedReturnAt?: string | null,
    reklaOrderNumber?: string | null,
    retoure_type?: RetoureType | null,
    retoure_reason?: string | null,
    retoure_manufacturing_fee?: number | null,
    retoure_refund_amount?: number | null,
    retoure_refund_contractors_share?: number | null,
    retoure_shipment?:  {
      __typename: "Shipment",
      id: string,
      typename?: string | null,
      length: number,
      width: number,
      height: number,
      gurtMass: number,
      weight: number,
      serviceProvider: string,
      costs: number,
      calculatedCosts?: number | null,
      shippedAt?: string | null,
      edited?: string | null,
      parcelNumber?: string | null,
      trackID?: string | null,
      suggest_splitting?: boolean | null,
      overrule_splitting_user?: string | null,
      labelData?:  {
        __typename: "LabelData",
        base64?: string | null,
        format?: LabelFormat | null,
      } | null,
      updatedAt?: string | null,
      createdAt: string,
    } | null,
    createdAt: string,
    orderProductsId?: string | null,
    tourProductsId?: string | null,
    productShipmentId?: string | null,
    productRetoure_shipmentId?: string | null,
  } | null,
};

export type OnDeleteProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
};

export type OnDeleteProductSubscription = {
  onDeleteProduct?:  {
    __typename: "Product",
    id: string,
    position?: number | null,
    typename?: string | null,
    name: string,
    sku?: string | null,
    product_id?: string | null,
    variation_id?: string | null,
    quantity?: number | null,
    length?: number | null,
    width?: number | null,
    height?: number | null,
    area?: number | null,
    weight?: string | null,
    categories?: Array< string | null > | null,
    variations?: Array< string | null > | null,
    options?: Array< string | null > | null,
    extraOptions?:  Array< {
      __typename: "Option",
      name?: string | null,
      value: string,
      imagesp?: string | null,
      price?: number | null,
    } | null > | null,
    tasks?: Array< string | null > | null,
    currentTask?: string | null,
    contractor_currentTask?: string | null,
    productContractorId?: string | null,
    contractor?:  {
      __typename: "Contractor",
      id: string,
      number: string,
      name: string,
      billing_rate?: number | null,
      billing_max?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: ProductStatus | null,
    contractor_status: string,
    shipping_label_printed?: boolean | null,
    orderNumber?: string | null,
    order?:  {
      __typename: "Order",
      id: string,
      typename?: string | null,
      orderNumber: string,
      date_created: string,
      date_paid: string,
      total?: number | null,
      cart_tax?: number | null,
      total_tax?: number | null,
      discount_total?: number | null,
      discount_tax?: number | null,
      shipping_total?: number | null,
      shipping_tax?: number | null,
      fees?:  Array< {
        __typename: "Fee",
        name?: string | null,
        amount?: number | null,
        total?: number | null,
        total_tax?: number | null,
      } | null > | null,
      weight?: number | null,
      customer:  {
        __typename: "Customer",
        first_name: string,
        last_name: string,
        street?: string | null,
        house_number?: string | null,
        zip?: string | null,
        city?: string | null,
        country?: string | null,
        email?: string | null,
        phone?: string | null,
        company?: string | null,
        add?: string | null,
      },
      shipping_method?: string | null,
      delivery_date: string,
      status?: OrderStatus | null,
      state?: OrderState | null,
      source: OrderSource,
      in_progressAt?: string | null,
      finishedAt?: string | null,
      updatedAt?: string | null,
      retoure_shipping_costs?: number | null,
      products?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          position?: number | null,
          typename?: string | null,
          name: string,
          sku?: string | null,
          product_id?: string | null,
          variation_id?: string | null,
          quantity?: number | null,
          length?: number | null,
          width?: number | null,
          height?: number | null,
          area?: number | null,
          weight?: string | null,
          categories?: Array< string | null > | null,
          variations?: Array< string | null > | null,
          options?: Array< string | null > | null,
          tasks?: Array< string | null > | null,
          currentTask?: string | null,
          contractor_currentTask?: string | null,
          productContractorId?: string | null,
          status?: ProductStatus | null,
          contractor_status: string,
          shipping_label_printed?: boolean | null,
          orderNumber?: string | null,
          productOrderId: string,
          backoffice_note?: string | null,
          total_before_discount?: number | null,
          total_net_price?: number | null,
          total_tax?: number | null,
          nipsild_fixed_costs?: number | null,
          delivery_time?: string | null,
          delivery_date: string,
          declinedTourDates?: Array< string | null > | null,
          billed_amount?: number | null,
          in_progressAt?: string | null,
          ready_to_packAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          billedAt?: string | null,
          recievedReturnAt?: string | null,
          reklaOrderNumber?: string | null,
          retoure_type?: RetoureType | null,
          retoure_reason?: string | null,
          retoure_manufacturing_fee?: number | null,
          retoure_refund_amount?: number | null,
          retoure_refund_contractors_share?: number | null,
          createdAt: string,
          orderProductsId?: string | null,
          tourProductsId?: string | null,
          productShipmentId?: string | null,
          productRetoure_shipmentId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
    } | null,
    productOrderId: string,
    backoffice_note?: string | null,
    total_before_discount?: number | null,
    total_net_price?: number | null,
    total_tax?: number | null,
    nipsild_fixed_costs?: number | null,
    delivery_time?: string | null,
    delivery_date: string,
    shipment?:  {
      __typename: "Shipment",
      id: string,
      typename?: string | null,
      length: number,
      width: number,
      height: number,
      gurtMass: number,
      weight: number,
      serviceProvider: string,
      costs: number,
      calculatedCosts?: number | null,
      shippedAt?: string | null,
      edited?: string | null,
      parcelNumber?: string | null,
      trackID?: string | null,
      suggest_splitting?: boolean | null,
      overrule_splitting_user?: string | null,
      labelData?:  {
        __typename: "LabelData",
        base64?: string | null,
        format?: LabelFormat | null,
      } | null,
      updatedAt?: string | null,
      createdAt: string,
    } | null,
    tour?:  {
      __typename: "Tour",
      id: string,
      typename?: string | null,
      date: string,
      products?:  {
        __typename: "ModelProductConnection",
        items:  Array< {
          __typename: "Product",
          id: string,
          position?: number | null,
          typename?: string | null,
          name: string,
          sku?: string | null,
          product_id?: string | null,
          variation_id?: string | null,
          quantity?: number | null,
          length?: number | null,
          width?: number | null,
          height?: number | null,
          area?: number | null,
          weight?: string | null,
          categories?: Array< string | null > | null,
          variations?: Array< string | null > | null,
          options?: Array< string | null > | null,
          tasks?: Array< string | null > | null,
          currentTask?: string | null,
          contractor_currentTask?: string | null,
          productContractorId?: string | null,
          status?: ProductStatus | null,
          contractor_status: string,
          shipping_label_printed?: boolean | null,
          orderNumber?: string | null,
          productOrderId: string,
          backoffice_note?: string | null,
          total_before_discount?: number | null,
          total_net_price?: number | null,
          total_tax?: number | null,
          nipsild_fixed_costs?: number | null,
          delivery_time?: string | null,
          delivery_date: string,
          declinedTourDates?: Array< string | null > | null,
          billed_amount?: number | null,
          in_progressAt?: string | null,
          ready_to_packAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          billedAt?: string | null,
          recievedReturnAt?: string | null,
          reklaOrderNumber?: string | null,
          retoure_type?: RetoureType | null,
          retoure_reason?: string | null,
          retoure_manufacturing_fee?: number | null,
          retoure_refund_amount?: number | null,
          retoure_refund_contractors_share?: number | null,
          createdAt: string,
          orderProductsId?: string | null,
          tourProductsId?: string | null,
          productShipmentId?: string | null,
          productRetoure_shipmentId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      deliveryNotificationSentTo?: Array< string | null > | null,
      info?: string | null,
      driver?: string | null,
      calculated_shipping_costs?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    declinedTourDates?: Array< string | null > | null,
    billed_amount?: number | null,
    in_progressAt?: string | null,
    ready_to_packAt?: string | null,
    finishedAt?: string | null,
    updatedAt?: string | null,
    billedAt?: string | null,
    recievedReturnAt?: string | null,
    reklaOrderNumber?: string | null,
    retoure_type?: RetoureType | null,
    retoure_reason?: string | null,
    retoure_manufacturing_fee?: number | null,
    retoure_refund_amount?: number | null,
    retoure_refund_contractors_share?: number | null,
    retoure_shipment?:  {
      __typename: "Shipment",
      id: string,
      typename?: string | null,
      length: number,
      width: number,
      height: number,
      gurtMass: number,
      weight: number,
      serviceProvider: string,
      costs: number,
      calculatedCosts?: number | null,
      shippedAt?: string | null,
      edited?: string | null,
      parcelNumber?: string | null,
      trackID?: string | null,
      suggest_splitting?: boolean | null,
      overrule_splitting_user?: string | null,
      labelData?:  {
        __typename: "LabelData",
        base64?: string | null,
        format?: LabelFormat | null,
      } | null,
      updatedAt?: string | null,
      createdAt: string,
    } | null,
    createdAt: string,
    orderProductsId?: string | null,
    tourProductsId?: string | null,
    productShipmentId?: string | null,
    productRetoure_shipmentId?: string | null,
  } | null,
};

export type OnCreateShipmentSubscriptionVariables = {
  filter?: ModelSubscriptionShipmentFilterInput | null,
};

export type OnCreateShipmentSubscription = {
  onCreateShipment?:  {
    __typename: "Shipment",
    id: string,
    typename?: string | null,
    length: number,
    width: number,
    height: number,
    gurtMass: number,
    weight: number,
    serviceProvider: string,
    costs: number,
    calculatedCosts?: number | null,
    shippedAt?: string | null,
    edited?: string | null,
    parcelNumber?: string | null,
    trackID?: string | null,
    suggest_splitting?: boolean | null,
    overrule_splitting_user?: string | null,
    labelData?:  {
      __typename: "LabelData",
      base64?: string | null,
      format?: LabelFormat | null,
    } | null,
    updatedAt?: string | null,
    createdAt: string,
  } | null,
};

export type OnUpdateShipmentSubscriptionVariables = {
  filter?: ModelSubscriptionShipmentFilterInput | null,
};

export type OnUpdateShipmentSubscription = {
  onUpdateShipment?:  {
    __typename: "Shipment",
    id: string,
    typename?: string | null,
    length: number,
    width: number,
    height: number,
    gurtMass: number,
    weight: number,
    serviceProvider: string,
    costs: number,
    calculatedCosts?: number | null,
    shippedAt?: string | null,
    edited?: string | null,
    parcelNumber?: string | null,
    trackID?: string | null,
    suggest_splitting?: boolean | null,
    overrule_splitting_user?: string | null,
    labelData?:  {
      __typename: "LabelData",
      base64?: string | null,
      format?: LabelFormat | null,
    } | null,
    updatedAt?: string | null,
    createdAt: string,
  } | null,
};

export type OnDeleteShipmentSubscriptionVariables = {
  filter?: ModelSubscriptionShipmentFilterInput | null,
};

export type OnDeleteShipmentSubscription = {
  onDeleteShipment?:  {
    __typename: "Shipment",
    id: string,
    typename?: string | null,
    length: number,
    width: number,
    height: number,
    gurtMass: number,
    weight: number,
    serviceProvider: string,
    costs: number,
    calculatedCosts?: number | null,
    shippedAt?: string | null,
    edited?: string | null,
    parcelNumber?: string | null,
    trackID?: string | null,
    suggest_splitting?: boolean | null,
    overrule_splitting_user?: string | null,
    labelData?:  {
      __typename: "LabelData",
      base64?: string | null,
      format?: LabelFormat | null,
    } | null,
    updatedAt?: string | null,
    createdAt: string,
  } | null,
};

export type OnCreateTourSubscriptionVariables = {
  filter?: ModelSubscriptionTourFilterInput | null,
};

export type OnCreateTourSubscription = {
  onCreateTour?:  {
    __typename: "Tour",
    id: string,
    typename?: string | null,
    date: string,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        position?: number | null,
        typename?: string | null,
        name: string,
        sku?: string | null,
        product_id?: string | null,
        variation_id?: string | null,
        quantity?: number | null,
        length?: number | null,
        width?: number | null,
        height?: number | null,
        area?: number | null,
        weight?: string | null,
        categories?: Array< string | null > | null,
        variations?: Array< string | null > | null,
        options?: Array< string | null > | null,
        extraOptions?:  Array< {
          __typename: "Option",
          name?: string | null,
          value: string,
          imagesp?: string | null,
          price?: number | null,
        } | null > | null,
        tasks?: Array< string | null > | null,
        currentTask?: string | null,
        contractor_currentTask?: string | null,
        productContractorId?: string | null,
        contractor?:  {
          __typename: "Contractor",
          id: string,
          number: string,
          name: string,
          billing_rate?: number | null,
          billing_max?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        status?: ProductStatus | null,
        contractor_status: string,
        shipping_label_printed?: boolean | null,
        orderNumber?: string | null,
        order?:  {
          __typename: "Order",
          id: string,
          typename?: string | null,
          orderNumber: string,
          date_created: string,
          date_paid: string,
          total?: number | null,
          cart_tax?: number | null,
          total_tax?: number | null,
          discount_total?: number | null,
          discount_tax?: number | null,
          shipping_total?: number | null,
          shipping_tax?: number | null,
          weight?: number | null,
          shipping_method?: string | null,
          delivery_date: string,
          status?: OrderStatus | null,
          state?: OrderState | null,
          source: OrderSource,
          in_progressAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          retoure_shipping_costs?: number | null,
          createdAt: string,
        } | null,
        productOrderId: string,
        backoffice_note?: string | null,
        total_before_discount?: number | null,
        total_net_price?: number | null,
        total_tax?: number | null,
        nipsild_fixed_costs?: number | null,
        delivery_time?: string | null,
        delivery_date: string,
        shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        tour?:  {
          __typename: "Tour",
          id: string,
          typename?: string | null,
          date: string,
          deliveryNotificationSentTo?: Array< string | null > | null,
          info?: string | null,
          driver?: string | null,
          calculated_shipping_costs?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        declinedTourDates?: Array< string | null > | null,
        billed_amount?: number | null,
        in_progressAt?: string | null,
        ready_to_packAt?: string | null,
        finishedAt?: string | null,
        updatedAt?: string | null,
        billedAt?: string | null,
        recievedReturnAt?: string | null,
        reklaOrderNumber?: string | null,
        retoure_type?: RetoureType | null,
        retoure_reason?: string | null,
        retoure_manufacturing_fee?: number | null,
        retoure_refund_amount?: number | null,
        retoure_refund_contractors_share?: number | null,
        retoure_shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        createdAt: string,
        orderProductsId?: string | null,
        tourProductsId?: string | null,
        productShipmentId?: string | null,
        productRetoure_shipmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deliveryNotificationSentTo?: Array< string | null > | null,
    info?: string | null,
    driver?: string | null,
    calculated_shipping_costs?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTourSubscriptionVariables = {
  filter?: ModelSubscriptionTourFilterInput | null,
};

export type OnUpdateTourSubscription = {
  onUpdateTour?:  {
    __typename: "Tour",
    id: string,
    typename?: string | null,
    date: string,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        position?: number | null,
        typename?: string | null,
        name: string,
        sku?: string | null,
        product_id?: string | null,
        variation_id?: string | null,
        quantity?: number | null,
        length?: number | null,
        width?: number | null,
        height?: number | null,
        area?: number | null,
        weight?: string | null,
        categories?: Array< string | null > | null,
        variations?: Array< string | null > | null,
        options?: Array< string | null > | null,
        extraOptions?:  Array< {
          __typename: "Option",
          name?: string | null,
          value: string,
          imagesp?: string | null,
          price?: number | null,
        } | null > | null,
        tasks?: Array< string | null > | null,
        currentTask?: string | null,
        contractor_currentTask?: string | null,
        productContractorId?: string | null,
        contractor?:  {
          __typename: "Contractor",
          id: string,
          number: string,
          name: string,
          billing_rate?: number | null,
          billing_max?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        status?: ProductStatus | null,
        contractor_status: string,
        shipping_label_printed?: boolean | null,
        orderNumber?: string | null,
        order?:  {
          __typename: "Order",
          id: string,
          typename?: string | null,
          orderNumber: string,
          date_created: string,
          date_paid: string,
          total?: number | null,
          cart_tax?: number | null,
          total_tax?: number | null,
          discount_total?: number | null,
          discount_tax?: number | null,
          shipping_total?: number | null,
          shipping_tax?: number | null,
          weight?: number | null,
          shipping_method?: string | null,
          delivery_date: string,
          status?: OrderStatus | null,
          state?: OrderState | null,
          source: OrderSource,
          in_progressAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          retoure_shipping_costs?: number | null,
          createdAt: string,
        } | null,
        productOrderId: string,
        backoffice_note?: string | null,
        total_before_discount?: number | null,
        total_net_price?: number | null,
        total_tax?: number | null,
        nipsild_fixed_costs?: number | null,
        delivery_time?: string | null,
        delivery_date: string,
        shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        tour?:  {
          __typename: "Tour",
          id: string,
          typename?: string | null,
          date: string,
          deliveryNotificationSentTo?: Array< string | null > | null,
          info?: string | null,
          driver?: string | null,
          calculated_shipping_costs?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        declinedTourDates?: Array< string | null > | null,
        billed_amount?: number | null,
        in_progressAt?: string | null,
        ready_to_packAt?: string | null,
        finishedAt?: string | null,
        updatedAt?: string | null,
        billedAt?: string | null,
        recievedReturnAt?: string | null,
        reklaOrderNumber?: string | null,
        retoure_type?: RetoureType | null,
        retoure_reason?: string | null,
        retoure_manufacturing_fee?: number | null,
        retoure_refund_amount?: number | null,
        retoure_refund_contractors_share?: number | null,
        retoure_shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        createdAt: string,
        orderProductsId?: string | null,
        tourProductsId?: string | null,
        productShipmentId?: string | null,
        productRetoure_shipmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deliveryNotificationSentTo?: Array< string | null > | null,
    info?: string | null,
    driver?: string | null,
    calculated_shipping_costs?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTourSubscriptionVariables = {
  filter?: ModelSubscriptionTourFilterInput | null,
};

export type OnDeleteTourSubscription = {
  onDeleteTour?:  {
    __typename: "Tour",
    id: string,
    typename?: string | null,
    date: string,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        position?: number | null,
        typename?: string | null,
        name: string,
        sku?: string | null,
        product_id?: string | null,
        variation_id?: string | null,
        quantity?: number | null,
        length?: number | null,
        width?: number | null,
        height?: number | null,
        area?: number | null,
        weight?: string | null,
        categories?: Array< string | null > | null,
        variations?: Array< string | null > | null,
        options?: Array< string | null > | null,
        extraOptions?:  Array< {
          __typename: "Option",
          name?: string | null,
          value: string,
          imagesp?: string | null,
          price?: number | null,
        } | null > | null,
        tasks?: Array< string | null > | null,
        currentTask?: string | null,
        contractor_currentTask?: string | null,
        productContractorId?: string | null,
        contractor?:  {
          __typename: "Contractor",
          id: string,
          number: string,
          name: string,
          billing_rate?: number | null,
          billing_max?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        status?: ProductStatus | null,
        contractor_status: string,
        shipping_label_printed?: boolean | null,
        orderNumber?: string | null,
        order?:  {
          __typename: "Order",
          id: string,
          typename?: string | null,
          orderNumber: string,
          date_created: string,
          date_paid: string,
          total?: number | null,
          cart_tax?: number | null,
          total_tax?: number | null,
          discount_total?: number | null,
          discount_tax?: number | null,
          shipping_total?: number | null,
          shipping_tax?: number | null,
          weight?: number | null,
          shipping_method?: string | null,
          delivery_date: string,
          status?: OrderStatus | null,
          state?: OrderState | null,
          source: OrderSource,
          in_progressAt?: string | null,
          finishedAt?: string | null,
          updatedAt?: string | null,
          retoure_shipping_costs?: number | null,
          createdAt: string,
        } | null,
        productOrderId: string,
        backoffice_note?: string | null,
        total_before_discount?: number | null,
        total_net_price?: number | null,
        total_tax?: number | null,
        nipsild_fixed_costs?: number | null,
        delivery_time?: string | null,
        delivery_date: string,
        shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        tour?:  {
          __typename: "Tour",
          id: string,
          typename?: string | null,
          date: string,
          deliveryNotificationSentTo?: Array< string | null > | null,
          info?: string | null,
          driver?: string | null,
          calculated_shipping_costs?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        declinedTourDates?: Array< string | null > | null,
        billed_amount?: number | null,
        in_progressAt?: string | null,
        ready_to_packAt?: string | null,
        finishedAt?: string | null,
        updatedAt?: string | null,
        billedAt?: string | null,
        recievedReturnAt?: string | null,
        reklaOrderNumber?: string | null,
        retoure_type?: RetoureType | null,
        retoure_reason?: string | null,
        retoure_manufacturing_fee?: number | null,
        retoure_refund_amount?: number | null,
        retoure_refund_contractors_share?: number | null,
        retoure_shipment?:  {
          __typename: "Shipment",
          id: string,
          typename?: string | null,
          length: number,
          width: number,
          height: number,
          gurtMass: number,
          weight: number,
          serviceProvider: string,
          costs: number,
          calculatedCosts?: number | null,
          shippedAt?: string | null,
          edited?: string | null,
          parcelNumber?: string | null,
          trackID?: string | null,
          suggest_splitting?: boolean | null,
          overrule_splitting_user?: string | null,
          updatedAt?: string | null,
          createdAt: string,
        } | null,
        createdAt: string,
        orderProductsId?: string | null,
        tourProductsId?: string | null,
        productShipmentId?: string | null,
        productRetoure_shipmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    deliveryNotificationSentTo?: Array< string | null > | null,
    info?: string | null,
    driver?: string | null,
    calculated_shipping_costs?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateContractorSubscriptionVariables = {
  filter?: ModelSubscriptionContractorFilterInput | null,
};

export type OnCreateContractorSubscription = {
  onCreateContractor?:  {
    __typename: "Contractor",
    id: string,
    number: string,
    name: string,
    billing_rate?: number | null,
    billing_max?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateContractorSubscriptionVariables = {
  filter?: ModelSubscriptionContractorFilterInput | null,
};

export type OnUpdateContractorSubscription = {
  onUpdateContractor?:  {
    __typename: "Contractor",
    id: string,
    number: string,
    name: string,
    billing_rate?: number | null,
    billing_max?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteContractorSubscriptionVariables = {
  filter?: ModelSubscriptionContractorFilterInput | null,
};

export type OnDeleteContractorSubscription = {
  onDeleteContractor?:  {
    __typename: "Contractor",
    id: string,
    number: string,
    name: string,
    billing_rate?: number | null,
    billing_max?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
